import http from "@web-app/utils/http";

export const uploadAvatar = async (payload: FormData) => {
  return http
    .post<string>("/avatar/upload", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};

export const uploadSpreadsheet = async (payload: FormData) => {
  return http
    .post<string>("/sheet/upload", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => response.data);
};
