import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import clsx from "clsx";
import { Toaster } from "react-hot-toast";

import { useSavedAddresses } from "@web-app/api";
import type { Icons } from "@web-app/components";
import { Icon } from "@web-app/components";
import { User } from "@web-app/types";

import Navigation from "../common/Navigation";

import AddressBookTab from "./AddressBookTab";
import AppsTab from "./AppsTab";
import CustomFieldsTab from "./CustomFieldsTab";
import NotificationTab from "./NotificationTab";
// import OutpostsTab from "./OutpostsTab"; TODO: Add outposts tab
import ProfileTab from "./ProfileTab";
import PublicOrderFormTab from "./PublicOrderFormTab";
import UsersTab from "./UsersTab";
import WorkspaceTab from "./WorkspaceTab";

export default function Settings({
  currentUser,
  logout,
}: // outposts,
// refetchOutposts,
{
  currentUser: User;
  logout: () => void;
  // outposts?: Outpost[];
  // refetchOutposts?: () => void;
}) {
  const { data: savedAddressesData, isLoading: isAddressFetchingLoading } =
    useSavedAddresses(currentUser.currentWorkspace.id);

  return (
    <div>
      <title>FULFLLD | Cloud Delivery Management</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <link
        rel="icon"
        href="https://cdn-assets-app.fulflld.com/favicon.png"
        type="image/png"
      />
      <style>{"html { overflow: hidden; }"}</style>
      <Navigation logout={logout} />
      <Toaster position="top-center" />
      <div className="ml-20 flex">
        <TabGroup className="flex w-full">
          <TabList
            className="flex h-[calc(100vh)] w-[300px] flex-col gap-2 border-r p-2 bg-gray-100"
            data-testid="settings-page-sidebar"
          >
            <SidebarTab icon="user" label="My Profile" />
            <SidebarTab icon="globe" label="Workspace" />
            <SidebarTab icon="users" label="Users" />
            {/* <SidebarTab icon="wareHouse" label="Outposts" /> */}
            <SidebarTab icon="notificationBell" label="Notification" />
            <SidebarTab icon="apps" label="Apps" />
            <SidebarTab icon="form" label="Customers Order Form" />
            <SidebarTab icon="addressBook" label="Address Book" />
            <SidebarTab icon="penField" label="Custom Fields" />
          </TabList>
          <TabPanels className="max-h-screen relative w-full overflow-y-auto">
            <TabPanel>
              <ProfileTab user={currentUser} />
            </TabPanel>
            <TabPanel>
              <WorkspaceTab workspace={currentUser.currentWorkspace} />
            </TabPanel>
            <TabPanel>
              <UsersTab workspace={currentUser.currentWorkspace} />
            </TabPanel>
            {/* <TabPanel> */}
            {/*   <OutpostsTab */}
            {/*     outposts={outposts} */}
            {/*     refetchOutposts={refetchOutposts} */}
            {/*   /> */}
            {/* </TabPanel> */}
            <TabPanel>
              <NotificationTab workspace={currentUser.currentWorkspace} />
            </TabPanel>
            <TabPanel>
              <AppsTab workspace={currentUser.currentWorkspace} />
            </TabPanel>
            <TabPanel>
              <PublicOrderFormTab workspace={currentUser.currentWorkspace} />
            </TabPanel>
            <TabPanel>
              <AddressBookTab
                addresses={savedAddressesData}
                isLoading={isAddressFetchingLoading}
                currentWorkspaceId={currentUser.currentWorkspace.id}
              />
            </TabPanel>
            <TabPanel>
              <CustomFieldsTab workspaceId={currentUser.currentWorkspace.id} />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  );
}

function SidebarTab({
  icon,
  label,
}: {
  icon: keyof typeof Icons;
  label: string;
}) {
  return (
    <Tab
      className={({ selected }) =>
        clsx(
          "flex items-center justify-start rounded-md p-3 px-6 text-sm font-semibold hover:bg-gray-200 focus:outline-none transition ease-in hover:text-gray-700",
          selected && "text-gray-800",
          !selected && "text-gray-500",
        )
      }
      data-testid={`settings-page-sidebar-${label.toLowerCase()}-tab`}
    >
      <Icon name={icon} className="mr-3" />
      {label}
    </Tab>
  );
}
