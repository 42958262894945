import clsx from "clsx";
import { format } from "date-fns";

import { Icon } from "@web-app/components";
import { OrderDeliveryTypeText, OrderTimeline } from "@web-app/types";
import {
  EntityPresentationType,
  OrderTimelineSchema,
} from "@web-app/utils/helpers";

type ScheduleTimeActivityPropTypes = {
  item: OrderTimeline;
  showTimelineBar: boolean;
  deliveryType: OrderDeliveryTypeText;
};

export default function ScheduleTimeActivity({
  item,
  showTimelineBar,
  deliveryType,
}: ScheduleTimeActivityPropTypes) {
  const ScheduledTimeActivityIcon =
    OrderTimelineSchema[item.entity].action[item.action].icon;
  const scheduledTimeActivityColor =
    OrderTimelineSchema[item.entity].action[item.action].color;

  return (
    <li key={item.id} className="relative flex gap-x-4">
      <div
        className={clsx(
          showTimelineBar ? "-bottom-6" : "h-6",
          "absolute left-0 top-0 flex w-6 justify-center",
        )}
      >
        <div className="w-px bg-gray-200" />
      </div>
      <div className="relative flex h-7 w-6 flex-none items-center justify-center bg-white">
        <Icon
          name={ScheduledTimeActivityIcon}
          className={clsx("h-4", scheduledTimeActivityColor)}
        />
      </div>
      <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
        <span
          className={clsx(
            "inline-block font-medium text-gray-900",
            scheduledTimeActivityColor,
          )}
        >
          {deliveryType === "Individual"
            ? `${OrderTimelineSchema[item.entity].action[item.action].text} ${
                // @ts-ignore typed any
                OrderTimelineSchema[item.entity].entityPresentation[
                  "individual"
                ] as Partial<EntityPresentationType>
              }
                      ${
                        item.previousValue &&
                        item.updatedValue &&
                        ` from ${format(
                          new Date(item.previousValue),
                          "MM-dd-yyyy' 'HH:mm",
                        )} to ${format(
                          new Date(item.updatedValue),
                          "MM-dd-yyyy' 'HH:mm",
                        )}`
                      }
                      `
            : deliveryType === "Route based drop-off"
              ? `${OrderTimelineSchema[item.entity].action[item.action].text} ${
                  // @ts-ignore typed any
                  OrderTimelineSchema[item.entity].entityPresentation[
                    "dropoff"
                  ] as Partial<EntityPresentationType>
                }${
                  item.previousValue &&
                  item.updatedValue &&
                  ` from ${format(
                    new Date(item.previousValue),
                    "MM-dd-yyyy' 'HH:mm",
                  )} to ${format(
                    new Date(item.updatedValue),
                    "MM-dd-yyyy' 'HH:mm",
                  )}`
                }`
              : deliveryType === "Route based pickup"
                ? `${OrderTimelineSchema[item.entity].action[item.action].text} ${
                    // @ts-ignore typed any
                    OrderTimelineSchema[item.entity].entityPresentation[
                      "pickup"
                    ] as Partial<EntityPresentationType>
                  }
                        ${
                          item.previousValue &&
                          item.updatedValue &&
                          ` from ${format(
                            new Date(item.previousValue),
                            "MM/dd/yyyy' 'hh:mm aa",
                          )} to ${format(
                            new Date(item.updatedValue),
                            "MM/dd/yyyy' 'hh:mm aa",
                          )}`
                        }`
                : ""}
        </span>
        <span className="block">
          {`${format(new Date(item?.createdAt), "MMMM dd, yyyy, hh:mma")} by ${
            item.createdBy?.name
          }`}
        </span>
      </p>
    </li>
  );
}
