import { Icons } from "@web-app/components";
import { IconDefinitionType, IconsType } from "@web-app/components/Icon/utils";
import type { OrderTimeline } from "@web-app/types";
import { ORDER_STATUS_COLORS } from "@web-app/utils/order";

export const TrackingOrderTimelineSchema: {
  [key: string]: {
    text: string;
    color: string;
    icon: IconsType;
  };
} = {
  PICKEDUP: {
    text: "Driver picked up your order",
    color: ORDER_STATUS_COLORS["INPROGRESS"].text,
    icon: "boxesPacking",
  },
  COMPLETE: {
    text: "Driver completed the order",
    color: ORDER_STATUS_COLORS["COMPLETE"].text,
    icon: "clipboardCheck",
  },
};

export const generateStatusBasedOnTimelineActivity = (
  activity: OrderTimeline,
): keyof typeof TrackingOrderTimelineSchema | null => {
  if (activity.entity === "actualPickupDateTime") {
    return "PICKEDUP";
  }
  if (activity.entity === "status") {
    if (activity.updatedValue === "COMPLETE") {
      return "COMPLETE";
    }
  }

  return null;
};

export type EntityPresentationType = {
  individual: string;
  pickup: string;
  dropoff: string;
};

export const OrderTimelineSchemaV2: {
  [key: string]: {
    entityPresentation: string | EntityPresentationType;
    action: {
      [key: string]: {
        text: string;
        color: string;
        icon: IconDefinitionType;
      };
    };
  };
} = {
  assigneeId: {
    entityPresentation: "Assignee",
    action: {
      UPDATE: {
        text: "Assigned order to",
        color: "text-black",
        icon: Icons["user"],
      },
      DELETE: {
        text: "Unassigned order",
        color: "text-black",
        icon: Icons["user"],
      },
    },
  },
  connectedWorkspace: {
    entityPresentation: "Connected Workspace",
    action: {
      UPDATE: {
        text: "Connected to ",
        color: "text-black",
        icon: Icons["earchAmericas"],
      },
      DELETE: {
        text: "Disconnected from ",
        color: "text-black",
        icon: Icons["earchAmericas"],
      },
    },
  },
  attachments: {
    entityPresentation: "Attachments",
    action: {
      CREATE: {
        text: "Added",
        color: "text-green-600",
        icon: Icons["fileImage"],
      },
      DELETE: {
        text: "Removed",
        color: "text-red-600",
        icon: Icons["fileImage"],
      },
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["fileImage"],
      },
    },
  },
  order: {
    entityPresentation: "order",
    action: {
      CREATE: {
        text: "Created",
        color: "text-black",
        icon: Icons["circleExclamation"],
      },
      DELETE: {
        text: "Deleted",
        color: "text-red-600",
        icon: Icons["circleExclamation"],
      },
    },
  },
  status: {
    entityPresentation: "status",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["locationDot"],
      },
    },
  },
  statusReason: {
    entityPresentation: "status reason",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["notes"],
      },
    },
  },
  statusNotes: {
    entityPresentation: "status notes",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["notes"],
      },
    },
  },
  priority: {
    entityPresentation: "priority",
    action: {
      UPDATE: {
        text: "Changed",
        color: "text-black",
        icon: Icons["flagSwallowtail"],
      },
    },
  },
  "pickup:name": {
    entityPresentation: "full name",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["font"],
      },
    },
  },
  "dropoff:name": {
    entityPresentation: "recipient name",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["font"],
      },
    },
  },
  "dropoff:businessName": {
    entityPresentation: "recipient business name",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["font"],
      },
    },
  },
  "dropoff:phone": {
    entityPresentation: "recipient phone number",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["mobile"],
      },
    },
  },
  "dropoff:note": {
    entityPresentation: "recipient note",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["notes"],
      },
    },
  },
  "pickup:businessName": {
    entityPresentation: "pickup business name",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["font"],
      },
    },
  },
  "pickup:phone": {
    entityPresentation: "pickup phone number",
    action: {
      UPDATE: {
        text: "Changed",
        color: "text-black",
        icon: Icons["mobile"],
      },
    },
  },
  scheduledStartDateTime: {
    entityPresentation: {
      individual: "pickup at time",
      pickup: "pickup start time",
      dropoff: "drop-off start time",
    },
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["clock"],
      },
    },
  },
  scheduledEndDateTime: {
    entityPresentation: {
      individual: "drop-off at time",
      pickup: "pickup end time",
      dropoff: "drop-off end time",
    },
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["clock"],
      },
    },
  },
  serviceDuration: {
    entityPresentation: "service time",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["clock"],
      },
    },
  },
  "requirements:signature": {
    entityPresentation: "customer requirement signature",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["signature"],
      },
    },
  },
  // same pattern from line 190-198 for customerRequirementNotes and customerRequirementPhoto
  "requirements:note": {
    entityPresentation: "customer requirement notes",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["notes"],
      },
    },
  },
  "requirements:photo": {
    entityPresentation: "customer requirement photo",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["images"],
      },
    },
  },
  "pickup:note": {
    entityPresentation: "notes",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["notes"],
      },
    },
  },
  "details:description": {
    entityPresentation: "description",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["subtitles"],
      },
    },
  },
  "details:value": {
    entityPresentation: "value",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["moneyBill1"],
      },
    },
  },
  "details:tip": {
    entityPresentation: "tip",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["moneyBill1"],
      },
    },
  },
  "details:itemsCount": {
    entityPresentation: "itemsCount",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["circleExclamation"],
      },
    },
  },
  minVehicle: {
    entityPresentation: "minVehicle",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["truck"],
      },
    },
  },
  externalId: {
    entityPresentation: "external reference id",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["circleExclamation"],
      },
    },
  },
  "pickup:address": {
    entityPresentation: "pickup address",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["locationPin"],
      },
    },
  },
  "dropoff:address": {
    entityPresentation: "drop-off location",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["locationPin"],
      },
    },
  },
  geoDropoffDateTime: {
    entityPresentation: "near destination at",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["clock"],
      },
    },
  },
  geoPickupDateTime: {
    entityPresentation: "near pickup at",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["clock"],
      },
    },
  },
  actualPickupDateTime: {
    entityPresentation: "pickup at",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: Icons["clock"],
      },
    },
  },
  smsNotification: {
    entityPresentation: "smsNotification",
    action: {
      CREATE: {
        text: "Sms sent for",
        color: "text-black",
        icon: Icons["circleExclamation"],
      },
    },
  },
};
