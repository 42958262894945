import {
  OrdersOrder,
  Route,
  assignRoutePayloadType,
  createRoutePayloadType,
  saveRoutePayloadType,
} from "@web-app/types";
import http from "@web-app/utils/http";

const create = async (payload: createRoutePayloadType) => {
  return http.post<Route>("/routes", payload).then((response) => response.data);
};

const save = async (payload: saveRoutePayloadType) => {
  return http
    .post<Route>(`/routes/${payload.routeId}/save`, payload)
    .then((response) => response.data);
};

const assign = async (payload: assignRoutePayloadType) => {
  return http
    .post<Route>(`/routes/${payload.routeId}/assign`, payload)
    .then((response) => response.data);
};

const remove = async (routeId: string) => {
  return http.delete(`/routes/${routeId}`);
};

const regenerate = async (payload: {
  routeId: string;
  ordersOrder: OrdersOrder[];
}) => {
  return http
    .post<Route>(`/routes/${payload.routeId}/regenerate`, payload)
    .then((response) => response.data);
};

export { assign, create, regenerate, remove, save };
