import type { PlaceAutocompleteResult } from "@googlemaps/google-maps-services-js";
import { useMemo } from "react";
import { z } from "zod";

import {
  Accordion,
  CustomFields,
  DateInput,
  GoogleMapAutoComplete,
  Input,
  OrderAttachments,
  SelectAssignee,
  SelectInput,
  TextArea,
  URLField,
} from "@web-app/components";
import config from "@web-app/config";
import { Order } from "@web-app/types";
import { OrderDeliveryType } from "@web-app/types/orders";
import {
  getOrderCompletionTime,
  getPolyLineDistance,
  getPropertyLabelFromList,
} from "@web-app/utils/helpers";

import {
  priorityOptions,
  statusOptions,
  statusOptionsForIndividualOrder,
  vehicleOptions,
} from "../data";

import ChecklistForm from "./ChecklistForm";

type OrderDetailsAndEditPropsType = {
  order: Order;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  debouncedOnChange: (changedValue: { property: string; value: any }[]) => void;
  onChangeDestination: (
    des: PlaceAutocompleteResult | null,
    type: "pickup" | "destination",
  ) => void;
  isReadOnly?: boolean;
};

const pickupChecklistSchema = z.object({
  pickupChecklist: z
    .array(
      z.object({
        isChecked: z.boolean(),
        isMandatory: z.boolean(),
        label: z.string().min(1, "Item name can't be empty"),
        quantity: z.coerce
          .number()
          .gte(1, "Must be 1 or above")
          .lt(1000, "Must not exceed 999"),
      }),
    )
    .optional(),
});
const dropoffChecklistSchema = z.object({
  dropoffChecklist: z
    .array(
      z.object({
        isChecked: z.boolean(),
        isMandatory: z.boolean(),
        label: z.string().min(1, "Item name can't be empty"),
        quantity: z.coerce
          .number()
          .gte(1, "Must be 1 or above")
          .lt(1000, "Must not exceed 999"),
      }),
    )
    .optional(),
});

export default function OrderDetailsAndEdit({
  order,
  debouncedOnChange,
  onChangeDestination,
  isReadOnly: isReadOnlyProps,
}: OrderDetailsAndEditPropsType) {
  const includesPickup = order.type.includes("PICKUP");
  const includesDropoff = order.type.includes("DROPOFF");

  const isIndividualDelivery = order.type === "INDIVIDUAL_PICKUP_AND_DROPOFF";
  const orderCompletionTime = getOrderCompletionTime(order);

  const hasExternalAssignee = order.externalAssigneeId;
  const readOnlyLabel = hasExternalAssignee
    ? hasExternalAssignee.startsWith("uber")
      ? "Uber"
      : "Doordash"
    : "";

  const isReadOnlyStatus = ["COMPLETE", "FAILED", "CANCELLED"].includes(
    order.status,
  );
  const isReadOnly = isReadOnlyProps || isReadOnlyStatus;

  const routeDistance = useMemo(() => {
    return order.routePolyline ? getPolyLineDistance(order.routePolyline) : "";
  }, [order]);

  return (
    <div className="">
      <div className="grid h-full grid-cols-12 gap-6">
        <div className="col-span-6 flex items-start">
          <SelectInput
            selected={
              order.status === "TODO" && order.assignee
                ? "ASSIGNED"
                : isIndividualDelivery && order.status === "INPROGRESS"
                  ? `${order.status}_${order?.statusProgress || "PICKUP"}`
                  : order.status
            }
            options={
              isIndividualDelivery
                ? statusOptionsForIndividualOrder
                : statusOptions
            }
            label="Status"
            onChange={(value) => {
              const [status, statusProgress] = value.split("_");

              debouncedOnChange(
                statusProgress
                  ? [
                      { property: "status", value: status },
                      { property: "statusProgress", value: statusProgress },
                    ]
                  : [{ property: "status", value }],
              );
            }}
            placeholder="Select Order Status"
            required
            readOnly={isReadOnlyProps}
            defaultValue={getPropertyLabelFromList(
              order.status === "TODO" && order.assignee
                ? "ASSIGNED"
                : order.status,
              isIndividualDelivery
                ? statusOptionsForIndividualOrder
                : statusOptions,
            )}
          />
        </div>
        <div className="col-span-6 flex items-start">
          <SelectInput
            selected={order.priority}
            options={priorityOptions}
            label="Priority"
            onChange={(value) => {
              debouncedOnChange([{ property: "priority", value }]);
            }}
            placeholder="Change Priority"
            required
            readOnly={isReadOnly}
            defaultValue={getPropertyLabelFromList(
              order.priority,
              priorityOptions,
            )}
          />
        </div>
        <div className="col-span-12 flex items-start">
          <SelectAssignee
            label="Assignee"
            selectedId={order.assigneeId || ""}
            onChange={(selected) => {
              debouncedOnChange([{ property: "assigneeId", value: selected }]);
            }}
            readOnly={isReadOnly || !!hasExternalAssignee}
            readOnlyLabel={readOnlyLabel}
          />
        </div>
        {includesPickup && isReadOnly && (
          <div className="col-span-12 flex flex-col gap-1">
            <h4 className="mb-1 block text-xs font-semibold tracking-wide text-gray-700">
              Pickup Location
            </h4>
            <div className="mt-0 p-0">
              <p className="text-xs text-gray-500">{order.pickup.name}</p>
              <p className="text-xs text-gray-500">{order.pickup.address}</p>
              <p className="text-xs text-gray-500">{order.pickup.phone}</p>
            </div>
          </div>
        )}
        {includesPickup && !isReadOnly && (
          <>
            <div className="col-span-12 flex items-start">
              <Input
                defaultValue={order.pickup.name}
                label="Pickup Name"
                type="text"
                onChange={(e) => {
                  debouncedOnChange([
                    { property: "pickup.name", value: e.target.value },
                  ]);
                }}
                readOnly={isReadOnly}
              />
            </div>

            <div className="col-span-12 flex items-start">
              <Input
                defaultValue={order.pickup.businessName}
                label="Pickup Business Name"
                type="text"
                onChange={(e) => {
                  debouncedOnChange([
                    { property: "pickup.businessName", value: e.target.value },
                  ]);
                }}
                readOnly={isReadOnly}
              />
            </div>
            <div className="col-span-12 flex items-start">
              <GoogleMapAutoComplete
                // @ts-ignore - address is not a required field
                address={{
                  description: order.pickup.address || "",
                }}
                setAddress={(newAddress) =>
                  // @ts-ignore - address is not a required field
                  onChangeDestination(newAddress, "pickup")
                }
                label="Pickup Address"
                display="inline"
                readOnly={isReadOnly}
              />
            </div>
            <div className="col-span-12 flex items-start">
              <Input
                label="Phone Number"
                type="text"
                defaultValue={order?.pickup.phone}
                onChange={(e) =>
                  debouncedOnChange([
                    { property: "pickup.phone", value: e.target.value },
                  ])
                }
                readOnly={isReadOnly}
              />
            </div>
          </>
        )}

        {includesDropoff && isReadOnly && (
          <div className="col-span-12 flex flex-col gap-1">
            <h4 className="mb-1 block text-xs font-semibold tracking-wide text-gray-700">
              Dropoff Location
            </h4>
            <div className="mt-0 p-0">
              <p className="text-xs text-gray-500">{order.dropoff.name}</p>
              <p className="text-xs text-gray-500">{order.dropoff.address}</p>
              <p className="text-xs text-gray-500">{order.dropoff.phone}</p>
            </div>
          </div>
        )}
        {includesDropoff && !isReadOnly && (
          <>
            <div className="col-span-12 flex items-start">
              <Input
                defaultValue={order.dropoff.name}
                label="Recipient Name"
                type="text"
                onChange={(e) => {
                  debouncedOnChange([
                    { property: "dropoff.name", value: e.target.value },
                  ]);
                }}
                readOnly={isReadOnly}
              />
            </div>

            <div className="col-span-12 flex items-start">
              <GoogleMapAutoComplete
                // @ts-ignore - address is not a required field
                address={{
                  description: order.dropoff.address || "",
                }}
                setAddress={(newAddress) =>
                  // @ts-ignore - address is not a required field
                  onChangeDestination(newAddress, "destination")
                }
                label="Drop-off Location"
                display="inline"
                readOnly={isReadOnly}
              />
            </div>

            <div className="col-span-12 flex items-start">
              <Input
                defaultValue={order.dropoff.phone}
                label="Recipient Phone"
                type="text"
                onChange={(e) => {
                  debouncedOnChange([
                    { property: "dropoff.phone", value: e.target.value },
                  ]);
                }}
                readOnly={isReadOnly}
              />
            </div>
          </>
        )}

        <div className="col-span-12 flex flex-col gap-1">
          <h4 className="mb-1 block text-xs font-semibold tracking-wide text-gray-700">
            Required actions for driver
          </h4>
          <div className="flex flex-col gap-1">
            <Input
              type="checkbox"
              defaultChecked={order.dropoffRequirements.signature}
              checkboxLabel="Customer Signature"
              onChange={(e) =>
                debouncedOnChange([
                  {
                    property: "dropoffRequirements.signature",
                    value: e.target.checked,
                  },
                ])
              }
              readOnly={isReadOnly}
            />
            <Input
              type="checkbox"
              defaultChecked={order.dropoffRequirements.photo}
              checkboxLabel="Photo of Delivery"
              onChange={(e) =>
                debouncedOnChange([
                  {
                    property: "dropoffRequirements.photo",
                    value: e.target.checked,
                  },
                ])
              }
              readOnly={isReadOnly}
            />
            <Input
              type="checkbox"
              defaultChecked={order.dropoffRequirements.note}
              checkboxLabel="Add Notes"
              onChange={(e) =>
                debouncedOnChange([
                  {
                    property: "dropoffRequirements.note",
                    value: e.target.checked,
                  },
                ])
              }
              readOnly={isReadOnly}
            />
            <Input
              type="checkbox"
              defaultChecked={order.dropoffRequirements.itemChecklist}
              checkboxLabel="Item Checklist"
              onChange={(e) =>
                debouncedOnChange([
                  {
                    property: "dropoffRequirements.itemChecklist",
                    value: e.target.checked,
                  },
                ])
              }
              readOnly={isReadOnly}
            />
          </div>
        </div>

        {isIndividualDelivery && (
          <>
            <ChecklistForm
              title="Pickup Checklist"
              subtitle="The driver will verify the items in the list while picking up the order"
              schema={pickupChecklistSchema}
              checklistName="pickupChecklist"
              defaultValues={{ pickupChecklist: order.pickupChecklist }}
              debouncedOnChange={(property, value) =>
                debouncedOnChange([{ property, value }])
              }
              isReadOnly={isReadOnly}
            />
            <ChecklistForm
              title="Drop-off Checklist"
              subtitle="The driver will verify the items in the list while dropping off the order"
              schema={dropoffChecklistSchema}
              checklistName="dropoffChecklist"
              defaultValues={{ dropoffChecklist: order.dropoffChecklist }}
              debouncedOnChange={(property, value) =>
                debouncedOnChange([{ property, value }])
              }
              isReadOnly={isReadOnly}
            />
          </>
        )}

        <div className="col-span-12 flex items-start">
          <OrderAttachments
            label="Attachments"
            attachments={order?.attachments || []}
          />
        </div>
        <div className="col-span-12 flex items-start">
          <TextArea
            rows={3}
            defaultValue={order.statusDetails?.note}
            label="Driver Notes"
            readOnly={true}
          />
        </div>
        <div className="col-span-12 flex items-start">
          <URLField
            label="Tracking Link"
            url={`${config.WEB_APP_URL}/tracking/orders/${order.id}`}
          />
        </div>
        <Accordion
          defaultOpen={!!isReadOnly}
          label="Delivery Details"
          className="col-span-12"
        >
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 flex items-start">
              <DateInput
                label={
                  order.type === OrderDeliveryType.INDIVIDUAL_PICKUP_AND_DROPOFF
                    ? "Pickup At (Start Time)"
                    : order.type === OrderDeliveryType.ROUTE_OPT_PICKUP
                      ? "Pickup After (Date/Time)"
                      : "Drop-off After (Date/Time)"
                }
                value={new Date(order.scheduledStartDateTime)}
                onChange={(date) => {
                  debouncedOnChange([
                    {
                      property: "scheduledStartDateTime",
                      value: date.toISOString(),
                    },
                  ]);
                }}
                readOnly={isReadOnly}
              />
            </div>

            <div className="col-span-12 flex items-start">
              <DateInput
                label={
                  order.type === OrderDeliveryType.INDIVIDUAL_PICKUP_AND_DROPOFF
                    ? "Drop-off At (End Time)"
                    : order.type === OrderDeliveryType.ROUTE_OPT_PICKUP
                      ? "Pickup Before (Date/Time)"
                      : "Drop-off Before (Date/Time)"
                }
                value={new Date(order.scheduledEndDateTime)}
                onChange={(date) => {
                  debouncedOnChange([
                    {
                      property: "scheduledEndDateTime",
                      value: date.toISOString(),
                    },
                  ]);
                }}
                readOnly={isReadOnly}
              />
            </div>
            {includesPickup && (
              <div className="col-span-12 flex items-start">
                <TextArea
                  rows={3}
                  defaultValue={order.pickup.note}
                  label="Pickup Notes"
                  maxLength={1500}
                  onChange={(e) => {
                    debouncedOnChange([
                      { property: "pickup.note", value: e.target.value },
                    ]);
                  }}
                  readOnly={isReadOnly}
                />
              </div>
            )}

            {includesDropoff && (
              <div className="col-span-12 flex items-start">
                <TextArea
                  rows={3}
                  defaultValue={order.dropoff.note}
                  maxLength={1500}
                  label="Dropoff Notes"
                  onChange={(e) => {
                    debouncedOnChange([
                      { property: "dropoff.note", value: e.target.value },
                    ]);
                  }}
                  readOnly={isReadOnly}
                />
              </div>
            )}

            {!!order.actualStartDateTime && (
              <div className="col-span-12 flex items-start">
                <DateInput
                  label="Started At"
                  value={new Date(order.actualStartDateTime)}
                  readOnly
                />
              </div>
            )}

            {!!orderCompletionTime && (
              <div className="col-span-12 flex items-start">
                <DateInput
                  label="Completed At"
                  value={new Date(orderCompletionTime)}
                  readOnly
                />
              </div>
            )}

            <div className="col-span-12 flex items-start">
              <TextArea
                defaultValue={order.details.description}
                maxLength={1500}
                label="Description"
                onChange={(e) => {
                  debouncedOnChange([
                    { property: "details.description", value: e.target.value },
                  ]);
                }}
                readOnly={isReadOnly}
              />
            </div>

            <div className="col-span-12 flex items-start">
              <Input
                defaultValue={order.details.itemsCount}
                label="Item Count"
                type="number"
                onChange={(e) => {
                  debouncedOnChange([
                    { property: "details.itemsCount", value: +e.target.value },
                  ]);
                }}
                readOnly={isReadOnly}
              />
            </div>

            <div className="col-span-12 flex items-start">
              <Input
                type="text"
                defaultValue={order.externalId}
                label="External Reference ID"
                onChange={(e) => {
                  debouncedOnChange([
                    { property: "externalId", value: e.target.value },
                  ]);
                }}
                readOnly={isReadOnly}
              />
            </div>
            <div className="col-span-6 flex items-start">
              <Input
                defaultValue={order.details.value}
                label="Value ($)"
                type="number"
                min={0}
                onChange={(e) => {
                  debouncedOnChange([
                    { property: "details.value", value: +e.target.value },
                  ]);
                }}
                onWheel={(e) => e.currentTarget.blur()}
                readOnly={isReadOnly}
              />
            </div>

            <div className="col-span-6 flex items-start">
              <Input
                defaultValue={order.details.tip}
                label="Tip ($)"
                min={0}
                type="number"
                onChange={(e) => {
                  debouncedOnChange([
                    { property: "details.tip", value: +e.target.value },
                  ]);
                }}
                onWheel={(e) => e.currentTarget.blur()}
                readOnly={isReadOnly}
              />
            </div>
            {routeDistance && (
              <div className="col-span-6 flex items-start">
                <Input
                  defaultValue={`${routeDistance} miles`}
                  label="Distance (miles)"
                  type="string"
                  onChange={() => null}
                  disabled
                />
              </div>
            )}

            <div className="col-span-6 flex items-start">
              <SelectInput
                selected={order.minVehicle || "WALK"}
                options={vehicleOptions}
                label="Mode of Transportation"
                onChange={(value) => {
                  debouncedOnChange([{ property: "minVehicle", value }]);
                }}
                placeholder="Select mode of transportation"
                required
                readOnly={isReadOnly}
                defaultValue={getPropertyLabelFromList(
                  order.minVehicle,
                  vehicleOptions,
                )}
              />
            </div>
          </div>
        </Accordion>
        <Accordion
          defaultOpen={!!isReadOnly}
          label="Custom Fields"
          className="col-span-12"
        >
          <CustomFields
            entity="order"
            workspaceId={order.workspace.id}
            entityId={order.id}
            entityObject={order}
            isReadOnly={isReadOnly}
            onChange={(_, customField, value) => {
              const existingCustomFieldIndex = order.customFields.findIndex(
                (cf) => cf.definition.id === customField.id,
              );

              if (existingCustomFieldIndex > -1) {
                const updatedCustomFields = [...order.customFields];

                updatedCustomFields[existingCustomFieldIndex] = {
                  definition: customField,
                  values: value,
                };

                debouncedOnChange([
                  { property: "customFields", value: updatedCustomFields },
                ]);
              } else {
                const updatedCustomFields = [
                  ...order.customFields,
                  {
                    definition: customField,
                    values: value,
                  },
                ];

                debouncedOnChange([
                  { property: "customFields", value: updatedCustomFields },
                ]);
              }
            }}
          />
        </Accordion>
      </div>
    </div>
  );
}
