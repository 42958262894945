import clsx from "clsx";
import React, { ReactNode } from "react";

import { Icon } from "../Icon";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: React.ComponentProps<typeof Icon>["name"];
  appearance?: "primary" | "secondary" | "success" | "warning" | "alert";
  children?: ReactNode;
  size?: "xs" | "sm" | "md" | "lg";
  isLoading?: boolean;
}

export default function Button({
  icon,
  appearance = "primary",
  children,
  size = "sm",
  isLoading = false,
  disabled,
  onClick,
  className,
  ...props
}: ButtonProps) {
  return (
    <button
      onClick={(e) => {
        if (isLoading) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        if (onClick && !isLoading) {
          onClick(e);
        }
      }}
      disabled={disabled}
      className={clsx(
        "h-[32px] flex items-center gap-x-1.5 shadow-sm rounded-md px-2 disabled:opacity-70  font-normal transition ease-in focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-black ",
        size === "md" && "px-5 py-2.5 text-sm font-medium",
        size === "sm" && "font-small px-3 py-2 text-xs",
        size === "xs" && "font-small rounded-full px-2 py-1 text-xs",
        size === "lg" && "px-6 py-4 text-sm",
        appearance === "primary" &&
          "rounded-lg bg-black text-center text-white hover:bg-gray-700  disabled:bg-gray-500 disabled:hover:bg-gray-500 dark:bg-blue-600",
        appearance === "secondary" &&
          "rounded-lg bg-white text-gray-700 hover:bg-gray-100 hover:text-gray-700 border border-gray-300 disabled:appearance-none disabled:bg-gray-100 disabled:text-gray-300 disabled:opacity-90 disabled:hover:text-gray-300 ",
        appearance === "success" &&
          "rounded-lg bg-green-600 text-white hover:bg-green-700 disabled:bg-green-300 disabled:hover:bg-green-300",
        appearance === "warning" &&
          "rounded-lg bg-yellow-600 text-white hover:bg-yellow-700 disabled:bg-yellow-300 disabled:hover:bg-yellow-300",
        appearance === "alert" &&
          "rounded-lg bg-red-600 text-white hover:bg-red-700 disabled:bg-red-300 disabled:hover:bg-red-300",
        isLoading && "cursor-wait opacity-90",
        isLoading && appearance === "primary" && "bg-gray-700",
        isLoading &&
          appearance === "secondary" &&
          "bg-gray-100 hover:bg-gray-100",
        isLoading && appearance === "success" && "bg-green-700",
        isLoading && appearance === "warning" && "bg-yellow-700",
        isLoading && appearance === "alert" && "bg-red-700",
        className,
      )}
      {...props}
    >
      {isLoading && (
        <svg
          className="-ml-1 mr-2 h-5 w-5 animate-spin text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke={
              appearance === "secondary" ? "rgb(0, 0, 0, 0.5)" : "currentColor"
            }
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {icon && <Icon name={icon} className="h-3 w-3" />}
      {children}
    </button>
  );
}
