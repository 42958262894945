import { Route } from "@tanstack/react-router";

import { App } from "./apps";
import { Attachment } from "./attachments";
import { CustomField, CustomFieldValue } from "./customFields";
import { NotificationEvent } from "./notificationEvents";
import { OrderTimeline } from "./orderTimelines";
import { User } from "./users";
import { Workspace } from "./workspaces";

export enum OrderPriorityType {
  HIGH = "HIGH",
  LOW = "LOW",
}

export type LocationDetails = {
  name: string;
  phone: string;
  note: string;
  businessName: string;
  address: string;
};

export type ETA = {
  progress: number;
  updateCount: number;
  dateTime: Date | null;
};

export enum OrderDeliveryType {
  ROUTE_OPT_PICKUP = "ROUTE_OPT_PICKUP",
  ROUTE_OPT_DROPOFF = "ROUTE_OPT_DROPOFF",
  INDIVIDUAL_PICKUP = "INDIVIDUAL_PICKUP",
  INDIVIDUAL_DROPOFF = "INDIVIDUAL_DROPOFF",
  INDIVIDUAL_PICKUP_AND_DROPOFF = "INDIVIDUAL_PICKUP_AND_DROPOFF",
}

export type OrderRequirements = {
  signature: boolean;
  photo: boolean;
  note: boolean;
  itemChecklist?: boolean;
};

export type ChecklistItem = {
  label: string;
  quantity: number;
  isChecked: boolean;
  isMandatory: boolean;
};

export enum OrderStatus {
  TODO = "TODO",
  ASSIGNED = "ASSIGNED",
  INPROGRESS = "INPROGRESS",
  COMPLETE = "COMPLETE",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
}

export enum OrderStatusProgress {
  PICKUP = "PICKUP",
  DROPOFF = "DROPOFF",
}

export type OrderDetails = {
  description: string;
  value: number;
  tip: number;
  currency: "USD";
  itemsCount: number;
  distanceString: string;
  serviceDuration: number;
};

export enum TransportType {
  WALK = "WALK",
  CYCLE = "CYCLE",
  BIKE = "BIKE",
  SEDAN = "SEDAN",
  ESTATE = "ESTATE",
  VAN = "VAN",
  PICK_UP = "PICK_UP",
  SUV = "SUV",
  TRUCK = "TRUCK",
  OTHER = "OTHER",
}

export type RoutePolylineType = {
  routes: Array<{
    legs: Array<{
      duration: string;
      polyline: {
        encodedPolyline: string;
      };
      steps?: Array<{
        polyline: {
          encodedPolyline: string;
        };
      }>;
    }>;
    distanceMeters: number;
    duration: string;
    polyline: {
      encodedPolyline: string;
    };
  }>;
};

export type OrderStatusText =
  | "Assigned"
  | "Unassigned"
  | "In Transit to Pickup"
  | "In Transit to Drop-off"
  | "In Transit"
  | "Completed"
  | "Cancelled"
  | "Failed"
  | "";

export type OrderDeliveryTypeText =
  | "Route based drop-off"
  | "Route based pickup"
  | "Individual"
  | "None";

export type OrderCustomField = {
  createdAt: string;
  customFieldId: string;
  customField: CustomField;
  customFieldValues: CustomFieldValue[];
  id: string;
  orderId: string;
  updatedAt: string;
  groupId: string;
  workspaceId: string;
};

export type UpdateOrderPayload = {
  id: string;
  referenceCount: number;
  priority: OrderPriorityType;
  dropoff: LocationDetails;
  pickup: LocationDetails;
  scheduledStartDateTime: Date;
  scheduledEndDateTime: Date;
  actualStartDateTime: Date | null;
  actualEndDateTime: Date | null;
  actualPickupDateTime: Date | null;
  geoDropoffDateTime: Date | null;
  geoPickupDateTime: Date | null;
  eta: ETA;
  etaOrigin: string;
  type: OrderDeliveryType;
  pickupRequirements: OrderRequirements;
  dropoffRequirements: OrderRequirements;
  pickupChecklist: Array<ChecklistItem>;
  dropoffChecklist: Array<ChecklistItem>;
  dropoffLocation: {
    type: string;
    coordinates: number[];
  };
  pickupLocation: {
    type: string;
    coordinates: number[];
  };
  status: OrderStatus;
  statusDetails: { reason: string; note: string };
  statusProgress: OrderStatusProgress | null;
  details: OrderDetails;
  externalId: string;
  sourceId: string | null;
  minVehicle: TransportType;
  routePolyline: RoutePolylineType | null;
  assignee: User | null;
  assigneeId: string | null;
  workspace: Workspace;
  connectedWorkspace: Workspace | null;
  createdBy: User;
  app: App;
  timelineEvents: OrderTimeline[];
  route: Route | null;
  attachments: Attachment[];
  notificationEvents: NotificationEvent[];
  externalAssigneeId: string | null;
  deliveryPlaceId: string | null;
  pickupPlaceId: string | null;
  displayId: string;
  statusText: OrderStatusText;
  deliveryTypeText: OrderDeliveryTypeText;
  pickupLocationLat: number;
  pickupLocationLng: number;
  dropoffLocationLat: number;
  dropoffLocationLng: number;
  trackingUrl: string;
  customFields: OrderCustomField[];
};

export type Order = {
  id: string;
  connectedWorkspaceId: string;
  referenceCount: number;
  priority: OrderPriorityType;
  dropoff: LocationDetails;
  pickup: LocationDetails;
  scheduledStartDateTime: Date;
  scheduledEndDateTime: Date;
  actualStartDateTime: Date | null;
  actualEndDateTime: Date | null;
  actualPickupDateTime: Date | null;
  geoDropoffDateTime: Date | null;
  geoPickupDateTime: Date | null;
  eta: ETA;
  etaOrigin: string;
  type: OrderDeliveryType;
  pickupRequirements: OrderRequirements;
  dropoffRequirements: OrderRequirements;
  pickupChecklist: Array<ChecklistItem>;
  driverLocation: {
    latitude: 0;
    longitude: 0;
    updatedAt: "";
  };
  dropoffChecklist: Array<ChecklistItem>;
  dropoffLocation: {
    type: string;
    coordinates: number[];
  };
  destinationAddress: string;
  pickupLocation: {
    type: string;
    coordinates: number[];
  };
  pickupAddress: string;
  status: OrderStatus;
  statusDetails: { reason: string; note: string };
  statusProgress: OrderStatusProgress | null;
  details: OrderDetails;
  externalId: string;
  sourceId: string | null;
  minVehicle: TransportType;
  routePolyline: RoutePolylineType | null;
  assignee: User | null;
  assigneeId: string;
  workspace: Workspace;
  connectedWorkspace: Workspace | null;
  createdBy: User;
  app: App;
  timelineEvents: OrderTimeline[];
  route: Route | null;
  attachments: Attachment[];
  notificationEvents: NotificationEvent[];
  externalAssigneeId: string | null;
  deliveryPlaceId: string | null;
  pickupPlaceId: string | null;
  displayId: string;
  statusText: OrderStatusText;
  deliveryTypeText: OrderDeliveryTypeText;
  pickupLocationLat: number;
  pickupLocationLng: number;
  dropoffLocationLat: number;
  dropoffLocationLng: number;
  trackingUrl: string;
  customFields: {
    values: CustomFieldValue[];
    definition: CustomField;
  }[];
  triggeringUserId: string;
};

export type CreateBulkOrdersPayload = {
  url: string;
  importType: "ROUTE" | "INDIVIDUAL";
  connectedWorkspaceId: string | null;
};

export type UpdateBulkOrdersPayload = {
  orderIds: string[];
  updatedValues: Partial<Order>;
};

export type DeleteBulkOrdersPayload = {
  orderIds: string[];
};
