import { Workspace } from "@web-app/types";

import SmsConfigForm from "./SmsConfigForm";

export default function SmsConfigTab({ workspace }: { workspace: Workspace }) {
  return (
    <>
      <h1 className="mb-6 text-sm text-gray-500">
        Send customized SMS notifications to customers.
      </h1>

      <div className="lg:w-1/2">
        <SmsConfigForm workspace={workspace} />
      </div>
    </>
  );
}
