import { z } from "zod";

import { AppFilterType } from "@web-app/store/dispatchStore";
import {
  CreateBulkOrdersPayload,
  DeleteBulkOrdersPayload,
  Order,
  UpdateBulkOrdersPayload,
} from "@web-app/types";
import http from "@web-app/utils/http";
import { GetOrderParamsType, createOrderRouteType } from "@web-app/utils/order";

export const getOrders = async (
  params: Partial<GetOrderParamsType>,
): Promise<Order[]> => {
  return http.get("/orders", { params }).then((response) => response.data);
};

export const create = (payload: z.infer<typeof createOrderRouteType>) => {
  return http.post<Order>("/orders", payload).then((response) => response.data);
};

export const createPublic = (payload: z.infer<typeof createOrderRouteType>) => {
  return http
    .post<Order>("/orders/publicForm", payload)
    .then((response) => response.data);
};

export const update = (id: string, payload: Partial<Order>) => {
  return http
    .patch<Order>(`/orders/${id}`, payload)
    .then((response) => response.data);
};

export const createBulkOrders = async (payload: CreateBulkOrdersPayload) => {
  return http.post("/orders/bulk", payload).then((response) => response.data);
};

export const updateBulkOrders = async (payload: UpdateBulkOrdersPayload) => {
  return http.patch("/orders/bulk", payload).then((response) => response.data);
};

export const deleteBulkOrders = async (payload: DeleteBulkOrdersPayload) => {
  return http
    .post("/orders/bulkDelete", payload)
    .then((response) => response.data);
};

export const get = async (payload: AppFilterType) => {
  return http
    .get(
      `/orders?startDate=${payload.scheduledDateTimeStart}&endDate=${
        payload.scheduledDateTimeEnd
      }&status=${payload.orderStatus.join(
        ",",
      )}&orderDeliveryType=${payload.orderDeliveryType.join(
        ",",
      )}&driverIds=${payload.assigneeIds.join(
        ",",
      )}&workspaceIds=${payload.workspaceIds.join(
        ",",
      )}&sortColumn=""&groupBy=""&sortBy=""`,
    )
    .then((response) => response.data)
    .then((orders: Order[]) => {
      const ordersMap: Map<string, Order> = new Map();
      orders.forEach((order) => ordersMap.set(order.id, order));
      return ordersMap;
    });
};

export const getOne = async (id: string) => {
  return http.get<Order>(`/orders/${id}`).then((response) => response.data);
};
export const copy = (id: string) => {
  return http
    .post<Order>(`/orders/${id}/copy`)
    .then((response) => response.data);
};

export const deleteOrder = (id: string) => {
  return http.delete(`/orders/${id}`).then((response) => response.data);
};

export const getTrackingOrder = (id: string) => {
  return http.get(`/tracking/orders/${id}`).then((response) => response.data);
};

export const exportOrders = async (
  payload: AppFilterType,
  visibleColumns: string[],
) => {
  return http
    .post<string>(
      `/orders/export?startDate=${payload.scheduledDateTimeStart}&endDate=${
        payload.scheduledDateTimeEnd
      }&status=${payload.orderStatus.join(
        ",",
      )}&orderDeliveryType=${payload.orderDeliveryType.join(
        ",",
      )}&driverIds=${payload.assigneeIds.join(
        ",",
      )}&workspaceIds=${payload.workspaceIds.join(
        ",",
      )}&sortColumn=""&groupBy=""&sortBy=""&visibleColumns=${visibleColumns.join(
        ",",
      )}`,
    )
    .then((response) => response.data);
};
