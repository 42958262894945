import { useQuery } from "@tanstack/react-query";

import { getSessionUser } from "../services/auth.js";

export const useSessionUser = () => {
  return useQuery({
    queryKey: ["sessionUser"],
    queryFn: async () => {
      const response = await getSessionUser();
      return response;
    },
    refetchOnWindowFocus: false,
    retry: 2,
  });
};
