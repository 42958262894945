import toast from "react-hot-toast";

export default {
  success: (message: string) => {
    toast.success(message);
  },
  error: (message: string) => {
    toast.error(message);
  },
  loading: (message: string) => {
    toast.loading(message);
  },
  info: (message: string) => {
    toast(message);
  },
};
