import { useSessionUser } from "@web-app/api";
import config from "@web-app/config";
import useDispatchStore from "@web-app/store/dispatchStore";

export default function useApp() {
  const { data: sessionUser, isLoading, isError } = useSessionUser();

  if (sessionUser) {
    useDispatchStore.setState({
      user: sessionUser,
    });
  }

  if (window.location.pathname === "/signin") {
    if (sessionUser) {
      window.location.href = "/";
    }
  } else {
    if (isError) {
      localStorage.removeItem(config.API_TOKEN);
      window.location.href = "/signin";
    }
  }

  const logout = () => {
    localStorage.removeItem(config.API_TOKEN);
    window.location.href = "/signin";
  };

  return { sessionUser, isLoading, logout };
}
