import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import clsx from "clsx";
import { useState } from "react";

import { Icon } from "./Icon";

export default function Tooltip({
  text,
  position = "right",
  spacing = "ml-1",
}: {
  text: string;
  position?: "left" | "right";
  spacing?: string;
}) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className={spacing}>
      <Popover
        className="relative"
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {() => (
          <>
            <PopoverButton>
              <Icon
                name="questionCircle"
                className={clsx(
                  "h-3 w-3 rounded-full border border-gray-500 bg-gray-500 text-white",
                )}
              />
            </PopoverButton>
            <PopoverPanel static={isVisible}>
              <div
                className={clsx(
                  "text-wrap absolute top-0 z-10 w-44 rounded-md bg-black p-2 text-xs text-white",
                  position === "right" && "left-[20px]",
                  position === "left" && "right-[20px]",
                )}
              >
                {text}
              </div>
            </PopoverPanel>
          </>
        )}
      </Popover>
    </div>
  );
}
