import toast from "react-hot-toast";

import { useAvatarMutation } from "@web-app/api";
import { Avatar, Icon } from "@web-app/components";

type AvatarInputType = {
  imageUrl?: string;
  name?: string;
  setImageUrl: (url: string) => void;
};

export default function AvatarInput({
  imageUrl,
  name,
  setImageUrl,
}: AvatarInputType) {
  const { upload: uploadAvatar } = useAvatarMutation();
  const handleAddAvatar = async (file: File) => {
    const maxFileSize = 5 * 1024 * 1024;
    if (file.size > maxFileSize) {
      toast.error("File is larger than 5MB!");
      return;
    } else {
      const formData = new FormData();
      formData.append("file", file);

      const avatarUrl = await toast.promise(
        uploadAvatar.mutateAsync(formData),
        {
          loading: "Uploading avatar ...",
          success: "Successfully Uploaded",
          error: "Something went wrong, please try again!",
        },
      );
      setImageUrl(avatarUrl);
    }
  };

  return (
    <label
      className="flex flex-col items-center gap-4 cursor-pointer"
      htmlFor="file-input"
    >
      <div className="relative">
        <>
          <Avatar name={name} size={20} image={imageUrl} />
          <Icon
            name="fasPencil"
            className="absolute bottom-0 right-0 rounded-full bg-gray-600 p-2 text-xs text-white"
          />
        </>
        <input
          hidden
          id="file-input"
          accept="image/png image/jpeg"
          type="file"
          onChange={(e) => handleAddAvatar(e.target.files![0])}
        />
      </div>
      <p className="text-xs text-gray-400">
        *Only JPEG and PNG images up to 5MB are allowed
      </p>
    </label>
  );
}
