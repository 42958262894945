import { Avatar, Icon } from "@web-app/components";
import type { User } from "@web-app/types";

export default function UsersTable({
  isLoading,
  userIds,
  usersData,
  setCurrentUserId,
}: {
  isLoading: boolean;
  userIds: string[];
  usersData?: Map<string, User>;
  setCurrentUserId: (id: string) => void;
}) {
  return (
    <div className=" w-full overflow-y-auto ">
      <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
        <thead className="sticky top-0 z-10 bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="rounded-l-md px-6 py-3">
              Full Name
            </th>
            <th scope="col" className="px-6 py-3">
              Email
            </th>
            <th scope="col" className="px-6 py-3">
              Role
            </th>
            <th scope="col" className="rounded-r-md px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading &&
            Array.from(Array(18).keys()).map((item) => (
              <tr className="animate-pulse border-b " key={item}>
                <th>
                  <div className="mx-6 my-4 h-1.5  rounded-full bg-gray-200 dark:bg-gray-700"></div>
                </th>
                <th>
                  <div className="mx-6 my-4 h-1.5  rounded-full bg-gray-200 dark:bg-gray-700"></div>
                </th>
                <th>
                  <div className="mx-6 my-4 h-1.5  rounded-full bg-gray-200 dark:bg-gray-700"></div>
                </th>
                <th>
                  <div className="mx-6 my-4  h-1.5  rounded-full bg-gray-200 dark:bg-gray-700"></div>
                </th>
              </tr>
            ))}
          {!isLoading &&
            userIds?.map((userId) => {
              const user = usersData?.get(userId);

              if (!user) {
                return <span key={userId}></span>;
              }

              const userRole = user.role?.role;

              return (
                <tr
                  className="border-b bg-white hover:bg-gray-50 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-600"
                  key={user.id}
                >
                  <th
                    scope="row"
                    className="flex items-center gap-2 truncate whitespace-nowrap px-6 py-2 text-xs font-medium text-gray-900 dark:text-white"
                  >
                    <Avatar
                      image={user.image || ""}
                      name={user.name || ""}
                      size={6}
                    />{" "}
                    {user.name}
                  </th>
                  <th
                    scope="row"
                    className="truncate whitespace-nowrap px-6 py-2 text-xs font-medium text-gray-900 dark:text-white"
                    data-testid={`user-email-${user.email}`}
                  >
                    {user.email}
                  </th>
                  <td className="truncate whitespace-nowrap px-6 py-2 text-xs font-medium text-gray-900 dark:text-white">
                    {userRole}
                  </td>
                  <td className="px-6 py-2">
                    <a
                      href="#"
                      className="font-medium text-gray-600 hover:underline"
                      onClick={() => setCurrentUserId(user.id)}
                      data-testid={`user-edit-${user.email}`}
                    >
                      <Icon name="fasPencil" />
                    </a>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {!isLoading && !userIds.length && (
        <div className="grid h-[20vh] w-full place-items-center">
          <div className="font-semibold">
            <Icon name="fasExclamationTriangle" className="mr-2" />
            No Users Found
          </div>
        </div>
      )}
    </div>
  );
}
