import { createFileRoute } from "@tanstack/react-router";

import SigninPage from "@web-app/pages/signin";

export const Route = createFileRoute("/signin")({
  component: RouteComponent,
});

function RouteComponent() {
  return <SigninPage />;
}
