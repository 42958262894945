import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import toast from "react-hot-toast";

import { useCustomFieldMutation } from "@web-app/api";
import { CustomFieldGroup } from "@web-app/types";

import { CustomField } from "../../types/customFields";
import { Order, OrderCustomField } from "../../types/orders";
import Accordion from "../Accordion";
import ErrorMessage from "../ErrorMessage";

import { NumberField } from "./NumberField";
import { TextField } from "./TextField";
import { mapCustomFieldsGroupData } from "./utils";

export default function CustomFields({
  entity,
  entityId,
  workspaceId,
  isReadOnly,
  onChange,
  entityObject,
}: {
  entity: "workspace" | "order";
  entityId?: string;
  workspaceId?: string;
  isReadOnly?: boolean;
  entityObject?: Order;
  onChange?: (
    fieldId: string,
    customField: CustomField,
    value: CustomField["defaultValues"],
  ) => void;
}) {
  const { updateOrderCustomField } = useCustomFieldMutation();
  const onChangeHandler = (
    fieldId: string,
    customField: CustomField,
    value: CustomField["defaultValues"],
  ) => {
    if (onChange) {
      onChange(fieldId, customField, value);
      return;
    }

    if (entity === "order" && entityId) {
      toast.promise(
        updateOrderCustomField.mutateAsync({
          payload: { customFieldValues: value },
          entityId: entityId,
          fieldId: fieldId,
        }),
        {
          loading: "Updating custom field...",
          success: `${customField.name} updated successfully`,
          error: "Failed to update custom field",
        },
      );
    }
  };

  if (entity === "order" && !entityId && workspaceId) {
    return (
      <ActiveCustomFields
        workspaceId={workspaceId}
        isReadOnly={isReadOnly}
        onChangeHandler={onChangeHandler}
      />
    );
  }

  if (entity === "order" && entityId && workspaceId && entityObject) {
    return (
      <OrderCustomFields
        workspaceId={workspaceId}
        isReadOnly={isReadOnly}
        onChangeHandler={onChangeHandler}
        order={entityObject}
      />
    );
  }

  return <></>;
}

const ActiveCustomFields = ({
  workspaceId,
  isReadOnly,
  onChangeHandler,
}: {
  workspaceId: string;
  isReadOnly?: boolean;
  onChangeHandler: (
    fieldId: string,
    customField: CustomField,
    value: CustomField["defaultValues"],
    groupId: string,
  ) => void;
}) => {
  const queryClient = useQueryClient();
  const customFieldsData = queryClient.getQueryData<CustomField[]>([
    "customFields",
    "activeCustomFields",
  ]);
  const customFieldsGroups = queryClient.getQueryData<CustomFieldGroup[]>([
    "customFieldGroups",
  ]);

  const [customFieldsWithGroup, setCustomFieldsWithGroup] = useState<
    Map<
      string,
      {
        fields: OrderCustomField[];
        name: string;
      }
    >
  >(new Map());

  useEffect(() => {
    if (customFieldsData?.length && customFieldsGroups?.length) {
      const cfs: OrderCustomField[] = [];
      customFieldsData.map((field) => {
        cfs.push({
          customFieldId: field.id,
          customField: field,
          customFieldValues: field.defaultValues ?? [],
          id: "field-id-" + field.name,
          orderId: "",
          workspaceId: workspaceId,
          createdAt: "",
          updatedAt: "",
          groupId: field.groupId,
        });
      });

      const sortedCfs = cfs.sort((a, b) => {
        return a.customField.name.localeCompare(b.customField.name);
      });
      const mapedData = mapCustomFieldsGroupData(customFieldsGroups, sortedCfs);

      setCustomFieldsWithGroup(mapedData);
    }
  }, [customFieldsData, customFieldsGroups, workspaceId]);

  if (customFieldsWithGroup.size) {
    return (
      <CustomFieldsGroupAccordion
        fieldsGroups={customFieldsWithGroup}
        isReadOnly={isReadOnly}
        onChangeHandler={onChangeHandler}
      />
    );
  } else {
    return <div></div>;
  }
};

const OrderCustomFields = ({
  workspaceId,
  isReadOnly,
  onChangeHandler,
  order,
}: {
  workspaceId: string;
  isReadOnly?: boolean;
  order: Order;
  onChangeHandler: (
    fieldId: string,
    customField: CustomField,
    value: CustomField["defaultValues"],
    groupId: string,
  ) => void;
}) => {
  const queryClient = useQueryClient();
  const customFieldsGroups = queryClient.getQueryData<CustomFieldGroup[]>([
    "customFieldGroups",
  ]);

  const customFieldsWithGroup = useMemo(() => {
    if (customFieldsGroups) {
      const cfs: OrderCustomField[] = [];
      order.customFields?.map((field) => {
        cfs.push({
          customFieldId: field.definition.id,
          customField: field.definition,
          customFieldValues: field.values ?? [],
          id: "field-id-" + field.definition.name,
          orderId: "",
          workspaceId: workspaceId,
          createdAt: "",
          updatedAt: "",
          groupId: field.definition.groupId,
        });
      });

      const sortedCfs = cfs.sort((a, b) => {
        return a.customField.name.localeCompare(b.customField.name);
      });
      const mapedData = mapCustomFieldsGroupData(customFieldsGroups, sortedCfs);

      return mapedData;
    }
    return {};
  }, [customFieldsGroups, order.customFields, workspaceId]);

  // @ts-ignore - fix
  if (customFieldsWithGroup.size) {
    return (
      <CustomFieldsGroupAccordion
        // @ts-ignore - fix
        fieldsGroups={customFieldsWithGroup}
        isReadOnly={isReadOnly}
        onChangeHandler={onChangeHandler}
      />
    );
  } else {
    return <div></div>;
  }
};

const CustomFieldsGroupAccordion = ({
  fieldsGroups,
  isReadOnly = false,
  onChangeHandler,
}: {
  fieldsGroups: Map<
    string,
    {
      fields: OrderCustomField[];
      name: string;
    }
  >;
  isReadOnly?: boolean;
  onChangeHandler: (
    fieldId: string,
    customField: CustomField,
    value: CustomField["defaultValues"],
    groupId: string,
  ) => void;
}) => {
  if (fieldsGroups && fieldsGroups.size === 0) {
    return <p>No custom fields available</p>;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const method = useFormContext();

  return (
    fieldsGroups &&
    Array.from(fieldsGroups).map(([groupId, { fields, name }]) => (
      <div key={groupId} className="mb-5">
        <Accordion
          label={name}
          labelClassName="text-base leading-5 text-gray-900"
        >
          <div className="mb-6 w-full md:mb-0">
            <div>
              {!fields.length ? <p>No custom fields available</p> : <></>}
            </div>
            <div className="flex-col flex gap-2">
              {fields.map((customField) => {
                switch (customField.customField.type) {
                  case "TEXT":
                    return (
                      <div className="relative mb-6">
                        {method ? (
                          <Controller
                            name={customField.customField.name}
                            control={method.control}
                            render={({
                              field: { onChange, ...field },
                              fieldState: { error },
                            }) => (
                              <>
                                <TextField
                                  fieldId={customField.id}
                                  customField={customField.customField}
                                  fieldValues={customField.customFieldValues}
                                  isReadOnly={isReadOnly}
                                  // @ts-ignore - fix
                                  onChange={(
                                    fieldId,
                                    customField,
                                    value,
                                    groupId,
                                  ) => {
                                    onChange(value[0].value || undefined);
                                    onChangeHandler(
                                      fieldId,
                                      customField,
                                      value,
                                      groupId,
                                    );
                                  }}
                                  isRequired={
                                    customField.customField.isRequired
                                  }
                                  {...field}
                                />
                                <ErrorMessage message={error?.message} />
                              </>
                            )}
                          />
                        ) : (
                          <TextField
                            fieldId={customField.id}
                            customField={customField.customField}
                            fieldValues={customField.customFieldValues}
                            isReadOnly={isReadOnly}
                            // @ts-ignore - fix
                            onChange={onChangeHandler}
                            isRequired={customField.customField.isRequired}
                            isDebounce
                          />
                        )}
                      </div>
                    );
                  case "NUMBER":
                    return (
                      <div className="relative mb-6">
                        {method ? (
                          <Controller
                            name={customField.customField.name}
                            control={method.control}
                            render={({
                              field: { onChange, ...field },
                              fieldState: { error },
                            }) => (
                              <>
                                <NumberField
                                  fieldId={customField.id}
                                  customField={customField.customField}
                                  fieldValues={customField.customFieldValues}
                                  isReadOnly={isReadOnly}
                                  // @ts-ignore - fix
                                  onChange={(
                                    fieldId,
                                    customField,
                                    value,
                                    groupId,
                                  ) => {
                                    onChange(value[0].value || undefined);
                                    onChangeHandler(
                                      fieldId,
                                      customField,
                                      value,
                                      groupId,
                                    );
                                  }}
                                  isRequired={
                                    customField.customField.isRequired
                                  }
                                  {...field}
                                />
                                <ErrorMessage message={error?.message} />
                              </>
                            )}
                          />
                        ) : (
                          <NumberField
                            fieldId={customField.id}
                            customField={customField.customField}
                            fieldValues={customField.customFieldValues}
                            isReadOnly={isReadOnly}
                            // @ts-ignore - fix
                            onChange={onChangeHandler}
                            isRequired={customField.customField.isRequired}
                            isDebounce
                          />
                        )}
                      </div>
                    );
                  default:
                    return <></>;
                }
              })}
            </div>
          </div>
        </Accordion>
      </div>
    ))
  );
};
