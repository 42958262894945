import type { ChangeEvent } from "react";
import { useState } from "react";
import toast from "react-hot-toast";

import { useAppMutation, useWorkspaceMutation } from "@web-app/api";
import { Toggle, URLField } from "@web-app/components";
import type { Workspace } from "@web-app/types";
import { SystemApp } from "@web-app/types";

import TabTitle from "../TabTitle";

const PublicOrderFormTab = ({ workspace }: { workspace: Workspace }) => {
  const [isActive, setIsActive] = useState(
    workspace?.publicFormSettings?.isEnabled,
  );

  const { updateWorkspace } = useWorkspaceMutation();
  const { createApp } = useAppMutation();

  const formUrl = `${window.location.protocol}//${window.location.host}/workspaces/${workspace.id}/orders/create`;

  const handleUpdateWorkspace = (publicFormSettings: {
    appId: string;
    isEnabled: boolean;
  }) => {
    toast.promise(
      updateWorkspace.mutateAsync({
        id: workspace.id,
        payload: { publicFormSettings },
      }),
      {
        loading: `${
          publicFormSettings.isEnabled ? "Enabling ..." : "Disabling..."
        }`,
        success: `${
          publicFormSettings.isEnabled ? "Enabled" : "Disabled"
        } customers order form.`,
        error: `Failed to ${
          publicFormSettings.isEnabled ? "Enable" : "Disable"
        } customers order form. Please try again or contact support`,
      },
    );
  };

  const handleActiveToggle = async (e: ChangeEvent<HTMLInputElement>) => {
    setIsActive(e.target.checked);
    if (!workspace.publicFormSettings?.appId) {
      createApp.mutateAsync(
        {
          name: "Public create order form",
          secret: "",
          callbackUrl: "",
          avatarUrl: "",
          workspaceId: workspace.id,
          events: [],
          systemApp: SystemApp.PUBLIC_FORM,
        },
        {
          onSuccess: (app) => {
            handleUpdateWorkspace({ appId: app.id, isEnabled: isActive });
          },
          onError: () => {
            setIsActive(false);
          },
        },
      );
    } else {
      handleUpdateWorkspace({
        appId: workspace.publicFormSettings.appId,
        isEnabled: e.target.checked,
      });
    }
  };

  return (
    <div className="p-4 px-6 max-w-xl">
      <div className="mb-8">
        <TabTitle icon="form" title="Customers Order Form" styles="mb-3" />

        <p className="text-sm text-gray-400">
          The Customer Order Form generates a unique URL for your workspace,
          allowing anyone with the link to submit orders without needing an
          account.
        </p>
      </div>
      <div>
        <div className="col-span-12 flex items-start mb-4">
          <Toggle
            label={isActive ? "Active" : "Inactive"}
            isChecked={isActive}
            onChange={handleActiveToggle}
          />
        </div>
        <URLField url={formUrl} />
      </div>
    </div>
  );
};

export default PublicOrderFormTab;
