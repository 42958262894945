import { Icon } from "@web-app/components";
import { IconsType } from "@web-app/components/Icon/utils";

export default function Card({
  icon,
  text,
  onClick,
}: {
  icon: IconsType;
  text: string;
  onClick: () => void;
}) {
  return (
    <div
      className="flex h-40 w-40 cursor-pointer flex-col items-center justify-center gap-2 rounded-md border border-dashed text-gray-500 border-gray-300 hover:border-gray-600 hover:text-gray-700 transition ease-in"
      onClick={onClick}
    >
      <Icon name={icon} />
      <span className="text-center text-sm">{text}</span>
    </div>
  );
}
