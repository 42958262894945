import { createFileRoute } from "@tanstack/react-router";

import { LoadingIcon } from "@web-app/components";
import { useApp } from "@web-app/hooks";
import SettingsPage from "@web-app/pages/settings";

export const Route = createFileRoute("/settings")({
  component: RouteComponent,
});

function RouteComponent() {
  const { sessionUser, isLoading, logout } = useApp();

  if (isLoading) {
    return (
      <div className="h-screen w-screen grid place-content-center">
        <LoadingIcon className="w-8 h-8" />
      </div>
    );
  }

  if (sessionUser) {
    return <SettingsPage currentUser={sessionUser} logout={logout} />;
  }
}
