import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import Pusher from "pusher-js";
import React, { ComponentProps, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import { useFeatureflags, useSessionUser } from "@web-app/api";
import config from "@web-app/config";
import { useDocument } from "@web-app/hooks";
import useDispatchStore from "@web-app/store/dispatchStore";
import { DriverEntity, Order } from "@web-app/types";

import DispatchMap from "../common/DispatchMap";
import MapLegend from "../common/MapLegend";
import Navigation from "../common/Navigation";
import OrdersSidebar from "../common/OrdersSidebar";
import TopNavigation from "../common/TopNavigation";

import RouteEditModal from "./RouteEditModal";

const BulkImportModal = React.lazy(() => import("./BulkImportModal"));
const CreateRouteModal = React.lazy(() => import("./CreateRouteModal"));

const OrderFormModal = React.lazy(() => import("../common/OrderFormModal"));

export default function DispatchPage({ logout }: { logout: () => void }) {
  useDocument({ title: "Dispatch" });

  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [isBulkImportModalOpen, setIsBulkImportModalOpen] = useState(false);
  const [selectWithPolygon, setSelectWithPolygon] = useState(false);
  const [sideBarMode, setSidebarMode] =
    useState<ComponentProps<typeof OrdersSidebar>["sideBarMode"]>("list");
  const { routeModalMode, focusedRouteId } = useDispatchStore();
  const { data: user } = useSessionUser();
  const { appFilter } = useDispatchStore();

  const { hasFeature } = useFeatureflags();

  const queryClient = useQueryClient();

  const assignees = queryClient.getQueryData<Map<string, DriverEntity>>([
    "drivers",
  ]);
  const orders = queryClient.getQueryData<Map<string, Order>>([
    "orders",
    appFilter,
  ]);

  const togglePolygonSelection = () => {
    setSelectWithPolygon(!selectWithPolygon);
  };

  useEffect(() => {
    if (orders && assignees) {
      queryClient.setQueryData(
        ["drivers"],
        (oldAssignees: Map<string, DriverEntity>) => {
          orders?.forEach((order) => {
            if (order.assignee && order.connectedWorkspace) {
              const assignee = {
                ...order.assignee,
                userProfile: order.assignee,
                dutyStatus: order?.assignee?.driverDetails?.dutyStatus,
                location: order?.driverLocation,
              };
              const oldAssignee = oldAssignees.get(order.assignee.id);
              if (oldAssignee) {
                oldAssignees.set(order.assignee.id, {
                  ...oldAssignee,
                  ...assignee,
                });
              } else {
                // @ts-ignore temporary fix
                oldAssignees.set(order.assignee.id, assignee);
              }
            }
          });

          return oldAssignees;
        },
      );
    }
  }, [orders, assignees, queryClient]);

  useEffect(() => {
    const pusher = new Pusher(config.SOKETI_APP_KEY, {
      wsHost: config.SOKETI_HOST,
      wsPort: parseInt(config.SOKETI_PORT),
      wssPort: parseInt(config.SOKETI_PORT),
      forceTLS: false,
      cluster: "",
      enabledTransports: ["ws", "wss"],
    });

    pusher.connection.bind("error", () => {
      console.log("Soketi - Something went wrong!");
    });

    pusher.connection.bind("connected", () => {
      console.log("Soketi - Connected!");
    });

    const assigneesChannel = pusher.subscribe("drivers");
    const workspaceChannelName = "internal-api@" + user?.currentWorkspaceId;
    const workspaceChannel = pusher.subscribe(workspaceChannelName);

    workspaceChannel.bind("orderUpdated", (updatedOrder: Order) => {
      queryClient.setQueryData<Map<string, Order>>(
        ["orders", appFilter],
        (orders) => {
          if (orders) {
            const clonedOrders = structuredClone(orders);
            const order = clonedOrders.get(updatedOrder.id);
            if (order) {
              clonedOrders.set(updatedOrder.id, {
                ...order,
                ...updatedOrder,
              });

              return clonedOrders;
            }
          }

          return orders;
        },
      );
    });

    workspaceChannel.bind(
      "bulkOrderCreated",
      (data: { status: boolean; totalCount: number }) => {
        if (data.status) {
          queryClient.invalidateQueries({
            queryKey: ["orders", appFilter],
          });
          toast.success("Orders list updated");
        }
      },
    );

    assigneesChannel.bind(
      "driver:update",
      (data: { userId: string; dutyStatus: DriverEntity["dutyStatus"] }) => {
        queryClient.setQueryData<Map<string, DriverEntity>>(
          ["drivers"],
          (drivers) => {
            if (drivers) {
              const clonedDrivers = structuredClone(drivers);
              const driver = clonedDrivers?.get(data.userId);
              if (driver) {
                const updatedAssignee = {
                  ...driver,
                  dutyStatus: data.dutyStatus,
                };
                clonedDrivers.set(data.userId, updatedAssignee);
                return clonedDrivers;
              }
            }
            return drivers;
          },
        );
      },
    );

    return () => {
      pusher.unsubscribe(workspaceChannelName);
      pusher.unsubscribe("drivers");
    };
  }, [appFilter, queryClient, user?.currentWorkspaceId]);

  return (
    <div>
      <Navigation logout={logout} />
      <Toaster position="top-center" />

      <div className="ml-0 lg:ml-20 bg-gray-100 max-h-screen flex min-h-screen">
        <aside
          className={clsx(
            `inset-y-0 left-20 hidden md:block w-[calc(100%-480px)] overflow-y-auto border-gray-200 bg-gray-100 px-4 py-4 sm:px-6 lg:px-5`,
            hasFeature("bulkActions") && sideBarMode === "table" && "!w-[55%]",
          )}
        >
          <TopNavigation
            setIsOrderModalOpen={() => setIsOrderModalOpen(!isOrderModalOpen)}
            setIsBulkImportModalOpen={() =>
              setIsBulkImportModalOpen(!isBulkImportModalOpen)
            }
          />
          <DispatchMap
            isPolygonSelectionEnable={
              selectWithPolygon && routeModalMode === "create" ? true : false
            }
          />

          <OrderFormModal
            isOpen={isOrderModalOpen}
            setIsOpen={setIsOrderModalOpen}
          />
        </aside>

        <CreateRouteModal
          isOpen={routeModalMode === "create"}
          onClose={() => {
            useDispatchStore.setState({ routeModalMode: "closed" });

            if (selectWithPolygon) {
              togglePolygonSelection();
            }

            useDispatchStore.setState({ selectedOrderIds: new Set() });
          }}
          selectWithPolygon={selectWithPolygon}
          togglePolygonSelection={togglePolygonSelection}
        />

        {routeModalMode === "edit" && focusedRouteId && <RouteEditModal />}

        {isBulkImportModalOpen && user && (
          <BulkImportModal
            isOpen={isBulkImportModalOpen}
            setIsOpen={setIsBulkImportModalOpen}
            currentWorkspaceName={user.currentWorkspace.name}
            workspaceId={user.currentWorkspace.id}
          />
        )}

        <main
          className={clsx(
            "w-[480px] bg-white",
            hasFeature("bulkActions") && sideBarMode === "table" && "!w-[45%]",
          )}
        >
          <MapLegend />
          <OrdersSidebar
            sideBarMode={sideBarMode}
            setSidebarMode={setSidebarMode}
          />
        </main>
      </div>
    </div>
  );
}
