import clsx from "clsx";

function FullWidthGridColumn({
  classes,
  children,
}: {
  classes?: string;
  children: React.ReactNode;
}) {
  return (
    <div className={clsx("col-span-12 relative", classes)}>{children}</div>
  );
}

export default FullWidthGridColumn;
