import { Icon } from "@web-app/components";
import type { SavedAddress } from "@web-app/types";

type AddressCardProps = {
  address: SavedAddress;
  onDelete: () => void;
  onClick: () => void;
};

export default function AddressCard({
  address,
  onClick,
  onDelete,
}: AddressCardProps) {
  return (
    <div className="flex" onClick={onClick}>
      <div className="group transition-all flex relative items-start gap-4 w-[392px] rounded-lg hover:ring-2 hover:ring-gray-300 hover:cursor-pointer border-gray-200 hover:border-black  border p-4 h-[72px] max-h-[72px] ">
        <Icon name="addressBook" className="h-4" />
        <div className="flex-1 flex flex-col truncate ">
          <h3 className="text-base leading-none font-normal text-black mb-1">
            {address.name}
          </h3>
          <p className="truncate text-gray-500 text-sm leading-5">
            {address.address}
          </p>
        </div>
        <span
          className="hidden group-hover:block"
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          <Icon
            name="trash"
            className="absolute right-[12px] top-[10px] h-4 hover:cursor-pointer hover:top-[8px] transition-all"
          />
        </span>
      </div>
    </div>
  );
}
