import { Avatar } from "@web-app/components";
import { Order } from "@web-app/types";

function WorkspaceCell({ data, value }: { data: Order; value: string }) {
  if (!data) {
    return value;
  }

  if (!data.connectedWorkspace) {
    return "";
  }
  return (
    <div className="relative flex items-center space-x-3 rounded-lg py-3">
      <div className="flex-shrink-0">
        <Avatar
          size={8}
          name={data.connectedWorkspace.name}
          image={data.connectedWorkspace.avatarUrl || ""}
        />
      </div>
      <div className="min-w-0 flex-1">
        <a href="#" className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900">
            {data.connectedWorkspace.name}
          </p>
        </a>
      </div>
    </div>
  );
}

export default WorkspaceCell;
