import { Transition } from "@headlessui/react";
import {
  DrawingManager,
  GoogleMap,
  Marker,
  MarkerClusterer,
  OverlayView,
  TrafficLayer,
  useJsApiLoader,
} from "@react-google-maps/api";
import type { Cluster } from "@react-google-maps/marker-clusterer";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { differenceInMinutes, formatDistance } from "date-fns";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";

import { LoadingIcon } from "@web-app/components";
import config from "@web-app/config";
import useManageRoute from "@web-app/pages/dispatchPage/RouteEditModal/useManageRoute.js";
import useDispatchStore from "@web-app/store/dispatchStore";
import {
  DriverEntity,
  Order,
  OrderDeliveryType,
  OrderStatus,
} from "@web-app/types";
import { ORDER_STATUS_COLORS } from "@web-app/utils/order";

import ClusterModal from "./ClusterModal";
import Route from "./Route.jsx";
import Settings from "./Settings.jsx";
import { decodePolyline, libraries, options } from "./utils.js";

const ORDER_POPUP_STATUS_BG: Record<Order["status"], string> = {
  TODO: "bg-black",
  INPROGRESS: "bg-sky-500",
  COMPLETE: "bg-green-700",
  CANCELLED: "bg-red-500",
  FAILED: "bg-red-500",
  ASSIGNED: "bg-purple-700",
};

export default function DispatchMap({
  isPolygonSelectionEnable,
}: {
  isPolygonSelectionEnable: boolean;
}) {
  const {
    focusedAssigneeId,
    focusedOrderId,
    focusedRouteId,
    routeModalMode,
    userLatestLocation,
    userLatestZoomLevel,
    mapSettings,
    appFilter,
    routes,
  } = useDispatchStore();
  const queryClient = useQueryClient();

  const orders = queryClient.getQueryData<Map<string, Order>>([
    "orders",
    appFilter,
  ]);
  const assignees = queryClient.getQueryData<Map<string, DriverEntity>>([
    "drivers",
  ]);

  const assigneesAfterFilter = useMemo(() => {
    if (assignees) {
      if (appFilter.driverStatus.length === 0) {
        return Array.from(assignees?.values());
      }
      return Array.from(assignees?.values()).filter((assignee) => {
        if (appFilter.driverStatus.includes(assignee.dutyStatus)) {
          return assignee;
        }
      });
    }

    return [];
  }, [assignees, orders, appFilter]);

  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);
  const polygonRef = useRef<google.maps.Polygon | null>(null);
  const [hoveredElementId, setHoveredPolylineElementId] = useState<
    string | null
  >(null);
  const [clusterLocation, setClusterLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [clusterOrders, setClusterOrders] = useState<string[]>([]);
  const [isOrderClusterVisible, setIsOrderClusterVisible] = useState(false);

  const [hoveredAssigneeId, setHoveredAssigneeId] = useState<string | null>(
    null,
  );
  const polygonOrders = useRef<string[]>([]);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: config.GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const [initialCenter] = useState(userLatestLocation);
  const [initialZoom] = useState(userLatestZoomLevel);

  const { addOrderToRoute, removeOrder } = useManageRoute();

  // FIXME: Need route endpoints for this
  // TODO: Use react query for this and remove this
  // const { addOrderToRoute, removeOrder } = useManageRoute(
  //   focusedRouteId || "",
  //   refetchOrders
  // );

  const onCenterChanged = debounce(() => {
    const center = mapInstance?.getCenter();
    if (center) {
      useDispatchStore.setState({
        userLatestLocation: { lat: center.lat(), lng: center.lng() },
        focusedAssigneeId: null,
      });
    }
  }, 1000);

  const onZoomChanged = debounce(() => {
    const zoom = mapInstance?.getZoom();
    if (zoom) {
      useDispatchStore.setState({
        userLatestZoomLevel: zoom,
        focusedAssigneeId: null,
      });
    }
  });

  const isOrderExistsInPolygon = (
    lat: number,
    lng: number,
    polygon: google.maps.Polygon,
  ) => {
    return google.maps.geometry.poly.containsLocation({ lat, lng }, polygon);
  };

  const updateSelectedOrderIdsList = (selectedIds: string[]) => {
    useDispatchStore.setState((prev) => {
      const selectedOrderIds = new Set([
        ...prev.selectedOrderIds,
        ...selectedIds,
      ]);

      return {
        selectedOrderIds,
      };
    });
  };

  const clearPolygonSelectedOrdersFromState = () => {
    useDispatchStore.setState((prev) => {
      const orderIds = new Set([...prev.selectedOrderIds]);
      polygonOrders.current.forEach((orderId) => {
        orderIds.delete(orderId);
      });

      return {
        selectedOrderIds: orderIds,
      };
    });
  };

  const getOrderIcon = (order: Order, type: "pickup" | "dropoff") => {
    if (type === "pickup") {
      if (order.status === "TODO" && order.assignee) {
        return `/markers/PICKUP_ASSIGNED.png`;
      } else {
        return `/markers/PICKUP_${order.status}.png`;
      }
    } else if (type === "dropoff") {
      if (order.status === "TODO" && order.assignee) {
        return `/markers/DROPOFF_ASSIGNED.png`;
      } else {
        return `/markers/DROPOFF_${order.status}.png`;
      }
    } else {
      return "";
    }
  };

  const getOrderStatus = (order: Order) => {
    if (order.status === "TODO" && order.assignee) {
      return "ASSIGNED";
    }

    return order.status;
  };

  const _validateOrderBeforeAddingToRoute = useCallback(
    (order: Order, displayAlert = false) => {
      const doesOrderExistInDifferentRoute =
        (routeModalMode === "edit" &&
          focusedRouteId &&
          order.route &&
          order.route.id !== focusedRouteId) ||
        (routeModalMode === "create" && order.route);

      if (doesOrderExistInDifferentRoute) {
        if (displayAlert) {
          toast.error("Order already exists in a different route!");
        }
        return false;
      } else if (order.status === OrderStatus.COMPLETE) {
        if (displayAlert) {
          toast.error("Completed order cannot be added to a route!");
        }
        return false;
      } else if (order.status === OrderStatus.CANCELLED) {
        if (displayAlert) {
          toast.error("Cancelled order cannot be added to a route!");
        }
        return false;
      } else if (order.status === OrderStatus.FAILED) {
        if (displayAlert) {
          toast.error("Failed order cannot be added to a route!");
        }
        return false;
      } else if (
        order.type === OrderDeliveryType.INDIVIDUAL_PICKUP_AND_DROPOFF
      ) {
        if (displayAlert) {
          toast.error("Individual order cannot be added to a route!");
        }
        return false;
      }

      return true;
    },
    [focusedRouteId, routeModalMode],
  );

  const handlePolygonComplete = (value: google.maps.Polygon) => {
    polygonRef.current?.setMap(null); // Set previous polygon to null (optional chaining)
    polygonRef.current = value;

    clearPolygonSelectedOrdersFromState();

    const selectedIds = pickupAndDropoffOrders
      .filter((order) => {
        const lat = order.type.includes("PICKUP")
          ? order.pickupLocation.coordinates[1]
          : order.dropoffLocation.coordinates[1];
        const lng = order.type.includes("PICKUP")
          ? order.pickupLocation.coordinates[0]!
          : order.dropoffLocation.coordinates[0]!;
        return isOrderExistsInPolygon(lat, lng, value);
      })
      .map((order) => order.id);

    polygonOrders.current = selectedIds;

    updateSelectedOrderIdsList(polygonOrders.current);
  };

  const onMouseDown = debounce(() => {
    if (focusedOrderId) {
      const order = orders?.get(focusedOrderId);
      if (order?.type === OrderDeliveryType.INDIVIDUAL_PICKUP_AND_DROPOFF) {
        return;
      }

      useDispatchStore.setState({
        focusedOrderId: null,
        focusedAssigneeId: null,
      });
    }
  }, 500);

  useEffect(() => {
    if (!isPolygonSelectionEnable) {
      if (polygonRef.current) {
        polygonRef.current.setMap(null);
      }

      clearPolygonSelectedOrdersFromState();
    }
  }, [isPolygonSelectionEnable]);

  const ordersArray = Array.from(orders?.values() || []);
  const assigneesArray = assigneesAfterFilter || [];
  const routesArray = routes ? Array.from(routes?.values() || []) : [];

  const pickupAndDropoffOrders = useMemo(() => {
    const pickupOrders: Order[] = [];
    const dropoffOrders: Order[] = [];

    ordersArray.forEach((order) => {
      if (!_validateOrderBeforeAddingToRoute(order)) {
        return;
      }

      if (order.type === "ROUTE_OPT_PICKUP") {
        pickupOrders.push(order);
      } else if (order.type === "ROUTE_OPT_DROPOFF") {
        dropoffOrders.push(order);
      }
    });

    return [...pickupOrders, ...dropoffOrders];
  }, [_validateOrderBeforeAddingToRoute, ordersArray]);

  useEffect(() => {
    if (focusedOrderId && orders && assignees) {
      const focusedOrder = orders.get(focusedOrderId);
      const assignee = assignees.get(focusedOrder?.assignee?.id || "");

      if (focusedOrder) {
        if (isOrderClusterVisible) {
          return;
        }

        try {
          const bounds = new window.google.maps.LatLngBounds();

          if (focusedOrder.type === "INDIVIDUAL_PICKUP_AND_DROPOFF") {
            bounds.extend({
              lat: focusedOrder.pickupLocation.coordinates[1],
              lng: focusedOrder.pickupLocation.coordinates[0],
            });
            bounds.extend({
              lat: focusedOrder.dropoffLocation.coordinates[1],
              lng: focusedOrder.dropoffLocation.coordinates[0]!,
            });
          } else if (focusedOrder.type === "ROUTE_OPT_PICKUP") {
            bounds.extend({
              lat: focusedOrder.pickupLocation.coordinates[1],
              lng: focusedOrder.pickupLocation.coordinates[0],
            });
          } else if (focusedOrder.type === "ROUTE_OPT_DROPOFF") {
            bounds.extend({
              lat: focusedOrder.dropoffLocation.coordinates[1],
              lng: focusedOrder.dropoffLocation.coordinates[0],
            });
          }
          if (
            assignee?.dutyStatus !== "OFFLINE" &&
            assignee?.location?.latitude &&
            assignee?.location?.longitude
          ) {
            bounds.extend({
              lat: assignee.location.latitude!,
              lng: assignee.location.longitude!,
            });
          }
          mapInstance?.fitBounds(bounds);

          if (
            focusedOrder.type === "ROUTE_OPT_PICKUP" ||
            focusedOrder.type === "ROUTE_OPT_DROPOFF"
          ) {
            mapInstance?.setZoom(18);
          }
        } catch {
          console.error("Error while setting bounds for focused order");
        }
      }
    }
  }, [focusedOrderId]);

  useEffect(() => {
    if (focusedRouteId) {
      const bounds = new window.google.maps.LatLngBounds();
      const route = routes.get(focusedRouteId);

      route?.routePolyline?.routes.forEach((route) =>
        decodePolyline(route.polyline.encodedPolyline).forEach((point) =>
          bounds.extend(point),
        ),
      );
      if (assignees) {
        const assignee = assignees.get(route?.assigneeId || "");

        if (
          assignee?.dutyStatus !== "OFFLINE" &&
          assignee?.location?.latitude &&
          assignee?.location?.longitude
        ) {
          bounds.extend({
            lat: assignee.location.latitude!,
            lng: assignee.location.longitude!,
          });
        }
      }

      mapInstance?.fitBounds(bounds);
    }
  }, [focusedRouteId]);

  useEffect(() => {
    if (focusedAssigneeId && assignees) {
      const focusedAssignee = assignees.get(focusedAssigneeId);
      if (focusedAssignee) {
        const bounds = new window.google.maps.LatLngBounds();
        if (focusedAssignee.location) {
          bounds.extend({
            lat: focusedAssignee.location.latitude,
            lng: focusedAssignee.location.longitude,
          });
          mapInstance?.fitBounds(bounds, 150);
          mapInstance?.setZoom(15);
        }
      }
    }
  }, [assignees, focusedAssigneeId]);

  const onClickOrderMarker = (order: Order) => {
    if (
      routeModalMode !== "closed" &&
      !_validateOrderBeforeAddingToRoute(order, true)
    ) {
      return;
    }

    if (routeModalMode === "edit" && focusedRouteId) {
      const route = routes.get(focusedRouteId);

      if (route) {
        // TODO: Need to implement this
        // FIXME: Need to implement this
        route?.ordersOrder.find((item) => item.id === order.id)
          ? removeOrder(order.id)
          : addOrderToRoute(route.id, order.id);
      }

      return;
    }

    useDispatchStore.setState((prev) => {
      const selectedOrderIds = new Set(prev.selectedOrderIds);

      if (routeModalMode === "create") {
        if (selectedOrderIds.has(order.id)) {
          selectedOrderIds.delete(order.id);
        } else {
          selectedOrderIds.add(order.id);
        }

        return {
          selectedOrderIds: selectedOrderIds,
          focusedRouteId: "",
        };
      } else if (order.route) {
        return {
          focusedOrderId: order.id,
          selectedOrderIds: new Set(),
        };
      } else {
        return {
          focusedOrderId: order.id,
        };
      }
    });
  };

  const _getMarkerKey = (lat: number, lng: number, displayId: string) => {
    return `${lat.toFixed(0)}-${lng.toFixed(0)}-${displayId}`;
  };

  const onClickMarkerCluster = (cluster: Cluster) => {
    const markers = cluster.getMarkers();
    const markerMap = new Map(
      markers.map((marker) => {
        const markerPosition = marker.getPosition();
        const displayId = marker.getTitle();

        if (!markerPosition || !displayId) {
          return [null, null];
        }

        return [
          _getMarkerKey(
            markerPosition?.lat(),
            markerPosition?.lng(),
            displayId,
          ),
          marker,
        ];
      }),
    );

    let clusterOrders: string[] = [];

    const clusterCenter = cluster.center;

    if (clusterCenter) {
      setClusterLocation({
        lat: clusterCenter.lat(),
        lng: clusterCenter.lng(),
      });
    }
    if ((routeModalMode === "create" || routeModalMode === "edit") && orders) {
      clusterOrders = Array.from(orders.keys()).filter((orderId) => {
        const order = orders.get(orderId)!;

        const lat =
          order.type === "ROUTE_OPT_PICKUP"
            ? order.pickupLocation.coordinates[1]
            : order.type === "ROUTE_OPT_DROPOFF"
              ? order.dropoffLocation.coordinates[1]
              : 0;
        const lng =
          order.type === "ROUTE_OPT_PICKUP"
            ? order.pickupLocation.coordinates[0]
            : order.type === "ROUTE_OPT_DROPOFF"
              ? order.dropoffLocation.coordinates[0]
              : 0;

        return !!markerMap.get(_getMarkerKey(lat, lng, order.id));
      });
    } else if (orders) {
      clusterOrders = Array.from(orders.keys()).filter((orderId) => {
        const order = orders.get(orderId)!;

        const lat = order.type.includes("PICKUP")
          ? order.pickupLocation.coordinates[1]
          : order.dropoffLocation.coordinates[1];
        const lng = order.type.includes("DROPOFF")
          ? order.dropoffLocation.coordinates[0]
          : order.pickupLocation.coordinates[0];

        const markers = !!markerMap.get(_getMarkerKey(lat, lng, order.id));
        return markers;
      });
    }

    setClusterOrders(clusterOrders);

    if (clusterOrders.length) {
      setIsOrderClusterVisible(true);
    }
  };

  if (!isLoaded || !orders) {
    return (
      <div className="grid h-full place-content-center rounded-xl bg-gray-300">
        <LoadingIcon className="h-10 w-10 " />
      </div>
    );
  }

  const closeClusterModal = () => {
    setIsOrderClusterVisible(false);
    setClusterOrders([]);
    setClusterLocation(null);
  };

  const onChangeHoverElement = (id: string | null) => {
    if (id === null) {
      setHoveredPolylineElementId(null);
    } else if (!hoveredElementId) {
      setHoveredPolylineElementId(id);
    }
  };

  return (
    <GoogleMap
      mapContainerClassName="h-[calc(100%-65px)] rounded-xl border-2 border-white transition ease-in mt-2 focus:outline-none focus:ring-none"
      zoom={initialZoom}
      center={initialCenter}
      onMouseDown={onMouseDown}
      onCenterChanged={onCenterChanged}
      onZoomChanged={onZoomChanged}
      options={
        mapSettings.isSatelliteView
          ? {
              ...options,
              mapTypeId: "hybrid",
            }
          : options
      }
      onLoad={(map) => {
        setMapInstance(map);
      }}
    >
      <div>
        <MarkerClusterer
          styles={[
            {
              textColor: "white",
              fontWeight: "700",
              textSize: 15,
              height: 30,
              url: `/markers/driver_cluster.svg`,
              width: 30,
              backgroundPosition: "auto 1px",
            },
          ]}
        >
          {(clusterer) => (
            <>
              {assigneesArray.map((assignee) => {
                if (!assignee.location) {
                  return <></>;
                }

                if (
                  assignee.location.latitude === 0 &&
                  assignee.location.longitude === 0
                ) {
                  return <></>;
                }

                const isHoveredOrderAssignee =
                  orders.get(hoveredElementId || "")?.assignee?.id ==
                  assignee.id;

                const isHovered =
                  hoveredAssigneeId === assignee.id ||
                  focusedAssigneeId === assignee.id ||
                  isHoveredOrderAssignee;

                const isOldUpdatedAt =
                  !assignee.location.updatedAt ||
                  (assignee.location.updatedAt &&
                    differenceInMinutes(
                      new Date(),
                      new Date(assignee.location.updatedAt),
                    ) >= 1);

                const isVeryOldUpdatedAt =
                  !assignee.location.updatedAt ||
                  (assignee.location.updatedAt &&
                    differenceInMinutes(
                      new Date(),
                      new Date(assignee.location.updatedAt),
                    ) >= 60);

                if (isVeryOldUpdatedAt) {
                  return <></>;
                }

                const dutyStatus = assignee.dutyStatus;

                return (
                  <span key={assignee.id}>
                    <Marker
                      position={{
                        lat: assignee.location.latitude,
                        lng: assignee.location.longitude,
                      }}
                      icon={{
                        url: `/markers/driver_${dutyStatus}.png`,
                        scaledSize: {
                          width: 25,
                          height: 25,
                          equals() {
                            return true;
                          },
                        },
                      }}
                      onMouseOver={() => setHoveredAssigneeId(assignee.id)}
                      onMouseOut={() => setHoveredAssigneeId(null)}
                      clusterer={clusterer}
                    />
                    {isHovered && (
                      <Transition show>
                        <div
                          className={clsx([
                            // Base styles
                            "absolute w-48 border transition ease-in-out",
                            // Shared closed styles
                            "data-[closed]:opacity-0",
                            // Entering styles
                            "data-[enter]:duration-100 data-[enter]:data-[closed]:-translate-x-full",
                            // Leaving styles
                            "data-[leave]:duration-300 data-[leave]:data-[closed]:translate-x-full",
                          ])}
                        >
                          <OverlayView
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            position={{
                              lat: assignee.location.latitude,
                              lng: assignee.location.longitude,
                            }}
                          >
                            <div
                              className={clsx(
                                "flex w-40 flex-col rounded-md bg-gray-700 p-2 text-white",
                              )}
                            >
                              <span className="text-xs font-normal">
                                {" "}
                                {assignee.userProfile.name}{" "}
                                {isOldUpdatedAt &&
                                  assignee.location.updatedAt &&
                                  `(${formatDistance(
                                    new Date(assignee.location.updatedAt),
                                    new Date(),
                                    { addSuffix: true },
                                  )})`}
                                {!assignee.location.updatedAt && "(∞ mins ago)"}
                              </span>
                            </div>
                          </OverlayView>
                        </div>
                      </Transition>
                    )}
                  </span>
                );
              })}
            </>
          )}
        </MarkerClusterer>
        <MarkerClusterer
          zoomOnClick={false}
          onClick={onClickMarkerCluster}
          styles={[
            {
              textColor: "white",
              fontWeight: "700",
              textSize: 15,
              height: 30,
              url: `/markers/cluster-marker.png`,
              width: 30,
              backgroundPosition: "0 0px",
            },
          ]}
        >
          {(clusterer) => (
            <>
              {ordersArray.map((order) => {
                const isHovered =
                  hoveredElementId === order.id ||
                  focusedOrderId === order.id ||
                  (!!hoveredAssigneeId &&
                    hoveredAssigneeId === order.assignee?.id);

                let routeOrderIndex: number | null = null;
                if (order.route) {
                  const route = routes.get(order.route.id);
                  if (route && route.ordersOrder.length) {
                    const routeOrder = route.ordersOrder.find(
                      (item) => item.id === order.id,
                    );
                    routeOrderIndex = routeOrder ? routeOrder.index + 1 : 0;
                  }
                }
                return (
                  <span key={order.id}>
                    {order.type.includes("PICKUP") && (
                      <Marker
                        position={{
                          lat: order.pickupLocation?.coordinates[1],
                          lng: order.pickupLocation?.coordinates[0],
                        }}
                        title={order.id}
                        label={
                          routeOrderIndex
                            ? {
                                text: `${routeOrderIndex}` || "",
                                color: `${
                                  ORDER_STATUS_COLORS[getOrderStatus(order)]
                                    .text
                                }`,
                                className: `font-bold mb-[2px] ${
                                  routeOrderIndex
                                    ? `${
                                        ORDER_STATUS_COLORS[
                                          getOrderStatus(order)
                                        ].card.darkBackground
                                      } mb-[24px] text-white ml-[21px] flex justify-center items-center h-[22px] w-[22px] rounded-full border-2 border-white`
                                    : ""
                                }`,
                                fontSize: "10px",
                              }
                            : undefined
                        }
                        icon={{
                          url: getOrderIcon(order, "pickup"),
                          scaledSize: {
                            width: 25,
                            height: 30,
                            equals() {
                              return true;
                            },
                          },
                        }}
                        onMouseOver={() => onChangeHoverElement(order.id)}
                        onMouseOut={() => onChangeHoverElement(null)}
                        clusterer={clusterer}
                        onClick={() => {
                          onClickOrderMarker(order);
                        }}
                      />
                    )}

                    {order.type.includes("PICKUP") &&
                      isHovered &&
                      focusedOrderId !== order.id && (
                        <Transition show>
                          <div
                            className={clsx([
                              // Base styles
                              "absolute w-48 border transition ease-out",
                              // Shared closed styles
                              "data-[closed]:opacity-0",
                              // Entering styles
                              "data-[enter]:duration-100 data-[enter]:scale-100 data-[enter]:data-[closed]:-translate-x-full ",
                              // Leaving styles
                              "data-[leave]:duration-300 data-[leave]:data-[closed]:translate-x-full data-[leave]:scale-95",
                            ])}
                          >
                            <OverlayView
                              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                              position={{
                                lat: order.pickupLocation.coordinates[1],
                                lng: order.pickupLocation.coordinates[0],
                              }}
                            >
                              <div
                                className={clsx(
                                  "flex w-60 flex-col rounded-md p-3 text-white",
                                  ORDER_POPUP_STATUS_BG[order.status],
                                )}
                              >
                                <span
                                  className={clsx(
                                    "text-sm font-normal text-white",
                                  )}
                                >
                                  {order.displayId}
                                </span>
                                <span className="text-sm font-normal">
                                  {order.pickup.address}
                                </span>
                                <div>
                                  <span className="text-sm font-bold">
                                    {order.pickup.name}
                                  </span>
                                </div>
                              </div>
                            </OverlayView>
                          </div>
                        </Transition>
                      )}

                    {order.type.includes("DROPOFF") && (
                      <Marker
                        position={{
                          lat: order.dropoffLocation.coordinates[1],
                          lng: order.dropoffLocation.coordinates[0],
                        }}
                        title={order.id}
                        icon={{
                          url: getOrderIcon(order, "dropoff"),
                          scaledSize: {
                            width: 25,
                            height: 30,
                            equals() {
                              return true;
                            },
                          },
                        }}
                        label={
                          routeOrderIndex
                            ? {
                                text: `${routeOrderIndex}` || "",
                                color: `${
                                  ORDER_STATUS_COLORS[getOrderStatus(order)]
                                    .text
                                }`,
                                className: `font-bold mb-[2px] ${
                                  routeOrderIndex
                                    ? `${
                                        ORDER_STATUS_COLORS[
                                          getOrderStatus(order)
                                        ].card.darkBackground
                                      } mb-[24px] text-white ml-[21px] flex justify-center items-center h-[22px] w-[22px] rounded-full border-2 border-white`
                                    : ""
                                }`,
                                fontSize: "10px",
                              }
                            : undefined
                        }
                        onMouseOver={() => onChangeHoverElement(order.id)}
                        onMouseOut={() => onChangeHoverElement(null)}
                        onClick={() => {
                          onClickOrderMarker(order);
                        }}
                        clusterer={clusterer}
                      />
                    )}
                    {isPolygonSelectionEnable && (
                      <DrawingManager
                        drawingMode={google.maps.drawing.OverlayType.POLYGON}
                        options={{
                          drawingControl: false,
                          polygonOptions: {
                            fillColor: `#000000`,
                            fillOpacity: 0.2,
                            strokeWeight: 5,
                            clickable: false,
                            zIndex: 1,
                          },
                        }}
                        onPolygonComplete={(value) =>
                          handlePolygonComplete(value)
                        }
                      />
                    )}
                    {(mapSettings.isAllRoutesVisible ||
                      focusedOrderId === order.id) &&
                      assignees && (
                        <Route
                          order={order}
                          assignee={assignees.get(order.assigneeId || "")}
                          isHovered={isHovered}
                          setHoveredPolylineElementId={onChangeHoverElement}
                        />
                      )}
                    {order.type.includes("DROPOFF") &&
                      isHovered &&
                      focusedOrderId !== order.id && (
                        <Transition show>
                          <div
                            className={clsx([
                              // Base styles
                              "absolute w-48 border transition ease-out",
                              // Shared closed styles
                              "data-[closed]:opacity-0",
                              // Entering styles
                              "data-[enter]:duration-100 data-[enter]:scale-100 data-[enter]:data-[closed]:-translate-x-full ",
                              // Leaving styles
                              "data-[leave]:duration-300 data-[leave]:data-[closed]:translate-x-full data-[leave]:scale-95",
                            ])}
                          >
                            <OverlayView
                              mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                              position={{
                                lat: order.dropoffLocation.coordinates[1],
                                lng: order.dropoffLocation.coordinates[0],
                              }}
                            >
                              <div
                                className={clsx(
                                  "flex w-60 flex-col rounded-md p-3 text-white",
                                  ORDER_POPUP_STATUS_BG[order.status],
                                )}
                              >
                                <span
                                  className={clsx(
                                    "text-sm font-normal text-white",
                                  )}
                                >
                                  {order.displayId}
                                </span>
                                <span className="text-sm font-normal">
                                  {order.dropoff.address}
                                </span>
                                <span className="text-sm font-bold">
                                  {order.dropoff.name}
                                </span>
                              </div>
                            </OverlayView>
                          </div>
                        </Transition>
                      )}
                  </span>
                );
              })}
              {mapSettings.isAllRoutesVisible &&
                routesArray.map((route) => {
                  const isRouteHovered =
                    hoveredElementId === route.id ||
                    focusedRouteId === route.id;
                  return (
                    <Route
                      key={route.id}
                      route={route}
                      isHovered={isRouteHovered}
                      setHoveredPolylineElementId={onChangeHoverElement}
                    />
                  );
                })}
              {!mapSettings.isAllRoutesVisible && focusedRouteId && (
                <Route
                  route={routes.get(focusedRouteId)}
                  isHovered={true}
                  setHoveredPolylineElementId={onChangeHoverElement}
                />
              )}
            </>
          )}
        </MarkerClusterer>
        <Settings
          settings={mapSettings}
          setSettings={(val) => {
            useDispatchStore.setState({
              mapSettings: val,
            });
          }}
        />
        {mapSettings.isTrafficVisible && (
          <TrafficLayer
            options={{
              autoRefresh: true,
            }}
          />
        )}

        {!!clusterOrders.length && clusterLocation && isOrderClusterVisible && (
          <ClusterModal
            selectedClusterLocation={clusterLocation}
            clusterOrders={clusterOrders}
            onClickOrderMarker={(order) => {
              onClickOrderMarker(order);
            }}
            closeClusterModal={closeClusterModal}
          />
        )}
      </div>
    </GoogleMap>
  );
}
