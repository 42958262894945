import { useQueryClient } from "@tanstack/react-query";
import {
  IServerSideGetRowsParams,
  SelectionChangedEvent,
} from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";

import {
  exportOrders,
  getOrders,
  useCustomFieldsAndGroups,
  useFeatureflags,
} from "@web-app/api";
import { Button } from "@web-app/components";
import { useBrowserCache } from "@web-app/hooks";
import { Order } from "@web-app/types";

import BulkActionsTooltip from "../common/BulkActions";
import OrdersFilterDropdown from "../common/Orders/OrdersFilterDropdown";
import { INITIAL_APP_FILTER_DATA } from "../common/Orders/data";

import Table from "./Table";
import { getInitialTableData } from "./data";

const OrderFormModal = React.lazy(() => import("../common/OrderFormModal"));

type OrderTableContainerProps = {
  selectedOrder: Order | null;
  setSelectedOrder: (val: Order | null) => void;
  setAgGridRef: (val: AgGridReact | null) => void;
  refetchOrders: () => void;
};

const OrderTableContainer: FC<OrderTableContainerProps> = ({
  selectedOrder,
  setSelectedOrder,
  setAgGridRef,
  refetchOrders,
}) => {
  const queryClient = useQueryClient();
  const { hasFeature } = useFeatureflags();
  const [{ data: customFields }] = useCustomFieldsAndGroups();

  const columnDefs = useMemo(() => {
    const defs = getInitialTableData(customFields);
    if (hasFeature("bulkActions")) {
      defs.push({
        headerCheckboxSelection: true,
        headerCheckboxSelectionCurrentPageOnly: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        lockPosition: "left",
        maxWidth: 100,
        suppressHeaderMenuButton: true,
      });
    }
    return defs;
  }, [customFields]);

  const [isOrderModalOpen, setIsOrderModalOpen] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState<string[]>([]);
  const [pageSize, setPageSize] = useBrowserCache("tablePageSize", 20);

  const [appFilter, setAppFilter] = useBrowserCache(
    "tableAppFilter",
    INITIAL_APP_FILTER_DATA,
  );

  // console.log(columnDefs);
  const gridRef = useRef<AgGridReact>(null);
  const selectedOrderRef = useRef<{ selectedOrder: Order | null }>({
    selectedOrder: null,
  });
  const [selectedOrderIds, setSelectedOrderIds] = useState<string[]>([]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      // allow every column to be grouped
      enableRowGroup: false,
      enablePivot: false,
      sortable: false,
    };
  }, []);
  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
    };
  }, []);

  const datasource = useMemo(() => {
    return {
      getRows: async (params: IServerSideGetRowsParams) => {
        const isAssigneeGroup =
          params.request.rowGroupCols?.[0]?.displayName === "Assignee";
        const isWorkspaceGroup =
          params.request.rowGroupCols?.[0]?.displayName === "Connections";
        const isStatusGroup =
          params.request.rowGroupCols?.[0]?.displayName === "Status";
        const ordersData: Order[] = await queryClient.fetchQuery({
          queryKey: ["orders"],
          queryFn: async () => {
            const result = await getOrders({
              offset: params.request.startRow,
              sortColumn:
                params.request.sortModel?.[0]?.colId ??
                "scheduledStartDateTime",
              sortBy: params.request.sortModel?.[0]?.sort ?? "asc",
              groupBy: isAssigneeGroup
                ? "assignee"
                : isWorkspaceGroup
                  ? "workspace"
                  : isStatusGroup
                    ? "status"
                    : undefined,
              startDate: appFilter.scheduledDateTimeStart || "",
              endDate: appFilter.scheduledDateTimeEnd || "",
              status: appFilter.orderStatus.join(","),
              orderDeliveryType: appFilter.orderDeliveryType.join(","),
              workspaceIds: isWorkspaceGroup
                ? params.request.groupKeys.join(",")
                : appFilter.workspaceIds.join(","),
              driverIds: isAssigneeGroup
                ? params.request.groupKeys.join(",")
                : appFilter.assigneeIds.join(","),
            });
            return result;
          },
        });

        params.success({
          rowData: ordersData || [],
          rowCount: ordersData?.length || 0,
        });

        if (selectedOrderRef?.current?.selectedOrder) {
          const updatedSelectedOrder = ordersData?.find(
            (order) =>
              order.id === selectedOrderRef?.current?.selectedOrder?.id,
          );
          setSelectedOrder(
            updatedSelectedOrder || selectedOrderRef?.current?.selectedOrder,
          );
        }
      },
    };
  }, [appFilter, queryClient, setSelectedOrder]);

  useEffect(() => {
    if (gridRef.current) {
      if (customFields) {
        gridRef?.current?.api?.setGridOption(
          "serverSideDatasource",
          // @ts-ignore - setGridOption is not assignable to type
          datasource,
        );
        gridRef?.current?.api?.refreshServerSide({ purge: true });
      }
    }
  }, [appFilter, datasource, customFields]);

  useEffect(() => {
    selectedOrderRef.current.selectedOrder = selectedOrder;
  }, [selectedOrder]);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
          toolPanelParams: {
            suppressRowGroups: false,
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnFilter: true,
            suppressColumnSelectAll: false,
            suppressColumnExpandAll: true,
          },
        },
      ],
      defaultToolPanel: "",
    };
  }, []);

  const updateVisibleColumns = useCallback(() => {
    const allDisplayedColumns = gridRef?.current?.api?.getAllDisplayedColumns();
    const visibleColumnFields = allDisplayedColumns?.map(
      (col) => col.getColDef().headerName || "",
    );
    if (visibleColumnFields) {
      setVisibleColumns(visibleColumnFields);
    }
  }, []);

  const updateGridState = () => {
    const columnState = localStorage.getItem(
      customFields?.length ? "columnStateWithCustomFields" : "columnState",
    );

    if (columnState) {
      gridRef.current?.api?.applyColumnState({
        state: JSON.parse(columnState),
        applyOrder: true,
      });
    }
  };

  useEffect(() => {
    if (columnDefs.length) {
      updateGridState();
    }
  }, [columnDefs]);

  const setColumnStates = () => {
    const columnState = gridRef?.current?.api?.getColumnState();
    localStorage.setItem(
      customFields?.length ? "columnStateWithCustomFields" : "columnState",
      JSON.stringify(columnState),
    );
  };

  const handleUpdateVisibleColumns = () => {
    setColumnStates();
    updateVisibleColumns();
  };

  const handleColumnMoved = () => {
    setColumnStates();
    updateVisibleColumns();
  };

  const handlePaginationChange = () => {
    const currentPageSize = gridRef?.current?.api.paginationGetPageSize();
    setPageSize(currentPageSize || 20);
  };

  const onGridReady = () => {
    updateVisibleColumns();
    updateGridState();
    setAgGridRef(gridRef.current);
  };

  const onSelectionChange = (params: SelectionChangedEvent) => {
    const selectedRows = gridRef.current?.api?.getSelectedRows();
    const selectAllState =
      // @ts-ignore
      params.api.selectionService.selectionStrategy.selectedState.selectAll;
    const selectionEvent = params.type;
    const eventSource = params.source;

    const isSelectAllEvent =
      selectionEvent === "selectionChanged" && selectAllState;

    if (eventSource == "uiSelectAllCurrentPage") {
      const renderedRows = gridRef.current?.api?.getRenderedNodes() ?? [];
      if (isSelectAllEvent) {
        renderedRows.forEach((row) => row.setSelected(true));
      } else {
        renderedRows.forEach((row) => row.setSelected(false));
      }
    } else {
      if (selectedRows?.length) {
        if (selectedRows.length > 1) {
          setSelectedOrderIds(selectedRows.map((row) => row.id));
          setSelectedOrder(null);
        } else {
          const order = selectedRows[0];
          setSelectedOrder(order);
          setSelectedOrderIds([order.id]);
        }
      } else {
        setSelectedOrder(null);
        setSelectedOrderIds([]);
      }
    }
  };

  const onExport = async () => {
    const location: string = await toast.promise(
      exportOrders(
        {
          scheduledDateTimeStart: appFilter.scheduledDateTimeStart,
          scheduledDateTimeEnd: appFilter.scheduledDateTimeEnd,
          orderStatus: appFilter.orderStatus,
          driverStatus: appFilter.driverStatus,
          orderDeliveryType: appFilter.orderDeliveryType,
          workspaceIds: appFilter.workspaceIds,
          assigneeIds: appFilter.assigneeIds,
        },
        visibleColumns,
      ),
      {
        loading: "Exporting orders...",
        success: "Orders exported successfully",
        error: "Error exporting orders",
      },
    );

    if (location) {
      window.open(location, "_blank");
    }
  };

  const onUnselectAllOrders = () => {
    setSelectedOrderIds([]);
    gridRef.current?.api?.deselectAll();
  };

  const onSuccessBulkAction = () => {
    onUnselectAllOrders;
    refetchOrders();
  };

  return (
    <div className="h-full">
      {hasFeature("bulkActions") && selectedOrderIds.length > 1 && (
        <BulkActionsTooltip
          className="h-[34px]"
          selectedOrderIds={selectedOrderIds}
          onSuccess={onSuccessBulkAction}
          onUnselectAllOrders={onUnselectAllOrders}
        />
      )}

      {(!hasFeature("bulkActions") ||
        (hasFeature("bulkActions") && selectedOrderIds.length <= 1)) && (
        <div className="flex justify-between">
          <div className="flex gap-4">
            <Button icon="plus" onClick={() => setIsOrderModalOpen(true)}>
              Add Order
            </Button>
          </div>
          <div className="flex gap-4">
            <OrdersFilterDropdown
              appFilter={appFilter}
              setAppFilter={setAppFilter}
              isDateRange
            />
            <Button onClick={onExport} icon="fileExport" appearance="secondary">
              Export
            </Button>
          </div>
        </div>
      )}

      <OrderFormModal
        isOpen={isOrderModalOpen}
        setIsOpen={setIsOrderModalOpen}
      />
      <div className="mt-4 h-[calc(100%-50px)] w-full ag-theme-quartz">
        <Table
          rowModelType="serverSide"
          rowHeight={70}
          defaultColDef={defaultColDef}
          // @ts-ignore
          columnDefs={columnDefs}
          autoGroupColumnDef={autoGroupColumnDef}
          onGridReady={onGridReady}
          pagination
          paginationPageSizeSelector={[20, 50, 100]}
          paginationPageSize={pageSize}
          onPaginationChanged={handlePaginationChange}
          cacheBlockSize={100}
          sideBar={sideBar}
          loadingCellRenderer={() => (
            <div className="animate-pulse h-3.5 bg-gray-300 rounded col-span-1 w-full m-3"></div>
          )}
          rowSelection={hasFeature("bulkActions") ? "multiple" : "single"}
          suppressRowDeselection={true}
          // @ts-ignore
          onSelectionChanged={onSelectionChange}
          ref={gridRef}
          onColumnVisible={handleUpdateVisibleColumns}
          onColumnMoved={handleColumnMoved}
        />
      </div>
    </div>
  );
};

export default OrderTableContainer;
