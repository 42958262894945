import AssigneeActivity from "@web-app/pages/common/Orders/OrderTimeline/AssigneeActivity";
import type { Order, OrderTimeline } from "@web-app/types";

import TrackingStatusActivity from "./TrackingStatusActivity";
import {
  OrderTimelineSchemaV2,
  generateStatusBasedOnTimelineActivity,
} from "./helper";

type TrackingOrderTimelineTypes = {
  order: Order;
  timeline: OrderTimeline[];
};

export default function TrackingOrderTimeline(
  props: TrackingOrderTimelineTypes,
) {
  const timelineData = props.timeline;

  const filteredTimeline = timelineData?.filter((item) => {
    if (item.entity === "assigneeId") {
      return true;
    } else if (generateStatusBasedOnTimelineActivity(item)) {
      return true;
    } else return false;
  });

  return (
    <>
      <ul role="list" className="space-y-6">
        {filteredTimeline &&
          filteredTimeline.map((item, itemIdx) => {
            if (OrderTimelineSchemaV2[item.entity]) {
              const showTimelineBar =
                itemIdx !== (filteredTimeline?.length || 0) - 1;

              return item.entity === "assigneeId" ? (
                <AssigneeActivity
                  key={item.id}
                  item={item}
                  showTimelineBar={showTimelineBar}
                />
              ) : (
                <TrackingStatusActivity
                  key={item.id}
                  item={item}
                  showTimelineBar={showTimelineBar}
                />
              );
            }
          })}
      </ul>
    </>
  );
}
