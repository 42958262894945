export type App = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  secret: string;
  callbackUrl: string;
  avatarUrl: string;
  workspaceId: string;
  events: AppEventType[];
  systemApp: SystemApp;
  userId: string;
};

export type CreateAppPayload = {
  name: string;
  secret: string;
  callbackUrl: string;
  avatarUrl: string;
  workspaceId: string;
  events: AppEventType[];
  systemApp?: SystemApp;
};

export type UpdateAppPayload = Omit<CreateAppPayload, "workspaceId">;

export enum AppEventType {
  ORDER_CREATED = "ORDER_CREATED",
  ORDER_UPDATED = "ORDER_UPDATED",
  ORDER_CANCELLED = "ORDER_CANCELLED",
  ORDER_IN_TRANSIT_TO_PICKUP = "ORDER_IN_TRANSIT_TO_PICKUP",
  ORDER_ARRIVED_AT_PICKUP = "ORDER_ARRIVED_AT_PICKUP",
  ORDER_PICKED_UP = "ORDER_PICKED_UP",
  ORDER_IN_TRANSIT_TO_DROPOFF = "ORDER_IN_TRANSIT_TO_DROPOFF",
  ORDER_ARRIVED_AT_DROPOFF = "ORDER_ARRIVED_AT_DROPOFF",
  ORDER_DROPPED_OFF = "ORDER_DROPPED_OFF",
  ORDER_ASSIGNED = "ORDER_ASSIGNED",
  ORDER_UNASSIGNED = "ORDER_UNASSIGNED",
  ROUTE_CREATED = "ROUTE_CREATED",
  ROUTE_UPDATED = "ROUTE_UPDATED",
}

export enum SystemApp {
  PUBLIC_FORM = "PUBLIC_FORM",
}
