import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { LicenseManager } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React from "react";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-061353}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Fulflld,_Inc}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Financial_Admin}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Financial_Admin}_need_to_be_licensed___{Financial_Admin}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{7_July_2025}____[v3]_[01]_MTc1MTg0MjgwMDAwMA==2bc93f3b29db6f80a8be96925c5ed237",
);

const Table = React.forwardRef<
  AgGridReact,
  React.ComponentProps<typeof AgGridReact>
>((props, ref) => {
  return <AgGridReact {...props} ref={ref} />;
});

export default Table;
