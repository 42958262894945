import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

import { useCustomFieldMutation } from "@web-app/api";
import {
  Button,
  Input,
  SelectInput,
  Sidebar,
  Toggle,
} from "@web-app/components";

import { customFieldSchema, inputTypeOptions } from "../utils";

export default function CreateCustomFieldsModal({
  onClose,
  workspaceId,
  groupId,
}: {
  onClose: () => void;
  workspaceId: string;
  groupId: string;
}) {
  const { createCustomField } = useCustomFieldMutation();
  const { handleSubmit, control, watch } = useForm({
    resolver: zodResolver(customFieldSchema),
    reValidateMode: "onChange",
    shouldUseNativeValidation: false,
    mode: "onTouched",
    defaultValues: {
      type: "TEXT",
      name: "",
      helpText: "",
      defaultValue: "",
      isVisibleMobile: false,
      isRequired: false,
      isActive: true,
      groupId,
    },
  });

  const getIsRequired = (
    propertyName: keyof z.infer<typeof customFieldSchema>,
  ) => {
    return !(customFieldSchema.shape[propertyName] instanceof z.ZodOptional);
  };

  const onSubmit = async (data: z.infer<typeof customFieldSchema>) => {
    const payload = {
      name: data.name,
      helpText: data.helpText,
      defaultValues: [
        {
          value: data.defaultValue,
          isSelected: false,
        },
      ],
      type: data.type,
      workspaceId: workspaceId,
      isVisibleOrder: true,
      isVisibleMobile: data.isVisibleMobile,
      isRequired: false,
      isVisibleAddressBook: false,
      isActive: data.isActive,
      groupId: groupId,
      isMulti: false,
    };

    await toast.promise(createCustomField.mutateAsync(payload), {
      loading: "Creating custom field...",
      success: "Custom field created successfully",
      error: "Failed to create custom field",
    });

    onClose();
  };

  const type = ["TEXT", "NUMBER"].includes(watch("type"))
    ? (watch("type") as "text" | "number")
    : "text";

  return (
    <Sidebar width="w-[550px]" isOpen>
      <div className=" px-5 md:px-8 lg:px-10 xl:px-14 py-7 h-full relative overflow-y-auto ">
        <h2 className="text-2xl leading-8 font-semibold text-gray-700 mb-7">
          Create New Field
        </h2>
        <form
          onSubmit={
            // @ts-expect-error - handleSubmit type is not compatible with onSubmit type
            handleSubmit(onSubmit)
          }
          className="w-full max-w-full flex flex-col"
        >
          <div className="flex flex-col divide-y gap-8 h-full min-h-full max-h-full mb-[90px]">
            <div className="flex flex-col gap-8">
              <div className="relative">
                <Controller
                  name="type"
                  control={control}
                  render={({
                    field: { value, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <SelectInput
                        label="Field Type"
                        placeholder="Select input type"
                        options={inputTypeOptions}
                        required
                        selected={value}
                        onChange={(value) => {
                          onChange(value);
                        }}
                        {...rest}
                      />
                      <ErrorMessage message={error?.message} />
                    </>
                  )}
                />
              </div>
              <div className="relative">
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Input
                        type="text"
                        label="Field Name"
                        {...field}
                        required={getIsRequired("name")}
                      />
                      <ErrorMessage message={error?.message} />
                    </>
                  )}
                />
              </div>
              <div className="relative">
                <Controller
                  name="helpText"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Input
                        type="text"
                        label="Help Text"
                        {...field}
                        required={getIsRequired("helpText")}
                      />
                      <ErrorMessage message={error?.message} />
                    </>
                  )}
                />
              </div>
              <div className="relative">
                <Controller
                  name="defaultValue"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Input
                        type={type}
                        label="Default Value"
                        {...field}
                        required={getIsRequired("defaultValue")}
                      />
                      <ErrorMessage message={error?.message} />
                    </>
                  )}
                />
              </div>
            </div>
            <div>
              <div className="mt-8 flex flex-col gap-8">
                <Controller
                  name="isVisibleMobile"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Toggle
                        label={field.value ? "Enabled" : "Disabled"}
                        title="Show in driver app"
                        titleHelpText="In enabled, this field will be shown in the driver app"
                        isChecked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      />
                    </>
                  )}
                />

                {/* NOTE: Temporary disabling this */}
                {/* <Controller
                  name="isRequired"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Toggle
                        label={field.value ? "Enabled" : "Disabled"}
                        title="Make it mandatory"
                        titleHelpText="Mandatory fields must be filled out while creating an order"
                        isChecked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      />
                    </>
                  )}
                /> */}

                <Controller
                  name="isActive"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Toggle
                        label={field.value ? "Enabled" : "Disabled"}
                        title="Status"
                        titleHelpText="If activated, this field will be displayed in the form"
                        isChecked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      />
                    </>
                  )}
                />
              </div>
            </div>
          </div>

          <div className="fixed bg-white bottom-0 left-0 right-0 py-6 px-5 md:px-8 lg:px-10 xl:px-14 py flex gap-4 border-t ">
            <Button type="submit" isLoading={createCustomField.isPending}>
              Create
            </Button>
            <Button appearance="secondary" type="button" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}

const ErrorMessage = ({ message }: { message: string | undefined }) => (
  <span className="text-red-500 text-xs absolute -bottom-5">{message}</span>
);
