import { Polyline } from "@react-google-maps/api";

import useDispatchStore from "@web-app/store/dispatchStore";
import { DriverEntity, Order } from "@web-app/types";

import { AssigneeRoute } from "./AssigneeRoute";

const ORDER_STATUS_ROUTE_COLOR: Record<Order["status"], string> = {
  TODO: "#141414",
  INPROGRESS: "#28BFFF",
  COMPLETE: "#008760",
  CANCELLED: "#FD4242",
  FAILED: "#FD4242",
  ASSIGNED: "#9747FF",
};

export default function IndividualOrderRoute({
  order,
  assignee,
  isHovered,
  setHoveredPolylineElementId,
}: {
  order: Order;
  assignee?: DriverEntity;
  isHovered: boolean;
  setHoveredPolylineElementId: (orderId: string | null) => void;
}) {
  const route = order.routePolyline?.routes?.[0];

  if (!route) {
    return (
      <>
        <AssigneeRoute
          order={order}
          assignee={assignee}
          isHovered={isHovered}
        />
        {order.type === "INDIVIDUAL_PICKUP_AND_DROPOFF" && (
          <Polyline
            path={[
              {
                lat: order.pickupLocation.coordinates[1],
                lng: order.pickupLocation.coordinates[0],
              },
              {
                lat: order.dropoffLocation.coordinates[1],
                lng: order.dropoffLocation.coordinates[0],
              },
            ]}
            options={{
              strokeWeight: 3,
              strokeColor: ORDER_STATUS_ROUTE_COLOR[order.status],
              strokeOpacity: isHovered ? 1 : 0.3,
              icons: [
                {
                  icon: {
                    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    strokeOpacity: isHovered ? 1 : 0.3,
                    fillColor: ORDER_STATUS_ROUTE_COLOR[order.status],
                    fillOpacity: isHovered ? 1 : 0.3,
                    strokeWeight: 2,
                  },
                  offset: "50%",
                },
              ],
            }}
            onMouseOver={() => setHoveredPolylineElementId(order.id)}
            onMouseOut={() => setHoveredPolylineElementId(null)}
          />
        )}
      </>
    );
  }

  return (
    <>
      <AssigneeRoute order={order} assignee={assignee} isHovered={isHovered} />
      <Polyline
        key={order.id}
        path={window.google?.maps.geometry?.encoding.decodePath(
          route.polyline.encodedPolyline,
        )}
        options={{
          strokeWeight: 4,
          strokeColor: ORDER_STATUS_ROUTE_COLOR[order.status],
          strokeOpacity: isHovered ? 1 : 0.3,
          icons: [
            {
              icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                strokeOpacity: isHovered ? 1 : 0.3,
                fillColor: ORDER_STATUS_ROUTE_COLOR[order.status],
                fillOpacity: isHovered ? 1 : 0.3,
                strokeWeight: 2,
              },
              offset: "70%",
            },
            {
              icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                strokeOpacity: isHovered ? 1 : 0.3,
                fillColor: ORDER_STATUS_ROUTE_COLOR[order.status],
                fillOpacity: isHovered ? 1 : 0.3,
                strokeWeight: 2,
              },
              offset: "30%",
            },
          ],
        }}
        onMouseOver={() => setHoveredPolylineElementId(order.id)}
        onMouseOut={() => setHoveredPolylineElementId(null)}
        onClick={() => {
          useDispatchStore.setState({
            focusedOrderId: order.id,
          });
        }}
      />
    </>
  );
}
