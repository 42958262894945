import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";

import {
  useFeatureflags,
  useGetDrivers,
  useGetOrder,
  useOrderMutation,
  useRouteMutation,
  useWorkspaceConnections,
} from "@web-app/api";
import {
  Accordion,
  AccordionV2,
  Icon,
  Input,
  LoadingIcon,
  OrderCard,
  RouteCard,
  SelectInput,
} from "@web-app/components";
import { IconsType } from "@web-app/components/Icon/utils";
import useDispatchStore from "@web-app/store/dispatchStore";
import { DutyStatus, Order, OrderSideBarEntity, Route } from "@web-app/types";

import OrderDetailsSidebar from "../Orders/OrderDetailsSidebar";

import SidebarTableView from "./SidebarTableView";
import {
  SortOptionType,
  getGroupedSideBarEntities,
  getSearchResultThroughProperty,
  getSortedOrderSideBarEntities,
  groupOptions,
  sortOptions,
} from "./utils";

type SidebarMode = "list" | "table";

export default function OrdersSidebar({
  sideBarMode,
  setSidebarMode,
}: {
  sideBarMode: SidebarMode;
  setSidebarMode: (mode: SidebarMode) => void;
}) {
  const { focusedOrderId, showOrderDetailsPanel } = useDispatchStore();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isReadOnly, setIsReadOnly] = useState(true);
  const { data: orders, isSuccess: isSuccessOrders } = useGetOrder({
    refetchInterval: 30000,
  });
  const { copyOrder, updateOrder, deleteOrderById } = useOrderMutation();
  const { data: assignees, isSuccess: isSuccessAssignees } = useGetDrivers({
    refetchInterval: 25000,
  });
  const { isSuccess: isSuccessWC } = useWorkspaceConnections();
  const { routeRegenerate } = useRouteMutation();
  const routes = useMemo(() => {
    if (!orders) {
      return new Map<string, Route>();
    }
    const routes = new Map<string, Route>();
    orders?.forEach((order) => {
      if (order.route) {
        // @ts-ignore FIXME: need to fix here
        routes.set(order.route.id, order.route);
      }
    });
    return routes;
  }, [orders, assignees]);

  useEffect(() => {
    useDispatchStore.setState({
      routes: routes,
    });
  }, [routes]);

  const onEdit = (orderId: string) => {
    useDispatchStore.setState({
      focusedOrderId: orderId,
      showOrderDetailsPanel: true,
    });
    setIsReadOnly(false);
  };

  const onCloseEdit = () => {
    useDispatchStore.setState({
      focusedOrderId: "",
      showOrderDetailsPanel: false,
    });
    setIsReadOnly(true);
  };

  const [sortParameter, setSortParameter] = useState<SortOptionType>(
    sortOptions[0],
  );
  const [groupParameter, setGroupParameter] = useState(groupOptions[0]);
  const { hasFeature } = useFeatureflags();

  const onShare = async (orderId: string, connectedWorkspaceId: string) => {
    await toast.promise(
      updateOrder.mutateAsync({
        id: orderId,
        connectedWorkspaceId,
      }),
      {
        loading: "Sending Order ...",
        success: "Successfully Sent Order",
        error: "Something went wrong, please try again!",
      },
    );
  };

  const onDelete = async (orderId: string) => {
    if (!isSuccessOrders) {
      return;
    }

    const order = orders.get(orderId)!;
    const orderDeliveryType = order.type;
    if (order.route) {
      const routeId = order.route.id;
      const route = routes.get(routeId)!;
      const routeOrders = route.ordersOrder.map(({ id }) => orders.get(id));
      const pickupOrderCount = routeOrders.filter(
        (order) => order?.type === "ROUTE_OPT_PICKUP",
      );
      const dropoffOrderCount = routeOrders.filter(
        (order) => order?.type === "ROUTE_OPT_DROPOFF",
      );

      if (
        pickupOrderCount.length === 1 &&
        orderDeliveryType === "ROUTE_OPT_PICKUP"
      ) {
        toast.error("Need atleast one Pickup order!");
        return;
      }

      if (
        dropoffOrderCount.length === 1 &&
        orderDeliveryType === "ROUTE_OPT_DROPOFF"
      ) {
        toast.error("Need atleast one Drop-off order!");
        return;
      }
    }

    await toast.promise(deleteOrderById.mutateAsync(orderId), {
      loading: "Deleting Order ...",
      success: "Successfully Deleted Order",
      error: "Something went wrong, please try again!",
    });
  };

  const onCopy = async (orderId: string) => {
    await toast.promise(copyOrder.mutateAsync(orderId), {
      loading: "Copying Order ...",
      success: "Successfully Copied Order",
      error: "Something went wrong, please try again!",
    });
  };

  const onViewDetails = (orderId: string) => {
    setIsReadOnly(true);
    useDispatchStore.setState({
      focusedOrderId: orderId,
      showOrderDetailsPanel: true,
    });
  };

  const ordersWithoutRoutes = useMemo(() => {
    const ordersWithoutRoutes: Order[] = [];
    for (const order of orders?.values() || []) {
      if (!order.route) {
        ordersWithoutRoutes.push(order);
      }
    }
    return ordersWithoutRoutes;
  }, [orders]);

  const { orderSideBarEntities } = useMemo(() => {
    if (!isSuccessOrders || !isSuccessAssignees) {
      return {
        orderSideBarEntities: [],
      };
    }

    const filteredOrders: Order[] = [];
    const filteredRoutes: Route[] = [];

    // TODO: Need to fix here
    ordersWithoutRoutes.forEach((order) => {
      const pickupAddress = order?.pickup?.address;
      const dropoffAddress = order?.dropoff?.address;
      const orderDisplayId = order?.displayId;
      const recipientName = order?.dropoff?.name;

      if (
        getSearchResultThroughProperty(searchTerm, pickupAddress || "") ||
        getSearchResultThroughProperty(searchTerm, orderDisplayId) ||
        getSearchResultThroughProperty(searchTerm, recipientName) ||
        getSearchResultThroughProperty(searchTerm, dropoffAddress!)
      ) {
        filteredOrders.push(order);
      }
    });
    if (routes.size && orders) {
      routes.forEach((route) => {
        const routeOrders = route.ordersOrder.reduce<Order[]>(
          (accum, order) => {
            const data = orders.get(order.id);
            if (data?.id) {
              accum.push(data);
            }
            return accum;
          },
          [],
        );

        if (routeOrders?.length !== route.ordersOrder?.length) {
          routeRegenerate.mutateAsync({
            // @ts-ignore TODO: fix this
            routeId: route?.id,
            ordersOrder: routeOrders.map((data, index) => ({
              id: data.id,
              type: "order",
              index,
            })),
          });
        }

        let doesSearchTermMatch = false;

        routeOrders.forEach((order) => {
          const pickupAddress = order?.pickup?.address;
          const dropoffAddress = order?.dropoff?.address;
          const orderDisplayId = order?.displayId;
          const recipientName = order?.dropoff?.name;

          if (
            getSearchResultThroughProperty(searchTerm, pickupAddress || "") ||
            getSearchResultThroughProperty(searchTerm, orderDisplayId) ||
            getSearchResultThroughProperty(searchTerm, recipientName) ||
            getSearchResultThroughProperty(searchTerm, dropoffAddress!)
          ) {
            doesSearchTermMatch = true;
          }
        });
        if (doesSearchTermMatch) {
          filteredRoutes.push(route);
        }
      });
    }

    const orderSideBarEntities: OrderSideBarEntity[] = [];

    filteredRoutes.forEach((route) => {
      orderSideBarEntities.push({ type: "route", entity: route });
    });
    filteredOrders.forEach((order) => {
      orderSideBarEntities.push({ type: "order", entity: order });
    });

    const sortedOrderSidebarEntities = Array.from(
      getSortedOrderSideBarEntities(
        orderSideBarEntities,
        sortParameter.value,
        orders,
      ).values(),
    );
    return {
      orderSideBarEntities: sortedOrderSidebarEntities,
    };
  }, [
    isSuccessOrders,
    isSuccessAssignees,
    ordersWithoutRoutes,
    routes,
    orders,
    sortParameter.value,
    searchTerm,
  ]);

  const onChangeSortParameter = (value: string) => {
    const sortOption = sortOptions.find((option) => option.value === value);
    if (sortOption) {
      setSortParameter(sortOption);
    }
  };

  const onChangeGroupParameter = (value: string) => {
    const groupOption = groupOptions.find((option) => option.value === value);
    if (groupOption) {
      setGroupParameter(groupOption);
    }
  };

  const { groupedSideBarEntities, workspaceNameById, assigneeNameById } =
    getGroupedSideBarEntities(orderSideBarEntities, assignees!);

  const isLoading = !isSuccessOrders || !isSuccessAssignees || !isSuccessWC;

  return (
    <>
      <div className="relative mt-2 flex h-[calc(100%-80px)] flex-col gap-2 overflow-y-auto ">
        <>
          <div className="sticky top-0 bg-white px-4 z-[15] pb-3">
            <div className="col-span-12 w-full mt-1">
              <Input
                label=""
                type="text"
                value={searchTerm}
                placeholder="Search by ID, Address or Receipient"
                onChange={(e) => setSearchTerm(e.target.value)}
                disabled={sideBarMode === "table"}
              />
            </div>
            <div className="flex justify-between items-center mt-2">
              {hasFeature("bulkActions") && (
                <div className="mt-5">
                  <SidebarTabs
                    selectedTab={sideBarMode}
                    onSelectTab={setSidebarMode}
                  />
                </div>
              )}
              <div
                className={clsx(
                  "flex justify-between gap-[4px]",
                  !hasFeature("bulkActions") && "w-full",
                )}
              >
                <div
                  className={clsx(
                    "w-[125px]",
                    !hasFeature("bulkActions") && "w-full",
                  )}
                >
                  <SelectInput
                    label="Sort By"
                    required
                    options={sortOptions}
                    selected={sortParameter.value}
                    disabled={sideBarMode === "table"}
                    onChange={(value) => {
                      onChangeSortParameter(value);
                    }}
                  />
                </div>
                <div
                  className={clsx(
                    "w-[150px]",
                    !hasFeature("bulkActions") && "w-full",
                  )}
                >
                  <SelectInput
                    label="Group By"
                    required
                    options={groupOptions}
                    selected={groupParameter.value}
                    disabled={sideBarMode === "table"}
                    onChange={(value) => {
                      onChangeGroupParameter(value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {isLoading && (
            <div className="grid place-content-center h-full  w-full">
              <div className="flex items-center gap-4">
                <LoadingIcon className="w-8 h-8" />
                Loading Orders
              </div>
            </div>
          )}

          {!isLoading &&
            (!hasFeature("bulkActions") ||
              (hasFeature("bulkActions") && sideBarMode === "list")) && (
              <div className="flex flex-col gap-2 px-4">
                {isSuccessOrders &&
                  isSuccessAssignees &&
                  groupParameter.value === "none" && (
                    <SideBarEntities
                      sideBarEntities={orderSideBarEntities}
                      onEdit={onEdit}
                      onViewDetails={onViewDetails}
                      onCopy={onCopy}
                      onDelete={onDelete}
                      onShare={onShare}
                      searchTerm={searchTerm}
                    />
                  )}

                {groupParameter.value === "connections" && (
                  <>
                    {Object.entries(groupedSideBarEntities).map(
                      ([workspaceId, sideBarEntityByAssignee]) => (
                        <>
                          <Accordion
                            label={workspaceNameById[workspaceId]}
                            labelClassName="text-sm font-medium flex justify-start"
                          >
                            <div className="mb-2">
                              {Object.entries(sideBarEntityByAssignee).map(
                                ([assigneeId, sideBarEntities]) => {
                                  return (
                                    <SideBarEntities
                                      key={assigneeId}
                                      sideBarEntities={sideBarEntities}
                                      onEdit={onEdit}
                                      onViewDetails={onViewDetails}
                                      onCopy={onCopy}
                                      onDelete={onDelete}
                                      onShare={onShare}
                                      searchTerm={searchTerm}
                                    />
                                  );
                                },
                              )}
                            </div>
                          </Accordion>
                        </>
                      ),
                    )}
                  </>
                )}

                {groupParameter.value === "connections-drivers" && (
                  <>
                    {Object.entries(groupedSideBarEntities).map(
                      ([workspaceId, sideBarEntityByAssignee]) => (
                        <>
                          <Accordion
                            label={workspaceNameById[workspaceId]}
                            labelClassName="text-sm font-medium flex justify-start"
                          >
                            <div className="mb-2">
                              {Object.entries(sideBarEntityByAssignee).map(
                                ([assigneeId, sideBarEntities]) => {
                                  const assignee = assigneeNameById[assigneeId];
                                  if (!assignee) {
                                    return <></>;
                                  }

                                  return (
                                    <div
                                      className="flex items-center gap-2 text-xs mt-2 "
                                      key={assigneeId}
                                    >
                                      <AccordionV2>
                                        <AccordionV2.Label labelClassName="text-xs font-normal text-gray-900">
                                          <div className="flex justify-between w-full items-center">
                                            <div className="flex items-center gap-2">
                                              <span
                                                className={clsx(
                                                  !assignee.dutyStatus &&
                                                    "hidden",
                                                )}
                                              >
                                                <DutyStatusIcon
                                                  status={assignee.dutyStatus!}
                                                />
                                              </span>
                                              <span
                                                className={clsx(
                                                  "font-medium text-gray-900 flex justify-between w-full",
                                                )}
                                              >
                                                {assignee.name || ""}
                                              </span>
                                              {(assignee.dutyStatus ===
                                                "ONLINE" ||
                                                assignee.dutyStatus ===
                                                  "INTRANSIT") && (
                                                <span>
                                                  <Icon
                                                    onClick={() => {
                                                      useDispatchStore.setState(
                                                        {
                                                          focusedAssigneeId:
                                                            assigneeId,
                                                        },
                                                      );
                                                    }}
                                                    className="h-3 w-3 text-gray-900"
                                                    name="locationCrossHairs"
                                                  />
                                                </span>
                                              )}
                                            </div>

                                            <span className="text-gray-500 text-xs">{`${
                                              sideBarEntities.length
                                            } task${
                                              sideBarEntities.length > 1
                                                ? "s"
                                                : ""
                                            }`}</span>
                                          </div>
                                        </AccordionV2.Label>
                                        <AccordionV2.Content>
                                          <SideBarEntities
                                            sideBarEntities={sideBarEntities}
                                            onEdit={onEdit}
                                            onViewDetails={onViewDetails}
                                            onCopy={onCopy}
                                            onDelete={onDelete}
                                            onShare={onShare}
                                            searchTerm={searchTerm}
                                          />
                                        </AccordionV2.Content>
                                      </AccordionV2>
                                    </div>
                                  );
                                },
                              )}
                            </div>
                          </Accordion>
                        </>
                      ),
                    )}
                  </>
                )}
              </div>
            )}
          {!isLoading &&
            hasFeature("bulkActions") &&
            sideBarMode === "table" && (
              <div className="h-[70%] px-4">
                <SidebarTableView />
              </div>
            )}
        </>
      </div>
      {focusedOrderId &&
        showOrderDetailsPanel &&
        orders?.get(focusedOrderId || "") && (
          <OrderDetailsSidebar
            onCloseEdit={onCloseEdit}
            order={orders.get(focusedOrderId || "") as Order}
            isReadOnly={isReadOnly}
          />
        )}
    </>
  );
}

const SideBarEntities = ({
  sideBarEntities,
  onEdit,
  onViewDetails,
  onCopy,
  onDelete,
  onShare,
  searchTerm,
}: {
  sideBarEntities: OrderSideBarEntity[];
  onEdit: (orderId: string) => void;
  onViewDetails: (orderId: string) => void;
  onCopy: (orderId: string) => void;
  onDelete: (orderId: string) => void;
  onShare: (orderId: string, connectedWorkspaceId: string) => void;
  searchTerm: string;
}) => {
  const currentUser = useDispatchStore((state) => state.user);
  const { data: workspaceConnections } = useWorkspaceConnections();

  if (!currentUser) {
    return <></>;
  }

  return (
    <div className="flex flex-col gap-3">
      {sideBarEntities.map((sideBarEntity) => {
        if (sideBarEntity.type === "route") {
          const route = sideBarEntity.entity;
          return (
            <div key={route.id}>
              <RouteCard
                route={route}
                onEdit={onEdit}
                onViewDetails={onViewDetails}
                onCopy={onCopy}
                onDelete={onDelete}
                searchTerm={searchTerm}
              />
            </div>
          );
        } else {
          const order = sideBarEntity.entity;
          return (
            <div key={order.id}>
              <OrderCard
                onEdit={() => onEdit(order.id)}
                onCopy={() => onCopy(order.id)}
                onDelete={() => onDelete(order.id)}
                onViewDetails={() => onViewDetails(order.id)}
                onShare={
                  currentUser!.currentWorkspaceId === order?.workspace?.id
                    ? (connectedWorkspaceId) =>
                        onShare(order.id, connectedWorkspaceId)
                    : undefined
                }
                workspaceConnections={workspaceConnections || []}
                key={order.id}
                order={order}
                searchTerm={searchTerm}
              />
            </div>
          );
        }
      })}
    </div>
  );
};

type SidebarTabsPropsTypes = {
  selectedTab: SidebarMode;
  onSelectTab: (tabName: SidebarMode) => void;
  className?: string;
};

const SidebarTabs = ({
  selectedTab,
  onSelectTab,
  className,
}: SidebarTabsPropsTypes) => {
  const tabs: { name: SidebarMode; icon: IconsType }[] = [
    { name: "list", icon: "list" },
    { name: "table", icon: "table" },
  ];

  return (
    <nav className="flex gap-1" aria-label="Tabs">
      {tabs.map((tab) => (
        <span
          key={tab.name}
          className={clsx(
            tab.name === selectedTab
              ? "bg-gray-200 text-gray-700 hover:bg-gray-700 hover:text-gray-300"
              : "text-gray-700 hover:bg-gray-700 hover:text-gray-300",
            "flex h-[32px] w-[32px] p-[6px] cursor-pointer items-center rounded-md   text-xs font-normal transition ease-in",
            className,
          )}
          onClick={() => onSelectTab(tab.name)}
        >
          <Icon name={tab.icon} className="h-5" />
        </span>
      ))}
    </nav>
  );
};

const DutyStatusIcon = ({ status }: { status: DutyStatus }) => {
  return (
    <Icon
      className={clsx(
        "h-2 rounded-full border-4 border-white drop-shadow-md",
        status === "ONLINE"
          ? "text-green-500"
          : status === "OFFLINE"
            ? "text-gray-500"
            : status === "INTRANSIT"
              ? "bg-blue-500"
              : "",
      )}
      name="circle"
    />
  );
};
