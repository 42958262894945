import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

import { getPlaceLatLng, useSavedAddressMutation } from "@web-app/api";
import {
  Button,
  GoogleMapAutoComplete,
  Icon,
  Input,
  Sidebar,
  TextArea,
} from "@web-app/components";
import type { GoogleAddress } from "@web-app/types";

const addressBookAddressSchema = z.object({
  name: z.string({ required_error: "Name is required." }),
  phone: z.string({ required_error: "Phone number is required." }).refine(
    (value) => {
      const pattern = /^\d{10,10}$/;

      return pattern.test(value);
    },
    {
      message: "Please enter a valid phone number.",
    },
  ),
  address: z.custom<GoogleAddress>((value) => !!value || !!value?.description, {
    message: "Address is required.",
  }),
  businessName: z.string({ required_error: "Business name is required." }),
  note: z.string().optional(),
});

export default function AddNewAddress({
  onClose,
  currentWorkspaceId,
}: {
  onClose: () => void;
  currentWorkspaceId: string;
}) {
  const { handleSubmit, control, reset } = useForm({
    resolver: zodResolver(addressBookAddressSchema),
    reValidateMode: "onChange",
    shouldUseNativeValidation: false,
    mode: "onTouched",
  });

  const { createSavedAddress } = useSavedAddressMutation();

  const onSubmit = async (data: z.infer<typeof addressBookAddressSchema>) => {
    const placeDetails = await getPlaceLatLng(data.address.place_id);

    const addressLocation = {
      latitude: placeDetails.lat!,
      longitude: placeDetails.lng!,
    };

    await toast.promise(
      createSavedAddress.mutateAsync({
        name: data.name,
        phone: data.phone,
        note: data.note ?? "",
        workspaceId: currentWorkspaceId,
        businessName: data.businessName,
        address: data.address?.description,
        // @ts-ignore any type
        addressLocation,
      }),
      {
        loading: "Updating address...",
        success: "Address updated successfully",
        error: "Failed to update address",
      },
    );

    reset();
    onClose();
  };
  const getIsRequired = (
    propertyName: keyof z.infer<typeof addressBookAddressSchema>,
  ) => {
    return !(
      addressBookAddressSchema.shape[propertyName] instanceof z.ZodOptional
    );
  };
  return (
    <Sidebar width="w-[550px]" isOpen>
      <div className=" px-5 md:px-8 lg:px-10 xl:px-14 py-7 h-full relative">
        <h2 className="text-2xl leading-8 font-semibold text-gray-700 mb-7">
          Create New Address
        </h2>
        <form
          onSubmit={
            // @ts-ignore any type
            handleSubmit(onSubmit)
          }
          className="w-full max-w-full flex flex-col gap-8"
        >
          <div className="relative">
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Input
                    type="text"
                    label="Full Name"
                    {...field}
                    required={getIsRequired("name")}
                  />
                  <ErrorMessage message={error?.message} />
                </>
              )}
            />
          </div>
          <div className="relative">
            <Controller
              name="phone"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Input
                    type="text"
                    label="Phone"
                    {...{
                      ...field,
                      required: getIsRequired("phone"),
                    }}
                  />

                  <ErrorMessage message={error?.message} />
                </>
              )}
            />
          </div>

          <div className="relative">
            <Controller
              name="address"
              control={control}
              render={({
                field: { value, onChange, ...field },
                fieldState: { error },
              }) => (
                <>
                  <GoogleMapAutoComplete
                    label="Address"
                    address={value}
                    setAddress={(e) => onChange(e)}
                    {...{
                      ...field,
                      required: getIsRequired("address"),
                    }}
                  />
                  <ErrorMessage message={error?.message} />
                </>
              )}
            />
          </div>

          <div className="relative">
            <Controller
              name="businessName"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Input
                    type="text"
                    label="Business Name"
                    {...{
                      ...field,
                      required: getIsRequired("businessName"),
                    }}
                  />

                  <ErrorMessage message={error?.message} />
                </>
              )}
            />
          </div>

          <div className="relative">
            <Controller
              name="note"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <TextArea
                    rows={5}
                    maxLength={1500}
                    label="Note"
                    {...{
                      ...field,
                      required: getIsRequired("note"),
                    }}
                  />

                  <ErrorMessage message={error?.message} />
                </>
              )}
            />
          </div>

          <div className=" absolute bottom-6 flex gap-4 ">
            <Button type="submit" isLoading={createSavedAddress.isPending}>
              Create
            </Button>
            <Button type="button" appearance="secondary" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </form>
        <span className="absolute right-7 top-8" onClick={onClose}>
          <Icon name="xMark" className="text-gray-600 h-5 cursor-pointer" />
        </span>
      </div>
    </Sidebar>
  );
}

// TODO: Make a separate component for this.
const ErrorMessage = ({ message }: { message: string | undefined }) => (
  <span className="text-red-500 text-xs absolute -bottom-5">{message}</span>
);
