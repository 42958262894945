import debounce from "lodash/debounce";
import { FC, useState } from "react";
import toast from "react-hot-toast";

import { useOrderMutation } from "@web-app/api";
import { ArrowButton, Sidebar } from "@web-app/components";
import { IconsType } from "@web-app/components/Icon/utils";
import { GoogleAddress, Order } from "@web-app/types";
import { validatePhone } from "@web-app/utils/helpers";

import OrderTimeline from "../OrderTimeline";

import OrderDetailsAndEdit from "./OrderDetailsAndEdit";
import SidebarTabs from "./SidebarTabs";

type OrderDetailsSidebarProps = {
  order: Order;
  isReadOnly: boolean;
  onCloseEdit: () => void;
  refetchOrders?: () => void;
};

const OrderDetailsSidebar: FC<OrderDetailsSidebarProps> = ({
  order,
  isReadOnly,
  onCloseEdit,
  refetchOrders,
}) => {
  const [currentTab, setCurrentTab] = useState("Order Details");
  const { updateOrder } = useOrderMutation();

  const onChangeDestination = async (
    newAddress: GoogleAddress | null,
    type: "pickup" | "destination",
  ) => {
    let orderPayload: Partial<Order>;

    if (type === "pickup") {
      orderPayload = {
        id: order.id,
        // triggeringUserId: currentUser?.id,
        pickup: {
          ...order.pickup,
          address: newAddress?.description || "",
        },
        pickupPlaceId: newAddress?.place_id,
      };
    } else {
      orderPayload = {
        dropoff: {
          ...order.dropoff,
          address: newAddress?.description || "",
        },
        deliveryPlaceId: newAddress?.place_id,
        id: order.id,
        // triggeringUserId: currentUser?.id,
      };
    }

    await toast.promise(updateOrder.mutateAsync(orderPayload), {
      loading: "Updating Order ...",
      success: "Successfully Updated Order",
      error: "Something went wrong, please try again!",
    });

    if (refetchOrders) refetchOrders();
  };

  const onChange = async (
    changedValue: { property: string; value: string }[],
  ) => {
    let payload = {};

    changedValue.forEach(({ property, value }) => {
      const [type, prop] = property.includes(".")
        ? property.split(".")
        : [undefined, property];

      if (type) {
        if (prop === "phone" && !validatePhone(value)) {
          toast.error("Please enter a valid phone number");
          return;
        }

        payload = {
          ...payload,
          [type]: {
            // @ts-ignore typed any
            ...order[type],
            [prop]: value,
          },
        };
      } else {
        payload = {
          ...payload,
          [prop]: prop.includes("DateTime") ? new Date(value) : value,
        };
      }
    });

    const data = {
      id: order.id,
      ...payload,
    };

    await toast.promise(updateOrder.mutateAsync(data), {
      loading: "Updating Order ...",
      success: "Successfully Updated Order",
      error: "Something went wrong, please try again!",
    });

    if (refetchOrders) refetchOrders();
  };

  const debouncedOnChange = debounce(onChange, 1000);

  const tabs: { name: string; icon: IconsType }[] = [
    { name: "Order Details", icon: "listTree" },
    { name: "Order Timeline", icon: "timeline" },
  ];

  const onClickTab = (tabName: string) => {
    setCurrentTab(tabName);
  };

  return (
    <div className="flex h-full">
      <Sidebar isOpen={!!order?.id}>
        <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
          <div className="relative flex-1 px-2 py-2 sm:px-8 sm:py-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-between gap-3">
                <ArrowButton onClick={onCloseEdit} rounded />
                <h2 className="text-l font-bold text-gray-700">
                  {order?.displayId}
                </h2>
              </div>
            </div>
            <SidebarTabs
              className="my-4"
              tabs={tabs}
              currentTab={currentTab}
              onClickTab={onClickTab}
            />
            {currentTab === "Order Details" && (
              <OrderDetailsAndEdit
                key={order.id}
                order={order}
                debouncedOnChange={debouncedOnChange}
                // @ts-ignore typed any
                onChangeDestination={onChangeDestination}
                isReadOnly={isReadOnly}
              />
            )}
            {currentTab === "Order Timeline" && <OrderTimeline order={order} />}
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default OrderDetailsSidebar;
