import { useState } from "react";

import { Button, Icon, Modal, SelectAssignee } from "@web-app/components";

export default function AssignModal({
  onClose,
  onAssign,
}: {
  onClose: () => void;
  onAssign: (assigneeId: string) => Promise<void>;
}) {
  const [selectedAssigneeId, setSelectedAssigneeId] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = async () => {
    try {
      setIsLoading(true);
      await onAssign(selectedAssigneeId);
      setIsLoading(false);
      onClose();
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={
        <>
          <Icon name="fasCircleInfo" className=" text-red-600 mr-2" />
          Assign Orders
        </>
      }
      isOpen={true}
      onClose={onClose}
      width={400}
      actionsAlign="end"
      actions={[
        <Button
          type="button"
          isLoading={isLoading}
          onClick={onConfirm}
          className="bg-black-600 hover:bg-black-700 text-white"
        >
          Confirm
        </Button>,
      ]}
    >
      <div>
        <SelectAssignee
          label="Select Assignee"
          onChange={(assigneeId) => setSelectedAssigneeId(assigneeId ?? "")}
        />
      </div>
    </Modal>
  );
}
