import { zodResolver } from "@hookform/resolvers/zod";
import type { FieldValues } from "react-hook-form";
import { useFieldArray, useForm } from "react-hook-form";
import type { z } from "zod";

import ItemChecklist from "./ItemChecklist";

type ChecklistFormPropsType = {
  title: string;
  subtitle: string;
  schema: z.ZodSchema<FieldValues>;
  checklistName: string;
  defaultValues: FieldValues;
  debouncedOnChange: (fieldName: string, value: any) => void;
  isReadOnly?: boolean;
};

export default function ChecklistForm({
  title,
  schema,
  checklistName,
  defaultValues,
  debouncedOnChange,
  isReadOnly,
  subtitle,
}: ChecklistFormPropsType) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: zodResolver(schema),
    reValidateMode: "onChange",
    mode: "onTouched",
    shouldUseNativeValidation: false,
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: checklistName,
  });

  return (
    <div className="col-span-12 flex flex-col">
      <h4 className="mb-1 block text-xs font-semibold tracking-wide text-gray-700">
        {title}
      </h4>
      <ItemChecklist
        subtitle={subtitle}
        isReadOnly={isReadOnly}
        checklistValues={fields}
        errors={errors[checklistName]}
        onChange={async (
          index: number,
          value: any,
          name: "isChecked" | "label" | "quantity",
        ) => {
          setValue(`${checklistName}.${index}.${name}`, value);
          await trigger(`${checklistName}`);
        }}
        onSubmit={handleSubmit((data) => {
          debouncedOnChange(checklistName, data[checklistName]);
        })}
        onRemoveItem={(index: number) => {
          remove(index);
        }}
        onAddItem={async () => {
          await trigger(`${checklistName}`);

          if (!errors[checklistName]) {
            append({
              isChecked: false,
              quantity: 1,
              label: "",
              isMandatory: false,
            });
          }
        }}
      />
    </div>
  );
}
