import { Popover, Transition } from "@headlessui/react";
import clsx from "clsx";

import { Icon } from "@web-app/components";

type SettingsOptionProps = {
  isTrafficVisible: boolean;
  isAllRoutesVisible: boolean;
  isSatelliteView: boolean;
};

export default function Settings({
  settings,
  setSettings,
}: {
  settings: SettingsOptionProps;
  setSettings: (val: SettingsOptionProps) => void;
}) {
  const handleChange = (key: string, value: boolean) => {
    setSettings({ ...settings, [key]: value });
  };

  return (
    <div className="absolute right-7 bottom-[55px] z-10 flex gap-y-2 sm:pl-6 lg:pl-8">
      {/* TODO: Need to align with headless UI */}
      <Popover className="relative">
        <Popover.Button className="flex items-center rounded-md bg-white p-3 shadow-md transition ease-in hover:bg-gray-100">
          <Icon name="fasCog" className="h-3 w-3" />
        </Popover.Button>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          className="relative z-[60] shadow-md"
          as="div"
        >
          <Popover.Panel className="absolute bottom-[40px] right-0 z-10">
            <div className="flex w-[170px] flex-col rounded-xl bg-white p-2 shadow-md">
              <div
                className={clsx(
                  "group relative flex cursor-pointer items-center gap-4 rounded-lg p-2 transition ease-in hover:bg-gray-200",
                )}
                onClick={() =>
                  handleChange("isTrafficVisible", !settings.isTrafficVisible)
                }
              >
                <Icon
                  className={clsx("h-6 w-6 text-gray-700")}
                  name="fasCarSide"
                />
                <div className="flex w-full items-center justify-between">
                  <span className={clsx("text-xs font-semibold text-gray-700")}>
                    Traffic
                  </span>
                  <Icon
                    name="fasCheck"
                    className={clsx(
                      "h-4 w-4",
                      settings.isTrafficVisible
                        ? "text-gray-700"
                        : "text-transparent",
                    )}
                  />
                </div>
              </div>
              <div
                className={clsx(
                  "group relative flex cursor-pointer items-center gap-4 rounded-lg p-2 transition ease-in hover:bg-gray-200",
                )}
                onClick={() =>
                  handleChange(
                    "isAllRoutesVisible",
                    !settings.isAllRoutesVisible,
                  )
                }
              >
                <Icon
                  className={clsx("h-6 w-6 text-gray-700")}
                  name="fasRoad"
                />
                <div className="flex w-full items-center justify-between">
                  <span className={clsx("text-xs font-semibold text-gray-700")}>
                    Routes
                  </span>
                  <Icon
                    name="fasCheck"
                    className={clsx(
                      "h-4 w-4",
                      settings.isAllRoutesVisible
                        ? "text-gray-700"
                        : "text-transparent",
                    )}
                  />
                </div>
              </div>
              <div
                className={clsx(
                  "group relative flex cursor-pointer items-center gap-4 rounded-lg p-2 transition ease-in hover:bg-gray-200",
                )}
                onClick={() =>
                  handleChange("isSatelliteView", !settings.isSatelliteView)
                }
              >
                <Icon
                  className={clsx("h-6 w-6 text-gray-700")}
                  name="fasGlobe"
                />
                <div className="flex w-full items-center justify-between">
                  <span className={clsx("text-xs font-semibold text-gray-700")}>
                    Satellite
                  </span>
                  <Icon
                    name="fasCheck"
                    className={clsx(
                      "h-4 w-4",
                      settings.isSatelliteView
                        ? "text-gray-700"
                        : "text-transparent",
                    )}
                  />
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}
