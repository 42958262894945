import { useQuery } from "@tanstack/react-query";

import { list } from "../services/workspaceRoles";

const queryKey = "workspaceRoles";

export const useWorkspaceRoles = () => {
  return useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const response = await list();
      return response;
    },
    refetchOnWindowFocus: false,
  });
};
