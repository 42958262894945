import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import clsx from "clsx";
import { format } from "date-fns";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";

import { useFeatureflags } from "@web-app/api";
import { OrderDoordashDetails } from "@web-app/pages/common/OrdersSidebar/ExternalAssignee/Doordash";
import { OrderUberDetails } from "@web-app/pages/common/OrdersSidebar/ExternalAssignee/Uber";
import { useExternalAssignee } from "@web-app/pages/common/OrdersSidebar/utils";
import useDispatchStore from "@web-app/store/dispatchStore";
import {
  Order,
  OrderPriorityType,
  Workspace,
  WorkspaceConnection,
} from "@web-app/types";
import {
  getOrderCompletionTime,
  getPolyLineDistance,
} from "@web-app/utils/helpers";
import http from "@web-app/utils/http";
import { ORDER_STATUS_COLORS } from "@web-app/utils/order";

import Avatar from "../Avatar";
import Button from "../Button";
import HighlightString from "../HighlightString";
import { Icon } from "../Icon";
import Modal from "../Modal";

const STATUS_BACKGROUND_MAP: Record<
  // @ts-ignore need to fix
  ReturnType<Order["getStatusText"]>,
  string
> = {
  "": "bg-gray-200",
  Unassigned: "bg-gray-200",
  Assigned: "bg-purple-400",
  "In Transit": "bg-sky-300",
  "In Transit to Pickup": "bg-sky-300",
  "In Transit to Drop-off": "bg-sky-600",
  Completed: "bg-green-400",
  Cancelled: "bg-red-300",
  Failed: "bg-red-300",
};

const STATUS_BORDER_MAP = {
  "": "border-gray-500",
  Unassigned: "border-gray-500",
  Assigned: "border-purple-400",
  "In Transit": "border-sky-300",
  "In Transit to Pickup": "border-sky-300",
  "In Transit to Drop-off": "border-sky-600",
  Completed: "border-green-400",
  Cancelled: "border-red-300",
  Failed: "border-red-300",
};

export type DoordashQuotation = {
  external_delivery_id: string;
  fee: number;
  pickup_time_estimated: string;
  dropoff_time_estimated: string;
  message: string;
  delivery_status: string;
  dasher_id: string;
  dasher_name: string;
  tracking_url: string;
};

export type UberQuotation = {
  id: string;
  fee: number;
  expires: string;
  pickup_eta: Date | string;
  dropoff_eta: string;
  dropoff_deadline: string;
  message: string;
  tracking_url?: string;
  status?: string;
};

export default function OrderCard({
  className,
  order,
  onEdit,
  onCopy,
  onDelete,
  onShare,
  onViewDetails,
  removeOrder,
  routeIndex,
  workspaceConnections,
  searchTerm,
}: {
  order: Order;
  onEdit?: () => void;
  onCopy?: () => void;
  onDelete?: () => void;
  onViewDetails?: () => void;
  onShare?: (workspaceId: string) => void;
  removeOrder?: (orderId: string) => void;
  routeIndex?: number | null;
  className?: string;
  workspaceConnections?: WorkspaceConnection[];
  searchTerm?: string;
}) {
  const contextMenuVisible = onEdit && onCopy && onDelete && onViewDetails;
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(
    order.connectedWorkspace,
  );
  const [query, setQuery] = useState("");
  const [isUberDetailsOpen, setIsUberDetailsOpen] = useState(false);
  const [isDoordashDetailsOpen, setIsDoordashDetailsOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { focusedOrderId, focusedRouteId } = useDispatchStore();

  const orderCompletionTime = getOrderCompletionTime(order);

  const handleShareOrder = () => {
    if (selectedWorkspace) {
      if (onShare) {
        onShare(selectedWorkspace.id);
      }
    }
    setIsShareModalVisible(false);
  };

  const { isUberAssigned, isDoordashAssigned } = useExternalAssignee(order);
  const orderStatus =
    order.status === "TODO"
      ? order?.assigneeId
        ? "ASSIGNED"
        : "UNASSIGNED"
      : order.status;

  const filteredWorkspaceConnections =
    query === ""
      ? workspaceConnections
      : workspaceConnections?.filter((workspaceConnection) => {
          return workspaceConnection.connectedWorkspace.name
            .toLowerCase()
            .includes(query.toLowerCase());
        });

  const routeDistance = useMemo(() => {
    return order.routePolyline ? getPolyLineDistance(order.routePolyline) : "";
  }, [order]);

  const handleClickCrosshair = () => {
    if (!order.route || (focusedRouteId && order.route.id !== focusedRouteId)) {
      useDispatchStore.setState({ focusedRouteId: null });
    }
    if (focusedOrderId === order.id) {
      useDispatchStore.setState({ focusedOrderId: null });
      return;
    }
    useDispatchStore.setState({ focusedOrderId: order.id });
  };

  const handleViewDetails = () => {
    if (isUberAssigned) {
      setIsUberDetailsOpen(true);
    } else if (isDoordashAssigned) {
      setIsDoordashDetailsOpen(true);
    }
  };

  const [deliveryInfo, setDeliveryInfo] = useState<{
    orderId: string;
    data: DoordashQuotation | UberQuotation | null;
  }>({
    orderId: "",
    data: null,
  });

  useEffect(() => {
    if (order.id !== deliveryInfo.orderId) {
      let externalAssigneeId = "";
      let deliveryOption = "";

      if (isUberAssigned) {
        externalAssigneeId = order.externalAssigneeId?.slice(5) ?? "";
        deliveryOption = "uber";
      } else if (isDoordashAssigned) {
        externalAssigneeId = order.externalAssigneeId?.split("_")[1] ?? "";
        deliveryOption = "doordash";
      }

      if (deliveryOption && externalAssigneeId) {
        http
          .get(
            `/integrations/${deliveryOption}/deliveries/${externalAssigneeId}`,
          )
          .then(({ data }) => {
            setDeliveryInfo({
              orderId: order.id,
              data: data,
            });
          })
          .catch(() => {
            setDeliveryInfo({
              orderId: order.id,
              data: null,
            });
            toast.error("Failed to fetch delivery details");
          });
      }
    }
  }, [isUberAssigned, order, deliveryInfo]);

  const handleCopyUrl = () => {
    if (deliveryInfo.data?.tracking_url) {
      navigator.clipboard.writeText(deliveryInfo.data.tracking_url);
      toast.success("Link copied to clipboard.");
    }
  };

  return (
    <div
      className={clsx(
        "relative flex w-full group rounded-md border text-gray-600 transition ease-in bg-gray-50",
        `hover:${STATUS_BORDER_MAP[order.statusText]}`,
        className,
        [
          focusedOrderId === order.id
            ? STATUS_BORDER_MAP[order.statusText]
            : "border-gray-200",
        ],
      )}
    >
      <div className="my-2 flex w-full">
        <div
          className={clsx(
            "w-1 rounded-r-xl",
            STATUS_BACKGROUND_MAP[order.statusText],
          )}
        />
        <div className="w-[calc(100%-5px)] px-2">
          <div className="flex justify-between items-center">
            <div className="flex items-center flex-shrink-0">
              {routeIndex && (
                <div className="mr-1 flex">
                  <Icon name="route" className="text-gray-400 text-sm mr-1" />
                  <div className="text-gray-600 text-[10px] h-4 w-4 bg-gray-50 rounded-full flex justify-center items-center">
                    {routeIndex}
                  </div>
                </div>
              )}

              {order.priority === OrderPriorityType.HIGH && (
                <Icon
                  name="flag"
                  className={clsx(
                    "mr-1 h-4",
                    orderStatus && ORDER_STATUS_COLORS[orderStatus].text,
                  )}
                />
              )}
              <p
                className={clsx(
                  "text-[10px] px-1 rounded-full text-white",
                  orderStatus &&
                    ORDER_STATUS_COLORS[orderStatus].card.darkBackground,
                )}
              >
                {order.statusText}
              </p>
              {routeDistance && (
                <p className="text-gray-600 text-[10px] ml-1">
                  {routeDistance} miles
                </p>
              )}
            </div>
            <div className="flex items-center">
              {order.externalId && (
                <div className="flex items-center text-[10px] gap-1">
                  <Icon
                    name="arrowTurnDownRight"
                    className="text-xs text-gray-400 mr-0.5"
                  />{" "}
                  {order.app?.secret === "relish-app-secret" && (
                    // TODO: FIXME: Need image optimization here
                    <img
                      src="https://cdn-assets-app.fulflld.com/images/ezcater_logo.svg"
                      className="h-3 block w-auto mr-0.5"
                      height={16}
                      width={18}
                      alt=""
                    />
                  )}
                  #{order.externalId}
                </div>
              )}
              <div
                className="mx-3 flex items-center"
                title={`The order belongs to ${order?.workspace.name}${order?.connectedWorkspace && ` and sent to ${order.connectedWorkspace.name} for delivery`}`}
              >
                {order?.workspace && (
                  <div className="flex items-center">
                    {order?.workspace.avatarUrl ? (
                      <img
                        src={order?.workspace.avatarUrl}
                        className="h-4 block"
                        height={16}
                        width={16}
                        alt=""
                      />
                    ) : (
                      <p
                        className={clsx(
                          "text-[10px] leading-[10px] px-1 py-0.5 rounded",
                          orderStatus && [
                            ORDER_STATUS_COLORS[orderStatus].card.background,
                            ORDER_STATUS_COLORS[orderStatus].text,
                          ],
                        )}
                      >
                        {order?.workspace.name.length > 7
                          ? `${order?.workspace.name.substring(0, 7)}...`
                          : order?.workspace.name}
                      </p>
                    )}
                  </div>
                )}
                {order?.connectedWorkspace && (
                  <div className="flex items-center">
                    <Icon
                      name="arrowRight"
                      className="text-xs text-gray-300 mx-2"
                    />
                    {order?.connectedWorkspace.avatarUrl ? (
                      // TODO: FIXME: Need image optimization here
                      <img
                        src={order?.connectedWorkspace.avatarUrl}
                        className="h-4 block"
                        height={16}
                        width={16}
                        alt=""
                      />
                    ) : (
                      <p
                        className={clsx(
                          "text-[10px] leading-[10px] px-1 py-0.5 rounded",
                          orderStatus && [
                            ORDER_STATUS_COLORS[orderStatus].card.background,
                            ORDER_STATUS_COLORS[orderStatus].text,
                          ],
                        )}
                      >
                        {order?.connectedWorkspace.name.length > 7
                          ? `${order?.connectedWorkspace.name.substring(0, 7)}...`
                          : order?.connectedWorkspace.name}
                      </p>
                    )}
                  </div>
                )}
                {(isUberAssigned || isDoordashAssigned) && (
                  <Icon
                    name="arrowRight"
                    className="text-xs text-gray-300 mx-2"
                  />
                )}
                {isUberAssigned && (
                  // TODO: FIXME: Need image optimization here
                  <img
                    src="/images/uber.png"
                    alt=""
                    width={25}
                    height={25}
                    className="w-4 h-4"
                  />
                )}
                {isDoordashAssigned && (
                  // TODO: FIXME: Need image optimization here
                  <img
                    src="/images/doordash.png"
                    alt=""
                    width={25}
                    height={25}
                    className="w-4 h-4"
                  />
                )}
              </div>
              <div className="flex items-center">
                <div
                  className={clsx(
                    "rounded-md h-6 w-6 hover:bg-gray-200 text-gray-600 flex items-center justify-center cursor-pointer",
                    focusedOrderId === order.id && "bg-gray-200",
                  )}
                  onClick={handleClickCrosshair}
                >
                  <Icon name="locationCrossHairs" className="h-3" />
                </div>
                {contextMenuVisible && (
                  <Menu as="div">
                    <div className="right cursor-pointer rounded-md transition ease-in hover:bg-gray-200 flex items-center">
                      <MenuButton
                        data-testid="cy-order-options"
                        as={React.Fragment}
                      >
                        <Icon name="ellipsis" className="h-4 w-4 p-1 mx-auto" />
                      </MenuButton>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <MenuItems className="absolute right-[5px] z-10 mt-2 min-w-max origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <MenuItem as={ContextMenuItem} onClick={onViewDetails}>
                          See Details
                        </MenuItem>
                        <MenuItem as={ContextMenuItem} onClick={onEdit}>
                          Edit
                        </MenuItem>
                        {deliveryInfo.data ? (
                          <>
                            <MenuItem
                              as={ContextMenuItem}
                              onClick={handleViewDetails}
                            >
                              View Details
                            </MenuItem>
                            <MenuItem
                              as={ContextMenuItem}
                              onClick={handleCopyUrl}
                            >
                              Copy Tracking URL
                            </MenuItem>
                          </>
                        ) : (
                          <MenuItem as="div">
                            {({ close }) => (
                              <SendToMenu
                                order={order}
                                onShare={onShare}
                                setIsUberDetailsOpen={setIsUberDetailsOpen}
                                setIsDoordashDetailsOpen={
                                  setIsDoordashDetailsOpen
                                }
                                setIsShareModalVisible={setIsShareModalVisible}
                                filteredWorkspaceConnections={
                                  filteredWorkspaceConnections
                                }
                                isUberAssigned={isUberAssigned}
                                isDoordashAssigned={isDoordashAssigned}
                                close={close}
                              />
                            )}
                          </MenuItem>
                        )}
                        <MenuItem as={ContextMenuItem} onClick={onCopy}>
                          Duplicate
                        </MenuItem>
                        <MenuItem
                          as={ContextMenuItem}
                          onClick={() => setIsDeleteModalOpen(true)}
                        >
                          Delete
                        </MenuItem>
                      </MenuItems>
                    </Transition>
                  </Menu>
                )}
              </div>
            </div>
          </div>
          <div className="my-2.5 flex flex-col gap-[4px] text-xs text-gray-900">
            {order.pickup?.address && (
              <span className="truncate">
                <Icon name="rightFromBracket" className="mr-2 text-gray-400" />
                <HighlightString
                  text={order.pickup.address}
                  searchTerm={searchTerm}
                />
              </span>
            )}
            {order.dropoff?.address && (
              <span className="truncate">
                <Icon name="rightToBracket" className="mr-2 text-gray-400" />
                <HighlightString
                  text={`${
                    order?.dropoff?.name && order?.dropoff?.name + ", "
                  }${order.dropoff.address}`}
                  searchTerm={searchTerm}
                />
              </span>
            )}
          </div>
          <div className="mt-2 flex items-center justify-between text-xs">
            <div className="flex items-center gap-2 ">
              {order.assignee ? (
                <span className="flex items-center gap-1">
                  <Avatar name={order.assignee?.name || ""} size={6} />
                  <span className="w-20 truncate">{order.assignee?.name}</span>
                </span>
              ) : (
                <Avatar size={6} />
              )}
            </div>
            {orderCompletionTime ? (
              <div className="text-center">
                Completed at {format(new Date(orderCompletionTime), "HH:mm")}
              </div>
            ) : order?.eta?.dateTime ? (
              <div>ETA {format(new Date(order.eta.dateTime), "HH:mm")}</div>
            ) : (
              <></>
            )}
            <div className="flex items-center justify-end w-[105px]">
              <Icon name="clockRotateLeft" className="mr-2" />
              {format(new Date(order.scheduledStartDateTime), "HH:mm")}-
              {format(new Date(order.scheduledEndDateTime), "HH:mm")}
            </div>
          </div>
          {order.status === "INPROGRESS" && order.eta?.progress !== null && (
            <div className="mt-2 h-1 w-full rounded-full bg-gray-200 dark:bg-gray-700">
              <div
                className={clsx(
                  "h-1 rounded-full",
                  STATUS_BACKGROUND_MAP[order.statusText],
                )}
                style={{ width: `${order.eta?.progress}%` }}
              ></div>
            </div>
          )}
        </div>
      </div>

      {removeOrder && (
        <div
          className="invisible absolute right-[-8px] top-[-10px] cursor-pointer group-hover:visible"
          onClick={(e) => {
            e.stopPropagation();
            removeOrder(order.id);
          }}
        >
          <Icon name="circleXMark" className="h-5 w-5" />
        </div>
      )}

      <Modal
        isOpen={isShareModalVisible}
        onClose={() => setIsShareModalVisible(false)}
        title="Send Order"
        actions={[
          <Button type="button" onClick={handleShareOrder}>
            Send
          </Button>,
          <Button
            type="button"
            appearance="secondary"
            onClick={() => setIsShareModalVisible(false)}
          >
            Cancel
          </Button>,
        ]}
      >
        <div className="flex items-center gap-4 w-[350px] m-auto">
          <label
            className={clsx(
              "mb-1 block text-xs font-medium leading-5 tracking-wide text-gray-700",
            )}
          >
            Workspace
          </label>
          <Combobox
            value={selectedWorkspace}
            immediate
            onChange={setSelectedWorkspace}
            onClose={() => setQuery("")}
            as="div"
            className="relative w-full"
          >
            <div className="relative">
              <ComboboxInput
                aria-label="Assignee"
                displayValue={(selectedWorkspace: Workspace) =>
                  selectedWorkspace?.name
                }
                placeholder="Select a workspace"
                onChange={(event) => setQuery(event.target.value)}
                className="box-border h-[38px] w-full rounded-md bg-gray-100 px-2 py-3 text-sm leading-5 text-gray-900 outline-none transition ease-in placeholder:text-gray-500 hover:bg-white focus:bg-white focus:ring-2 focus:ring-gray-300 disabled:pointer-events-none disabled:bg-gray-50 disabled:text-gray-400"
              />
              <ComboboxOptions anchor="bottom" className="bg-white w-[250px]">
                <ComboboxOption
                  value={{ id: "", name: "None" }}
                  className="data-[focus]:bg-blue-100 cursor-pointer"
                >
                  <div className="flex gap-x-4 items-center p-2">
                    <div className="">
                      <p className="text-sm">None</p>
                    </div>
                  </div>
                </ComboboxOption>
                {(filteredWorkspaceConnections || []).map(
                  (workspaceConnection) => (
                    <ComboboxOption
                      key={workspaceConnection.workspace.id}
                      value={workspaceConnection.connectedWorkspace}
                      className="data-[focus]:bg-blue-100 cursor-pointer"
                    >
                      <div className="flex gap-x-4 items-center p-2">
                        <Avatar
                          name={workspaceConnection.connectedWorkspace.name}
                          size={8}
                          image={
                            workspaceConnection.connectedWorkspace.avatarUrl ??
                            ""
                          }
                        />
                        <div className="">
                          <p className="text-sm">
                            {workspaceConnection.connectedWorkspace.name}
                          </p>
                        </div>
                      </div>
                    </ComboboxOption>
                  ),
                )}
              </ComboboxOptions>
            </div>
          </Combobox>
        </div>
      </Modal>
      <Modal
        title={
          <>
            <Icon name="fasCircleInfo" className=" text-red-600 mr-2" />
            Delete Order
          </>
        }
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        width={400}
        actionsAlign="end"
        actions={[
          <Button
            type="button"
            onClick={() => {
              if (onDelete) onDelete();
              setIsDeleteModalOpen(false);
            }}
            className="bg-red-600 hover:bg-red-700 text-white"
          >
            Yes, Delete
          </Button>,
        ]}
      >
        <p className="text-sm font-normal leading-5">
          Are you sure you want to delete the order {order.displayId}?
        </p>
      </Modal>
      {isUberDetailsOpen && (
        <OrderUberDetails
          order={order}
          onClose={() => setIsUberDetailsOpen(false)}
          deliveryData={deliveryInfo.data as UberQuotation | null}
        />
      )}
      {isDoordashDetailsOpen && (
        <OrderDoordashDetails
          order={order}
          onClose={() => setIsDoordashDetailsOpen(false)}
          deliveryData={deliveryInfo.data as DoordashQuotation | null}
        />
      )}
    </div>
  );
}

const ContextMenuItem = React.forwardRef<
  HTMLSpanElement,
  {
    children: React.ReactNode;
    onClick: () => void;
  }
>((props, ref) => (
  <span
    ref={ref}
    className="block cursor-pointer items-center p-2 py-2 text-sm font-normal text-gray-600 hover:bg-gray-200 leading-none whitespace-nowrap w-[150px] max-w-full overflow-hidden text-ellipsis"
    onClick={props.onClick}
  >
    {props.children}
  </span>
));

ContextMenuItem.displayName = "ContextMenuItem";

function SendToMenu({
  order,
  onShare,
  setIsShareModalVisible,
  filteredWorkspaceConnections,
  setIsUberDetailsOpen,
  setIsDoordashDetailsOpen,
  close,
  isUberAssigned,
  isDoordashAssigned,
}: {
  order: Order;
  onShare?: (workspaceId: string) => void;
  setIsShareModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  filteredWorkspaceConnections?: WorkspaceConnection[];
  setIsUberDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDoordashDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  close: () => void;
  isUberAssigned: boolean;
  isDoordashAssigned: boolean;
}) {
  const { hasFeature } = useFeatureflags();

  return (
    <Menu as="div" className="relative">
      <MenuButton className="px-2 py-1.5 hover:bg-gray-200 w-full text-sm text-left flex items-center">
        <span>Send To</span>
        <Icon name="chevronRight" className="h-3 ml-auto" />
      </MenuButton>
      <MenuItems className=" absolute left-[-160px] top-[0px] z-10 mt-2 w-38 origin-top-right divide-y rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="pb-2">
          {onShare && (
            <ExternalWorkspaceSendToOptions
              order={order}
              setIsShareModalVisible={setIsShareModalVisible}
              filteredWorkspaceConnections={filteredWorkspaceConnections}
            />
          )}
        </div>
        {hasFeature("uber") && hasFeature("doordash") && (
          <div>
            <span className="text-xs text-gray-400 pb-1 p-2 block">
              External
            </span>
            <MenuItem
              as={ContextMenuItem}
              onClick={() => {
                close();
                setIsUberDetailsOpen(true);
              }}
              disabled={isDoordashAssigned}
            >
              <div className="flex items-center gap-2">
                <img src="/images/uber.png" alt={""} width="25" height="25" />
                Uber
              </div>
            </MenuItem>
            <MenuItem
              as={ContextMenuItem}
              onClick={() => {
                close();
                setIsDoordashDetailsOpen(true);
              }}
              disabled={isUberAssigned}
            >
              <div className="flex items-center gap-2">
                <img
                  src="/images/doordash.png"
                  alt={""}
                  width="25"
                  height="25"
                />
                Doordash
              </div>
            </MenuItem>
          </div>
        )}
      </MenuItems>
    </Menu>
  );
}

function ExternalWorkspaceSendToOptions({
  order,
  setIsShareModalVisible,
  filteredWorkspaceConnections,
}: {
  order: Order;
  setIsShareModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  filteredWorkspaceConnections?: WorkspaceConnection[];
}) {
  if ((filteredWorkspaceConnections || []).length) {
    return (
      <>
        <span className="text-xs text-gray-400 p-2 pb-1 block">Internal</span>
        <MenuItem
          as={ContextMenuItem}
          onClick={() => setIsShareModalVisible(true)}
        >
          {order.connectedWorkspace ? (
            <>
              <Icon
                name="circleCheck"
                className="h-4 text-green-400 ml-auto mr-2"
              />
              {order.connectedWorkspace.name}
            </>
          ) : (
            "Select"
          )}{" "}
          Workspace
        </MenuItem>
      </>
    );
  } else {
    return (
      <>
        <span className="text-xs text-gray-400 p-2 pb-1 block">Internal</span>
        <MenuItem as={ContextMenuItem} onClick={() => {}} disabled>
          {order.workspace && (
            <>
              <Icon
                name="fileExport"
                className="h-4 text-green-400 ml-auto mr-2"
              />
              {order.workspace.name}
            </>
          )}
        </MenuItem>
      </>
    );
  }
}
