import { createFileRoute } from "@tanstack/react-router";

import PublicOrderFormPage from "@web-app/pages/publicOrderForm";

export const Route = createFileRoute("/workspaces/$workspaceId/orders/create")({
  component: RouteComponent,
});

function RouteComponent() {
  const { workspaceId } = Route.useParams();

  return <PublicOrderFormPage workspaceId={workspaceId} />;
}
