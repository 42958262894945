import {
  CreateCustomFieldPayload,
  CustomField,
  CustomFieldGroup,
  CustomFieldValue,
  OrderCustomField,
  UpdateCustomFieldPayload,
} from "@web-app/types";
import http from "@web-app/utils/http";

export const list = async (workspaceId: string, active: boolean = false) => {
  return http
    .get<CustomField[]>(
      `/customFields${active ? "/active" : ""}?workspaceId=` + workspaceId,
    )
    .then((response) => response.data)
    .then((data) => {
      const sortedCustomFields = (data || []).sort(
        (a: CustomField, b: CustomField) => a.name.localeCompare(b.name),
      );

      return sortedCustomFields;
    });
};

export const groupList = async () => {
  return http
    .get<CustomFieldGroup[]>("/customFieldGroups")
    .then((response) => response.data || []);
};

export const create = async (payload: CreateCustomFieldPayload) => {
  return http
    .post<CustomField>("/customFields", payload)
    .then((response) => response.data);
};

export const update = async (
  payload: Partial<UpdateCustomFieldPayload>,
  customFieldId: string,
) => {
  return http
    .patch<CustomField>("/customFields/" + customFieldId, payload)
    .then((response) => response.data);
};

export const orderCustomFieldsList = async (entityId: string) => {
  return http
    .get<OrderCustomField[]>("/orders/" + entityId + "/customFields")
    .then((response) => response.data)
    .then((data) => {
      const sortedCfs = (data || []).sort(
        (a: OrderCustomField, b: OrderCustomField) => {
          return a.customField.name.localeCompare(b.customField.name);
        },
      );

      return sortedCfs;
    });
};

export const updateOrderCustomFieldValue = async (
  payload: { customFieldValues: CustomFieldValue[] },
  entityId: string,
  fieldId: string,
) => {
  return http
    .patch<OrderCustomField>(
      "/orders/" + entityId + "/customFields/" + fieldId,
      payload,
    )
    .then((response) => response.data);
};
