import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from "@headlessui/react";
import clsx from "clsx";
import React from "react";

import { Icon } from "../Icon";

type AccordioPropsTypes = {
  children: React.ReactNode;
  label: string;
  labelClassName?: string;
  defaultOpen?: boolean;
  className?: string;
  onLabelClick?: (isOpen: boolean) => void;
};

export default function Accordion({
  label,
  children,
  defaultOpen = false,
  className,
  labelClassName = "",
  onLabelClick = () => {},
}: AccordioPropsTypes) {
  return (
    <div className={clsx("w-full max-w-full", className)}>
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <DisclosureButton
              className="py-0"
              onClick={() => onLabelClick(open)}
            >
              <div className="flex items-center">
                <Icon
                  className={clsx(
                    open ? "rotate-90 transform" : "",
                    "duration-400 mr-2 inline-block transition ease-in-out h-4 w-4",
                  )}
                  name="play"
                />
                <p
                  className={clsx(
                    "font-medium text-gray-900 text-lg",
                    labelClassName,
                  )}
                >
                  {label}
                </p>
              </div>
            </DisclosureButton>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 -translate-y-10 opacity-0"
              enterTo="transform scale-100 translate-y-0 opacity-100"
              leave="transition duration-75  ease-out"
              leaveFrom="transform scale-100 translate-y-10 opacity-100"
              leaveTo="transform scale-95 -translate-y-0 opacity-0"
            >
              <DisclosurePanel className="ml-5 mt-4 max-w-full text-gray-500">
                {children}
              </DisclosurePanel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
}
