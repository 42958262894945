import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import clsx from "clsx";
import { Fragment } from "react";

import { Workspace } from "@web-app/types";

import TabTitle from "../TabTitle";

import SmsConfigTab from "./SmsConfigTab";

export default function NotificationTab({
  workspace,
}: {
  workspace: Workspace;
}) {
  return (
    <div className="p-4 px-6 max-h-[calc(100vh-60px)]">
      <div className="sticky top-0 z-20 bg-white">
        <div>
          <TabTitle
            icon="fasNotificationBell"
            title="Notifications"
            styles="mb-3"
          />
          <p className="text-sm text-gray-400">
            Setup notifications for your customers.
          </p>
        </div>
      </div>

      <div className="mt-3">
        <TabGroup>
          <TabList className="sticky">
            <Tab as={Fragment}>
              {({ selected }) => (
                <div
                  className={clsx(
                    "text-md inline-flex cursor-pointer border-b-2 px-4 py-2 font-semibold transition ease-in hover:border-gray-500",
                    selected && "border-gray-700",
                    !selected && "border-transparent",
                  )}
                  data-testid="users-tab-client-users"
                >
                  SMS
                </div>
              )}
            </Tab>
          </TabList>

          <TabPanels className="py-3">
            <TabPanel>
              <SmsConfigTab workspace={workspace} />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  );
}
