export default {
  // This is used to store API token in localStorage
  API_TOKEN: "apiToken",

  WEB_API_URL: import.meta.env.DEV
    ? "http://localhost:8080"
    : "https://app-server.fulflld.com",
  WEB_APP_URL: import.meta.env.DEV
    ? "http://localhost:5173"
    : "https://app.fulflld.com",
  GOOGLE_MAPS_API_KEY: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  APP_ENV: import.meta.env.DEV ? "local" : "production",
  SOKETI_APP_KEY: import.meta.env.VITE_SOKETI_APP_KEY,
  SOKETI_HOST: import.meta.env.DEV
    ? import.meta.env.VITE_SOKETI_HOST
    : "soketi.fulflld.com",
  SOKETI_PORT: import.meta.env.DEV ? import.meta.env.VITE_SOKETI_PORT : 443,
};
