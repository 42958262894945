import clsx from "clsx";
import { format } from "date-fns";

import { Icon } from "@web-app/components";
import { OrderDeliveryType, OrderStatus, OrderTimeline } from "@web-app/types";
import { OrderTimelineSchema } from "@web-app/utils/helpers";
import { ORDER_STATUS_COLORS } from "@web-app/utils/order";

type StatusActivityPropsTypes = {
  item: OrderTimeline;
  showTimelineBar: boolean;
  orderType: OrderDeliveryType;
};
export default function StatusActivity({
  item,
  showTimelineBar,
  orderType,
}: StatusActivityPropsTypes) {
  type ValueMapperType = Record<OrderStatus, string> & {
    ASSIGNED: string;
    ORDER_IN_TRANSIT_TO_PICKUP: string;
    ORDER_ARRIVED_AT_PICKUP: string;
    ORDER_PICKED_UP: string;
    ORDER_IN_TRANSIT_TO_DROPOFF: string;
    ORDER_ARRIVED_AT_DROPOFF: string;
    ORDER_DROPPED_OFF: string;
    ORDER_FAILED: string;
    ORDER_CANCELLED: string;
  };
  const StatusIcon = OrderTimelineSchema[item.entity].action[item.action].icon;

  const valueMapper: ValueMapperType = {
    TODO: "unassigned",
    ASSIGNED: "assigned",
    INPROGRESS: "in transit",
    ORDER_IN_TRANSIT_TO_PICKUP: "in transit to pickup",
    ORDER_ARRIVED_AT_PICKUP: "near the pickup location",
    ORDER_PICKED_UP: "picked up the order",
    ORDER_IN_TRANSIT_TO_DROPOFF: "in transit to drop-off",
    ORDER_ARRIVED_AT_DROPOFF: "near the drop-off location",
    ORDER_DROPPED_OFF: "dropped off the order",
    COMPLETE: "completed",
    FAILED: "failed",
    ORDER_FAILED: "failed",
    ORDER_CANCELLED: "cancelled",
    CANCELLED: "cancelled",
  };

  const isCompleted =
    (orderType === OrderDeliveryType.ROUTE_OPT_PICKUP &&
      item.updatedValue === "ORDER_PICKED_UP") ||
    (orderType === OrderDeliveryType.ROUTE_OPT_DROPOFF &&
      item.updatedValue === "ORDER_DROPPED_OFF") ||
    (orderType === OrderDeliveryType.INDIVIDUAL_PICKUP_AND_DROPOFF &&
      item.updatedValue === "ORDER_DROPPED_OFF");

  const statusTextColor = isCompleted
    ? ORDER_STATUS_COLORS["COMPLETE"].text
    : ORDER_STATUS_COLORS[item.updatedValue as keyof ValueMapperType].text;

  return (
    <li key={item.id} className="relative flex gap-x-4">
      <div
        className={clsx(
          showTimelineBar ? "-bottom-6" : "h-6",
          "absolute left-0 top-0 flex w-6 justify-center",
        )}
      >
        <div className="w-px bg-gray-200" />
      </div>
      <div className="relative flex h-7 w-6 flex-none items-center justify-center bg-white">
        <Icon name={StatusIcon} className={clsx("h-4", statusTextColor)} />
      </div>
      <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
        {item.updatedValue.startsWith("ORDER") ? (
          <span
            className={clsx(
              "inline-block font-medium text-gray-900",
              statusTextColor,
            )}
          >
            {isCompleted
              ? "Order completed"
              : `${item.updatedValue.includes("FAILED") || item.updatedValue.includes("CANCELLED") ? "Order" : "Driver"} ${valueMapper[item.updatedValue as keyof ValueMapperType]}`}
          </span>
        ) : (
          <span
            className={clsx(
              "inline-block font-medium text-gray-900",
              statusTextColor,
            )}
          >
            {`${OrderTimelineSchema[item.entity].action[item.action].text} ${
              OrderTimelineSchema[item.entity].entityPresentation
            }`}
            {item.previousValue &&
              item.updatedValue &&
              item.previousValue !== item.updatedValue &&
              ` from ${
                valueMapper[item.previousValue as keyof ValueMapperType]
              } to ${valueMapper[item.updatedValue as keyof ValueMapperType]}`}
            {item.previousValue === item.updatedValue &&
              ` from ${
                valueMapper[item.previousValue as keyof ValueMapperType]
              } pickup to ${
                valueMapper[item.previousValue as keyof ValueMapperType]
              } drop-off`}
          </span>
        )}

        <span className="block">
          {`${format(new Date(item?.createdAt), "MMMM dd, yyyy, hh:mma")} by ${
            item.createdBy?.name
          }`}
        </span>
      </p>
    </li>
  );
}
