import { createFileRoute } from "@tanstack/react-router";

import TrackingOrderPage from "@web-app/pages/trackingOrder";

export const Route = createFileRoute("/tracking/orders/$orderId")({
  component: RouteComponent,
});

function RouteComponent() {
  const { orderId } = Route.useParams();

  return <TrackingOrderPage orderId={orderId} />;
}
