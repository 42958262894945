import { Accordion, Button } from "@web-app/components";
import type { CustomField } from "@web-app/types";

import CustomFieldCard from "./CustomFieldCard";

type CustomFieldGroupPropsType = {
  groupName: string;
  fields: CustomField[];
  onClickAdd: () => void;
  onClickEdit: (fieldId: string) => void;
  defaultOpen?: boolean;
};

export default function CustomFieldGroup({
  groupName,
  fields,
  onClickAdd,
  onClickEdit,
  defaultOpen,
}: CustomFieldGroupPropsType) {
  return (
    <>
      <Accordion
        label={`${groupName} (${fields.length})`}
        className="mb-6 last:mb-0"
        defaultOpen={defaultOpen}
      >
        <div className="flex justify-end mb-4">
          <Button type="button" onClick={onClickAdd}>
            Add New
          </Button>
        </div>
        {fields && fields.length ? (
          fields.map((field) => {
            if (!field) {
              return;
            }

            return (
              <CustomFieldCard
                key={field.id}
                field={field}
                onClickEdit={() => onClickEdit(field.id)}
              />
            );
          })
        ) : (
          <div className="text-gray-500 text-sm">No fields found</div>
        )}
      </Accordion>
    </>
  );
}
