import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import clsx from "clsx";
import { useState } from "react";

import Avatar from "../Avatar";
import { Icon } from "../Icon";
import { IconsType } from "../Icon/utils";
import Input from "../Input";

type Option = {
  id: string;
  label: string;
  value: string;
  icon?: IconsType;
  avatar?: { name: string; url: string };
};

export default function SearchableDropdown({
  options,
  onSelect,
  buttonComponent,
  placeholder = "Search...",
  panelClassName,
}: {
  options: Option[];
  onSelect: (value: Option) => void;
  buttonComponent: React.ReactNode;
  placeholder?: string;
  panelClassName?: string;
}) {
  const [query, setQuery] = useState("");

  const filteredOptions =
    query === ""
      ? options
      : options.filter((opt) =>
          opt.label.toLowerCase().includes(query.toLowerCase()),
        );

  return (
    <Popover as="div">
      <PopoverButton className="outline-none focus:outline-none">
        {buttonComponent}
      </PopoverButton>
      {options.length > 0 && (
        <PopoverPanel
          as="section"
          className={clsx(
            "w-[250px] z-10 transition ease-in absolute p-2 rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",
            panelClassName,
          )}
        >
          {options.length > 1 && (
            <Input
              type="text"
              placeholder={placeholder}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className="mb-2 sticky"
            />
          )}
          <div className="flex flex-col overflow-auto max-h-56 ">
            {filteredOptions.map((option) => (
              <span
                key={option.id}
                onClick={() => onSelect(option)}
                className="relative cursor-pointer select-none p-2 hover:bg-gray-100 rounded-md transition ease-in flex gap-2 items-center"
              >
                {option.icon && <Icon name={option.icon} className="mr-2" />}
                {option.avatar && (
                  <Avatar
                    name={option.avatar.name}
                    image={option.avatar.url}
                    size={8}
                  />
                )}
                <span>{option.label}</span>
              </span>
            ))}
          </div>
        </PopoverPanel>
      )}
    </Popover>
  );
}
