import { Polyline } from "@react-google-maps/api";
import { differenceInMinutes } from "date-fns";

import { DriverEntity, Order } from "@web-app/types";

const ORDER_STATUS_ROUTE_COLOR: Record<Order["status"], string> = {
  TODO: "#141414",
  INPROGRESS: "#28BFFF",
  COMPLETE: "#008760",
  CANCELLED: "#FD4242",
  FAILED: "#FD4242",
  ASSIGNED: "#9747FF",
};

export function AssigneeRoute({
  order,
  assignee,
  isHovered,
}: {
  order: Order;
  assignee?: DriverEntity;
  isHovered: boolean;
}) {
  if (!assignee || order.status === "COMPLETE") {
    return <></>;
  }
  if (assignee.location?.latitude === 0 && assignee.location?.longitude === 0) {
    return <></>;
  }

  const isVeryOldUpdatedAt =
    !assignee?.location?.updatedAt ||
    (assignee.location.updatedAt &&
      differenceInMinutes(new Date(), new Date(assignee.location.updatedAt)) >=
        60);

  if (isVeryOldUpdatedAt) {
    return <></>;
  }

  if (order.type === "INDIVIDUAL_PICKUP_AND_DROPOFF") {
    const startLat = assignee.location?.latitude;
    const startLng = assignee.location?.longitude;

    let endLat = order.pickupLocation.coordinates[1];
    let endLng = order.pickupLocation.coordinates[0];

    if (order.status === "INPROGRESS" && order.statusProgress == "DROPOFF") {
      endLat = order.dropoffLocation.coordinates[1];
      endLng = order.dropoffLocation.coordinates[0];
    }

    return (
      <Polyline
        path={[
          {
            lat: startLat!,
            lng: startLng!,
          },
          {
            lat: endLat!,
            lng: endLng!,
          },
        ]}
        options={{
          strokeWeight: 3,
          strokeColor: ORDER_STATUS_ROUTE_COLOR[order.status],
          strokeOpacity: 0,
          icons: [
            {
              icon: {
                path: "M 0,-1 0,1",
                strokeOpacity: isHovered ? 1 : 0,
                scale: 4,
                strokeWeight: 2,
                strokeColor: ORDER_STATUS_ROUTE_COLOR[order.status],
              },
              offset: "0",
              repeat: "20px",
            },
            {
              icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                strokeOpacity: isHovered ? 1 : 0,
                fillColor: ORDER_STATUS_ROUTE_COLOR[order.status],
                fillOpacity: isHovered ? 1 : 0,
                strokeWeight: 2,
              },
              offset: "50%",
            },
          ],
        }}
      />
    );
  } else {
    const startLat = assignee.location?.latitude;
    const startLng = assignee.location?.longitude;

    const endLat =
      order.type === "ROUTE_OPT_PICKUP"
        ? order.pickupLocation.coordinates[1]
        : order.dropoffLocation.coordinates[1];
    const endLng =
      order.type === "ROUTE_OPT_PICKUP"
        ? order.pickupLocation.coordinates[0]
        : order.dropoffLocation.coordinates[0];

    return (
      <Polyline
        path={[
          {
            lat: startLat!,
            lng: startLng!,
          },
          {
            lat: endLat!,
            lng: endLng!,
          },
        ]}
        options={{
          strokeWeight: 3,
          strokeColor: ORDER_STATUS_ROUTE_COLOR[order.status],
          strokeOpacity: 0,
          icons: [
            {
              icon: {
                path: "M 0,-1 0,1",
                strokeOpacity: isHovered ? 1 : 0,
                scale: 4,
                strokeWeight: 2,
                strokeColor: ORDER_STATUS_ROUTE_COLOR[order.status],
              },
              offset: "0",
              repeat: "20px",
            },
            {
              icon: {
                path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                strokeOpacity: isHovered ? 1 : 0,
                fillColor: ORDER_STATUS_ROUTE_COLOR[order.status],
                fillOpacity: isHovered ? 1 : 0,
                strokeWeight: 2,
              },
              offset: "50%",
            },
          ],
        }}
      />
    );
  }
}
