import { useMutation } from "@tanstack/react-query";

import { uploadAvatar, uploadSpreadsheet } from "../services/upload";

export const useAvatarMutation = () => {
  const upload = useMutation({
    mutationFn: async (payload: FormData) => {
      const response = await uploadAvatar(payload);
      return response;
    },
  });
  return { upload };
};

export const useSpreadsheetMutation = () => {
  const upload = useMutation({
    mutationFn: async (payload: FormData) => {
      const response = await uploadSpreadsheet(payload);
      return response;
    },
  });
  return { upload };
};
