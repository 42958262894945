import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { z } from "zod";

import { getPlaceLatLng, useOrderMutation } from "@web-app/api";
import {
  Accordion,
  Button,
  CustomFields,
  DateInput,
  ErrorMessage,
  GoogleMapAutoComplete,
  Input,
  ItemChecklist,
  RadioButtonGroup,
  SelectInput,
  TextArea,
  Toggle,
} from "@web-app/components";
import {
  GoogleAddress,
  Order,
  OrderDeliveryType,
  OrderPriorityType,
  SavedAddress,
  TransportType,
} from "@web-app/types";
import {
  createOrderRouteType,
  pickupChecklistSchema,
  vehicleOptions,
} from "@web-app/utils/order";

import FullWidthGridColumn from "../common/FullWidthGridColumn";
import HalfWidthGridColumn from "../common/HalfWidthGridColumn";

import { generateOrderFormDefaultValue, orderTypeOptions } from "./data";

const PublicOrderFormPage = ({ workspaceId }: { workspaceId: string }) => {
  const { createPublicOrder } = useOrderMutation();

  const [orderType, setOrderType] = useState<Order["type"]>(
    OrderDeliveryType.INDIVIDUAL_PICKUP_AND_DROPOFF,
  );
  const [priority, setPriority] = useState<Order["priority"]>(
    OrderPriorityType.LOW,
  );
  const [selectedPickupAddress, setSelectedPickupAddress] =
    useState<SavedAddress | null>(null);
  const [selectedDropoffAddress, setSelectedDropoffAddress] =
    useState<SavedAddress | null>(null);
  const [customFields, setCustomFields] = useState<
    z.infer<typeof createOrderRouteType>["customFields"]
  >([]);

  const orderFormDefaultValue = generateOrderFormDefaultValue(
    selectedPickupAddress,
    selectedDropoffAddress,
  );

  const orderFormSchema = z.object({
    vehicleType: z.nativeEnum(TransportType, {
      required_error: "Mode of Transportation is required.",
    }),
    description: z.string({ required_error: "This field is required." }),
    itemsCount: z
      .string({
        required_error: "This field is required.",
        invalid_type_error: "Item Count must be a number",
      })
      .refine((value) => parseInt(value) >= 0, {
        message: "Item count must be greater than or equal to 0.",
      })
      .transform((value) => parseInt(value)),
    value: z
      .string({
        required_error: "This field is required.",
        invalid_type_error: "Value must be a number",
      })
      .refine((value) => parseInt(value) >= 0, {
        message: "Value must be greater than or equal to 0.",
      })
      .transform((value) => parseFloat(value)),
    tip: z
      .string({
        required_error: "This field is required.",
        invalid_type_error: "Tip must be a number",
      })
      .refine((value) => parseInt(value) >= 0, {
        message: "Tip must be greater than or equal to 0.",
      })
      .transform((value) => parseFloat(value)),
    externalId: z.nullable(z.string()).default(null),
    orderRequirements_SIGNATURE: z.boolean().default(false),
    orderRequirements_PHOTO: z.boolean().default(false),
    orderRequirements_NOTES: z.boolean().default(false),
    orderRequirements_ITEMCHECKLIST: z.boolean().default(false),
    pickupChecklist: pickupChecklistSchema,
    isOrderSourceAConnectedWorkspace: z.boolean().optional(),
  });

  const dropOffOrderSchema = {
    dropOffPhone: z.string().refine(
      (value) => {
        const pattern = /^\d{10,10}$/;

        return pattern.test(value);
      },
      {
        message: "Please enter a valid phone number",
      },
    ),
    dropOffName: z.string({ required_error: "This field is required." }),
    dropOffNote: z.string().optional(),
    dropOffBusinessName: z.string({
      required_error: "This field is required.",
    }),
    serviceTimeDropoff: z
      .string()
      .refine((value) => !value || parseInt(value) >= 0, {
        message: "Service time must be greater than or equal to 0.",
      })
      .transform((value) => parseInt(value))
      .optional(),
    dropoffLocation: z.custom<GoogleAddress>(
      (value) => !!value || !!value?.description,
      {
        message: "This field is required.",
      },
    ),
  };

  const pickupOrderSchema = {
    pickupPhone: z.string().refine(
      (value) => {
        const pattern = /^\d{10,10}$/;

        return pattern.test(value);
      },
      {
        message: "Please enter a valid phone number",
      },
    ),
    pickupName: z.string({ required_error: "This field is required." }),
    pickupNote: z.string().optional(),
    pickupBusinessName: z.string({ required_error: "This field is required." }),
    serviceTime: z
      .string()
      .refine((value) => parseInt(value) >= 0, {
        message: "Service time must be greater than or equal to 0.",
      })
      .transform((value) => parseInt(value))
      .optional(),
    pickupLocation: z.custom<GoogleAddress>(
      (value) =>
        value !== null && typeof value === "object" && !!value.description,
      {
        message: "This field is required.",
      },
    ),
  };

  const individualPickupDropoffSchema = orderFormSchema.extend({
    ...dropOffOrderSchema,
    ...pickupOrderSchema,
    scheduledStartDateTime: z.date(),
    scheduledEndDateTime: z.date(),
  });

  const pickupSchema = orderFormSchema.extend({
    ...pickupOrderSchema,
    scheduledStartDateTime: z.date(),
    pickupEndTime: z.date(),
  });

  const dropoffSchema = orderFormSchema.extend({
    ...dropOffOrderSchema,
    dropOffStartTime: z.date(),
    scheduledEndDateTime: z.date(),
  });

  const getSchema = () => {
    if (orderType === OrderDeliveryType.INDIVIDUAL_PICKUP_AND_DROPOFF) {
      return individualPickupDropoffSchema.refine(
        (data) =>
          !data.orderRequirements_ITEMCHECKLIST ||
          (data.pickupChecklist && data.pickupChecklist.length > 0),
        {
          message: "Required to add item checklist.",
          path: ["orderRequirements_ITEMCHECKLIST"],
        },
      );
    } else if (orderType === OrderDeliveryType.ROUTE_OPT_DROPOFF) {
      return dropoffSchema;
    }

    return pickupSchema;
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    resetField,
    reset,
    trigger,
  } = useForm({
    resolver: zodResolver(getSchema()),
    reValidateMode: "onChange",
    mode: "onTouched",
    shouldUseNativeValidation: false,
    defaultValues: generateOrderFormDefaultValue(
      selectedPickupAddress,
      selectedDropoffAddress,
    ),
  });

  const {
    fields: pickupChecklistFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "pickupChecklist",
  });

  useEffect(() => {
    resetField("pickupName", {
      defaultValue: orderFormDefaultValue.pickupName,
    });
    resetField("pickupPhone", {
      defaultValue: orderFormDefaultValue.pickupPhone,
    });
    resetField("pickupNote", {
      defaultValue: orderFormDefaultValue.pickupNote,
    });
    resetField("pickupBusinessName", {
      defaultValue: orderFormDefaultValue.pickupBusinessName,
    });
    resetField("pickupLocation", {
      defaultValue: orderFormDefaultValue.pickupLocation,
    });
  }, [selectedPickupAddress]);

  useEffect(() => {
    resetField("dropOffName", {
      defaultValue: orderFormDefaultValue.dropOffName,
    });
    resetField("dropOffPhone", {
      defaultValue: orderFormDefaultValue.dropOffPhone,
    });
    resetField("dropOffNote", {
      defaultValue: orderFormDefaultValue.dropOffNote,
    });
    resetField("dropOffBusinessName", {
      defaultValue: orderFormDefaultValue.dropOffBusinessName,
    });
    resetField("dropoffLocation", {
      defaultValue: orderFormDefaultValue.dropoffLocation,
    });
  }, [selectedDropoffAddress]);

  useEffect(() => {
    if (createPublicOrder.isSuccess) {
      reset();
      setSelectedDropoffAddress(null);
      setSelectedPickupAddress(null);
    }
  }, [createPublicOrder.isSuccess, reset]);

  const onSubmit = async (
    data: z.infer<
      typeof orderFormSchema & typeof pickupSchema & typeof dropoffSchema
    >,
  ) => {
    type orderType = z.infer<typeof createOrderRouteType>;

    let pickupLocation: { latitude: number; longitude: number } | undefined =
      undefined;
    let dropoffLocation: { latitude: number; longitude: number } | undefined =
      undefined;

    if ((isPickupDelivery || isIndividualDelivery) && !selectedPickupAddress) {
      const placeLatLng = await getPlaceLatLng(
        data.pickupLocation?.place_id || "",
      );
      pickupLocation = {
        latitude: placeLatLng.lat,
        longitude: placeLatLng.lng,
      };
    }

    if (
      (isDropoffDelivery || isIndividualDelivery) &&
      !selectedDropoffAddress
    ) {
      const placeLatLng = await getPlaceLatLng(
        data.dropoffLocation?.place_id || "",
      );
      dropoffLocation = {
        latitude: placeLatLng.lat,
        longitude: placeLatLng.lng,
      };
    }

    if (selectedPickupAddress) {
      pickupLocation = {
        latitude: selectedPickupAddress.addressLocation.coordinates[1],
        longitude: selectedPickupAddress.addressLocation.coordinates[0],
      };
    }

    if (selectedDropoffAddress) {
      dropoffLocation = {
        latitude: selectedDropoffAddress.addressLocation.coordinates[1],
        longitude: selectedDropoffAddress.addressLocation.coordinates[0],
      };
    }

    const _generateRouteOptDropoffOrder = (): orderType => ({
      pickup: {
        name: "",
        phone: "",
        note: "",
        businessName: "",
        address: "",
      },
      dropoff: {
        name: data.dropOffName,
        phone: data.dropOffPhone,
        note: data.dropOffNote || "",
        businessName: data.dropOffBusinessName,
        address: selectedDropoffAddress
          ? selectedDropoffAddress.address
          : data.dropoffLocation?.description || "",
      },
      dropoffLocation: {
        type: "POINT",
        coordinates: [dropoffLocation!.longitude, dropoffLocation!.latitude],
      },
      scheduledStartDateTime: data.dropOffStartTime,
      scheduledEndDateTime: data.scheduledEndDateTime,
      priority: priority,
      details: {
        serviceDuration: data.serviceTimeDropoff || 0,
        itemsCount: data.itemsCount,
        description: data.description,
        value: data.value,
        tip: data.tip,
      },
      type: "ROUTE_OPT_DROPOFF",
      dropoffRequirements: {
        note: data.orderRequirements_NOTES,
        photo: data.orderRequirements_PHOTO,
        signature: data.orderRequirements_SIGNATURE,
        itemChecklist: data.orderRequirements_ITEMCHECKLIST,
      },
      minVehicle: data.vehicleType,
      externalId: data.externalId as string,
      deliveryPlaceId: data.dropoffLocation?.place_id || "",
      pickupPlaceId: "",
      status: null,
      assigneeId: null,
      isOrderSourceAConnectedWorkspace: data.isOrderSourceAConnectedWorkspace,
      customFields: customFields,
    });

    const _generateRouteOptPickupOrder = (): orderType => ({
      pickup: {
        name: data.pickupName,
        phone: data.pickupPhone,
        note: data.pickupNote || "",
        businessName: data.pickupBusinessName,
        address: selectedPickupAddress
          ? selectedPickupAddress.address
          : data.pickupLocation?.description || "",
      },
      dropoff: {
        name: "",
        phone: "",
        note: "",
        businessName: "",
        address: "",
      },
      pickupLocation: {
        type: "POINT",
        coordinates: [pickupLocation!.longitude, pickupLocation!.latitude],
      },
      scheduledStartDateTime: data.scheduledStartDateTime,
      scheduledEndDateTime: data.pickupEndTime,
      priority: priority,
      details: {
        serviceDuration: data.serviceTime || 0,
        itemsCount: data.itemsCount,
        description: data.description,
        value: data.value,
        tip: data.tip,
      },
      type: "ROUTE_OPT_PICKUP",
      dropoffRequirements: {
        note: data.orderRequirements_NOTES,
        photo: data.orderRequirements_PHOTO,
        signature: data.orderRequirements_SIGNATURE,
        itemChecklist: data.orderRequirements_ITEMCHECKLIST,
      },
      minVehicle: data.vehicleType,
      externalId: data.externalId || "",
      deliveryPlaceId: "",
      pickupPlaceId: data.pickupLocation?.place_id || "",
      status: null,
      assigneeId: null,
      isOrderSourceAConnectedWorkspace: data.isOrderSourceAConnectedWorkspace,
      customFields: customFields,
    });

    const _generateIndividualOrder = (): orderType => ({
      pickup: {
        name: data.pickupName,
        phone: data.pickupPhone,
        note: data.pickupNote || "",
        businessName: data.pickupBusinessName,
        address: selectedPickupAddress
          ? selectedPickupAddress.address
          : data.pickupLocation?.description || "",
      },
      dropoff: {
        name: data.dropOffName,
        phone: data.dropOffPhone,
        note: data.dropOffNote || "",
        businessName: data.dropOffBusinessName,
        address: selectedDropoffAddress
          ? selectedDropoffAddress.address
          : data.dropoffLocation?.description || "",
      },
      pickupLocation: {
        type: "POINT",
        coordinates: [pickupLocation!.longitude, pickupLocation!.latitude],
      },
      dropoffLocation: {
        type: "POINT",
        coordinates: [dropoffLocation!.longitude, dropoffLocation!.latitude],
      },
      scheduledStartDateTime: data.scheduledStartDateTime,
      scheduledEndDateTime: data.scheduledEndDateTime,
      priority: priority,
      details: {
        serviceDuration: data.serviceTimeDropoff || 0,
        itemsCount: data.itemsCount,
        description: data.description,
        value: data.value,
        tip: data.tip,
      },
      type: "INDIVIDUAL_PICKUP_AND_DROPOFF",
      dropoffRequirements: {
        note: data.orderRequirements_NOTES,
        photo: data.orderRequirements_PHOTO,
        signature: data.orderRequirements_SIGNATURE,
        itemChecklist: data.orderRequirements_ITEMCHECKLIST,
      },
      minVehicle: data.vehicleType,
      externalId: data.externalId || "",
      deliveryPlaceId: data.dropoffLocation?.place_id || "",
      pickupPlaceId: data.pickupLocation?.place_id || "",
      status: null,
      assigneeId: null,
      isOrderSourceAConnectedWorkspace: data.isOrderSourceAConnectedWorkspace,
      pickupChecklist: data.pickupChecklist || [],
      customFields: customFields,
    });

    const _generateOrder = () => {
      if (orderType === "INDIVIDUAL_PICKUP_AND_DROPOFF") {
        return [_generateIndividualOrder()];
      }
      if (orderType === "ROUTE_OPT_DROPOFF") {
        return [_generateRouteOptDropoffOrder()];
      }

      if (orderType === "ROUTE_OPT_PICKUP") {
        return [_generateRouteOptPickupOrder()];
      }

      return [];
    };

    const payload = _generateOrder();

    toast.promise(createPublicOrder.mutateAsync(payload[0]), {
      loading: "Creating Order ...",
      success: "Successfully created order",
      error: "Failed to create error. Please try again or contact support",
    });

    if (createPublicOrder.isSuccess) {
      reset();
    }

    setSelectedDropoffAddress(null);
    setSelectedPickupAddress(null);
  };

  const getIsRequired = (propertyName: string) => {
    const isZodOptional = (
      schema: { shape: Record<string, z.ZodTypeAny> },
      prop: string,
    ) => schema.shape[prop] instanceof z.ZodOptional;

    if (orderType === OrderDeliveryType.INDIVIDUAL_PICKUP_AND_DROPOFF) {
      return !isZodOptional(individualPickupDropoffSchema, propertyName);
    } else if (orderType === OrderDeliveryType.ROUTE_OPT_DROPOFF) {
      return !isZodOptional(dropoffSchema, propertyName);
    } else {
      return !isZodOptional(pickupSchema, propertyName);
    }
  };

  const isIndividualDelivery = orderType === "INDIVIDUAL_PICKUP_AND_DROPOFF";
  const isPickupDelivery = orderType === "ROUTE_OPT_PICKUP";
  const isDropoffDelivery = orderType === "ROUTE_OPT_DROPOFF";

  return (
    <div className="bg-white h-screen overflow-auto">
      <div className="max-w-[700px] mx-auto pt-4">
        <Toaster position="top-center" />
        <form
          // @ts-ignore any type data
          onSubmit={handleSubmit(onSubmit)}
          className="relative w-full max-w-full"
        >
          <div>
            <div className="mb-8 bg-[#efefef] rounded-md p-4">
              <div className="mb-4">
                <Toggle
                  label="Point to Point Delivery"
                  isChecked={isIndividualDelivery}
                  onChange={() => {
                    reset(
                      {
                        pickupChecklist: [],
                      },
                      {
                        keepDefaultValues: true,
                      },
                    );

                    if (isPickupDelivery || isDropoffDelivery) {
                      setOrderType(
                        OrderDeliveryType.INDIVIDUAL_PICKUP_AND_DROPOFF,
                      );
                    } else {
                      setOrderType(OrderDeliveryType.ROUTE_OPT_PICKUP);
                    }
                  }}
                  helpText="Orders that have one pick-up and one drop-off location."
                />

                {!isIndividualDelivery && (
                  <RadioButtonGroup
                    orient="horizontal"
                    options={orderTypeOptions(setOrderType)}
                  />
                )}
              </div>
              <div className="-mb-2">
                <Toggle
                  label="High Priority"
                  isChecked={priority === "HIGH"}
                  onChange={() =>
                    setPriority(
                      priority === "HIGH"
                        ? OrderPriorityType.LOW
                        : OrderPriorityType.HIGH,
                    )
                  }
                />
              </div>
            </div>
            <div className="rounded will-change-scroll">
              {(isIndividualDelivery || isPickupDelivery) && (
                <Accordion label="Pickup Details" defaultOpen={true}>
                  <div className="mt-4 grid grid-cols-12 gap-7">
                    <HalfWidthGridColumn classes="mt-0">
                      <Controller
                        name="pickupName"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <Input
                              type="text"
                              label="Full Name"
                              {...{
                                ...field,
                                required: getIsRequired("pickupName"),
                              }}
                            />
                            <ErrorMessage message={error?.message} />
                          </>
                        )}
                      />
                    </HalfWidthGridColumn>
                    <HalfWidthGridColumn classes="mt-0">
                      <Controller
                        name="pickupPhone"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <Input
                              type="text"
                              label="Phone Number"
                              {...{
                                ...field,
                                required: getIsRequired("pickupPhone"),
                              }}
                            />

                            <ErrorMessage message={error?.message} />
                          </>
                        )}
                      />
                    </HalfWidthGridColumn>

                    <FullWidthGridColumn>
                      <Controller
                        name="pickupLocation"
                        control={control}
                        render={({
                          field: { value, onChange, ...field },
                          fieldState: { error },
                        }) => (
                          <>
                            <GoogleMapAutoComplete
                              label="Pickup Location"
                              // @ts-ignore TODO: fix me
                              address={
                                // @ts-ignore TODO: fix me
                                value?.description
                                  ? value
                                  : { description: value }
                              }
                              setAddress={(e) => {
                                onChange(e);
                              }}
                              placeholder=""
                              {...{
                                ...field,
                                required: getIsRequired("pickupLocation"),
                              }}
                            />
                            <ErrorMessage message={error?.message} />
                          </>
                        )}
                      />
                    </FullWidthGridColumn>
                    <div
                      className={clsx(
                        !isIndividualDelivery ? "col-span-6" : "col-span-12",
                      )}
                    >
                      <Controller
                        name="scheduledStartDateTime"
                        control={control}
                        render={({
                          field: { onChange, ...rest },
                          fieldState: { error },
                        }) => (
                          <>
                            <DateInput
                              label={
                                isIndividualDelivery
                                  ? "Pickup At (Start Time)"
                                  : "Pickup After (Date/Time)"
                              }
                              minDate={new Date()}
                              {...{
                                onChange: (date) => {
                                  onChange(date);
                                },
                                ...rest,
                                required: getIsRequired(
                                  isIndividualDelivery
                                    ? "scheduledStartDateTime"
                                    : "pickupStartTime",
                                ),
                              }}
                            />

                            <ErrorMessage message={error?.message} />
                          </>
                        )}
                      />
                    </div>
                    {!isIndividualDelivery && (
                      <HalfWidthGridColumn>
                        <Controller
                          name="pickupEndTime"
                          control={control}
                          render={({
                            field: { onChange, ...rest },
                            fieldState: { error },
                          }) => (
                            <>
                              <DateInput
                                label="Pickup Before (Date/Time)"
                                minDate={new Date()}
                                {...{
                                  onChange: (date) => {
                                    onChange(date);
                                  },
                                  ...rest,
                                  required: getIsRequired("pickupEndTime"),
                                }}
                              />

                              <ErrorMessage message={error?.message} />
                            </>
                          )}
                        />
                      </HalfWidthGridColumn>
                    )}
                    {!isIndividualDelivery && (
                      <FullWidthGridColumn>
                        <Controller
                          name="serviceTime"
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <Input
                                type="number"
                                min={0}
                                label="Service Time (in minutes)"
                                {...{
                                  ...field,
                                  required: getIsRequired("serviceTime"),
                                }}
                              />

                              <ErrorMessage message={error?.message} />
                            </>
                          )}
                        />
                      </FullWidthGridColumn>
                    )}
                    <FullWidthGridColumn>
                      <Controller
                        name="pickupBusinessName"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <Input
                              type="text"
                              label="Business Name"
                              {...{
                                ...field,
                                required: getIsRequired("pickupBusinessName"),
                              }}
                            />

                            <ErrorMessage message={error?.message} />
                          </>
                        )}
                      />
                    </FullWidthGridColumn>
                    <FullWidthGridColumn>
                      <Controller
                        name="pickupNote"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <TextArea
                              rows={5}
                              maxLength={1500}
                              label="Note"
                              {...{
                                ...field,
                                required: getIsRequired("pickupNote"),
                              }}
                            />

                            <ErrorMessage message={error?.message} />
                          </>
                        )}
                      />
                    </FullWidthGridColumn>
                  </div>
                </Accordion>
              )}
              {(isIndividualDelivery || isDropoffDelivery) && (
                <Accordion
                  label="Drop-off Details"
                  className={clsx(isIndividualDelivery ? "mt-5" : "mt-0")}
                  defaultOpen={isDropoffDelivery}
                >
                  <div className="mt-4 grid grid-cols-12 gap-7">
                    <HalfWidthGridColumn classes="mt-0">
                      <Controller
                        name="dropOffName"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <Input
                              type="text"
                              label="Full Name"
                              {...{
                                ...field,
                                required: getIsRequired("dropOffName"),
                              }}
                            />

                            <ErrorMessage message={error?.message} />
                          </>
                        )}
                      />
                    </HalfWidthGridColumn>
                    <HalfWidthGridColumn classes="mt-0">
                      <Controller
                        name="dropOffPhone"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <Input
                              type="text"
                              label="Phone Number"
                              {...{
                                ...field,
                                required: getIsRequired("dropOffPhone"),
                              }}
                            />

                            <ErrorMessage message={error?.message} />
                          </>
                        )}
                      />
                    </HalfWidthGridColumn>
                    <FullWidthGridColumn>
                      <Controller
                        name="dropoffLocation"
                        control={control}
                        render={({
                          field: { value, onChange, ...field },
                          fieldState: { error },
                        }) => (
                          <>
                            <GoogleMapAutoComplete
                              label="Drop-off Location"
                              // @ts-ignore TODO: fix me
                              address={
                                // @ts-ignore TODO: fix me
                                value?.description
                                  ? value
                                  : { description: value }
                              }
                              setAddress={(e) => onChange(e)}
                              {...{
                                ...field,
                                required: getIsRequired("dropoffLocation"),
                              }}
                            />

                            <ErrorMessage message={error?.message} />
                          </>
                        )}
                      />
                    </FullWidthGridColumn>
                    {!isIndividualDelivery && (
                      <HalfWidthGridColumn>
                        <Controller
                          name="dropOffStartTime"
                          control={control}
                          render={({
                            field: { onChange, ...rest },
                            fieldState: { error },
                          }) => (
                            <>
                              <DateInput
                                label={
                                  isIndividualDelivery
                                    ? "Pickup At (Start Time)"
                                    : "Drop-off After (Date/Time)"
                                }
                                minDate={new Date()}
                                onChange={(date) => {
                                  onChange(date);
                                }}
                                {...{
                                  ...rest,
                                  required: getIsRequired("dropoffStartTime"),
                                }}
                              />
                              <ErrorMessage message={error?.message} />
                            </>
                          )}
                        />
                      </HalfWidthGridColumn>
                    )}

                    <div
                      className={clsx(
                        !isIndividualDelivery ? "col-span-6" : "col-span-12",
                      )}
                    >
                      <Controller
                        name="scheduledEndDateTime"
                        control={control}
                        render={({
                          field: { onChange, ...rest },
                          fieldState: { error },
                        }) => (
                          <>
                            <DateInput
                              label={
                                isIndividualDelivery
                                  ? "Drop-off At (End Time)"
                                  : "Drop-off Before (Date/Time)"
                              }
                              minDate={new Date()}
                              onChange={(date) => {
                                onChange(date);
                              }}
                              {...{
                                ...rest,
                                required: getIsRequired(
                                  isIndividualDelivery
                                    ? "scheduledEndDateTime"
                                    : "dropOffEndTime",
                                ),
                              }}
                            />
                            <ErrorMessage message={error?.message} />
                          </>
                        )}
                      />
                    </div>
                    <FullWidthGridColumn>
                      <Controller
                        name="serviceTimeDropoff"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <Input
                              type="number"
                              label="Service Time (in minutes)"
                              min={0}
                              {...{
                                ...field,
                                required: getIsRequired("serviceTimeDropoff"),
                              }}
                            />

                            <ErrorMessage message={error?.message} />
                          </>
                        )}
                      />
                    </FullWidthGridColumn>
                    <FullWidthGridColumn>
                      <Controller
                        name="dropOffBusinessName"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <Input
                              type="text"
                              label="Business Name"
                              {...{
                                ...field,
                                required: getIsRequired("dropOffBusinessName"),
                              }}
                            />
                            <ErrorMessage message={error?.message} />
                          </>
                        )}
                      />
                    </FullWidthGridColumn>
                    <FullWidthGridColumn>
                      <Controller
                        name="dropOffNote"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <TextArea
                              maxLength={1500}
                              rows={5}
                              label="Note"
                              {...{
                                ...field,
                                required: getIsRequired("dropOffNote"),
                              }}
                            />
                            <ErrorMessage message={error?.message} />
                          </>
                        )}
                      />
                    </FullWidthGridColumn>
                  </div>
                </Accordion>
              )}

              <Accordion label="Order Details" className="mt-5">
                <div className="mt-4 grid grid-cols-12 gap-7">
                  <FullWidthGridColumn>
                    <Controller
                      name="vehicleType"
                      control={control}
                      render={({
                        field: { onChange, value, ...rest },
                        fieldState: { error },
                      }) => (
                        <>
                          <SelectInput
                            label="Mode of Transportation"
                            options={vehicleOptions}
                            selected={value}
                            onChange={(value) => {
                              onChange(value);
                            }}
                            placeholder="Select mode of transportation"
                            {...{
                              ...rest,
                              required: getIsRequired("vehicleType"),
                            }}
                          />

                          <ErrorMessage message={error?.message} />
                        </>
                      )}
                    />
                  </FullWidthGridColumn>
                  <FullWidthGridColumn>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <TextArea
                            rows={5}
                            label="Description"
                            maxLength={1500}
                            {...{
                              ...field,
                              required: getIsRequired("description"),
                            }}
                          />
                          <ErrorMessage message={error?.message} />
                        </>
                      )}
                    />
                  </FullWidthGridColumn>
                  <FullWidthGridColumn>
                    <Controller
                      name="value"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <Input
                            type="number"
                            min={0}
                            label="Value ($)"
                            {...{
                              ...field,
                              required: getIsRequired("value"),
                            }}
                            onWheel={(e) => e.currentTarget.blur()}
                          />

                          <ErrorMessage message={error?.message} />
                        </>
                      )}
                    />
                  </FullWidthGridColumn>
                  <FullWidthGridColumn>
                    <Controller
                      name="tip"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <Input
                            type="number"
                            min={0}
                            label="Tip ($)"
                            {...{
                              ...field,
                              required: getIsRequired("tip"),
                            }}
                            onWheel={(e) => e.currentTarget.blur()}
                          />
                          <ErrorMessage message={error?.message} />
                        </>
                      )}
                    />
                  </FullWidthGridColumn>
                  <FullWidthGridColumn>
                    <Controller
                      name="itemsCount"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <Input
                            type="number"
                            min={0}
                            label="Item Count"
                            toolTipText="Number of bags of orders"
                            {...{
                              ...field,
                              required: getIsRequired("itemsCount"),
                            }}
                            onWheel={(e) => e.currentTarget.blur()}
                          />
                          <ErrorMessage message={error?.message} />
                        </>
                      )}
                    />
                  </FullWidthGridColumn>
                  <FullWidthGridColumn>
                    <Controller
                      name="externalId"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <Input
                            type="text"
                            label="External Reference ID"
                            {...{
                              ...field,
                              required: false,
                            }}
                          />
                          <ErrorMessage message={error?.message} />
                        </>
                      )}
                    />
                  </FullWidthGridColumn>
                  <FullWidthGridColumn>
                    <h3 className="mb-1 block text-xs font-semibold tracking-wide text-gray-700">
                      Order Requirements
                    </h3>
                    <div className="mt-3 flex items-center justify-start gap-4">
                      <Controller
                        name="orderRequirements_SIGNATURE"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <Input
                              type="checkbox"
                              checkboxLabel="Signature"
                              {...field}
                              value={field.value.toString()}
                              checked={field.value}
                            />
                            <ErrorMessage message={error?.message} />
                          </>
                        )}
                      />
                      <Controller
                        name="orderRequirements_PHOTO"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <Input
                              type="checkbox"
                              checkboxLabel="Photo of Delivery"
                              {...field}
                              value={field.value.toString()}
                              checked={field.value}
                            />
                            <ErrorMessage message={error?.message} />
                          </>
                        )}
                      />
                      <Controller
                        name="orderRequirements_NOTES"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <Input
                              type="checkbox"
                              checkboxLabel="Add Notes"
                              {...field}
                              value={field.value.toString()}
                              checked={field.value}
                            />
                            <ErrorMessage message={error?.message} />
                          </>
                        )}
                      />
                      <Controller
                        name="orderRequirements_ITEMCHECKLIST"
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <Input
                              type="checkbox"
                              checkboxLabel="Item Checklist"
                              {...field}
                              value={field.value?.toString()}
                              checked={field.value}
                            />
                            <ErrorMessage message={error?.message} />
                          </>
                        )}
                      />
                    </div>
                  </FullWidthGridColumn>

                  {isIndividualDelivery && (
                    <FullWidthGridColumn>
                      <ItemChecklist
                        subtitle="Item checklist will be checked by the driver when they are delivering
  the order"
                        isReadOnly={false}
                        checklistValues={pickupChecklistFields}
                        errors={errors.pickupChecklist}
                        onChange={async (
                          index: number,
                          value: string,
                          name: "isChecked" | "label" | "quantity",
                        ) => {
                          setValue(`pickupChecklist.${index}.${name}`, value);
                          await trigger(`pickupChecklist`);
                          await trigger(`orderRequirements_ITEMCHECKLIST`);
                        }}
                        onRemoveItem={async (index: number) => {
                          remove(index);

                          await trigger(`pickupChecklist`);
                          await trigger(`orderRequirements_ITEMCHECKLIST`);
                        }}
                        onAddItem={async () => {
                          await trigger(`pickupChecklist`);
                          await trigger(`orderRequirements_ITEMCHECKLIST`);

                          if (!errors.pickupChecklist) {
                            append({
                              isChecked: false,
                              quantity: 1,
                              label: "",
                              isMandatory: false,
                            });
                          }
                        }}
                      />
                    </FullWidthGridColumn>
                  )}
                </div>
              </Accordion>
              <Accordion label="Custom Fields" className="my-5">
                <CustomFields
                  entity="order"
                  workspaceId={workspaceId}
                  onChange={(_, customField, value) => {
                    const existingCustomFieldIndex = customFields.findIndex(
                      // @ts-ignore TODO: fix me
                      (cf) => cf.definition.id === customField.id,
                    );

                    if (existingCustomFieldIndex > -1) {
                      const updatedCustomFields = [...customFields];

                      updatedCustomFields[existingCustomFieldIndex] = {
                        // @ts-ignore TODO: fix me
                        definition: customField,
                        // @ts-ignore TODO: fix me
                        values: value,
                      };

                      setCustomFields(updatedCustomFields);
                    } else {
                      const updatedCustomFields = [
                        ...customFields,
                        {
                          definition: customField,
                          values: value,
                        },
                      ];
                      // @ts-ignore TODO: fix me
                      setCustomFields(updatedCustomFields);
                    }
                  }}
                />
              </Accordion>
            </div>
            <div className="">
              <div className="flex gap-5 p-2">
                <Button
                  type="submit"
                  size="md"
                  isLoading={createPublicOrder.isPending}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PublicOrderFormPage;
