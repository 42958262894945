import clsx from "clsx";

function StatusCell({
  value,
}: {
  value:
    | ""
    | "Assigned"
    | "Unassigned"
    | "In Transit to Pickup"
    | "In Transit to Drop-off"
    | "In Transit"
    | "Completed"
    | "Cancelled"
    | "Failed";
}) {
  if (!value) {
    return "";
  }

  let statusStyles = "";

  if (value === "Assigned") {
    statusStyles = "bg-purple-200 text-purple-700";
  }

  if (value.includes("In Transit")) {
    statusStyles = "bg-blue-300 text-blue-700";
  }

  if (value === "Completed") {
    statusStyles = "bg-emerald-200 text-green-700";
  }

  if (value === "Failed" || value === "Cancelled") {
    statusStyles = "bg-red-200 text-red-700";
  }

  if (value === "Unassigned") {
    statusStyles = "bg-gray-200 text-gray-700";
  }

  return (
    <div className={clsx("p-1 px-2 rounded-full", statusStyles)}>{value}</div>
  );
}

export default StatusCell;
