import clsx from "clsx";
import { format } from "date-fns";

import { Icon } from "@web-app/components";
import { OrderTimeline } from "@web-app/types";
import { OrderTimelineSchema } from "@web-app/utils/helpers";

type AttachmentsActivityPropsTypes = {
  item: OrderTimeline;
  showTimelineBar: boolean;
};

export default function AttachmentsActivity({
  item,
  showTimelineBar,
}: AttachmentsActivityPropsTypes) {
  const AttachmentsIcon =
    OrderTimelineSchema[item.entity].action[item.action].icon;
  const ordertimelineColor =
    OrderTimelineSchema[item.entity].action[item.action].color;

  return (
    <li key={item.id} className="relative flex gap-x-4">
      <div
        className={clsx(
          showTimelineBar ? "-bottom-6" : "h-6",
          "absolute left-0 top-0 flex w-6 justify-center",
        )}
      >
        <div className="w-px bg-gray-200" />
      </div>
      <div className="relative flex h-7 w-6 flex-none items-center justify-center bg-white">
        <Icon
          name={AttachmentsIcon}
          className={clsx("h-4", ordertimelineColor)}
        />
      </div>
      <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
        <span
          className={clsx(
            "inline-block font-medium text-gray-900",
            ordertimelineColor,
          )}
        >
          <>
            {OrderTimelineSchema[item.entity].entityPresentation}{" "}
            {OrderTimelineSchema[item.entity].action[item.action].text}
          </>
        </span>
        <span className="block">
          {`${format(new Date(item?.createdAt), "MMMM dd, yyyy, hh:mma")} by ${
            item.createdBy?.name
          }`}
        </span>
      </p>
    </li>
  );
}
