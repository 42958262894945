import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

import { Icon } from "@web-app/components";
import useDispatchStore from "@web-app/store/dispatchStore";

import Button from "../../components/Button";
import OrdersFilterDropdown from "../../components/OrderFilterDropdown";

export default function TopNavigation({
  setIsOrderModalOpen,
  setIsBulkImportModalOpen,
}: {
  setIsOrderModalOpen: () => void;
  setIsBulkImportModalOpen: () => void;
}) {
  return (
    <div className="flex justify-between">
      <div className="flex gap-3">
        <Menu as="div">
          <MenuButton className="flex items-center gap-x-1.5 rounded-md bg-black bg-black-dark h-[32px] px-2.5 text-xs text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-700 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white">
            <Icon name="plus" className="h-3 w-3" />
            Add Order
          </MenuButton>

          <MenuItems
            transition
            anchor="bottom start"
            className="w-52 origin-top-right mt-1 shadow-md rounded-xl border border-white bg-white p-1 text-xs font-normal text-black  transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0"
          >
            <MenuItem as="div" className="hover:bg-gray-100">
              <button
                onClick={setIsOrderModalOpen}
                className="group flex w-full items-center gap-2 rounded-lg py-2 px-3 data-[focus]:bg-black data-[focus]:text-white"
              >
                Single Order
              </button>
            </MenuItem>
            <MenuItem as="div" className="hover:bg-gray-100">
              <button
                onClick={setIsBulkImportModalOpen}
                className="group flex w-full items-center gap-2 rounded-lg py-2 px-3 data-[focus]:bg-black data-[focus]:text-white"
              >
                Bulk Import
              </button>
            </MenuItem>
          </MenuItems>
        </Menu>
        <Button
          icon="route"
          onClick={() => {
            useDispatchStore.setState({
              focusedRouteId: "",
              routeModalMode: "create",
            });
          }}
          appearance="secondary"
        >
          Create Route
        </Button>
      </div>
      <OrdersFilterDropdown />
    </div>
  );
}
