import { useQuery } from "@tanstack/react-query";

import { getTimeline } from "../services/timeline";

export const useTimeline = (params: {
  orderId: string;
  timelineLimit: number;
}) =>
  useQuery({
    queryKey: ["orders-timeline", params],
    queryFn: getTimeline,
    retry: 2,
    staleTime: 30000,
  });
