import { useQueryClient } from "@tanstack/react-query";
import { AgGridReact } from "ag-grid-react";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";

import { useGetDrivers } from "@web-app/api";
import { useDocument } from "@web-app/hooks";
import { DriverEntity, Order } from "@web-app/types";

import Navigation from "../common/Navigation";
import OrderDetailsSidebar from "../common/Orders/OrderDetailsSidebar";

import OrderTableContainer from "./OrderTableContainer";

const ManagePage = ({ logout }: { logout: () => void }) => {
  useDocument({ title: "Table View · FULFLLD" });

  const queryClient = useQueryClient();
  const { data: assignees } = useGetDrivers();
  // const orders = queryClient.getQueryData(["orders"]);

  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [agGridRef, setAgGridRef] = useState<AgGridReact | null>(null);
  const [orders, setOrders] = useState<Order[] | undefined>(() =>
    queryClient.getQueryData(["orders"]),
  );

  useEffect(() => {
    const updateOrders = () => {
      const updatedOrders: Order[] | undefined = queryClient.getQueryData([
        "orders",
      ]);
      setOrders(updatedOrders);
    };

    const unsubscribe = queryClient.getQueryCache().subscribe(updateOrders);

    return () => {
      unsubscribe();
    };
  }, [queryClient]);

  useEffect(() => {
    if (orders && assignees) {
      queryClient.setQueryData<Map<string, DriverEntity>>(
        ["drivers"],
        (oldAssignees) => {
          orders?.forEach((order) => {
            if (order.assignee && order.connectedWorkspace) {
              const assignee = {
                ...order.assignee,
                userProfile: order.assignee,
                location: order?.driverLocation,
              };
              // @ts-ignore temporary fix
              oldAssignees.set(order.assignee.id, assignee);
            }
          });

          return oldAssignees;
        },
      );
    }
  }, [orders, assignees, queryClient]);

  const refetchOrders = () => {
    agGridRef?.api?.refreshServerSide({ purge: true });
  };

  return (
    <div>
      <Navigation logout={logout} />
      <Toaster position="top-center" />
      <div className="ml-20 flex h-screen">
        <main
          className={clsx(
            `h-full inset-y-0 left-20 hidden  overflow-y-hidden border-gray-200 bg-gray-100 px-4 py-4 sm:px-6 lg:px-5 xl:block`,
            selectedOrder ? "w-[calc(100%-480px)]" : "w-full",
          )}
        >
          <div className="h-full">
            <OrderTableContainer
              selectedOrder={selectedOrder}
              setSelectedOrder={setSelectedOrder}
              setAgGridRef={setAgGridRef}
              refetchOrders={refetchOrders}
            />
          </div>
        </main>
        {selectedOrder && (
          <aside className={`w-[480px] bg-white`}>
            <OrderDetailsSidebar
              onCloseEdit={() => setSelectedOrder(null)}
              order={selectedOrder}
              isReadOnly={false}
              refetchOrders={refetchOrders}
            />
          </aside>
        )}
      </div>
    </div>
  );
};

export default ManagePage;
