import { useQuery } from "@tanstack/react-query";

import { getFeatureflags } from "../services/featureFlags";

export const useFeatureflags = () => {
  const get = async () => {
    const response = await getFeatureflags();
    return response;
  };

  const { data } = useQuery({
    queryKey: ["featureflags"],
    queryFn: get,
    refetchOnWindowFocus: false,
  });

  const hasFeature = (feature: string) => {
    return data?.find((flag) => flag.feature === feature)?.enabled;
  };

  return {
    data,
    hasFeature,
  };
};
