import { Switch } from "@headlessui/react";
import clsx from "clsx";
import type { ChangeEvent, TextareaHTMLAttributes } from "react";
import { Mention, MentionsInput } from "react-mentions";

import { Tooltip } from "@web-app/components";

const mentionInputStyle = {
  highlighter: {
    padding: 9,
  },
  input: {
    padding: 9,
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};

const getTextLength = (
  suggestions: MentionTextFieldProp["suggestions"],
  value: MentionTextFieldProp["defaultValue"],
) => {
  suggestions?.forEach((element) => {
    value = value?.replaceAll(element.markup, " ".repeat(element.count));
  });
  return value?.length;
};

type MentionTextFieldProp = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
  name: string;
  maxLength?: number;
  isChecked: boolean;
  tooltipText?: string;
  suggestions?: {
    id: string;
    display: string;
    markup: string;
    count: number;
  }[];
  onCheckboxChange: (name: string) => void;
  onValueChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  isError?: boolean;
  defaultValue: string;
};

export function TextFieldWithMentions({
  label,
  maxLength,
  isChecked,
  onCheckboxChange,
  onValueChange,
  isError,
  name,
  defaultValue,
  suggestions,
  tooltipText,
}: MentionTextFieldProp) {
  return (
    <div>
      <div className="mb-2 flex items-center justify-between">
        <div className="flex items-center gap-1">
          <p className="text-sm text-gray-700">{label}</p>
          {tooltipText && <Tooltip text={tooltipText} />}
        </div>

        <div className="flex flex-col items-start justify-between gap-2">
          <label className="flex cursor-pointer items-center gap-3">
            <Switch
              name={name}
              defaultChecked={isChecked}
              onChange={() => {
                if (onCheckboxChange) {
                  onCheckboxChange(name);
                }
              }}
              className={clsx(
                isChecked ? "bg-black" : "bg-gray-400",
                "relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none",
              )}
            >
              <span
                aria-hidden="true"
                className={clsx(
                  isChecked ? "translate-x-6" : "translate-x-0",
                  "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                )}
              />
            </Switch>
            <span
              className={`text-sm font-medium   ${
                isChecked
                  ? "text-gray-700 dark:text-gray-300"
                  : "text-gray-700/50 dark:text-gray-300"
              }`}
            >
              {isChecked ? "Enabled" : "Disabled"}
            </span>
          </label>
        </div>
      </div>

      <div className=" w-full md:mb-0">
        <div className="relative ">
          <MentionsInput
            name={name}
            style={mentionInputStyle}
            placeholder="Enter message. Use '/' for suggestion "
            // @ts-ignore
            onChange={onValueChange}
            value={defaultValue}
            className={clsx(`rounded-md border bg-gray-100
           text-sm
            ${isError && "border-red-600"}`)}
          >
            <Mention
              trigger="/"
              markup="/[__display__](__id__)"
              data={suggestions ?? []}
              className=" bg-slate-300 "
            />
          </MentionsInput>

          <span
            className={` -bottom-4 left-0  text-right text-xs ${
              isError ? "text-red-600" : "text-gray-00"
            }`}
          >
            {maxLength &&
              `${getTextLength(suggestions, defaultValue)} / ${maxLength}`}
          </span>
        </div>
      </div>
    </div>
  );
}
