// @ts-nocheck - This file is not type checked
import { Combobox, Transition } from "@headlessui/react";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { useState } from "react";

import { DriverEntity } from "@web-app/types";

import { Icon } from "../Icon";

type AssigneeSelectType = {
  selectedId?: string | null;
  onChange?: (assigneeId: string | null) => void;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  readOnlyLabel?: string;
};

export default function SelectAssignee(props: AssigneeSelectType) {
  const [searchTerm, setSearchTerm] = useState("");
  const queryClient = useQueryClient();
  const assignees = queryClient.getQueryData<Map<string, DriverEntity>>([
    "drivers",
  ]);
  const [selectedAssignee, setSelectedAssignee] = useState<DriverEntity | null>(
    props.selectedId ? assignees!.get(props.selectedId) : "",
  );

  if (!assignees) {
    return <span>Loading</span>;
  }

  const allAssigneesList = Array.from(assignees!.values())
    .sort((driverA, driverB) =>
      driverA.userProfile.name! < driverB.userProfile.name! ? -1 : 1,
    )
    .map((driver) => ({
      label: driver.userProfile.name || "No Name",
      value: driver.userProfile.id,
    }));

  const selectedAssigneeOption = selectedAssignee
    ? {
        label: selectedAssignee.userProfile.name,
        value: selectedAssignee.userProfile.id,
      }
    : null;

  const assigneesList =
    searchTerm === ""
      ? allAssigneesList
      : allAssigneesList.filter((driver: DriverEntity) => {
          return driver.label.toLowerCase().includes(searchTerm.toLowerCase());
        });

  const handleOnChange = (driverValue: { label: string; value: string }) => {
    const driver = assignees!.get(driverValue.value);
    if (driver) {
      if (props.onChange) {
        props.onChange?.(driver.userProfile.id);
        if (!props.selectedId) {
          setSelectedAssignee(driver);
        }
      }
      return;
    }

    props.onChange?.("");
  };

  return (
    <Combobox
      value={selectedAssigneeOption}
      immediate
      onChange={handleOnChange}
      disabled={props.disabled}
    >
      {({ open }) => (
        <div className="relative w-full">
          {props.label && (
            <label
              className={clsx(
                "mb-1 block text-xs font-medium leading-5 tracking-wide",
                props.disabled ? "text-gray-400" : "text-gray-700",
              )}
            >
              {props.label}
              {props.required && "*"}
            </label>
          )}
          {props.readOnly ? (
            <span className="tw-form-field-readonly-style">
              {props.readOnlyLabel ||
                selectedAssigneeOption?.label ||
                "Unassigned"}
            </span>
          ) : (
            <>
              <div className="relative leading-none">
                <Combobox.Input
                  onChange={(event) => setSearchTerm(event.target.value)}
                  className="box-border h-[38px] w-full rounded-md bg-gray-100 px-2 py-3 text-sm leading-5 text-gray-900 outline-none transition ease-in placeholder:text-gray-500 hover:bg-white focus:bg-white focus:ring-2 focus:ring-gray-300 disabled:pointer-events-none disabled:bg-gray-50 disabled:text-gray-400"
                  displayValue={(assignee?: { label: string }) =>
                    assignee ? assignee.label : ""
                  }
                  placeholder={
                    props.disabled ? "Unassigned" : "Assign a Driver"
                  }
                />
                {selectedAssigneeOption && !props.disabled && (
                  <span className="-transition-y-1/2 absolute right-0 top-1/2 flex">
                    {" "}
                    <Icon
                      name="xMark"
                      className="h-3 w-3 -translate-y-1/2 cursor-pointer pl-2 pr-2 text-gray-500 transition ease-in"
                      onClick={() => {
                        props.onChange?.("");
                        setSelectedAssignee("");
                      }}
                    />
                  </span>
                )}
              </div>
              <Transition
                show={open}
                as="div"
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="relative z-[60] w-full"
              >
                <Combobox.Options
                  className="absolute mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  static
                >
                  {assigneesList?.map((assignee: DriverEntity) => (
                    <Combobox.Option
                      key={assignee.value}
                      value={assignee}
                      className={({ active }) =>
                        clsx(
                          active ? "bg-black text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9",
                        )
                      }
                    >
                      {assignee.label}
                    </Combobox.Option>
                  ))}
                  {!assigneesList?.length && (
                    <Combobox.Option
                      className={({ active }) =>
                        clsx(
                          active ? "bg-black text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9",
                        )
                      }
                      disabled
                      value={"none"}
                    >
                      No Assignees
                    </Combobox.Option>
                  )}
                </Combobox.Options>
              </Transition>
            </>
          )}
        </div>
      )}
    </Combobox>
  );
}
