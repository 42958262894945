import type { InputHTMLAttributes } from "react";

import { Attachment } from "@web-app/types";

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  attachments?: Attachment[];
};

export default function OrderAttachments(props: InputProps) {
  return (
    <div className="mb-6 w-full md:mb-0">
      {props.label && (
        <label className="mb-1 block text-xs font-semibold tracking-wide text-gray-700">
          {props.label}
          {props.required && "*"}
        </label>
      )}
      <div className="flex cursor-pointer flex-col items-center gap-2">
        {props.attachments?.length ? (
          props.attachments.map((attachment) => (
            <img
              alt=""
              key={attachment.id}
              src={attachment?.url || ""}
              className="object-cover w-[200px] h-[200px]"
            />
          ))
        ) : (
          <span className="mt-2 text-xs text-gray-500">
            No attachments available
          </span>
        )}
        <img />
      </div>
    </div>
  );
}
