import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { format } from "date-fns";

import { Icon } from "@web-app/components";
import { DriverEntity, OrderTimeline } from "@web-app/types";
import { OrderTimelineSchema } from "@web-app/utils/helpers";

type AssigneeActivityPropsType = {
  item: OrderTimeline;
  showTimelineBar: boolean;
};

export default function AssigneeActivity({
  item,
  showTimelineBar,
}: AssigneeActivityPropsType) {
  const drivers = useQueryClient().getQueryData<Map<string, DriverEntity>>([
    "drivers",
  ]);

  const driver = drivers?.get(item.updatedValue);

  if (!driver) {
    return null;
  }

  const AssigneeIcon =
    OrderTimelineSchema[item.entity].action[item.action].icon;
  const assigneeColor =
    OrderTimelineSchema[item.entity].action[item.action].color;

  return (
    <li key={item.id} className="relative flex gap-x-4">
      <div
        className={clsx(
          showTimelineBar ? "-bottom-6" : "h-6",
          "absolute left-0 top-0 flex w-6 justify-center",
        )}
      >
        <div className="w-px bg-gray-200" />
      </div>
      <div className="relative flex h-7 w-6 flex-none items-center justify-center bg-white">
        <Icon name={AssigneeIcon} className={clsx("h-4", assigneeColor)} />
      </div>
      <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
        <span
          className={clsx(
            "inline-block font-medium text-gray-900",
            assigneeColor,
          )}
        >
          {!item.updatedValue &&
            `${OrderTimelineSchema[item.entity].action.DELETE.text}`}
          {item.updatedValue &&
            `${OrderTimelineSchema[item.entity].action[item.action].text}`}
          {item.updatedValue && ` ${driver?.userProfile?.name}`}
        </span>
        <span className="block">
          {`${format(new Date(item?.createdAt), "MMMM dd, yyyy, hh:mma")} by ${
            item.createdBy?.name
          }`}
        </span>
      </p>
    </li>
  );
}
