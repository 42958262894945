import type { CustomFieldGroup } from "@web-app/types";

import { Icons } from "../Icon/utils";

export const inputTypeOptions: {
  value: string;
  label: string;
  disabled: boolean;
  icon: keyof typeof Icons;
}[] = [
  {
    value: "TEXT",
    label: "Text",
    disabled: false,
    icon: "textSize",
  },
  {
    value: "NUMBER",
    label: "Number",
    disabled: false,
    icon: "hashtag",
  },
  {
    value: "DROPDOWN",
    label: "Dropdown",
    disabled: true,
    icon: "dropdown",
  },
];
enum CustomFieldType {
  TEXT = "TEXT",
  NUMBER = "NUMBER",
  DROPDOWN = "DROPDOWN",
  DATE = "DATE",
  BOOLEAN = "BOOLEAN",
}

export const orderCardIconMapper: {
  [type in CustomFieldType]: keyof typeof Icons;
} = {
  TEXT: "textSize",
  NUMBER: "hashtag",
  DROPDOWN: "dropdown",
  DATE: "user",
  BOOLEAN: "user",
};

interface Fields {
  name?: string;
  groupId: string;
}

export const mapCustomFieldsGroupData = <T extends Fields>(
  fieldGroups: CustomFieldGroup[],
  fields: T[],
  searchText = "",
) => {
  const fieldsMap = new Map<
    string,
    {
      fields: T[];
      name: string;
    }
  >();

  fieldGroups.map((group) => {
    if (!fieldsMap.get(group.id)) {
      fieldsMap.set(group.id, {
        fields: [],
        name: group.name,
      });
    }

    fields.map((field) => {
      if (searchText) {
        const searchValue = searchText.toLowerCase();

        if (field.name && !field.name.toLowerCase().includes(searchValue)) {
          return;
        }
      }

      if (field.groupId === group.id) {
        fieldsMap.set(group.id, {
          name: group.name,
          fields: [...fieldsMap.get(group.id)!.fields, field],
        });
      }
    });
  });

  return fieldsMap;
};
