import clsx from "clsx";
import type { ChangeEventHandler } from "react";

import Tooltip from "../Tooltip";

type ToggleType = {
  label: string;
  isChecked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  required?: boolean;
  name?: string;
  title?: string;
  titleHelpText?: string;
  helpText?: string;
};

export default function Toggle({
  title,
  titleHelpText,
  helpText,
  label,
  isChecked,
  onChange,
  required = false,
  name,
}: ToggleType) {
  return (
    <div className={clsx(title ? "inline-block" : "flex items-center")}>
      {title && (
        <div className="flex flex-col gap-1 items-start justify-center mb-2">
          <span className="leading-5 font-semibold text-sm text-gray-700">
            {title}
          </span>
          {titleHelpText && (
            <span className="text-gray-500 leading-4 text-xs">
              {titleHelpText}
            </span>
          )}
          {helpText && <Tooltip text={helpText} />}
        </div>
      )}
      <label className="relative inline-flex cursor-pointer items-center">
        <input
          type="checkbox"
          className="peer sr-only"
          checked={isChecked}
          onChange={onChange}
          required={required}
          data-testid={`toggle-${name}`}
          name={name}
        />
        <div className="relative  peer h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-500 after:bg-gray-500 after:transition-all after:content-['']  peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-black peer-focus:outline-none  dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
          {label}
          {required && "*"}
        </span>
      </label>
    </div>
  );
}
