import { DriverEntity } from "@web-app/types";
import http from "@web-app/utils/http";

export const get = async (workspaceId: string) => {
  return http
    .get<Map<string, DriverEntity>>(`/driversv2?workspaceId=${workspaceId}`, {
      transformResponse: [
        (data) => {
          const drivers = JSON.parse(data);
          if (drivers) {
            const driverMap = new Map<string, DriverEntity>(
              drivers.map((driver: DriverEntity) => [
                driver.userProfile.id,
                driver,
              ]),
            );
            return driverMap;
          }
        },
      ],
    })
    .then((res) => {
      return res.data;
    });
};
