import { Session } from "./auth";
import { Device } from "./device";
import { TransportType } from "./orders";
import { User } from "./users";

export type DriverEntity = {
  id: string;
  capacity: number;
  licenseNumber: string;
  ssnNumber: string;
  routing: string;
  dutyStatus: DutyStatus;
  transportType: TransportType;
  transport: {
    model: string;
    plateNumber: string;
  };
  device: Device;
  userProfile: User;
  location: {
    latitude: number;
    longitude: number;
    updatedAt: string;
  } | null;
  label: string;
  value: string;
  session: Session;
};

export enum DutyStatus {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
  INTRANSIT = "INTRANSIT",
}
