import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";

import { useRouteMutation } from "@web-app/api";
import useDispatchStore from "@web-app/store/dispatchStore";
import { Order } from "@web-app/types";

export default function useManageRoute() {
  const { routes, appFilter, focusedRouteId: routeId } = useDispatchStore();

  const queryClient = useQueryClient();
  const orders = queryClient.getQueryData<Map<string, Order>>([
    "orders",
    appFilter,
  ]);
  const { saveRoute, assignRoute, removeRoute, routeRegenerate } =
    useRouteMutation();
  const [updatedAssignee, setUpdatedAssignee] = useState(false);

  const route = routeId ? routes.get(routeId) : null;

  const isRefetching = useIsFetching({ queryKey: ["orders", appFilter] });
  useEffect(() => {
    if (!isRefetching) {
      setUpdatedAssignee(false);
    }
  }, [isRefetching]);

  const handleAssignRoute = async (assignee: string | null) => {
    setUpdatedAssignee(true);
    const res = assignRoute.mutateAsync({
      assigneeId: assignee,
      routeId: routeId!,
    });

    await toast.promise(res, {
      loading: "Assigning route ...",
      success: "Successfully assigned route",
      error: "Something went wrong, please try again!",
    });
  };

  const moveOrder = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newRoutes = new Map(routes);
      const newRoute = newRoutes.get(routeId!)!;

      let newRouteOrdersOrder = newRoute.ordersOrder;
      const temp = newRouteOrdersOrder[hoverIndex];
      newRouteOrdersOrder[hoverIndex] = newRouteOrdersOrder[dragIndex];
      newRouteOrdersOrder[dragIndex] = temp;
      newRouteOrdersOrder = newRouteOrdersOrder.map((orderItem, index) => {
        return {
          ...orderItem,
          index,
        };
      });
      newRoute.ordersOrder = newRouteOrdersOrder;

      newRoutes.set(routeId!, newRoute);
      useDispatchStore.setState(() => ({
        routes: newRoutes,
        isRouteOrdersOrderChanged: true,
      }));
    },
    [routes],
  );

  const handleReorderRoute = async (routeId: string) => {
    const route = routes.get(routeId);

    const ordersOrder = route?.ordersOrder || [];
    const firstOrder = orders?.get(ordersOrder[0].id);
    const hasOrderETAs = !!firstOrder?.eta?.dateTime;
    await toast.promise(
      saveRoute.mutateAsync({
        routeId: routeId,
        ordersOrder,
        hasOrderETAs,
      }),
      {
        loading: "Reordering Route",
        success: "Successfully Re-ordered Route",
        error: "Something went wrong, please try again!",
      },
    );
  };

  const addOrderToRoute = async (routeId: string, orderId: string) => {
    const route = routes.get(routeId);
    const order = orders?.get(orderId);

    if (route && order) {
      const newRoutes = structuredClone(routes);
      newRoutes.get(routeId)!.ordersOrder.push({
        id: orderId,
        index: route.ordersOrder.length,
        type: "order",
      });

      useDispatchStore.setState(() => {
        return {
          routes: newRoutes,
          isRouteOrdersOrderChanged: true,
        };
      });
    }
  };

  const removeOrder = async (orderId: string) => {
    const order = orders!.get(orderId)!;
    const orderDeliveryType = order.type;
    // @ts-ignore TODO: fix this
    const route = routes.get(routeId)!;
    const routeOrders = route.ordersOrder.map(({ id }) => orders?.get(id));
    const pickupOrderCount = routeOrders.filter(
      (order) => order?.type === "ROUTE_OPT_PICKUP",
    );
    const dropoffOrderCount = routeOrders.filter(
      (order) => order?.type === "ROUTE_OPT_DROPOFF",
    );

    if (
      pickupOrderCount.length === 1 &&
      orderDeliveryType === "ROUTE_OPT_PICKUP"
    ) {
      return toast.error("Need atleast one Pickup order!");
    }

    if (
      dropoffOrderCount.length === 1 &&
      orderDeliveryType === "ROUTE_OPT_DROPOFF"
    ) {
      return toast.error("Need atleast one Drop-off order!");
    }
    const newRoute = structuredClone(route);

    if (newRoute.ordersOrder.length) {
      newRoute.ordersOrder = newRoute.ordersOrder
        .filter((orderItem) => orderItem.id !== orderId)
        .map((orderItem, index) => {
          orderItem.index = index;
          return orderItem;
        });
    }

    const newRoutes = new Map(routes);
    // @ts-ignore TODO: fix this
    newRoutes.set(routeId, newRoute);

    useDispatchStore.setState(() => ({
      routes: newRoutes,
      isRouteOrdersOrderChanged: true,
    }));
  };

  const handleRemoveRoute = async (routeId: string) => {
    await toast.promise(removeRoute.mutateAsync(routeId), {
      loading: "Removing route ...",
      success: "Successfully removed route",
      error: "Something went wrong, please try again!",
    });
  };

  const regenerateRoute = async () => {
    // @ts-ignore TODO: fix this
    const route = routes.get(routeId);
    await toast.promise(
      routeRegenerate.mutateAsync({
        // @ts-ignore TODO: fix this
        routeId: routeId,
        ordersOrder: route?.ordersOrder || [],
      }),
      {
        loading: "Generating Reroute ...",
        success: "Successfully generated reroute",
        error: "Something went wrong, please try again!",
      },
    );

    return;
  };

  const handleCloseRouteEditModal = () => {
    useDispatchStore.setState({
      routeModalMode: "closed",
      focusedRouteId: "",
      isRouteOrdersOrderChanged: false,
    });
  };

  return {
    handleAssignRoute,
    moveOrder,
    removeOrder,
    handleRemoveRoute,
    regenerateRoute,
    route,
    orders,
    handleReorderRoute,
    addOrderToRoute,
    updatedAssignee,
    handleCloseRouteEditModal,
    isRefetching,
    isAssigningRoute: assignRoute.isPending,
  };
}
