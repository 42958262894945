import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import useDispatchStore from "@web-app/store/dispatchStore";
import { UpdateWorkspacePayload } from "@web-app/types";

import { getWorkspaceConnections, update } from "../services/workspaces";

const queryKey = "workspaces";
export const useWorkspaceMutation = () => {
  const queryClient = useQueryClient();

  const updateWorkspace = useMutation({
    mutationFn: async (params: {
      id: string;
      payload: Partial<UpdateWorkspacePayload>;
    }) => {
      const response = await update(params.id, params.payload);
      return response;
    },
    onSuccess: () => {
      // @ts-ignore - check this
      queryClient.invalidateQueries([queryKey]);
    },
  });

  return {
    updateWorkspace,
  };
};

export const useWorkspaceConnections = () => {
  const { user } = useDispatchStore();

  return useQuery({
    queryKey: ["connections", user?.currentWorkspaceId],
    queryFn: async () => {
      const response = await getWorkspaceConnections(
        user?.currentWorkspaceId || "",
      );
      return response;
    },
    refetchOnWindowFocus: false,
  });
};
