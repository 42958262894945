import type { FormEvent } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";

import { useUserMutation } from "@web-app/api";
import { AvatarInput, Button, Input, SelectInput } from "@web-app/components";
import { User } from "@web-app/types";

import TabTitle from "./TabTitle";

type ProfileTabType = {
  user: User;
};

export default function ProfileTab({ user }: ProfileTabType) {
  const [avatarUrl, setAvatarUrl] = useState(user?.image);
  const [name, setName] = useState(user?.name);

  const { updateUser } = useUserMutation();

  const onSaveProfile = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      name: { value: string };
    };

    const formData = {
      name: target.name.value,
      image: avatarUrl || "",
      userId: user.id,
    };

    toast.promise(
      updateUser.mutateAsync({
        id: user.id,
        payload: formData,
      }),
      {
        loading: "Updating User ...",
        success: "Successfully Updated User!",
        error: "Something went wrong! Please try again",
      },
    );
  };

  return (
    <div className="max-w-lg p-4 px-6">
      <TabTitle styles="mb-8" icon="fasUser" title="Profile" />
      <form onSubmit={onSaveProfile}>
        <div className="mb-8">
          <AvatarInput
            imageUrl={avatarUrl || ""}
            setImageUrl={setAvatarUrl}
            name={name || ""}
          />
        </div>
        <div className="my-2">
          <Input
            type="text"
            required
            label="Name"
            name="name"
            defaultValue={user?.name || ""}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="my-2">
          <Input
            type="text"
            label="Email"
            name="email"
            disabled
            defaultValue={user?.email || ""}
          />
        </div>
        <div className="my-2">
          <SelectInput
            disabled
            label="Role"
            selected={user.role.role}
            // display="normal"
            options={[
              {
                label: "Super Admin",
                value: "BEAST",
              },
              {
                label: "Admin",
                value: "ADMIN",
              },
              {
                label: "Default User",
                value: "USER",
              },
              {
                label: "Driver",
                value: "DRIVER",
              },
              {
                label: "Client",
                value: "CLIENT",
              },
              {
                label: "Dispatcher",
                value: "DISPATCHER",
              },
            ]}
            placeholder="Select Role"
            required
          />
        </div>
        <div className="mt-6 flex gap-2">
          <Button disabled={updateUser.isPending} type="submit">
            Update
          </Button>
        </div>
      </form>
    </div>
  );
}
