import clsx from "clsx";

type RadioButtonGroupPropsTypes = {
  options: {
    id: string;
    name: string;
    description: string;
    onChange: () => void;
  }[];
  orient?: "vertical" | "horizontal";
};
export default function RadioButtonGroup({
  options,
  orient = "vertical",
}: RadioButtonGroupPropsTypes) {
  return (
    <div
      className={clsx({
        "space-y-5": orient === "vertical",
        "flex py-3": orient === "horizontal",
      })}
    >
      {options.map((option) => (
        <div key={option.id} className="relative flex items-start">
          <div className="flex h-6 min-w-[44px] items-center justify-end">
            <input
              id={option.id}
              aria-describedby={`${option.id}-description`}
              name="option"
              type="radio"
              defaultChecked={option.id === "1"}
              className="h-5 w-5 border-gray-300 !bg-black text-black focus:ring-black"
              onChange={option.onChange}
            />
          </div>
          <div className="ml-3 text-sm leading-6">
            <label htmlFor={option.id} className="font-medium text-gray-900">
              {option.name}
            </label>
            <p id={`${option.id}-description`} className="text-gray-500">
              {option.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
