import type { FormEvent } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";

import { useUserMutation } from "@web-app/api";
import { Button, Icon, Input, Modal } from "@web-app/components";
import type { User } from "@web-app/types";
import { validatePhoneNumber } from "@web-app/utils/helpers";

export default function UserPage({
  setCurrentUserId,
  user,
}: {
  setCurrentUserId: (userId: string) => void;
  user: User;
}) {
  const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);
  const [password, setPassword] = useState("");
  const userRole = user.role.role;

  const { updateUser } = useUserMutation();

  const handleResetPassword = async () => {
    if (!password) {
      toast.error("Please enter a valid password");
      return;
    }

    await toast.promise(
      updateUser.mutateAsync({
        id: user.id,
        payload: {
          password: password,
        },
      }),
      {
        loading: "Updating Password ...",
        success: "Successfully Updated User Password!",
        error: "Something went wrong, please try again!",
      },
    );

    setIsPasswordModalVisible(false);
    setPassword("");
    setCurrentUserId("");
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      name: { value: string };
      email: { value: string };
      phone: { value: string };
    };

    if (userRole === "DRIVER" && !validatePhoneNumber(target.phone.value)) {
      return;
    }

    const formData = {
      name: target.name.value,
      phone: target.phone?.value,
    };

    await toast.promise(
      updateUser.mutateAsync({
        id: user.id,
        payload: formData,
      }),
      {
        loading: "Updating User ...",
        success: "Successfully Updated User!",
        error: "Something went wrong, please try again!",
      },
    );
    setCurrentUserId("");
  };

  return (
    <div className="max-w-lg p-5">
      <div
        onClick={() => setCurrentUserId("")}
        className="mb-3 cursor-pointer text-sm text-gray-500"
      >
        <Icon name="fasChevronLeft" className="mr-1" /> Back
      </div>

      <h1 className="text-lg font-semibold">Edit User</h1>
      <div className="mt-3">
        <form onSubmit={onSubmit}>
          <div>
            <Input
              type="text"
              label="Full Name"
              name="name"
              required
              defaultValue={user.name || ""}
            />
          </div>
          <div className="mt-3">
            <Input
              type="email"
              label="Email"
              name="email"
              required
              defaultValue={user.email || ""}
              disabled
            />
          </div>
          <div className="mt-3">
            <Input
              type="text"
              label="Role"
              name="role"
              required
              defaultValue={userRole}
              disabled
            />
          </div>
          {userRole === "DRIVER" && (
            <div className="mt-3">
              <Input
                label="Phone"
                type="string"
                name="phone"
                defaultValue={user.phone! || ""}
              />
            </div>
          )}
          <div className="mt-6 flex gap-2">
            <Button type="submit">Update</Button>
            <Button
              type="button"
              onClick={() => setIsPasswordModalVisible(true)}
            >
              Reset Password
            </Button>
          </div>
        </form>
      </div>

      <Modal
        isOpen={isPasswordModalVisible}
        onClose={() => setIsPasswordModalVisible(false)}
        title="Reset Password"
        actions={[
          <Button type="button" onClick={handleResetPassword}>
            Reset
          </Button>,
          <Button
            type="button"
            onClick={() => setIsPasswordModalVisible(false)}
          >
            Cancel
          </Button>,
        ]}
      >
        <Input
          label=""
          type="password"
          name="password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Modal>
    </div>
  );
}
