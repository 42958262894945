import { useMutation, useQueryClient } from "@tanstack/react-query";

import useDispatchStore from "@web-app/store/dispatchStore";
import {
  assignRoutePayloadType,
  createRoutePayloadType,
  regenerateRoutePayloadType,
  saveRoutePayloadType,
} from "@web-app/types";

import { assign, create, regenerate, remove, save } from "../services/routes";

export const useRouteMutation = () => {
  const queryClient = useQueryClient();

  const { appFilter } = useDispatchStore();
  const createRoute = useMutation({
    mutationFn: async (payload: createRoutePayloadType) => {
      const response = await create(payload);
      return response;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["orders", appFilter] });

      useDispatchStore.setState({
        selectedOrderIds: new Set(),
        focusedRouteId: data.id,
      });
    },
  });

  const saveRoute = useMutation({
    mutationFn: async (payload: saveRoutePayloadType) => {
      const response = await save(payload);
      return response;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["orders", appFilter] });

      useDispatchStore.setState({
        isRouteOrdersOrderChanged: false,
        focusedRouteId: "",
        routeModalMode: "closed",
      });
    },
  });

  const assignRoute = useMutation({
    mutationFn: async (payload: assignRoutePayloadType) => {
      const response = await assign(payload);
      return response;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["orders", appFilter] });
    },
  });

  const removeRoute = useMutation({
    mutationFn: async (routeId: string) => {
      await remove(routeId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["orders", appFilter] });

      useDispatchStore.setState({
        focusedRouteId: "",
      });
    },
  });

  const routeRegenerate = useMutation({
    mutationFn: async (payload: regenerateRoutePayloadType) => {
      await regenerate(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["orders", appFilter] });

      useDispatchStore.setState({
        focusedRouteId: "",
        isRouteOrdersOrderChanged: false,
        routeModalMode: "closed",
      });
    },
  });

  return { createRoute, saveRoute, assignRoute, removeRoute, routeRegenerate };
};
