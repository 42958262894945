import {
  DriverEntity,
  DutyStatus,
  Order,
  OrderSideBarEntity,
} from "@web-app/types";

export type SortParameterType =
  | "scheduledStartDateTime"
  | "scheduledEndDateTime"
  | "createdAt"
  | "updatedAt";

export type SortOptionType = {
  value: SortParameterType;
  label: string;
  disabled: boolean;
};

export const sortOptions: SortOptionType[] = [
  {
    value: "scheduledStartDateTime",
    label: "Pickup Time",
    disabled: false,
  },
  {
    value: "scheduledEndDateTime",
    label: "Drop-off Time",
    disabled: false,
  },
  {
    value: "createdAt",
    label: "Creation Time",
    disabled: false,
  },
  {
    value: "updatedAt",
    label: "Last Modified",
    disabled: false,
  },
];

export const groupOptions = [
  {
    value: "none",
    label: "Default",
    disabled: false,
  },
  {
    value: "connections-drivers",
    label: "Drivers & Connections",
    disabled: false,
  },
  {
    value: "connections",
    label: "Connections",
    disabled: false,
  },
];
export const getSortedOrderSideBarEntities = (
  entities: OrderSideBarEntity[],
  sortParameter: SortParameterType,
  orders: Map<string, Order>,
) => {
  // TODO: add scheduledEndDateTime column for route
  const sortedOrders = entities.sort((aEntity, bEntity) => {
    let aDate: Date;
    let bDate: Date;

    if (aEntity.type === "order") {
      // @ts-ignore - fix this
      aDate = new Date(aEntity["entity"][sortParameter]);
    } else {
      if (sortParameter === "scheduledStartDateTime") {
        let firstRoutedOrder = orders.get(aEntity["entity"].ordersOrder[0].id)!;
        // FIXME: temporary fix for the case where the first order is not present
        if (!firstRoutedOrder) {
          firstRoutedOrder = orders.get(aEntity["entity"].ordersOrder[1].id)!;
        }

        const firstOrderScheduledStartDateTime =
          firstRoutedOrder.scheduledStartDateTime;
        aDate = new Date(firstOrderScheduledStartDateTime);
      } else if (sortParameter === "scheduledEndDateTime") {
        const lastOrderIndex = aEntity["entity"].ordersOrder.length - 1;
        const lastRoutedOrderId =
          aEntity["entity"].ordersOrder[lastOrderIndex].id;
        const lastRoutedOrder = orders.get(lastRoutedOrderId)!;
        const lastOrderScheduledEndDateTime =
          lastRoutedOrder.scheduledEndDateTime;
        aDate = new Date(lastOrderScheduledEndDateTime);
      } else {
        aDate = new Date(aEntity["entity"][sortParameter]);
      }
    }
    if (bEntity.type === "order") {
      // @ts-ignore - fix this
      bDate = new Date(bEntity["entity"][sortParameter]);
    } else {
      if (sortParameter === "scheduledStartDateTime") {
        let firstRoutedOrder = orders.get(bEntity["entity"].ordersOrder[0].id)!;

        if (!firstRoutedOrder) {
          firstRoutedOrder = orders.get(bEntity["entity"].ordersOrder[1].id)!;
        }
        const firstOrderScheduledStartDateTime =
          firstRoutedOrder.scheduledStartDateTime;
        bDate = new Date(firstOrderScheduledStartDateTime);
      } else if (sortParameter === "scheduledEndDateTime") {
        const lastOrderIndex = bEntity["entity"].ordersOrder.length - 1;
        const lastRoutedOrderId =
          bEntity["entity"].ordersOrder[lastOrderIndex].id;
        const lastRoutedOrder = orders.get(lastRoutedOrderId)!;
        const lastOrderScheduledEndDateTime =
          lastRoutedOrder.scheduledEndDateTime;
        bDate = new Date(lastOrderScheduledEndDateTime);
      } else {
        bDate = new Date(bEntity["entity"][sortParameter]);
      }
    }

    return aDate.getTime() - bDate.getTime();
  });

  return sortedOrders;
};

export const getSearchResultThroughProperty = (
  searchText: string,
  property: string,
) => {
  const searchTextRegex = new RegExp(`\\w*?${searchText}\\w*?\\s*?`);

  if (property) {
    return (
      searchTextRegex.test(property) ||
      searchTextRegex.test(property.toLowerCase())
    );
  }

  return false;
};

export const getGroupedSideBarEntities = (
  orderSideBarEntities: OrderSideBarEntity[],
  assignees: Map<string, DriverEntity>,
) => {
  const groupedSideBarEntities: {
    [key: string]: { [key: string]: Array<OrderSideBarEntity> };
  } = {};
  const workspaceNameById: { [key: string]: string } = {
    "No Connected Workspaces": "No Connected Workspaces",
  };
  const assigneeNameById: {
    [key: string]: {
      name: string | null;
      image?: string | null;
      dutyStatus?: DutyStatus | undefined;
    };
  } = { "No Assignees": { name: "No Assignees" } };

  orderSideBarEntities.forEach((sideBarEntity: OrderSideBarEntity) => {
    const entity = sideBarEntity.entity;
    if (entity.workspace) {
      workspaceNameById[entity.workspace.id] = entity.workspace.name;
    }

    const assignee = assignees.get(
      entity.assignee?.id || entity?.assigneeId || "",
    );
    if (assignee?.id) {
      assigneeNameById[assignee.id] = {
        name: assignee.userProfile.name,
        image: assignee.userProfile.image,
        dutyStatus: assignee && assignee.dutyStatus,
      };
    }

    const workspaceKey = entity?.workspace?.id ?? "No Workspaces";
    if (!groupedSideBarEntities[workspaceKey]) {
      groupedSideBarEntities[workspaceKey] = {};
    }

    const assigneeKey = assignee?.id ?? "No Assignees";
    if (!groupedSideBarEntities[workspaceKey][assigneeKey]) {
      groupedSideBarEntities[workspaceKey][assigneeKey] = [];
    }
    groupedSideBarEntities[workspaceKey][assigneeKey].push(sideBarEntity);
  });

  return {
    groupedSideBarEntities,
    workspaceNameById,
    assigneeNameById,
  };
};

export const useExternalAssignee = (order: Order) => {
  const isUberAssigned = order.externalAssigneeId?.split("_")[0] === "uber";
  const isDoordashAssigned =
    order.externalAssigneeId?.split("_")[0] === "doordash";

  return {
    isUberAssigned,
    isDoordashAssigned,
  };
};
