// import OptimizedRoute from "./OptimizedRoute";
import { DriverEntity, Order, Route as RouteType } from "@web-app/types";

import IndividualOrderRoute from "./IndividualOrderRoute";
import OptimizedRoute from "./OptimizedRoute";

export default function Route({
  order,
  route,
  assignee,
  isHovered,
  setHoveredPolylineElementId,
}: {
  order?: Order;
  route?: RouteType;
  assignee?: DriverEntity;
  isHovered: boolean;
  setHoveredPolylineElementId: (orderId: string | null) => void;
}) {
  if (order) {
    return (
      <IndividualOrderRoute
        order={order}
        assignee={assignee}
        isHovered={isHovered}
        setHoveredPolylineElementId={setHoveredPolylineElementId}
      />
    );
  }

  if (route) {
    return (
      <OptimizedRoute
        route={route}
        isHovered={isHovered}
        setHoveredPolylineElementId={setHoveredPolylineElementId}
      />
    );
  }
  return <></>;
}
