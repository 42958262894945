import { ZodRawShape, z } from "zod";

import { CustomField } from "@web-app/types";

import { GoogleAddress } from "../types/google";
import { OrderDeliveryType } from "../types/orders";

import { customFieldSchema } from "./customField";

export const ORDER_STATUS_COLORS = {
  TODO: {
    text: "text-gray-700",
    lightText: "text-gray-700",
    card: {
      border: "border-gray-100 hover:border-gray-400",
      background: "bg-white",
      darkBackground: "bg-black",
    },
    route: "#000000",
  },
  ASSIGNED: {
    text: "text-status-purple",
    lightText: "text-status-purple",
    card: {
      border: "border-status-purple hover:border-purple-700",
      background: "bg-status-purple-bg",
      darkBackground: "bg-purple-400",
    },
    route: "#9747FF",
  },
  UNASSIGNED: {
    text: "text-gray-600",
    lightText: "text-gray-100",
    card: {
      border: "border-gray-600 hover:border-gray-700",
      background: "bg-gray-100",
      darkBackground: "bg-gray-600",
    },
    route: "#575C64",
  },
  INPROGRESS: {
    text: "text-status-blue",
    lightText: "text-status-blue",
    card: {
      border: "border-status-blue hover:border-blue-500",
      background: "bg-status-blue-bg",
      darkBackground: "bg-blue-500",
    },
    route: "#28BFFF",
    progressBar: "bg-gray-300",
  },

  ORDER_IN_TRANSIT_TO_PICKUP: {
    text: "text-status-blue",
    lightText: "text-status-blue",
    card: {
      border: "border-status-blue hover:border-blue-500",
      background: "bg-status-blue-bg",
      darkBackground: "bg-blue-500",
    },
    route: "#28BFFF",
    progressBar: "bg-gray-300",
  },
  ORDER_ARRIVED_AT_PICKUP: {
    text: "text-status-blue",
    lightText: "text-status-blue",
    card: {
      border: "border-status-blue hover:border-blue-500",
      background: "bg-status-blue-bg",
      darkBackground: "bg-blue-500",
    },
    route: "#28BFFF",
    progressBar: "bg-gray-300",
  },
  ORDER_PICKED_UP: {
    text: "text-status-blue",
    lightText: "text-status-blue",
    card: {
      border: "border-status-blue hover:border-blue-500",
      background: "bg-status-blue-bg",
      darkBackground: "bg-blue-500",
    },
    route: "#28BFFF",
    progressBar: "bg-gray-300",
  },
  ORDER_IN_TRANSIT_TO_DROPOFF: {
    text: "text-status-blue",
    lightText: "text-status-blue",
    card: {
      border: "border-status-blue hover:border-blue-500",
      background: "bg-status-blue-bg",
      darkBackground: "bg-blue-500",
    },
    route: "#28BFFF",
    progressBar: "bg-gray-300",
  },
  ORDER_ARRIVED_AT_DROPOFF: {
    text: "text-status-blue",
    lightText: "text-status-blue",
    card: {
      border: "border-status-blue hover:border-blue-500",
      background: "bg-status-blue-bg",
      darkBackground: "bg-blue-500",
    },
    route: "#28BFFF",
    progressBar: "bg-gray-300",
  },
  ORDER_DROPPED_OFF: {
    text: "text-status-blue",
    lightText: "text-status-blue",
    card: {
      border: "border-status-blue hover:border-blue-500",
      background: "bg-status-blue-bg",
      darkBackground: "bg-blue-500",
    },
    route: "#28BFFF",
    progressBar: "bg-gray-300",
  },
  COMPLETE: {
    text: "text-status-green",
    lightText: "text-green-500",
    card: {
      border: "bg-status-green hover:border-green-700",
      background: "bg-status-green-bg",
      darkBackground: "bg-green-700",
    },
    route: "#008760",
    progressBar: "bg-gray-500",
  },

  ORDER_FAILED: {
    text: "text-status-red",
    lightText: "text-red-300",
    card: {
      border: "bg-status-red hover:border-red-500",
      background: "bg-status-red-bg",
      darkBackground: "bg-red-500",
    },
    route: "#FD4242",
  },
  FAILED: {
    text: "text-status-red",
    lightText: "text-red-300",
    card: {
      border: "bg-status-red hover:border-red-500",
      background: "bg-status-red-bg",
      darkBackground: "bg-red-500",
    },
    route: "#FD4242",
  },
  CANCELLED: {
    text: "text-status-red",
    lightText: "text-red-300",
    card: {
      border: "bg-status-red hover:border-red-500",
      background: "bg-status-red-bg",
      darkBackground: "bg-red-500",
    },
    route: "#FD4242",
  },

  ORDER_CANCELLED: {
    text: "text-status-red",
    lightText: "text-red-300",
    card: {
      border: "bg-status-red hover:border-red-500",
      background: "bg-status-red-bg",
      darkBackground: "bg-red-500",
    },
    route: "#FD4242",
  },
};

const anchorStyle = "w-[18px] h-[22px]";
export const mapLegendOptions = [
  {
    label: "Unassigned",
    color: ORDER_STATUS_COLORS["TODO"].text,
    image: "/markers/PICKUP_TODO.png",
    style: anchorStyle,
  },
  {
    label: "Assigned",
    color: ORDER_STATUS_COLORS["ASSIGNED"].text,
    image: "/markers/PICKUP_ASSIGNED.png",
    style: anchorStyle,
  },
  {
    label: "In Transit",
    color: ORDER_STATUS_COLORS["INPROGRESS"].text,
    image: "/markers/PICKUP_INPROGRESS.png",
    style: anchorStyle,
  },
  {
    label: "Completed",
    color: ORDER_STATUS_COLORS["COMPLETE"].text,
    image: "/markers/PICKUP_COMPLETE.png",
    style: anchorStyle,
  },
  {
    label: "Failed/Cancelled",
    color: ORDER_STATUS_COLORS["CANCELLED"].text,
    image: "/markers/PICKUP_CANCELLED.png",
    style: anchorStyle,
  },
  {
    label: "Driver Idle",
    color: ORDER_STATUS_COLORS["COMPLETE"].text,
    image: "/markers/driver_ONLINE.png",
    style: "w-5 h-5",
  },
  {
    label: "Driver In-transit",
    color: ORDER_STATUS_COLORS["INPROGRESS"].text,
    image: "/markers/driver_INTRANSIT.png",
    style: "w-5 h-5",
  },
  {
    label: "Pickup",
    color: ORDER_STATUS_COLORS["TODO"].text,
    image: "/markers/PICKUP_TODO.png",
    style: anchorStyle,
  },
  {
    label: "Drop-off",
    color: ORDER_STATUS_COLORS["TODO"].text,
    image: "/markers/DROPOFF_TODO.png",
    style: anchorStyle,
  },
];

const LocationDetails = z.object({
  name: z.string(),
  phone: z.string(),
  note: z.string(),
  businessName: z.string(),
  address: z.string(),
});

const OrderDetails = z
  .object({
    description: z.string(),
    value: z.number(),
    tip: z.number(),
    currency: z.enum(["USD"]),
    itemsCount: z.number(),
    distanceString: z.string(),
    serviceDuration: z.number(),
  })
  .partial();

const Requirements = z.object({
  signature: z.boolean(),
  photo: z.boolean(),
  note: z.boolean(),
  itemChecklist: z.boolean().optional(),
});
export const createOrderRouteType = z.object({
  id: z.string().optional(),
  priority: z.enum(["HIGH", "LOW", "NORMAL"]),
  dropoff: LocationDetails,
  pickup: LocationDetails,
  status: z
    .enum(["TODO", "INPROGRESS", "COMPLETE", "FAILED", "CANCELLED", "ASSIGNED"])
    .nullable()
    .optional()
    .default("TODO"),
  scheduledStartDateTime: z.date(),
  scheduledEndDateTime: z.date(),
  dropoffLatLng: z
    .object({
      lat: z.number(),
      lng: z.number(),
    })
    .optional(),
  pickupLatLng: z
    .object({
      lat: z.number(),
      lng: z.number(),
    })
    .optional(),
  details: OrderDetails,
  assigneeId: z.string().nullable().default(null),
  type: z.enum([
    "ROUTE_OPT_PICKUP",
    "ROUTE_OPT_DROPOFF",
    "INDIVIDUAL_PICKUP",
    "INDIVIDUAL_DROPOFF",
    "INDIVIDUAL_PICKUP_AND_DROPOFF",
  ]),
  pickupRequirements: Requirements.optional(),
  dropoffRequirements: Requirements,
  minVehicle: z.enum([
    "ANY",
    "WALK",
    "CYCLE",
    "BIKE",
    "SEDAN",
    "ESTATE",
    "VAN",
    "PICK_UP",
    "SUV",
    "TRUCK",
    "OTHER",
  ]),
  externalId: z.string(),
  sourceId: z.string().optional(),
  deliveryPlaceId: z.string().nullable(),
  pickupPlaceId: z.string().nullable(),
  geoDropoffDateTime: z.date().optional().nullable(),
  geoPickupDateTime: z.date().optional().nullable(),
  pickupAddress: z.string().optional(),
  destinationAddress: z.string().optional(),
  connectedWorkspaceId: z.nullable(z.string()).optional(),
  pickupLocation: z
    .object({
      type: z.string(),
      coordinates: z.number().array(),
    })
    .optional(),
  dropoffLocation: z
    .object({
      type: z.string(),
      coordinates: z.number().array(),
    })
    .optional(),
  isOrderSourceAConnectedWorkspace: z.boolean().optional(),
  pickupChecklist: z
    .array(
      z.object({
        isChecked: z.boolean(),
        label: z.string(),
        quantity: z.number(),
        isMandatory: z.boolean(),
      }),
    )
    .optional(),
  dropoffChecklist: z
    .array(
      z.object({
        isChecked: z.boolean(),
        label: z.string(),
        quantity: z.number(),
        isMandatory: z.boolean(),
      }),
    )
    .optional(),
  customFields: z.array(
    z.object({
      definition: customFieldSchema,
      values: z.array(
        z.object({
          value: z.string(),
          isSelected: z.boolean(),
        }),
      ),
    }),
  ),
});

export type GetOrderParamsType = {
  offset: number;
  sortColumn: string;
  sortBy?: "desc" | "asc";
  groupBy: "assignee" | "workspace" | "status";
  startDate: string;
  endDate: string;
  status: string;
  orderDeliveryType: string;
  workspaceIds: string;
  driverIds: string;
};

export type CreateOrderType = z.infer<typeof createOrderRouteType>;

enum TransportType {
  WALK = "WALK",
  CYCLE = "CYCLE",
  BIKE = "BIKE",
  SEDAN = "SEDAN",
  ESTATE = "ESTATE",
  VAN = "VAN",
  PICK_UP = "PICK_UP",
  SUV = "SUV",
  TRUCK = "TRUCK",
  OTHER = "OTHER",
}

export const pickupChecklistSchema = z
  .array(
    z.object({
      isChecked: z.boolean(),
      isMandatory: z.boolean(),
      label: z.string().min(1, "Item name can't be empty"),
      quantity: z.coerce
        .number()
        .gte(1, "Must be 1 or above")
        .lt(1000, "Must not exceed 999"),
    }),
  )
  .optional();
const orderFormSchema = z.object({
  vehicleType: z.nativeEnum(TransportType, {
    required_error: "Mode of Transportation is required.",
  }),
  description: z.string({ required_error: "This field is required." }),
  itemsCount: z
    .string({
      required_error: "This field is required.",
      invalid_type_error: "Item Count must be a number",
    })
    .refine((value) => parseInt(value) >= 0, {
      message: "Item count must be greater than or equal to 0.",
    })
    .transform((value) => parseInt(value)),
  value: z
    .string({
      required_error: "This field is required.",
      invalid_type_error: "Value must be a number",
    })
    .refine((value) => parseInt(value) >= 0, {
      message: "Value must be greater than or equal to 0.",
    })
    .transform((value) => parseFloat(value)),
  tip: z
    .string({
      required_error: "This field is required.",
      invalid_type_error: "Tip must be a number",
    })
    .refine((value) => parseInt(value) >= 0, {
      message: "Tip must be greater than or equal to 0.",
    })
    .transform((value) => parseFloat(value)),
  externalId: z.nullable(z.string()).default(""),
  orderRequirements_SIGNATURE: z.boolean().default(false),
  orderRequirements_PHOTO: z.boolean().default(false),
  orderRequirements_NOTES: z.boolean().default(false),
  orderRequirements_ITEMCHECKLIST: z.boolean().default(false),
  connectedWorkspaceId: z.nullable(z.string()).optional(),
  pickupChecklist: pickupChecklistSchema,
  isOrderSourceAConnectedWorkspace: z.boolean().optional(),
});

const dropOffOrderSchema = {
  dropOffPhone: z.string().refine(
    (value) => {
      const pattern = /^\d{10,10}$/;

      return pattern.test(value);
    },
    {
      message: "Please enter a valid phone number",
    },
  ),
  dropOffName: z.string().min(1, { message: "This field is required." }),
  dropOffNote: z.string().optional(),
  dropOffBusinessName: z
    .string()
    .min(1, { message: "This field is required." }),
  serviceTimeDropoff: z
    .string()
    .refine((value) => !value || parseInt(value) >= 0, {
      message: "Service time must be greater than or equal to 0.",
    })
    .transform((value) => parseInt(value))
    .optional(),
  dropoffLocation: z.custom<GoogleAddress>(
    (value) => !!value || !!value?.description,
    {
      message: "This field is required.",
    },
  ),
  dropOffEndTime: z.date().optional(),
};

const pickupOrderSchema = {
  pickupPhone: z.string().refine(
    (value) => {
      const pattern = /^\d{10,10}$/;

      return pattern.test(value);
    },
    {
      message: "Please enter a valid phone number",
    },
  ),
  pickupName: z.string().min(1, { message: "This field is required." }),
  pickupNote: z.string().optional(),
  pickupBusinessName: z.string().min(1, { message: "This field is required." }),
  serviceTime: z
    .string()
    .refine((value) => parseInt(value) >= 0, {
      message: "Service time must be greater than or equal to 0.",
    })
    .transform((value) => parseInt(value))
    .optional(),
  pickupLocation: z.custom<GoogleAddress>(
    (value) => !!value || !!value?.description,
    {
      message: "This field is required.",
    },
  ),
  pickupStartTime: z.date().optional(),
};

const individualPickupDropoffSchema = orderFormSchema.extend({
  ...dropOffOrderSchema,
  ...pickupOrderSchema,
  ...{
    scheduledStartDateTime: z.date(),
    scheduledEndDateTime: z.date(),
  },
});

const pickupSchema = orderFormSchema.extend({
  ...pickupOrderSchema,
  ...{
    scheduledStartDateTime: z.date(),
    pickupEndTime: z.date(),
  },
});

const dropoffSchema = orderFormSchema.extend({
  ...dropOffOrderSchema,
  ...{
    dropOffStartTime: z.date(),
    scheduledEndDateTime: z.date(),
  },
});

const getCustomFieldSchema = (customFieldsData?: CustomField[]) =>
  customFieldsData?.reduce((accum: ZodRawShape, item) => {
    switch (item.type) {
      case "NUMBER": {
        accum[item.name] = z.number().optional();
        break;
      }
      default: {
        accum[item.name] = z.string().optional();
      }
    }

    return accum;
  }, {}) || {};

export const getSchema = (
  orderType: OrderDeliveryType,
  customFieldsData?: CustomField[],
) => {
  const customFieldSchema = getCustomFieldSchema(customFieldsData);

  if (orderType === OrderDeliveryType.INDIVIDUAL_PICKUP_AND_DROPOFF) {
    const individualMappedSchema = individualPickupDropoffSchema
      .extend(customFieldSchema)
      .refine(
        (data) =>
          !data.orderRequirements_ITEMCHECKLIST ||
          (data.pickupChecklist && data.pickupChecklist.length > 0),
        {
          message: "Required to add item checklist.",
          path: ["orderRequirements_ITEMCHECKLIST"],
        },
      );

    return individualMappedSchema;
  } else if (orderType === OrderDeliveryType.ROUTE_OPT_DROPOFF) {
    return dropoffSchema.extend(customFieldSchema);
  }

  return pickupSchema.extend(customFieldSchema);
};
type IndividualSchemaType = z.infer<typeof individualPickupDropoffSchema>;
type PickupSchemaType = z.infer<typeof pickupSchema>;
type DropoffSchemaType = z.infer<typeof dropoffSchema>;

type RequiredFieldsType =
  | {
      propertyName: keyof IndividualSchemaType;
      orderType: OrderDeliveryType.INDIVIDUAL_PICKUP_AND_DROPOFF;
    }
  | {
      propertyName: keyof PickupSchemaType;
      orderType: OrderDeliveryType.ROUTE_OPT_PICKUP;
    }
  | {
      propertyName: keyof DropoffSchemaType;
      orderType: OrderDeliveryType.ROUTE_OPT_DROPOFF;
    };

export const getIsRequired = ({
  propertyName,
  orderType,
}: RequiredFieldsType) => {
  if (orderType === OrderDeliveryType.INDIVIDUAL_PICKUP_AND_DROPOFF) {
    return !(
      individualPickupDropoffSchema.shape[propertyName] instanceof z.ZodOptional
    );
  } else if (orderType === OrderDeliveryType.ROUTE_OPT_DROPOFF) {
    return !(dropoffSchema.shape[propertyName] instanceof z.ZodOptional);
  } else {
    return !(pickupSchema.shape[propertyName] instanceof z.ZodOptional);
  }
};

export const vehicleOptions = [
  {
    label: "Bike",
    value: "BIKE",
    disabled: false,
  },
  {
    label: "Cycle",
    value: "CYCLE",
    disabled: false,
  },
  {
    label: "Estate",
    value: "ESTATE",
    disabled: false,
  },
  {
    label: "Other",
    value: "OTHER",
    disabled: false,
  },
  {
    label: "Pick Up",
    value: "PICK_UP",
    disabled: false,
  },
  {
    label: "Sedan",
    value: "SEDAN",
    disabled: false,
  },
  {
    label: "SUV",
    value: "SUV",
    disabled: false,
  },
  {
    label: "Truck",
    value: "TRUCK",
    disabled: false,
  },
  {
    label: "Van",
    value: "VAN",
    disabled: false,
  },
  {
    label: "Walk",
    value: "WALK",
    disabled: false,
  },
];

export const schemaKeyValueMapper: {
  [key: string]: string;
} = {
  pickupName: "Full Name (Pickup)",
  pickupPhone: "Phone Number (Pickup)",
  pickupLocation: "Pickup Location",
  scheduledStartDateTime: "Pickup At (Start Time) / Pickup After (Date/Time)",
  pickupEndTime: "Pickup Before (Date/Time)",
  serviceTime: "Service Time (in minutes)",
  pickupBusinessName: "Business Name",
  pickupNote: "Note",
  dropOffName: "Full Name (Drop-off)",
  dropOffPhone: "Phone Number (Drop-off)",
  dropoffLocation: "Drop-off Location",
  dropOffStartTime: "Pickup At (Start Time) / Drop-off After (Date/Time)",
  scheduledEndDateTime: "Drop-off Before (Date/Time) / Drop-off At (End Time)",
  serviceTimeDropoff: "Service Time (in minutes)",
  dropOffBusinessName: "Business Name (Drop-off)",
  dropOffNote: "Note (Drop-off)",
  connectedWorkspaceId: "Order Source",
  vehicleType: "Mode of Transportation",
  description: "Description",
  itemsCount: "Item Count",
  value: "Value",
  tip: "Tip",
};

export const ROUTE_STATUS_COLORS: Record<
  string,
  (typeof ORDER_STATUS_COLORS)[keyof typeof ORDER_STATUS_COLORS]
> = {
  "Un-assigned": ORDER_STATUS_COLORS["TODO"],
  "In Transit": ORDER_STATUS_COLORS["INPROGRESS"],
  "Not Started": ORDER_STATUS_COLORS["ASSIGNED"],
  Completed: ORDER_STATUS_COLORS["COMPLETE"],
};
