import { Polyline } from "@react-google-maps/api";
import { useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import useDispatchStore from "@web-app/store/dispatchStore";
import { DriverEntity, Order, Route, RouteStatus } from "@web-app/types";
import { splitToPairs } from "@web-app/utils/helpers";
import { ROUTE_STATUS_COLORS } from "@web-app/utils/order";

import { AssigneeRoute } from "./AssigneeRoute";

export default function OptimizedRoute({
  route,
  isHovered,
  setHoveredPolylineElementId,
}: {
  route: Route;
  isHovered: boolean;
  setHoveredPolylineElementId: (orderId: string | null) => void;
}) {
  const {
    focusedRouteId,
    isRouteOrdersOrderChanged,
    routeModalMode,
    appFilter,
  } = useDispatchStore();

  const queryClient = useQueryClient();
  const orders = queryClient.getQueryData<Map<string, Order>>([
    "orders",
    appFilter,
  ]);
  const assignees = queryClient.getQueryData<Map<string, DriverEntity>>([
    "drivers",
  ]);

  // TODO: add property routeStatus value here
  const routeOrders = route.ordersOrder.map((orderItem) => {
    return orders!.get(orderItem.id)!;
  });

  const routeStatus = useMemo(() => {
    let routeStatus: RouteStatus = "Un-assigned";
    // @ts-ignore TODO: fix this
    if (route.status === "COMPLETE") {
      routeStatus = "Completed";
    } else if (!route.assignee) {
      routeStatus = "Un-assigned";
    } else {
      let todoCount = 0;
      let completedCount = 0;
      for (const order of routeOrders) {
        if (order.status === "COMPLETE" || order.status === "FAILED") {
          completedCount += 1;
        }
        if (order.status === "TODO") {
          todoCount += 1;
        }
      }
      if (todoCount === routeOrders.length) {
        routeStatus = "Not Started";
      } else if (completedCount === routeOrders.length) {
        routeStatus = "Completed";
      } else {
        routeStatus = "In Transit";
      }
    }

    return routeStatus;
  }, [orders]);
  // const { routeStatus } = useRoute(route);

  if (focusedRouteId && routeModalMode === "edit") {
    if (focusedRouteId === route.id && isRouteOrdersOrderChanged) {
      const path = route.ordersOrder.map((orderItem) => {
        const order = orders!.get(orderItem.id)!;
        const lat =
          order.type === "ROUTE_OPT_PICKUP"
            ? order.pickupLocation.coordinates[1]
            : order.type === "ROUTE_OPT_DROPOFF"
              ? order.dropoffLocation.coordinates[1]
              : 0;
        const lng =
          order.type === "ROUTE_OPT_PICKUP"
            ? order.pickupLocation.coordinates[0]
            : order.type === "ROUTE_OPT_DROPOFF"
              ? order.dropoffLocation.coordinates[0]
              : 0;
        return {
          lat,
          lng,
          id: order.id,
        };
      });

      const splittedPath = splitToPairs(path);
      return (
        <>
          {splittedPath.map((path, index) => (
            <Polyline
              key={`${route.id}-${index}`}
              path={path}
              options={{
                strokeOpacity: isHovered ? 0.3 : 1,
                strokeColor: "rgb(0 0 0)",
                strokeWeight: 2,
                icons: [
                  {
                    icon: {
                      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    },
                    offset: "60%",
                  },
                ],
              }}
            />
          ))}
        </>
      );
    }
  }

  const orderTowardDriver = route.ordersOrder?.find((orderItem) => {
    const order = orders!.get(orderItem.id);
    if (!order) {
      return false;
    }

    return order.status == "INPROGRESS";
  });

  const order = orderTowardDriver ? orders!.get(orderTowardDriver.id) : null;
  const assignee = route.assigneeId ? assignees!.get(route.assigneeId) : null;

  return (
    <>
      {order && assignee && (
        <AssigneeRoute
          order={order}
          assignee={assignee}
          isHovered={isHovered}
        />
      )}
      {route.routePolyline?.routes?.map((routeData, index) => (
        <Polyline
          key={index}
          path={window.google?.maps.geometry?.encoding.decodePath(
            routeData.polyline.encodedPolyline,
          )}
          options={{
            strokeWeight: 4,
            strokeColor: ROUTE_STATUS_COLORS[routeStatus].route,
            strokeOpacity: isHovered ? 1 : 0.3,
            icons: [
              {
                icon: {
                  path: google.maps.SymbolPath.CIRCLE,
                  fillColor: ROUTE_STATUS_COLORS[routeStatus].route,
                  strokeOpacity: isHovered ? 1 : 0.3,
                  fillOpacity: isHovered ? 1 : 0.3,
                  strokeWeight: 2,
                },
                offset: "100%",
              },
              {
                icon: {
                  path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  fillColor: ROUTE_STATUS_COLORS[routeStatus].route,
                  strokeOpacity: isHovered ? 1 : 0.3,
                  fillOpacity: isHovered ? 1 : 0.3,
                  strokeWeight: 2,
                },
                offset: "90%",
              },
              {
                icon: {
                  path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  strokeOpacity: isHovered ? 1 : 0.3,
                  fillOpacity: isHovered ? 1 : 0.3,
                  strokeWeight: 2,
                },
                offset: "60%",
              },
              {
                icon: {
                  path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                  strokeOpacity: isHovered ? 1 : 0.3,
                  fillOpacity: isHovered ? 1 : 0.3,
                  strokeWeight: 2,
                },
                offset: "30%",
              },
              {
                icon: {
                  path: google.maps.SymbolPath.CIRCLE,
                  strokeOpacity: isHovered ? 1 : 0.3,
                  fillOpacity: isHovered ? 1 : 0.3,
                  strokeWeight: 2,
                },
                offset: "0%",
              },
            ],
          }}
          onMouseOver={() => setHoveredPolylineElementId(route.id)}
          onMouseOut={() => setHoveredPolylineElementId(null)}
          onClick={() => {
            useDispatchStore.setState({
              focusedRouteId: route.id,
            });
          }}
        />
      ))}
    </>
  );
}
