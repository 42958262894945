import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo } from "react";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

import { getPlaceLatLng, useSavedAddressMutation } from "@web-app/api";
import {
  Button,
  GoogleMapAutoComplete,
  Icon,
  Input,
  Sidebar,
  TextArea,
} from "@web-app/components";
import type { GoogleAddress, SavedAddress } from "@web-app/types";

const addressBookAddressSchema = z.object({
  name: z
    .string({ required_error: "Please enter a name" })
    .trim()
    .min(1, "Name cannot be empty")
    .refine(
      (value) => value.replace(/\s/g, "").length > 0,
      "Name cannot contain only spaces",
    ),
  phone: z
    .string()
    .trim()
    .min(1, "Phone number is required")
    .refine(
      (value) => {
        const pattern = /^\d{10,10}$/;
        return pattern.test(value);
      },
      {
        message: "Please enter a valid phone number",
      },
    ),
  address: z.custom<GoogleAddress>((value) => value),
  businessName: z
    .string()
    .trim()
    .min(1, "Business name cannot be empty")
    .refine(
      (value) => value.replace(/\s/g, "").length > 0,
      "Business name cannot contain only spaces",
    ),
  note: z.string().optional(),
});

export default function EditAddressSidebar({
  address,
  onClose,
}: {
  address: SavedAddress;
  onClose: () => void;
}) {
  const { handleSubmit, control, reset } = useForm({
    resolver: zodResolver(addressBookAddressSchema),
    reValidateMode: "onChange",
    defaultValues: useMemo(() => address, [address]),
    shouldUseNativeValidation: false,
    mode: "onBlur",
  });

  useEffect(() => {
    reset(address);
  }, [address]);

  const { updateSavedAddress } = useSavedAddressMutation();

  const onSubmit: SubmitHandler<
    z.infer<typeof addressBookAddressSchema>
  > = async (data) => {
    const payload = {
      name: data.name,
      phone: data.phone,
      address: data.address?.description,
      businessName: data.businessName,
      note: data.note,
    };

    if (
      data.address?.description &&
      address.address !== data.address?.description
    ) {
      const placeDetails = await getPlaceLatLng(data.address.place_id);

      // @ts-ignore any type
      payload["addressLocation"] = {
        latitude: placeDetails.lat,
        longitude: placeDetails.lng,
      };
    }

    await toast.promise(
      updateSavedAddress.mutateAsync({ id: address.id, ...payload }),
      {
        loading: "Updating address...",
        success: "Address updated successfully",
        error: "Failed to update address",
      },
    );
  };

  const getIsRequired = (
    propertyName: keyof z.infer<typeof addressBookAddressSchema>,
  ) => {
    return !(
      addressBookAddressSchema.shape[propertyName] instanceof z.ZodOptional
    );
  };
  return (
    <Sidebar width="w-[550px]" isOpen>
      <div className=" px-5 md:px-8 lg:px-10 xl:px-14 py-7 h-full relative">
        <h2 className="text-2xl leading-8 font-semibold text-gray-700 mb-7">
          Edit details
        </h2>
        <form
          onSubmit={
            // @ts-ignore any type
            handleSubmit(onSubmit)
          }
          className="w-full max-w-full flex flex-col gap-8"
        >
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <Input
                  type="text"
                  label="Full Name"
                  {...field}
                  required={getIsRequired("name")}
                />
                <ErrorMessage message={error?.message} />
              </>
            )}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <Input
                  type="text"
                  label="Phone"
                  {...{
                    ...field,
                    required: getIsRequired("phone"),
                  }}
                />

                <ErrorMessage message={error?.message} />
              </>
            )}
          />

          <Controller
            name="address"
            control={control}
            render={({
              field: { value, onChange, ...field },
              fieldState: { error },
            }) => (
              <>
                <GoogleMapAutoComplete
                  label="Address"
                  // @ts-ignore any type
                  address={
                    // @ts-ignore any type
                    value?.description
                      ? value
                      : { description: address.address }
                  }
                  setAddress={(e) => onChange(e)}
                  {...{
                    ...field,
                    required: getIsRequired("address"),
                  }}
                />
                <ErrorMessage message={error?.message} />
              </>
            )}
          />

          <Controller
            name="businessName"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <Input
                  type="text"
                  label="Business Name"
                  {...{
                    ...field,
                    required: getIsRequired("businessName"),
                  }}
                />

                <ErrorMessage message={error?.message} />
              </>
            )}
          />
          <Controller
            name="note"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <TextArea
                  rows={5}
                  maxLength={1500}
                  label="Note"
                  {...{
                    ...field,
                    required: getIsRequired("note"),
                  }}
                />

                <ErrorMessage message={error?.message} />
              </>
            )}
          />
          <div className=" absolute bottom-6 flex gap-4 ">
            <Button type="submit" isLoading={updateSavedAddress.isPending}>
              Submit
            </Button>
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </form>
        <span className="absolute right-7 top-8" onClick={onClose}>
          <Icon name="xMark" className="text-gray-600 h-5 cursor-pointer" />
        </span>
      </div>
    </Sidebar>
  );
}

// TODO: Make a separate component for this.
const ErrorMessage = ({ message }: { message: string | undefined }) => (
  <span className="text-red-500 text-xs absolute -bottom-5">{message}</span>
);
