import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import useDispatchStore from "@web-app/store/dispatchStore";
import {
  CreateBulkOrdersPayload,
  DeleteBulkOrdersPayload,
  Order,
  UpdateBulkOrdersPayload,
} from "@web-app/types";
import { createOrderRouteType } from "@web-app/utils/order";

import {
  copy,
  create,
  createBulkOrders,
  createPublic,
  deleteBulkOrders,
  deleteOrder,
  get,
  getOne,
  getTrackingOrder,
  update,
  updateBulkOrders,
} from "../services/orders";

export const useOrderMutation = () => {
  const queryClient = useQueryClient();
  const appFilter = useDispatchStore((state) => state.appFilter);

  const createOrder = useMutation({
    mutationFn: async (payload: z.infer<typeof createOrderRouteType>) => {
      const order = await create(payload);
      return order;
    },
    onSuccess: (data) => {
      const existingOrders = queryClient.getQueryData<Map<string, Order>>([
        "orders",
        appFilter,
      ]);

      if (existingOrders) {
        const updatedOrders = new Map([...existingOrders, [data.id, data]]);
        queryClient.setQueryData(["orders", appFilter], updatedOrders);
      }
    },
  });

  const updateOrder = useMutation({
    mutationFn: async (params: Partial<Order>) => {
      const response = await update(params?.id || "", params);
      return response;
    },
    onSuccess: (updatedOrder) => {
      const existingOrders = queryClient.getQueryData<Map<string, Order>>([
        "orders",
        appFilter,
      ]);

      if (existingOrders) {
        const updatedOrders = new Map([
          ...existingOrders,
          [
            updatedOrder.id,
            {
              ...existingOrders.get(updatedOrder.id),
              ...updatedOrder,
            },
          ],
        ]);

        queryClient.setQueryData(["orders", appFilter], updatedOrders);
      }
    },
  });

  const createPublicOrder = useMutation({
    mutationFn: async (payload: z.infer<typeof createOrderRouteType>) => {
      const order = await createPublic(payload);
      return order;
    },
  });

  const copyOrder = useMutation({
    mutationFn: async (id: string) => {
      const response = await copy(id);
      return response;
    },
    onSuccess: (copiedOrder) => {
      const existingOrders = queryClient.getQueryData<Map<string, Order>>([
        "orders",
        appFilter,
      ]);

      if (existingOrders) {
        const updatedOrders = new Map([
          ...existingOrders,
          [copiedOrder.id, copiedOrder],
        ]);

        queryClient.setQueryData(["orders", appFilter], updatedOrders);
      }
    },
  });

  const getOneOrder = useMutation({
    mutationFn: async (id: string) => {
      const response = await getOne(id);
      return response;
    },
  });

  const deleteOrderById = useMutation({
    mutationFn: async (id: string) => {
      await deleteOrder(id);
      return id;
    },
    onSuccess: (id) => {
      const existingOrders = queryClient.getQueryData<Map<string, Order>>([
        "orders",
        appFilter,
      ]);

      if (existingOrders) {
        const updatedOrders = new Map(existingOrders);
        updatedOrders.delete(id);
        queryClient.setQueryData(["orders", appFilter], updatedOrders);
      }
    },
  });

  return {
    createOrder,
    updateOrder,
    copyOrder,
    deleteOrderById,
    createPublicOrder,
    getOneOrder,
  };
};

export const useBulkOrderMutation = () => {
  const queryClient = useQueryClient();
  const create = useMutation({
    mutationFn: async (payload: CreateBulkOrdersPayload) => {
      const response = await createBulkOrders(payload);
      return response;
    },
  });

  const update = useMutation({
    mutationFn: async (payload: UpdateBulkOrdersPayload) => {
      const response = await updateBulkOrders(payload);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["orders"],
      });
    },
  });

  const remove = useMutation({
    mutationFn: async (payload: DeleteBulkOrdersPayload) => {
      const response = await deleteBulkOrders(payload);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["orders"],
      });
    },
  });
  return { create, update, remove };
};

export const useGetOrder = (props?: { refetchInterval: number }) => {
  const appFilter = useDispatchStore((state) => state.appFilter);

  return useQuery({
    queryKey: ["orders", appFilter],
    queryFn: async () => await get(appFilter),
    refetchOnWindowFocus: false,
    staleTime: 0,
    refetchInterval: props?.refetchInterval,
  });
};

export const useGetTrackingOrder = (id: string) => {
  return useQuery({
    queryKey: ["trackingOrder", id],
    queryFn: async () => await getTrackingOrder(id),
    refetchOnWindowFocus: false,
  });
};
