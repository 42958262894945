import { User } from "@web-app/types";

export function searchContacts(searchTerm: string, list?: Map<string, User>) {
  if (!list) {
    return [];
  }

  const filteredContactsIds = Array.from(list.keys()).filter((contactId) => {
    const contact = list.get(contactId)!;
    const fullName = contact.name?.toLowerCase();
    const email = contact.email?.toLowerCase();
    const searchValue = searchTerm.toLowerCase();

    return fullName?.includes(searchValue) || email?.includes(searchValue);
  });

  return filteredContactsIds;
}
