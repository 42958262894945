import { createFileRoute } from "@tanstack/react-router";

import { LoadingIcon } from "@web-app/components";
import DispatchPage from "@web-app/pages/dispatchPage";

import { useApp } from "../hooks/index.js";

export const Route = createFileRoute("/")({
  component: Index,
});

function Index() {
  const { sessionUser, isLoading, logout } = useApp();

  if (isLoading || !sessionUser) {
    return (
      <div className="h-screen w-screen grid place-content-center">
        <LoadingIcon className="w-8 h-8" />
      </div>
    );
  }

  return (
    <div>
      <DispatchPage logout={logout} />
    </div>
  );
}
