import { NotificationConfig, SmsConfigType } from "@web-app/types/notification";
import http from "@web-app/utils/http";

const base_url = "/notificationConfigs";

export const get = async (workspaceId: string) => {
  return http
    .get<NotificationConfig>(`${base_url}/${workspaceId}`)
    .then((response) => response.data);
};

export const update = async (workspaceId: string, payload: SmsConfigType) => {
  return http
    .patch<NotificationConfig>(`${base_url}/${workspaceId}`, payload)
    .then((response) => response.data);
};
