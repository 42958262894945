import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type {
  CreateSavedAddessPayload,
  UpdateSavedAddessPayload,
} from "@web-app/types";

import { create, list, remove, update } from "../services/savedAddress";

const queryKey = "savedAddresses";

export const useSavedAddresses = (workspaceId: string) => {
  return useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const response = await list(workspaceId);
      return response;
    },
    refetchOnWindowFocus: false,
  });
};

export const useSavedAddressMutation = () => {
  const queryClient = useQueryClient();

  const createSavedAddress = useMutation({
    mutationFn: async (payload: CreateSavedAddessPayload) => {
      const response = await create(payload);
      return response;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey] });
    },
  });

  const updateSavedAddress = useMutation({
    mutationFn: async (payload: Partial<UpdateSavedAddessPayload>) => {
      const response = await update(payload);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey] });
    },
  });

  const deleteSavedAddress = useMutation({
    mutationFn: async (addressId: string) => {
      await remove(addressId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey] });
    },
  });

  return {
    createSavedAddress,
    updateSavedAddress,
    deleteSavedAddress,
  };
};
