import { Combobox, Transition } from "@headlessui/react";
import clsx from "clsx";
import debounce from "lodash/debounce";
import type { ChangeEvent } from "react";
import { useEffect, useState } from "react";

import { useAutoCompletion } from "@web-app/api";
import { Icon, LoadingIcon } from "@web-app/components";

import type { GoogleAddress } from "../../types/google";

type GoogleMapAutoCompletePropType = {
  address: GoogleAddress | null;
  setAddress: (arg0: GoogleAddress | null) => void;
  label: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
};

export default function GoogleMapAutoComplete({
  address,
  setAddress,
  label,
  required = false,
  disabled = false,
  readOnly = false,
  ...controllerFields
}: GoogleMapAutoCompletePropType) {
  const [inputValue, setInputValue] = useState("");
  const [selectedAddress, setSelectedAddress] = useState<GoogleAddress | null>(
    address,
  );

  useEffect(() => {
    setSelectedAddress(address);
  }, [address]);

  const { data: addresses, isLoading: isQueryingAddresses } =
    useAutoCompletion(inputValue);
  const onQueryAddress = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setInputValue(e.target.value);
    }
  };

  const debouncedOnQueryAddress = debounce(onQueryAddress, 1000);

  const updateAddress = (address: GoogleAddress | null) => {
    if (address) {
      setSelectedAddress(address);
      setAddress(address);
    }
  };

  return (
    <div className="mb-6 w-full md:mb-0" data-testid="google-map-auto-complete">
      <label
        className={clsx(
          "mb-1 block text-xs font-medium leading-5 tracking-wide",
          disabled ? "text-gray-400" : "text-gray-700",
        )}
      >
        {label}
        {required && "*"}
      </label>
      {readOnly ? (
        <span className="tw-form-field-readonly-style">
          {selectedAddress?.description ?? "N/A"}
        </span>
      ) : (
        <Combobox
          value={selectedAddress}
          onChange={updateAddress}
          disabled={disabled}
          {...controllerFields}
        >
          {({ open }) => (
            <>
              <Combobox.Input
                className="tw-form-field-style"
                onChange={debouncedOnQueryAddress}
                displayValue={(address: GoogleAddress) =>
                  address?.description || ""
                }
                name="input"
              />
              <Transition
                as="div"
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
                className="relative z-[60]"
              >
                <Combobox.Options
                  static
                  className="absolute list-none top-0 mt-1 max-h-56 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  as="ul"
                >
                  {isQueryingAddresses && (
                    <div className="relative cursor-pointer select-none">
                      <li className="flex items-center py-2 pl-3 pr-9 font-normal">
                        <LoadingIcon className="mr-2 h-3 w-3" /> Fetching
                        Suggestions ....
                      </li>
                    </div>
                  )}
                  {!addresses?.length && !isQueryingAddresses && (
                    <div className="relative cursor-pointer select-none">
                      <li className="flex items-center py-2 pl-3 pr-9 font-normal">
                        <Icon
                          className="mr-2 h-4 w-4"
                          name="circleExclamation"
                        />
                        No Addresses Found!
                      </li>
                    </div>
                  )}
                  {addresses?.map((address, idx) => (
                    <Combobox.Option
                      key={address.place_id}
                      value={address}
                      as="div"
                      data-testid={`address-${idx}`}
                      className="relative cursor-pointer select-none"
                    >
                      {({ active, selected }) => (
                        <li
                          className={clsx(
                            "py-2 pl-3 pr-9 overflow-hidden",
                            active
                              ? "bg-gray-500 text-white"
                              : "bg-white text-black",
                          )}
                        >
                          {selected && (
                            <span
                              className={clsx(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4",
                              )}
                            >
                              <Icon name="circleCheck" />
                            </span>
                          )}
                          {address.description}
                        </li>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              </Transition>
            </>
          )}
        </Combobox>
      )}
    </div>
  );
}
