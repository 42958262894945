import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { format } from "date-fns";
import React, { Fragment, useEffect, useMemo, useState } from "react";

import useManageRoute from "@web-app/pages/dispatchPage/RouteEditModal/useManageRoute";
import useDispatchStore from "@web-app/store/dispatchStore";
import { DriverEntity, Order, Route, RouteStatus } from "@web-app/types";
import { getPolyLineDistance } from "@web-app/utils/helpers";
import { ROUTE_STATUS_COLORS } from "@web-app/utils/order";

import Avatar from "../Avatar";
import { Icon } from "../Icon";
import OrderCard from "../OrderCard";

export default function RouteCard({
  route,
  onEdit,
  onCopy,
  onDelete,
  onViewDetails,
  searchTerm,
}: {
  route: Route;
  onEdit: (orderId: string) => void;
  onViewDetails: (orderId: string) => void;
  onCopy: (orderId: string) => void;
  onDelete: (orderId: string) => void;
  searchTerm: string;
}) {
  const { focusedRouteId, appFilter } = useDispatchStore();
  const queryClient = useQueryClient();
  const orders = queryClient.getQueryData<Map<string, Order>>([
    "orders",
    appFilter,
  ]);
  const [isExpanded, setIsExpanded] = useState(false);
  const { handleRemoveRoute } = useManageRoute();
  const assignees = queryClient.getQueryData<Map<string, DriverEntity>>([
    "drivers",
  ]);
  const assignee = assignees?.get(route.assigneeId || "");

  const [routeScheduledStartDateTime, routeScheduledEndDateTime] =
    useMemo(() => {
      if (!orders) return [];
      const firstOrderId = route.ordersOrder[0].id;
      const firstOrder = orders.get(firstOrderId);

      const lastOrderId = route.ordersOrder[route.ordersOrder.length - 1].id;
      const lastOrder = orders.get(lastOrderId);

      if (!firstOrder || !lastOrder) {
        return [];
      }

      return [
        firstOrder.scheduledStartDateTime,
        lastOrder.scheduledEndDateTime,
      ];
    }, [route]);

  const routeStatus = useMemo(() => {
    let routeStatus: RouteStatus = "Un-assigned";
    // @ts-ignore fix this
    if (route.status === "COMPLETE") {
      routeStatus = "Completed";
    } else if (!route.assigneeId) {
      routeStatus = "Un-assigned";
    } else {
      let todoCount = 0;
      let completedCount = 0;
      for (const ord of route.ordersOrder) {
        const order = orders?.get(ord.id);
        if (order?.status === "COMPLETE" || order?.status === "FAILED") {
          completedCount += 1;
        }
        if (order?.status === "TODO") {
          todoCount += 1;
        }
      }
      if (todoCount === route.ordersOrder.length) {
        routeStatus = "Not Started";
      } else if (completedCount === route.ordersOrder.length) {
        routeStatus = "Completed";
      } else {
        routeStatus = "In Transit";
      }
    }

    return routeStatus;
  }, [route]);

  const routeDistance = useMemo(() => {
    return route.routePolyline ? getPolyLineDistance(route.routePolyline) : "";
  }, [route]);

  const lastOrder = orders
    ? orders.get(route.ordersOrder.at(-1)?.id || "")
    : null;

  useEffect(() => {
    if (focusedRouteId && focusedRouteId === route.id) {
      setIsExpanded(true);
    }
  }, [focusedRouteId, route]);

  const getRouteEtaOrCompletionTime = () => {
    if (routeStatus === "Completed" && lastOrder) {
      if (
        lastOrder.type === "ROUTE_OPT_DROPOFF" &&
        lastOrder.actualEndDateTime
      ) {
        return `Completed At ${format(new Date(lastOrder.actualEndDateTime), "HH:MM")}`;
      } else if (
        lastOrder.type === "ROUTE_OPT_PICKUP" &&
        lastOrder.actualPickupDateTime
      ) {
        return `Completed At ${format(new Date(lastOrder.actualPickupDateTime), "HH:MM")}`;
      } else {
        return "";
      }
    } else {
      if (lastOrder && lastOrder?.eta?.dateTime) {
        return `ETA ${format(new Date(lastOrder?.eta?.dateTime), "HH:MM")}`;
      }
    }
  };

  return (
    <div
      className={clsx(
        "flex w-full py-[8px] px-[12px] flex-col items-start gap-[10px] rounded-lg border border-gray-200 bg-gray-100",
        ROUTE_STATUS_COLORS[routeStatus].text,
        focusedRouteId === route.id && "border-gray-500",
      )}
    >
      <div className="flex justify-between items-center self-stretch">
        <div className="flex items-center gap-[24px]">
          <div className="flex items-center gap-[4px] ">
            <Icon name="fasRoute" className="text-[#ADAFB3]" />
            {/* <FontAwesomeIcon
              icon={faRoute}
              size="xs"
              style={{ color: "#ADAFB3" }}
            /> */}
            <div
              className={clsx(
                "flex p-[2.5px] px-[8px] py-[3.5px] justify-center items-center font-normal font-inter text-xs leading-none text-center rounded-[33px]",
              )}
            >
              {route.displayId} • {routeStatus}
            </div>
          </div>
        </div>
        <div className="text-xs text-gray-600">{routeDistance} miles</div>
        <div className="flex items-center gap-[10px] relative">
          <div
            className={clsx(
              "rounded-md flex justify-center items-center p-1 hover:bg-gray-200 text-gray-600",
              focusedRouteId === route.id && "bg-gray-200",
            )}
            onClick={() => {
              if (focusedRouteId === route.id) {
                useDispatchStore.setState({
                  focusedRouteId: null,
                  focusedOrderId: null,
                });
                return;
              }

              useDispatchStore.setState({
                focusedRouteId: route.id,
                focusedOrderId: null,
              });
            }}
          >
            <Icon name="locationCrossHairs" className="h-3 cursor-pointer" />
          </div>
          {/* <FontAwesomeIcon
            icon={!isExpanded ? faChevronDown : faChevronUp}
            onClick={() => setIsExpanded(!isExpanded)}
            className="w-3 rounded h-3 p-1 text-gray-500 bg-white cursor-pointer"
          /> */}
          <Icon
            name={!isExpanded ? "fasChevronDown" : "fasChevronUp"}
            onClick={() => setIsExpanded(!isExpanded)}
            className="w-3 rounded h-3 p-1 text-gray-500 bg-white cursor-pointer"
          />
          <Menu>
            <MenuButton as={React.Fragment} data-testid="cy-order-options">
              <Icon
                name="fasEllipsis"
                className="w-3 h-3 p-1 text-gray-500 cursor-pointer rounded-md ease-in leading-none bg-white"
              />
            </MenuButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className=" absolute right-[5px] top-[20px]  z-10 mt-2 w-28 origin-top-right divide-y rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <MenuItemV2
                  onClick={() => {
                    useDispatchStore.setState({
                      routeModalMode: "edit",
                      focusedRouteId: route.id,
                    });
                  }}
                >
                  Edit
                </MenuItemV2>

                <MenuItemV2 onClick={() => handleRemoveRoute(route.id)}>
                  Clear
                </MenuItemV2>
              </MenuItems>
            </Transition>
          </Menu>
        </div>
      </div>

      {isExpanded ? (
        <OrderContainer
          routeOrders={route.ordersOrder}
          onEdit={onEdit}
          onViewDetails={onViewDetails}
          onCopy={onCopy}
          onDelete={onDelete}
          searchTerm={searchTerm}
        />
      ) : (
        <div className="flex justify-between items-center self-stretch mt-[7px] text-gray-600 text-xs">
          <div className="flex items-center gap-[8px]">
            <Avatar name={assignee?.userProfile?.name || ""} size={7} />
            <span>{assignee?.userProfile?.name}</span>
          </div>
          <span>{getRouteEtaOrCompletionTime()}</span>

          {routeScheduledStartDateTime && routeScheduledEndDateTime && (
            <div className="flex justify-end items-center gap-[2px]">
              <Icon name="fasClockRotateLeft" className="mr-[2px]" />
              {format(new Date(routeScheduledStartDateTime), "HH:mm")}-
              {format(new Date(routeScheduledEndDateTime), "HH:mm")}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function OrderContainer({
  routeOrders,
  onEdit,
  onCopy,
  onDelete,
  onViewDetails,
  searchTerm,
}: {
  routeOrders: Route["ordersOrder"];
  onEdit: (orderId: string) => void;
  onViewDetails: (orderId: string) => void;
  onCopy: (orderId: string) => void;
  onDelete: (orderId: string) => void;
  searchTerm: string;
}) {
  const queryClient = useQueryClient();
  const { appFilter } = useDispatchStore();
  const orders = queryClient.getQueryData<Map<string, Order>>([
    "orders",
    appFilter,
  ]);

  return (
    <>
      <div className="w-full max-w-full flex gap-[5px] flex-col">
        {routeOrders.map((routeOrder, index) => {
          if (!orders) return null;

          const order = orders.get(routeOrder.id);

          if (!order) {
            return null;
          }

          const isOrderCompleted = order.status === "COMPLETE";
          const isNextOrderCompleted =
            index !== routeOrders.length - 1 &&
            orders.get(routeOrders[index + 1].id)?.status === "COMPLETE";
          return (
            <div
              className={clsx(
                "flex flex-row w-full items-center gap-[4px] before:h-2 before:w-2 relative before:absolute before:left-0 before:top-1/2 before:rounded-full after:w-[1px] after:top-[calc(50%+4px)] before:-translate-y-1/2 after:-translate-y-1 after:h-[calc(100%+2px)] after:z-0 before:z-10 after:last:hidden after:absolute after:left-[3px] ",
                isOrderCompleted ? "before:bg-gray-500" : "before:bg-gray-300",
                isNextOrderCompleted
                  ? "after:bg-gray-500"
                  : "after:bg-gray-300",
              )}
              key={order.id}
            >
              <div className="ml-4 w-full overflow-hidden">
                <OrderCard
                  className="!static"
                  order={order}
                  onEdit={() => onEdit(order.id)}
                  onCopy={() => onCopy(order.id)}
                  onDelete={() => onDelete(order.id)}
                  onViewDetails={() => onViewDetails(order.id)}
                  routeIndex={index + 1}
                  searchTerm={searchTerm}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export function MenuItemV2({
  onClick,
  children,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <MenuItem>
      <span
        className="flex cursor-pointer items-center p-2 py-2 text-sm font-normal hover:bg-gray-200 text-gray-600"
        onClick={onClick}
      >
        <span className="leading-none ">{children}</span>
      </span>
    </MenuItem>
  );
}
