import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

import { useCustomFieldMutation } from "@web-app/api";
import {
  Button,
  Input,
  SelectInput,
  Sidebar,
  Toggle,
} from "@web-app/components";
import type { CustomField, CustomFieldGroup } from "@web-app/types";

import { customFieldSchema, inputTypeOptions } from "../utils";

export default function EditCustomField({
  customField,
  customFieldsGroups,
  onClose,
}: {
  customField: CustomField;
  customFieldsGroups: CustomFieldGroup[];
  onClose: () => void;
}) {
  const { updateCustomField } = useCustomFieldMutation();
  const { handleSubmit, control, reset } = useForm({
    resolver: zodResolver(customFieldSchema),
    reValidateMode: "onChange",
    shouldUseNativeValidation: false,
    mode: "onTouched",
    defaultValues: {
      type: customField.type,
      name: customField.name,
      helpText: customField.helpText,
      isRequired: false,
      isVisibleMobile: customField.isVisibleMobile,
      groupId: customField.groupId,
    },
  });

  const getIsRequired = (
    propertyName: keyof z.infer<typeof customFieldSchema>,
  ) => {
    return !(customFieldSchema.shape[propertyName] instanceof z.ZodOptional);
  };

  const groupOptions = customFieldsGroups.map((group) => {
    return {
      label: group.name,
      value: group.id,
    };
  });

  const onSubmit = async (data: z.infer<typeof customFieldSchema>) => {
    toast.promise(
      updateCustomField.mutateAsync({
        payload: {
          name: data.name,
          helpText: data.helpText || "",
          isActive: customField.isActive,
          type: data.type,
          isRequired: false,
          isVisibleMobile: data.isVisibleMobile,
          groupId: data.groupId,
        },
        customFieldId: customField.id,
      }),
      {
        loading: "Updating custom field...",
        success: "Custom field updated successfully",
        error: "Failed to update custom field",
      },
    );

    reset();
    onClose();
  };

  return (
    <Sidebar width="w-[550px]" isOpen>
      <div className=" px-5 md:px-8 lg:px-10 xl:px-14 py-7 h-full relative ">
        <h2 className="text-2xl leading-8 font-semibold text-gray-700 mb-7">
          Edit Field
        </h2>
        <form
          onSubmit={
            // @ts-ignore
            handleSubmit(onSubmit)
          }
          className="w-full max-w-full flex flex-col gap-8"
        >
          <div className="flex flex-col gap-8 divide-y">
            <div className="flex flex-col gap-8">
              <div className="relative">
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Input
                        type="text"
                        label="Name"
                        {...field}
                        required={getIsRequired("name")}
                      />
                      <ErrorMessage message={error?.message} />
                    </>
                  )}
                />
              </div>
              <div className="relative">
                <Controller
                  name="helpText"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Input
                        type="text"
                        label="Help Text"
                        {...field}
                        required={getIsRequired("helpText")}
                      />
                      <ErrorMessage message={error?.message} />
                    </>
                  )}
                />
              </div>
              <div className="relative">
                <Controller
                  name="type"
                  control={control}
                  render={({
                    field: { value, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <SelectInput
                        label="Type"
                        options={inputTypeOptions}
                        selected={value}
                        onChange={(value) => {
                          onChange(value);
                        }}
                        {...rest}
                        required={getIsRequired("type")}
                        disabled
                      />
                      <ErrorMessage message={error?.message} />
                    </>
                  )}
                />
              </div>
            </div>
            <div>
              <div className=" mt-8 flex flex-col gap-8">
                <Controller
                  name="isVisibleMobile"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Toggle
                        label={field.value ? "Enabled" : "Disabled"}
                        title="Show in driver app"
                        isChecked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      />
                    </>
                  )}
                />

                {/* NOTE: Temporarily disabling this */}
                {/* <Controller
                  name="isRequired"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Toggle
                        label={field.value ? "Enabled" : "Disabled"}
                        title="Make it mandatory"
                        isChecked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      />
                    </>
                  )}
                /> */}

                <Controller
                  name="groupId"
                  control={control}
                  render={({
                    field: { value, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <SelectInput
                        label="Group Type"
                        placeholder="Select group"
                        options={groupOptions}
                        selected={value}
                        required={true}
                        onChange={(value) => {
                          onChange(value);
                        }}
                        {...rest}
                      />
                      <ErrorMessage message={error?.message} />
                    </>
                  )}
                />
              </div>
            </div>
          </div>

          <div className=" absolute bottom-6 flex gap-4 ">
            <Button type="submit" isLoading={updateCustomField.isPending}>
              Update
            </Button>
            <Button type="button" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </Sidebar>
  );
}

const ErrorMessage = ({ message }: { message: string | undefined }) => (
  <span className="text-red-500 text-xs absolute -bottom-5">{message}</span>
);
