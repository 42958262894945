import clsx from "clsx";
import { useRef } from "react";
import type { XYCoord } from "react-dnd";
import { useDrag, useDrop } from "react-dnd";

import { Order } from "@web-app/types";

import OrderCard from "../OrderCard";

interface DragItem {
  index: number;
  id: string;
  type: string;
}

interface OrderCardWithDragProps {
  order: Order;
  index: number;
  moveOrder: (dragIndex: number, hoverIndex: number) => void;
  orderNumber: number;
  removeOrder?: (orderId: string) => void;
}

export default function DraggableOrderCard({
  order,
  index,
  moveOrder,
  orderNumber,
  removeOrder,
}: OrderCardWithDragProps) {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop<DragItem, void, { handlerId: any; isOver: boolean }>(
    {
      accept: "OrderCard",
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
          isOver: monitor.isOver(),
        };
      },
      hover(item: DragItem, monitor) {
        if (moveOrder && typeof index === "number" && index > -1) {
          if (!ref.current) {
            return;
          }

          const dragIndex = item.index;
          const hoverIndex = index;

          if (dragIndex === hoverIndex) {
            return;
          }

          // Determine rectangle on screen
          const hoverBoundingRect = ref.current?.getBoundingClientRect();

          // Get vertical middle
          const hoverMiddleY =
            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

          // Determine mouse position
          const clientOffset = monitor.getClientOffset();

          // Get pixels to the top
          const hoverClientY =
            (clientOffset as XYCoord).y - hoverBoundingRect.top;

          // Only perform the move when the mouse has crossed half of the items height
          // When dragging downwards, only move when the cursor is below 50%
          // When dragging upwards, only move when the cursor is above 50%

          // Dragging downwards
          if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
          }

          // Dragging upwards
          if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
          }

          // Time to actually perform the action
          moveOrder(dragIndex, hoverIndex);

          // Note: we're mutating the monitor item here!
          // Generally it's better to avoid mutations,
          // but it's good here for the sake of performance
          // to avoid expensive index searches.
          item.index = hoverIndex;
        }
      },
    },
  );

  const [{ isDragging }, drag] = useDrag({
    type: "OrderCard",
    item: () => {
      return { id: order.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  const opacity = isDragging ? 0 : 1;

  return (
    <div ref={ref} className={clsx(opacity === 0 && "opacity-0")}>
      <OrderCard
        order={order}
        routeIndex={orderNumber + 1}
        removeOrder={removeOrder}
      />
    </div>
  );
}
