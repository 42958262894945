import { subDays } from "date-fns";

import { AppFilterType } from "@web-app/types";

subDays(new Date(), 30).setHours(0, 0, 0, 0);

const scheduledDateTimeEnd = new Date();
scheduledDateTimeEnd.setHours(0, 0, 0, 0);

export const INITIAL_APP_FILTER_DATA: AppFilterType = {
  scheduledDateTimeStart: subDays(new Date(), 30).toISOString(),
  scheduledDateTimeEnd: scheduledDateTimeEnd.toISOString(),
  orderStatus: [],
  driverStatus: [],
  orderDeliveryType: [],
  workspaceIds: [],
  assigneeIds: [],
};

export const TIME_OPTIONS: {
  [key: number]: { h: number; m: number; s: number; ms: number; text: string };
} = {
  0: {
    h: 0,
    m: 0,
    s: 0,
    ms: 0,
    text: "12 AM",
  },
  1: {
    h: 1,
    m: 0,
    s: 0,
    ms: 0,
    text: "1 AM",
  },
  2: {
    h: 2,
    m: 0,
    s: 0,
    ms: 0,
    text: "2 AM",
  },
  3: {
    h: 3,
    m: 0,
    s: 0,
    ms: 0,
    text: "3 AM",
  },
  4: {
    h: 4,
    m: 0,
    s: 0,
    ms: 0,
    text: "4 AM",
  },
  5: {
    h: 5,
    m: 0,
    s: 0,
    ms: 0,
    text: "5 AM",
  },
  6: {
    h: 6,
    m: 0,
    s: 0,
    ms: 0,
    text: "6 AM",
  },
  7: {
    h: 7,
    m: 0,
    s: 0,
    ms: 0,
    text: "7 AM",
  },
  8: {
    h: 8,
    m: 0,
    s: 0,
    ms: 0,
    text: "8 AM",
  },
  9: {
    h: 9,
    m: 0,
    s: 0,
    ms: 0,
    text: "9 AM",
  },
  10: {
    h: 10,
    m: 0,
    s: 0,
    ms: 0,
    text: "10 AM",
  },
  11: {
    h: 11,
    m: 0,
    s: 0,
    ms: 0,
    text: "11 AM",
  },
  12: {
    h: 12,
    m: 0,
    s: 0,
    ms: 0,
    text: "12 PM",
  },
  13: {
    h: 13,
    m: 0,
    s: 0,
    ms: 0,
    text: "1 PM",
  },
  14: {
    h: 14,
    m: 0,
    s: 0,
    ms: 0,
    text: "2 PM",
  },
  15: {
    h: 15,
    m: 0,
    s: 0,
    ms: 0,
    text: "3 PM",
  },
  16: {
    h: 16,
    m: 0,
    s: 0,
    ms: 0,
    text: "4 PM",
  },
  17: {
    h: 17,
    m: 0,
    s: 0,
    ms: 0,
    text: "5 PM",
  },
  18: {
    h: 18,
    m: 0,
    s: 0,
    ms: 0,
    text: "6 PM",
  },
  19: {
    h: 19,
    m: 0,
    s: 0,
    ms: 0,
    text: "7 PM",
  },
  20: {
    h: 20,
    m: 0,
    s: 0,
    ms: 0,
    text: "8 PM",
  },
  21: {
    h: 21,
    m: 0,
    s: 0,
    ms: 0,
    text: "9 PM",
  },
  22: {
    h: 22,
    m: 0,
    s: 0,
    ms: 0,
    text: "10 PM",
  },
  23: {
    h: 23,
    m: 0,
    s: 0,
    ms: 0,
    text: "11 PM",
  },
};

export const priorityOptions = [
  {
    label: "High",
    value: "HIGH",
    disabled: false,
  },
  {
    label: "Low",
    value: "LOW",
    disabled: false,
  },
];

export const vehicleOptions = [
  {
    label: "Bike",
    value: "BIKE",
    disabled: false,
  },
  {
    label: "Cycle",
    value: "CYCLE",
    disabled: false,
  },
  {
    label: "Estate",
    value: "ESTATE",
    disabled: false,
  },
  {
    label: "Other",
    value: "OTHER",
    disabled: false,
  },
  {
    label: "Pick Up",
    value: "PICK_UP",
    disabled: false,
  },
  {
    label: "Sedan",
    value: "SEDAN",
    disabled: false,
  },
  {
    label: "SUV",
    value: "SUV",
    disabled: false,
  },
  {
    label: "Truck",
    value: "TRUCK",
    disabled: false,
  },
  {
    label: "Van",
    value: "VAN",
    disabled: false,
  },
  {
    label: "Walk",
    value: "WALK",
    disabled: false,
  },
];

export const statusOptions = [
  {
    label: "Unassigned",
    value: "TODO",
    disabled: false,
  },
  {
    label: "Assigned",
    value: "ASSIGNED",
    disabled: true,
  },
  {
    label: "In Transit",
    value: "INPROGRESS",
    disabled: false,
  },
  {
    label: "Completed",
    value: "COMPLETE",
    disabled: false,
  },
  {
    label: "Cancelled",
    value: "CANCELLED",
    disabled: false,
  },
  {
    label: "Failed",
    value: "FAILED",
    disabled: false,
  },
];

export const statusOptionsForIndividualOrder = [
  {
    label: "Unassigned",
    value: "TODO",
    disabled: false,
  },
  {
    label: "Assigned",
    value: "ASSIGNED",
    disabled: true,
  },
  {
    label: "In Transit to Pickup",
    value: "INPROGRESS_PICKUP",
    disabled: false,
  },
  {
    label: "In Transit to Dropoff",
    value: "INPROGRESS_DROPOFF",
    disabled: false,
  },
  {
    label: "Completed",
    value: "COMPLETE",
    disabled: false,
  },
  {
    label: "Cancelled",
    value: "CANCELLED",
    disabled: false,
  },
  {
    label: "Failed",
    value: "FAILED",
    disabled: false,
  },
];
