import clsx from "clsx";

import { Icon } from "../Icon";

type ArrowButtonProps = {
  direction?: "left" | "right";
  rounded?: boolean;
  onClick: () => void;
};

export default function ArrowButton({
  direction = "left",
  rounded = false,
  onClick,
}: ArrowButtonProps) {
  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      <button
        type="button"
        className={clsx(
          "relative inline-flex h-7 w-7 items-center justify-center bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10",
          rounded
            ? "rounded-md"
            : direction === "left"
              ? "rounded-l-md"
              : "rounded-r-md",
        )}
        onClick={onClick}
      >
        {direction === "left" ? (
          <>
            <span className="sr-only">Previous</span>
            <Icon name="fasChevronLeft" className="h-4 w-4" />
          </>
        ) : (
          <>
            <span className="sr-only">Next</span>
            <Icon name="fasChevronRight" className="h-4 w-4" />
          </>
        )}
      </button>
    </span>
  );
}
