import { useState } from "react";

import { useTimeline } from "@web-app/api";
import { Order } from "@web-app/types";
import { OrderTimelineSchema } from "@web-app/utils/helpers";

import AssigneeActivity from "./AssigneeActivity";
import AttachmentsActivity from "./AttachmentsActivity";
import ConnectedWorkspaceActivity from "./ConnectedWorkspaceActivity";
import NoteActivity from "./NoteActivity";
import OrderEditActivity from "./OrderEditActivity";
import ScheduleTimeActivity from "./ScheduleTimeActivity";
import SmsNotificationActivityV2 from "./SmsNotificationActivity";
import StatusActivity from "./StatusActivity";

type OrderTimelinePropsTypes = {
  order: Order;
};

export default function OrderTimeline({ order }: OrderTimelinePropsTypes) {
  const [timelineLimit] = useState(10);

  const { data: timelineData } = useTimeline({
    orderId: order.id,
    timelineLimit,
  });

  const timelineTotalLength = timelineData?.length || 0;

  return (
    <>
      <ul role="list" className="space-y-6">
        {timelineData &&
          timelineData.map((item, itemIdx) => {
            if (OrderTimelineSchema[item.entity]) {
              const showTimelineBar = itemIdx !== timelineTotalLength - 1;

              switch (item.entity) {
                case "assigneeId":
                  return (
                    <AssigneeActivity
                      item={item}
                      showTimelineBar={showTimelineBar}
                    />
                  );
                case "statusNotes":
                  return (
                    <NoteActivity
                      item={item}
                      showTimelineBar={showTimelineBar}
                    />
                  );
                case "connectedWorkspace":
                  return (
                    <ConnectedWorkspaceActivity
                      item={item}
                      showTimelineBar={showTimelineBar}
                    />
                  );
                case "status":
                  return (
                    <StatusActivity
                      item={item}
                      showTimelineBar={showTimelineBar}
                      orderType={order.type}
                    />
                  );
                case "attachments":
                  return (
                    <AttachmentsActivity
                      item={item}
                      showTimelineBar={showTimelineBar}
                    />
                  );
                case "scheduledStartDateTime":
                case "scheduledEndDateTime":
                  return (
                    <ScheduleTimeActivity
                      item={item}
                      deliveryType={order?.deliveryTypeText}
                      showTimelineBar={showTimelineBar}
                    />
                  );
                case "smsNotification":
                  return (
                    <SmsNotificationActivityV2
                      item={item}
                      showTimelineBar={showTimelineBar}
                    />
                  );
                default:
                  return (
                    <OrderEditActivity
                      item={item}
                      showTimelineBar={showTimelineBar}
                    />
                  );
              }
            }
          })}
      </ul>
    </>
  );
}
