import { Dialog } from "@headlessui/react";
import clsx from "clsx";
import { ReactNode } from "react";

type ModalPropTypes = {
  isOpen: boolean;
  onClose: () => void;
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  children: JSX.Element;
  actions?: ReactNode[];
  actionsAlign?: "start" | "center" | "between" | "end";
  width?: number;
  noClose?: boolean;
};

export default function Modal({
  isOpen,
  onClose,
  title,
  subtitle,
  children,
  actions,
  actionsAlign = "start",
  width = 700,
  noClose,
}: ModalPropTypes) {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Dialog.Panel
            className="relative h-full w-full md:h-auto"
            style={{ maxWidth: width }}
          >
            <div className="relative rounded-lg bg-white shadow-md dark:bg-stone-800 p-6">
              <div>
                <div
                  className={clsx(
                    "flex items-start justify-between rounded-t",
                    {
                      "mb-4 dark:border-gray-700":
                        !noClose && (title || subtitle),
                    },
                  )}
                >
                  <div>
                    {title && (
                      <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                        {title}
                      </h3>
                    )}
                    {subtitle && (
                      <p className="max-w-2xl text-sm text-gray-500 dark:text-gray-300 mt-2">
                        {subtitle}
                      </p>
                    )}
                  </div>
                  {!noClose && (
                    <button
                      type="button"
                      onClick={() => onClose()}
                      className="ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-black hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                      data-modal-toggle="defaultModal"
                    >
                      <svg
                        aria-hidden="true"
                        className="h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  )}
                </div>
                {children}
              </div>
              {actions && actions?.length > 0 && (
                <div
                  className={clsx(`flex gap-3 mt-4 justify-${actionsAlign}`)}
                >
                  {actions?.map((item, i) => <span key={i}>{item}</span>)}
                </div>
              )}
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
