import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import clsx from "clsx";

import { Icon } from "../Icon";
import type { Icons } from "../Icon/utils";

type SelectInputPropTypes = {
  options: {
    value: string;
    label: string;
    icon?: keyof typeof Icons;
    disabled?: boolean;
  }[];
  selected?: string;
  label: string;
  required?: boolean;
  onChange?: (value: any) => void;
  disabled?: boolean;
  placeholder?: string;
  compact?: boolean;
  name?: string;
  readOnly?: boolean;
  defaultValue?: string;
};

export default function SelectInputV2({
  options,
  selected,
  label,
  required,
  onChange,
  disabled,
  placeholder,
  compact,
  name,
  readOnly,
  defaultValue,
  ...controllerFields
}: SelectInputPropTypes) {
  const selectedOption = options.find((opt) => opt.value === selected);

  return (
    <div className={clsx("relative mb-6 flex w-full flex-col md:mb-0")}>
      {label && (
        <label
          className={clsx(
            "mb-1 block text-xs font-medium leading-5 tracking-wide",
            disabled ? "text-gray-400" : "text-gray-700",
          )}
        >
          {label}
          {required && !disabled && "*"}
        </label>
      )}
      {readOnly ? (
        <span className="tw-form-field-readonly-style">
          {defaultValue ?? "N/A"}
        </span>
      ) : (
        <Listbox
          value={selected}
          onChange={onChange}
          disabled={disabled}
          name={name}
          data-testid={`select-input-${name}`}
          {...controllerFields}
        >
          {({ open }) => (
            <>
              <ListboxButton
                className={clsx(
                  "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25",
                  "tw-form-field-style flex items-center justify-between",
                )}
              >
                <div className="inline-flex items-center">
                  {selectedOption && selectedOption.icon && (
                    <Icon
                      name={selectedOption.icon}
                      className="mr-2 text-gray-900"
                    />
                  )}
                  <span className="truncate text-gray-900">
                    {selectedOption ? selectedOption.label : placeholder}
                  </span>
                </div>
                <span className="flex items-center gap-1">
                  {selectedOption && !disabled && !required && (
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        onChange?.("");
                      }}
                    >
                      <Icon
                        name="xMark"
                        className={clsx(
                          "h-3 w-3 text-gray-500",
                          selectedOption && "hover:bg-gray-200 p-1 rounded-sm",
                        )}
                      />
                    </span>
                  )}
                  {!disabled && (
                    <Icon
                      name="chevronDown"
                      className={clsx(
                        open && "rotate-180",
                        "h-3 w-3 text-gray-500 transition ease-in",
                        selectedOption && " hover:bg-gray-200 p-1 rounded-sm",
                      )}
                    />
                  )}
                </span>
              </ListboxButton>
              <ListboxOptions
                anchor="bottom"
                transition
                className={clsx(
                  "z-[60] w-[var(--button-width)] rounded-md max-h-56 overflow-auto bg-white py-1 mt-2 text-base shadow-lg  ring-1 ring-black ring-opacity-5 sm:text-sm focus:outline-none",
                  "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0",
                )}
              >
                {options.map((opt, idx) => (
                  <ListboxOption
                    key={opt.value}
                    value={opt.value}
                    data-testid={`${name}-${idx}`}
                    disabled={opt.value === selected || opt.disabled}
                    // className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
                    className={({ active }) =>
                      clsx(
                        active ? "bg-black text-white" : "text-gray-900",
                        "relative cursor-pointer select-none",
                        !compact && "py-1 pl-3 pr-7",
                        compact && "p-1",
                      )
                    }
                  >
                    {({ selected, active }) => (
                      <>
                        <div
                          className={clsx(
                            "flex items-center",
                            opt.disabled && "text-gray-400",
                          )}
                        >
                          {opt.icon && (
                            <Icon className="mr-2" name={opt.icon} />
                          )}
                          <span
                            className={clsx(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate",
                              compact && "ml-1",
                              !!compact && "ml-3",
                            )}
                          >
                            {opt.label}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={clsx(
                              active ? "text-white" : "text-gray-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                              compact && "pr-2",
                              !!compact && "pr-4",
                            )}
                          >
                            <Icon name="circleCheck" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </>
          )}
        </Listbox>
      )}
    </div>
  );
}
