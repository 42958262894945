import clsx from "clsx";

import { Icon } from "@web-app/components";
import { IconsType } from "@web-app/components/Icon/utils";

type SidebarTabsPropsTypes = {
  tabs: {
    name: string;
    icon: IconsType;
  }[];
  currentTab: string;
  onClickTab: (tabName: string) => void;
  className?: string;
};

export default function SidebarTabs({
  tabs,
  currentTab,
  onClickTab,
  className,
}: SidebarTabsPropsTypes) {
  return (
    <nav className="flex space-x-2" aria-label="Tabs">
      {tabs.map((tab) => (
        <span
          key={tab.name}
          className={clsx(
            tab.name === currentTab
              ? "bg-gray-200 text-gray-700 hover:bg-gray-700 hover:text-gray-300"
              : "text-gray-700 hover:bg-gray-700 hover:text-gray-300",
            "flex h-9 cursor-pointer items-center rounded-md px-3 py-2 text-xs font-normal transition ease-in",
            className,
          )}
          aria-current={tab.name === currentTab ? "page" : undefined}
          onClick={() => onClickTab(tab.name)}
        >
          <Icon name={tab.icon} className="mr-2 h-4 w-4" />
          {tab.name}
        </span>
      ))}
    </nav>
  );
}
