import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";

import useDispatchStore from "@web-app/store/dispatchStore";
import {
  CreateCustomFieldPayload,
  CustomFieldValue,
  UpdateCustomFieldPayload,
} from "@web-app/types/customFields";

import {
  create,
  groupList,
  list,
  orderCustomFieldsList,
  update,
  updateOrderCustomFieldValue,
} from "../services/customFields";

export const useCustomFieldsAndGroups = () => {
  const { user } = useDispatchStore();

  return useQueries({
    queries: [
      {
        queryKey: ["customFields"],
        queryFn: async () => {
          const response = await list(user?.currentWorkspaceId || "");
          return response;
        },
      },
      {
        queryKey: ["customFieldGroups"],
        queryFn: async () => {
          const response = await groupList();
          return response;
        },
      },
    ],
  });
};

export const useActiveCustomFieldsAndGroups = () => {
  const { user } = useDispatchStore();

  return useQueries({
    queries: [
      {
        queryKey: ["customFields", "activeCustomFields"],
        queryFn: async () => {
          const response = await list(user?.currentWorkspaceId || "", true);
          return response;
        },
      },
      {
        queryKey: ["customFieldGroups"],
        queryFn: async () => {
          const response = await groupList();
          return response;
        },
      },
    ],
  });
};

export const useOrderCustomFieldsWithGroups = (entityId: string) => {
  return useQueries({
    queries: [
      {
        queryKey: ["orderCustomFields", entityId],
        queryFn: async () => {
          const response = await orderCustomFieldsList(entityId);
          return response;
        },
      },
      {
        queryKey: ["customFieldGroups"],
        queryFn: async () => {
          const response = await groupList();
          return response;
        },
      },
    ],
  });
};

export const useCustomFieldMutation = () => {
  const queryClient = useQueryClient();

  const createCustomField = useMutation({
    mutationFn: async (payload: CreateCustomFieldPayload) => {
      const response = await create(payload);
      return response;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["customFields"] });
    },
  });

  const updateCustomField = useMutation({
    mutationFn: async ({
      payload,
      customFieldId,
    }: {
      payload: Partial<UpdateCustomFieldPayload>;
      customFieldId: string;
    }) => {
      const response = await update(payload, customFieldId);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["customFields"] });
    },
  });

  const updateOrderCustomField = useMutation({
    mutationFn: async ({
      payload,
      entityId,
      fieldId,
    }: {
      payload: { customFieldValues: CustomFieldValue[] };
      entityId: string;
      fieldId: string;
    }) => {
      const response = await updateOrderCustomFieldValue(
        payload,
        entityId,
        fieldId,
      );
      return response;
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["orderCustomFields"] });
    },
  });

  const deleteCustomField = () => {};

  return {
    createCustomField,
    updateCustomField,
    deleteCustomField,
    updateOrderCustomField,
  };
};
