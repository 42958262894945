import { create } from "zustand";
import { persist } from "zustand/middleware";

import { DriverEntity, Order, Route, User } from "@web-app/types";

const scheduledDateTimeStart = new Date();
scheduledDateTimeStart.setHours(0, 0, 0, 0);

const scheduledDateTimeEnd = new Date();
scheduledDateTimeEnd.setHours(23, 59, 59, 999);

export type AppFilterType = {
  scheduledDateTimeStart: string;
  scheduledDateTimeEnd: string;
  orderStatus: Order["status"][];
  driverStatus: DriverEntity["dutyStatus"][];
  orderDeliveryType: Order["type"][];
  workspaceIds: string[];
  assigneeIds: string[];
};

export const initialAppFilterData: AppFilterType = {
  scheduledDateTimeStart: scheduledDateTimeStart.toISOString(),
  scheduledDateTimeEnd: scheduledDateTimeEnd.toISOString(),
  orderStatus: [],
  driverStatus: [],
  orderDeliveryType: [],
  workspaceIds: [],
  assigneeIds: [],
};

interface DispatchStore {
  focusedOrderId: string | null;
  focusedRouteId: string | null;
  focusedAssigneeId: string | null;
  userLatestLocation: google.maps.LatLngLiteral;
  userLatestZoomLevel: number | undefined;
  appFilter: AppFilterType;
  user: Omit<User, "role" | "createdAt"> | null;
  selectedOrderIds: Set<string>;
  routeModalMode: "edit" | "create" | "closed";
  mapSettings: {
    isTrafficVisible: boolean;
    isAllRoutesVisible: boolean;
    isSatelliteView: boolean;
  };
  showOrderDetailsPanel: boolean;
  routes: Map<string, Route>;
  isRouteOrdersOrderChanged: boolean;
}

const useDispatchStore = create<DispatchStore>()(
  persist(
    (): DispatchStore => ({
      focusedOrderId: null,
      focusedRouteId: null,
      focusedAssigneeId: null,
      userLatestLocation: {
        lat: 38.3604509,
        lng: -99.1247856,
      },
      userLatestZoomLevel: 5,
      appFilter: initialAppFilterData,
      user: null,
      selectedOrderIds: new Set(),
      routeModalMode: "closed",
      mapSettings: {
        isTrafficVisible: false,
        isAllRoutesVisible: true,
        isSatelliteView: false,
      },
      showOrderDetailsPanel: false,
      routes: new Map(),
      isRouteOrdersOrderChanged: false,
    }),
    {
      name: "dispatchStore",
      partialize: (state) => ({
        userLatestLocation: state.userLatestLocation,
        userLatestZoomLevel: state.userLatestZoomLevel,
        appFilter: state.appFilter,
        mapSettings: state.mapSettings,
      }),
    },
  ),
);

export default useDispatchStore;
