import clsx from "clsx";

type PillPropsTypes = {
  onClick: () => void;
  onClose?: () => void;
  children: React.ReactNode;
  isClosable?: boolean;
};

export default function Pill({
  children,
  isClosable = true,
  onClick,
  onClose,
}: PillPropsTypes) {
  return (
    <div className="flex cursor-pointer divide-x divide-gray-200 rounded-md bg-white border border-gray-200 text-xs font-semibold hover:bg-gray-200 transition ease-in">
      <span
        className={clsx(
          " px-2 py-2 transition ease-in",
          isClosable ? "rounded-l-full" : "rounded-full",
        )}
        onClick={onClick}
      >
        {children}
      </span>
      {isClosable && (
        <span
          className="rounded-r-full px-2 py-2 transition ease-in hover:bg-gray-200"
          onClick={onClose}
        >
          <i className="fa-solid fa-xmark" />
        </span>
      )}
    </div>
  );
}
