import { createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";

import { LoadingIcon } from "@web-app/components";
import http from "@web-app/utils/http";

export const Route = createFileRoute("/auth/google/callback")({
  component: RouteComponent,
});

function RouteComponent() {
  const tryGoogleLogin = async () => {
    const queryParams = location.href.split("?")[1];
    if (queryParams) {
      const response = await http.post(`/auth/google/callback?${queryParams}`);
      if (response.status === 200) {
        localStorage.setItem("apiToken", response.data);
        window.location.href = "/";
      } else {
        window.location.href = "/signin";
      }
    } else {
      window.location.href = "/signin";
    }
  };

  useEffect(() => {
    tryGoogleLogin();
  }, []);

  return (
    <div className="h-screen w-screen grid place-content-center text-center">
      <div className="flex items-center gap-4">
        <LoadingIcon className="w-8 h-8" />
        Authenticating
      </div>
    </div>
  );
}
