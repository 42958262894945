import clsx from "clsx";

type SidebarProps = {
  children: JSX.Element;
  isOpen: boolean;
  width?: string;
};

export default function Sidebar({ children, isOpen, width }: SidebarProps) {
  return (
    <div
      className={clsx(
        "absolute top-0 transition-all overflow-y-auto overflow-x-hidden z-20 flex h-screen flex-col bg-white drop-shadow-xl",
        isOpen ? "right-0" : "-right-[480px]",
        width ? width : "w-[480px]",
      )}
    >
      {children}
    </div>
  );
}
