import { useState } from "react";
import toast from "react-hot-toast";

import Button from "../Button";
import Input from "../Input";
import Tooltip from "../Tooltip";

export default function URLField({
  url,
  label,
  tooltipText,
  openInNewTab = true,
}: {
  url: string;
  label?: string;
  tooltipText?: string;
  openInNewTab?: boolean;
}) {
  const [isCopied, setIsCopied] = useState(false);

  const handleClick = () => {
    navigator.clipboard.writeText(url);

    setIsCopied(true);
    toast.success("Link copied to clipboard.");
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
    <div className="flex w-full flex-col">
      <div className="flex items-center">
        {label && (
          <label className="mb-1 block text-xs font-semibold tracking-wide text-gray-700">
            {label}
          </label>
        )}
        {tooltipText && <Tooltip text={tooltipText || ""} position="right" />}
      </div>
      <div className="flex items-center gap-2">
        {openInNewTab ? (
          <a
            href={url}
            className="w-full"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Input defaultValue={url} disabled type="url" />
          </a>
        ) : (
          <Input defaultValue={url} disabled type="url" />
        )}
        <Button
          onClick={handleClick}
          disabled={isCopied}
          icon="copy"
          type="button"
        >
          Copy
        </Button>
      </div>
    </div>
  );
}
