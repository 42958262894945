import type { FieldValues } from "react-hook-form";

import { Icon } from "../Icon";
import Input from "../Input";

export type ItemChecklistProps = {
  isReadOnly?: boolean;
  checklistValues: FieldValues[];
  onChange: (
    index: number,
    value: any,
    name: "isChecked" | "label" | "quantity",
  ) => void;
  onRemoveItem: (index: number) => void;
  onAddItem: () => void;
  errors: any;
  onSubmit?: () => void;
  subtitle: string;
};

export default function ItemChecklist({
  isReadOnly,
  checklistValues,
  onChange,
  onRemoveItem,
  onAddItem,
  errors,
  onSubmit,
  subtitle,
}: ItemChecklistProps) {
  return (
    <>
      <h3 className="mb-1 block text-xs tracking-wide text-gray-700">
        {subtitle}
      </h3>

      <div className="">
        {checklistValues &&
          checklistValues?.map((item, index: number) => (
            <div
              key={item.id}
              className={`flex items-end ${isReadOnly ? "py-1" : "py-2"}`}
            >
              <div
                className={
                  checklistValues.length < 10
                    ? "w-4"
                    : checklistValues.length < 100
                      ? "w-5"
                      : "w-8"
                }
              >
                <p className="text-right   text-gray-400 text-xs mb-1">
                  {index + 1}.
                </p>
              </div>

              <div className="w-18 mx-2">
                <Input
                  disabled={true}
                  type="checkbox"
                  defaultChecked={item.isChecked}
                  readOnly={true}
                  checkboxLabel={
                    isReadOnly ? `${item.quantity} ${item.label}` : ""
                  }
                />
              </div>

              {!isReadOnly && (
                <div className="mr-4">
                  <Input
                    type="number"
                    min={1}
                    max={999}
                    className="h-6 w-20 text-right no-spinner pr-3"
                    defaultValue={item.quantity}
                    placeholder={`Quantity`}
                    onChange={(e) => {
                      onChange(index, e.target.value, "quantity");
                      onSubmit && onSubmit();
                    }}
                    onWheel={(e) => e.currentTarget.blur()}
                    readOnly={isReadOnly}
                  />
                  {errors?.[index]?.quantity && (
                    <ErrorMessage message={errors[index]?.quantity?.message} />
                  )}
                </div>
              )}

              {!isReadOnly && (
                <div className="mr-4 ">
                  <Input
                    type="text"
                    maxLength={40}
                    lengthCountClasses="top-1"
                    className="h-6 w-70  "
                    defaultValue={item.label}
                    placeholder="Item Name"
                    onChange={(e) => {
                      onChange(index, e.target.value, "label");
                      onSubmit && onSubmit();
                    }}
                    readOnly={isReadOnly}
                  />
                  {errors?.[index]?.label && (
                    <ErrorMessage message={errors[index]?.label?.message} />
                  )}
                </div>
              )}

              {!isReadOnly && (
                <button
                  type="button"
                  onClick={() => {
                    onRemoveItem(index);
                    onSubmit && onSubmit();
                  }}
                >
                  <Icon name="trash" className="h-4" />
                </button>
              )}
            </div>
          ))}

        {!isReadOnly && (
          <div className="flex justify-between mt-2">
            <button
              className="h-8 w-24 text-black"
              type="button"
              onClick={onAddItem}
            >
              + Add Item
            </button>
          </div>
        )}
      </div>
    </>
  );
}

const ErrorMessage = ({ message }: { message: string | undefined }) => (
  <span className="text-red-500 absolute text-[10px]">{message} </span>
);
