import clsx from "clsx";

type AvatarType = {
  image?: string;
  name?: string;
  size: number;
};

export default function Avatar({ image, name, size = 10 }: AvatarType) {
  if (image) {
    return (
      <img
        className={`inline-block h-${size} w-${size} rounded-full object-cover ring-2 ring-white`}
        src={image}
      />
    );
  }
  const initials = name
    ?.split(" ")
    .splice(0, 2)
    .map((n) => n[0])
    .join("");

  return (
    <div
      className={clsx(
        `w-${size} h-${size}`,
        "relative inline-flex items-center justify-center overflow-hidden rounded-full bg-gray-200 dark:bg-gray-600",
      )}
    >
      <span className="text-sm font-medium text-gray-600 dark:text-gray-300">
        {initials}
        {!initials && <i className="fa-regular fa-user" />}
      </span>
    </div>
  );
}
