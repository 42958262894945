import debounce from "lodash/debounce";

import type { CustomField } from "@web-app/types";

import Input from "../Input";

export function TextField({
  fieldId,
  customField,
  fieldValues,
  isReadOnly,
  onChange,
  isRequired,
  isDebounce,
}: {
  fieldId: string;
  customField: CustomField;
  fieldValues: CustomField["defaultValues"];
  isReadOnly: boolean;
  isRequired?: boolean;
  isDebounce?: boolean;
  onChange: (
    fieldId: string,
    customField: CustomField,
    value: CustomField["defaultValues"],
    groupId: string,
  ) => void;
}) {
  const onChangeHander = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(
      fieldId,
      customField,
      [
        {
          value: e.target.value,
          isSelected: false,
        },
      ],
      customField.groupId,
    );
  };

  const debouncedOnChangeHandler = debounce(onChangeHander, 1000);

  return (
    <div>
      <Input
        // @ts-ignore fix type
        defaultValue={fieldValues.length ? fieldValues[0].value : undefined}
        label={customField.name}
        toolTipText={customField.helpText}
        type="text"
        readOnly={isReadOnly}
        onChange={isDebounce ? debouncedOnChangeHandler : onChangeHander}
        required={isRequired}
      />
    </div>
  );
}
