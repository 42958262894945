import * as Sentry from "@sentry/react";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import ReactDOM from "react-dom/client";

import config from "./config";
import "./index.css";
// Import the generated route tree
import { routeTree } from "./routeTree.gen";

// Create a new router instance
const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

if (config.APP_ENV === "production") {
  Sentry.init({
    environment: config.APP_ENV,
    dsn: "https://6103bf9235652295894b93ce6037cc2a@o4504831428919296.ingest.us.sentry.io/4508464387653632",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      Sentry.feedbackIntegration({
        colorScheme: "system",
        autoInject: false,
      }),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    replaysSessionSampleRate: 1, // Capture all
    replaysOnErrorSampleRate: 1, // Capture all
  });
}

// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    // Need to remove strictmode for now due to google maps marker issues
    // <StrictMode>
    <RouterProvider router={router} />,
    // </StrictMode>
  );
}
