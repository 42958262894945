import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Transition,
} from "@headlessui/react";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { endOfDay, format, getHours, startOfDay } from "date-fns";
import { enUS } from "date-fns/locale";
// import startOfToday from "date-fns/startOfToday";
import React, { useRef, useState } from "react";
import {
  DatePickerCalendar,
  DateRangePickerCalendar, // @ts-expect-error Something is wrong with the types
  START_DATE,
} from "react-nice-dates";
import "react-nice-dates/build/style.css";
import ReactSlider from "react-slider";

import type { AppFilterType } from "@web-app/store/dispatchStore";
import useDispatchStore, {
  initialAppFilterData,
} from "@web-app/store/dispatchStore";
import { User } from "@web-app/types";

import { DriverEntity, DutyStatus } from "../../types/drivers";
import { Order, OrderDeliveryType, OrderStatus } from "../../types/orders";
import Button from "../Button";
import { Icon } from "../Icon";
import Input from "../Input";
import Pill from "../Pill";

const TIME_OPTIONS = {
  0: {
    h: 0,
    m: 0,
    s: 0,
    ms: 0,
    text: "12 AM",
  },
  1: {
    h: 1,
    m: 0,
    s: 0,
    ms: 0,
    text: "1 AM",
  },
  2: {
    h: 2,
    m: 0,
    s: 0,
    ms: 0,
    text: "2 AM",
  },
  3: {
    h: 3,
    m: 0,
    s: 0,
    ms: 0,
    text: "3 AM",
  },
  4: {
    h: 4,
    m: 0,
    s: 0,
    ms: 0,
    text: "4 AM",
  },
  5: {
    h: 5,
    m: 0,
    s: 0,
    ms: 0,
    text: "5 AM",
  },
  6: {
    h: 6,
    m: 0,
    s: 0,
    ms: 0,
    text: "6 AM",
  },
  7: {
    h: 7,
    m: 0,
    s: 0,
    ms: 0,
    text: "7 AM",
  },
  8: {
    h: 8,
    m: 0,
    s: 0,
    ms: 0,
    text: "8 AM",
  },
  9: {
    h: 9,
    m: 0,
    s: 0,
    ms: 0,
    text: "9 AM",
  },
  10: {
    h: 10,
    m: 0,
    s: 0,
    ms: 0,
    text: "10 AM",
  },
  11: {
    h: 11,
    m: 0,
    s: 0,
    ms: 0,
    text: "11 AM",
  },
  12: {
    h: 12,
    m: 0,
    s: 0,
    ms: 0,
    text: "12 PM",
  },
  13: {
    h: 13,
    m: 0,
    s: 0,
    ms: 0,
    text: "1 PM",
  },
  14: {
    h: 14,
    m: 0,
    s: 0,
    ms: 0,
    text: "2 PM",
  },
  15: {
    h: 15,
    m: 0,
    s: 0,
    ms: 0,
    text: "3 PM",
  },
  16: {
    h: 16,
    m: 0,
    s: 0,
    ms: 0,
    text: "4 PM",
  },
  17: {
    h: 17,
    m: 0,
    s: 0,
    ms: 0,
    text: "5 PM",
  },
  18: {
    h: 18,
    m: 0,
    s: 0,
    ms: 0,
    text: "6 PM",
  },
  19: {
    h: 19,
    m: 0,
    s: 0,
    ms: 0,
    text: "7 PM",
  },
  20: {
    h: 20,
    m: 0,
    s: 0,
    ms: 0,
    text: "8 PM",
  },
  21: {
    h: 21,
    m: 0,
    s: 0,
    ms: 0,
    text: "9 PM",
  },
  22: {
    h: 22,
    m: 0,
    s: 0,
    ms: 0,
    text: "10 PM",
  },
  23: {
    h: 23,
    m: 0,
    s: 0,
    ms: 0,
    text: "11 PM",
  },
};

export default function OrdersFilterDropdown({
  isDateRange,
}: {
  isDateRange?: boolean;
}) {
  const appFilter = useDispatchStore((state) => state.appFilter);
  const queryClient = useQueryClient();
  const [temporaryAppFilter, setTemporaryAppFilter] =
    useState<AppFilterType>(appFilter);
  const popoverFilterButtonRef = useRef<any>(null);
  const [tabgroupDefaultIndex, setTabGroupDefaultIndex] = useState<
    number | null
  >(null);
  const [focus, setFocus] = useState(START_DATE);
  const [assigneeSearch, setAssigneeSearch] = useState("");
  const handleFocusChange = (newFocus: string) => {
    setFocus(newFocus || START_DATE);
  };

  const assignees = queryClient.getQueryData<Map<string, DriverEntity>>([
    "drivers",
  ]);

  const clickPopoverFilterButton = () => {
    popoverFilterButtonRef.current?.click();
  };

  const openAppFilterPopover = (index: number) => {
    clickPopoverFilterButton();
    setTabGroupDefaultIndex(index);
  };

  const formattedText = (count: number, text: string) =>
    count > 1 ? `${count} ${text}s` : `${count} ${text}`;

  const onChange = (data: Partial<AppFilterType>, apply = false) => {
    setTemporaryAppFilter({ ...temporaryAppFilter, ...data });

    if (apply) {
      useDispatchStore.setState({
        appFilter: { ...temporaryAppFilter, ...data },
      });

      queryClient.invalidateQueries({ queryKey: [appFilter] });
    }
  };

  const onReset = () => {
    setTemporaryAppFilter(initialAppFilterData);
    useDispatchStore.setState({
      appFilter: initialAppFilterData,
    });

    clickPopoverFilterButton();
  };

  const onApply = () => {
    useDispatchStore.setState({
      appFilter: temporaryAppFilter,
    });

    clickPopoverFilterButton();
  };

  const onChangeOrderStatus = (
    e: React.ChangeEvent<HTMLInputElement>,
    status: Order["status"],
  ) => {
    onChange({
      orderStatus: e.target.checked
        ? [...temporaryAppFilter.orderStatus, status]
        : temporaryAppFilter.orderStatus.filter((s) => status !== s),
    });
  };

  const onChangeDriverStatus = (
    e: React.ChangeEvent<HTMLInputElement>,
    status: User["driverDetails"]["dutyStatus"],
  ) =>
    onChange({
      driverStatus: e.target.checked
        ? [...temporaryAppFilter.driverStatus, status]
        : temporaryAppFilter.driverStatus.filter((s) => status !== s),
    });

  const onChangeDeliveryType = (
    e: React.ChangeEvent<HTMLInputElement>,
    deliveryType: Order["type"],
  ) =>
    onChange({
      orderDeliveryType: e.target.checked
        ? [...temporaryAppFilter.orderDeliveryType, deliveryType]
        : temporaryAppFilter.orderDeliveryType.filter(
            (s) => deliveryType !== s,
          ),
    });

  const onChangeAssignee = (
    e: React.ChangeEvent<HTMLInputElement>,
    assigneeId: string,
  ) =>
    onChange({
      assigneeIds: e.target.checked
        ? [...temporaryAppFilter.assigneeIds, assigneeId]
        : temporaryAppFilter.assigneeIds.filter((a) => a !== assigneeId),
    });

  const mapAssignees = (assignees: DriverEntity[]): DriverEntity[] => {
    const searchRegex = new RegExp(assigneeSearch, "i");
    const filteredAssignees = assignees.filter(
      (assignee) =>
        assignee?.userProfile?.name &&
        searchRegex.test(assignee.userProfile.name),
    );

    const sortedData = filteredAssignees.sort((a, b) => {
      const nameA = a.userProfile.name?.toLowerCase() || "";
      const nameB = b.userProfile.name?.toLowerCase() || "";

      return nameA.localeCompare(nameB);
    });

    return sortedData;
  };

  const statusCount =
    appFilter.orderStatus.length + appFilter.driverStatus.length;
  const deliveryTypeCount = appFilter.orderDeliveryType.length;
  const assigneeCount = appFilter.assigneeIds.length;

  return (
    <div className="relative flex items-center gap-2">
      <div className="flex items-center gap-2">
        {!isDateRange && (
          <Pill onClick={() => openAppFilterPopover(0)} isClosable={false}>
            {format(new Date(appFilter.scheduledDateTimeStart), "MMM dd, yyyy")}{" "}
            {format(new Date(appFilter.scheduledDateTimeStart), "p")}
            {" - "}
            {format(new Date(appFilter.scheduledDateTimeEnd), "p")}
          </Pill>
        )}
        {isDateRange && (
          <Pill onClick={() => openAppFilterPopover(0)} isClosable={false}>
            {format(new Date(appFilter.scheduledDateTimeStart), "MMM dd, yyyy")}
            {" - "}
            {format(new Date(appFilter.scheduledDateTimeEnd), "MMM dd, yyyy")}
          </Pill>
        )}
        {!!statusCount && (
          <Pill
            onClick={() => openAppFilterPopover(1)}
            onClose={() => {
              onChange(
                {
                  ...temporaryAppFilter,
                  orderStatus: [],
                  driverStatus: [],
                },
                true,
              );
            }}
          >
            {statusCount} Status
          </Pill>
        )}
        {!!deliveryTypeCount && (
          <Pill
            onClick={() => openAppFilterPopover(2)}
            onClose={() =>
              onChange(
                {
                  ...temporaryAppFilter,
                  orderDeliveryType: [],
                },
                true,
              )
            }
          >
            {formattedText(deliveryTypeCount, "Type")}
          </Pill>
        )}

        {!!assigneeCount && (
          <Pill
            onClick={() => openAppFilterPopover(4)}
            onClose={() =>
              onChange(
                {
                  ...temporaryAppFilter,
                  assigneeIds: [],
                },
                true,
              )
            }
          >
            {formattedText(assigneeCount, "Assignee")}
          </Pill>
        )}
      </div>
      <Popover>
        <PopoverButton
          ref={popoverFilterButtonRef}
          className={clsx(
            "rounded-md border border-gray-200 bg-white p-2 text-sm font-semibold text-gray-700 transition ease-in hover:bg-gray-900 hover:text-white focus:outline-none",
          )}
        >
          <Icon name="filter" />
        </PopoverButton>
        <Transition
          as="div"
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          className="absolute right-0 top-[45px] z-10 w-[470px] rounded-xl bg-white shadow-xl"
        >
          <PopoverPanel>
            <>
              <TabGroup
                defaultIndex={tabgroupDefaultIndex || 0}
                className="flex"
              >
                <TabList className="flex w-[250px] flex-col gap-2 rounded-l-xl border-gray-200 bg-gray-900 pt-2">
                  <TabItem>
                    <span className="font-semibold">Date & Time</span>
                    {!isDateRange && (
                      <span className="">
                        {format(
                          new Date(temporaryAppFilter.scheduledDateTimeStart),
                          "MM/dd/yyyy",
                        )}
                        <br />
                        {format(
                          new Date(temporaryAppFilter.scheduledDateTimeStart),
                          "p",
                        )}
                        {" - "}
                        {format(
                          new Date(temporaryAppFilter.scheduledDateTimeEnd),
                          "p",
                        )}
                      </span>
                    )}

                    {isDateRange && (
                      <span className="">
                        {format(
                          new Date(temporaryAppFilter.scheduledDateTimeStart),
                          "MM/dd/yyyy",
                        )}{" "}
                        {" - "}
                        <br />
                        {format(
                          new Date(temporaryAppFilter.scheduledDateTimeEnd),
                          "MM/dd/yyyy",
                        )}
                      </span>
                    )}
                  </TabItem>
                  <TabItem>
                    <span className="font-semibold">
                      Status{" "}
                      {!!(
                        temporaryAppFilter.orderStatus.length ||
                        temporaryAppFilter.driverStatus.length
                      ) &&
                        `(${
                          temporaryAppFilter.orderStatus.length +
                          temporaryAppFilter.driverStatus.length
                        })`}
                    </span>
                  </TabItem>
                  <TabItem>
                    <span className="font-semibold">
                      Delivery Type{" "}
                      {!!temporaryAppFilter.orderDeliveryType.length &&
                        `(${temporaryAppFilter.orderDeliveryType.length})`}
                    </span>
                  </TabItem>
                  <TabItem>
                    <span className="font-semibold">
                      Assignee{" "}
                      {!!temporaryAppFilter.assigneeIds.length &&
                        `(${temporaryAppFilter.assigneeIds.length})`}
                    </span>
                  </TabItem>
                </TabList>
                <TabPanels className="relative h-[360px] w-full py-2 ">
                  <TabPanelWrapper>
                    <div className="mx-2">
                      {isDateRange && (
                        <DateRangePickerCalendar
                          focus={focus}
                          onFocusChange={handleFocusChange}
                          startDate={
                            new Date(temporaryAppFilter.scheduledDateTimeStart)
                          }
                          endDate={
                            new Date(temporaryAppFilter.scheduledDateTimeEnd)
                          }
                          onStartDateChange={(date) => {
                            if (date) {
                              date = startOfDay(date);
                            }
                            setTemporaryAppFilter({
                              ...temporaryAppFilter,
                              scheduledDateTimeStart: date?.toISOString() || "",
                            });
                          }}
                          onEndDateChange={(date) => {
                            if (date) {
                              date = endOfDay(date);
                            }
                            setTemporaryAppFilter({
                              ...temporaryAppFilter,
                              scheduledDateTimeEnd: date?.toISOString() || "",
                            });
                          }}
                          locale={enUS}
                        />
                      )}
                      {!isDateRange && (
                        <DatePickerCalendar
                          locale={enUS}
                          date={
                            new Date(temporaryAppFilter.scheduledDateTimeStart)
                          }
                          onDateChange={(date: Date | null) => {
                            const startDate = new Date(date || "");
                            const endDate = new Date(date || "");
                            startDate.setHours(0, 0, 0, 0);
                            endDate.setHours(23, 59, 59, 999);

                            onChange({
                              ...temporaryAppFilter,
                              scheduledDateTimeStart: startDate.toISOString(),
                              scheduledDateTimeEnd: endDate.toISOString(),
                            });
                          }}
                        />
                      )}
                      {!isDateRange && (
                        <div className="m-auto mt-6 flex w-[90%] items-center">
                          {/* @ts-ignore */}
                          <ReactSlider
                            className="flex w-full items-center"
                            thumbClassName="bg-gray-700 p-2 rounded-full text-xs font-semibold"
                            trackClassName="bg-gray-200 h-1"
                            ariaValuetext={(state) =>
                              `Thumb value ${state.valueNow}`
                            }
                            renderThumb={(props, state) => {
                              return (
                                <div {...props}>
                                  <span className="absolute bottom-[20px] left-[-10px] w-10 text-gray-700">
                                    {/* @ts-ignore */}
                                    {TIME_OPTIONS[state.valueNow].text}
                                  </span>
                                </div>
                              );
                            }}
                            marks={Array.from(
                              Array(Object.keys(TIME_OPTIONS).length).keys(),
                            )}
                            pearling
                            min={0}
                            value={[
                              getHours(
                                new Date(
                                  temporaryAppFilter.scheduledDateTimeStart,
                                ),
                              ),
                              getHours(
                                new Date(
                                  temporaryAppFilter.scheduledDateTimeEnd,
                                ),
                              ),
                            ]}
                            max={Object.keys(TIME_OPTIONS).length - 1}
                            markClassName="bg-gray-700"
                            onChange={(value) => {
                              const startDate = new Date(
                                temporaryAppFilter.scheduledDateTimeStart,
                              );
                              const endDate = new Date(
                                temporaryAppFilter.scheduledDateTimeStart,
                              );
                              startDate.setHours(
                                // @ts-expect-error
                                TIME_OPTIONS[value[0]].h,
                                // @ts-ignore
                                TIME_OPTIONS[value[0]].m,
                                // @ts-ignore
                                TIME_OPTIONS[value[0]].s,
                                // @ts-ignore
                                TIME_OPTIONS[value[0]].ms,
                              );
                              endDate.setHours(
                                // @ts-ignore
                                TIME_OPTIONS[value[1]].h,
                                // @ts-ignore
                                TIME_OPTIONS[value[1]].m,
                                // @ts-ignore
                                TIME_OPTIONS[value[1]].s,
                                // @ts-ignore
                                TIME_OPTIONS[value[1]].ms,
                              );

                              onChange({
                                ...temporaryAppFilter,
                                scheduledDateTimeStart: startDate.toISOString(),
                                scheduledDateTimeEnd: endDate.toISOString(),
                              });
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </TabPanelWrapper>
                  <TabPanelWrapper>
                    <TabPanelTitle>Order Status</TabPanelTitle>
                    <div className="mb-8 mt-2 flex flex-col gap-1">
                      <Input
                        type="checkbox"
                        checked={temporaryAppFilter.orderStatus.includes(
                          OrderStatus.TODO,
                        )}
                        checkboxLabel="Unassigned"
                        onChange={(e) =>
                          onChangeOrderStatus(e, OrderStatus.TODO)
                        }
                      />
                      <Input
                        type="checkbox"
                        checked={temporaryAppFilter.orderStatus.includes(
                          OrderStatus.ASSIGNED,
                        )}
                        checkboxLabel="Assigned"
                        onChange={(e) =>
                          onChangeOrderStatus(e, OrderStatus.ASSIGNED)
                        }
                      />
                      <Input
                        type="checkbox"
                        checked={temporaryAppFilter.orderStatus.includes(
                          OrderStatus.INPROGRESS,
                        )}
                        checkboxLabel="In Transit"
                        onChange={(e) =>
                          onChangeOrderStatus(e, OrderStatus.INPROGRESS)
                        }
                      />
                      <Input
                        type="checkbox"
                        checkboxLabel="Completed"
                        checked={temporaryAppFilter.orderStatus.includes(
                          OrderStatus.COMPLETE,
                        )}
                        onChange={(e) =>
                          onChangeOrderStatus(e, OrderStatus.COMPLETE)
                        }
                      />
                      <Input
                        type="checkbox"
                        checkboxLabel="Failed"
                        checked={temporaryAppFilter.orderStatus.includes(
                          OrderStatus.FAILED,
                        )}
                        onChange={(e) =>
                          onChangeOrderStatus(e, OrderStatus.FAILED)
                        }
                      />
                      <Input
                        type="checkbox"
                        checkboxLabel="Cancelled"
                        checked={temporaryAppFilter.orderStatus.includes(
                          OrderStatus.CANCELLED,
                        )}
                        onChange={(e) =>
                          onChangeOrderStatus(e, OrderStatus.CANCELLED)
                        }
                      />
                    </div>
                    <TabPanelTitle>Driver Status</TabPanelTitle>
                    <div className="mt-2 flex flex-col gap-1">
                      <Input
                        type="checkbox"
                        checkboxLabel="Online"
                        checked={temporaryAppFilter.driverStatus.includes(
                          DutyStatus.ONLINE,
                        )}
                        onChange={(e) =>
                          onChangeDriverStatus(e, DutyStatus.ONLINE)
                        }
                      />
                      <Input
                        type="checkbox"
                        checkboxLabel="Offline"
                        checked={temporaryAppFilter.driverStatus.includes(
                          DutyStatus.OFFLINE,
                        )}
                        onChange={(e) =>
                          onChangeDriverStatus(e, DutyStatus.OFFLINE)
                        }
                      />
                      <Input
                        type="checkbox"
                        checkboxLabel="In Transit"
                        checked={temporaryAppFilter.driverStatus.includes(
                          DutyStatus.INTRANSIT,
                        )}
                        onChange={(e) =>
                          onChangeDriverStatus(e, DutyStatus.INTRANSIT)
                        }
                      />
                    </div>
                  </TabPanelWrapper>
                  <TabPanelWrapper>
                    <TabPanelTitle>Delivery Type</TabPanelTitle>
                    <div className="mb-8 mt-2 flex flex-col gap-1">
                      <Input
                        type="checkbox"
                        checkboxLabel="Route Based Pickup"
                        checked={temporaryAppFilter.orderDeliveryType.includes(
                          OrderDeliveryType.ROUTE_OPT_PICKUP,
                        )}
                        onChange={(e) =>
                          onChangeDeliveryType(
                            e,
                            OrderDeliveryType.ROUTE_OPT_PICKUP,
                          )
                        }
                      />
                      <Input
                        type="checkbox"
                        checkboxLabel="Route Based Drop-off"
                        checked={temporaryAppFilter.orderDeliveryType.includes(
                          OrderDeliveryType.ROUTE_OPT_DROPOFF,
                        )}
                        onChange={(e) =>
                          onChangeDeliveryType(
                            e,
                            OrderDeliveryType.ROUTE_OPT_DROPOFF,
                          )
                        }
                      />
                      <Input
                        type="checkbox"
                        checkboxLabel="Point-to-Point"
                        checked={temporaryAppFilter.orderDeliveryType.includes(
                          OrderDeliveryType.INDIVIDUAL_PICKUP_AND_DROPOFF,
                        )}
                        onChange={(e) =>
                          onChangeDeliveryType(
                            e,
                            OrderDeliveryType.INDIVIDUAL_PICKUP_AND_DROPOFF,
                          )
                        }
                      />
                    </div>
                  </TabPanelWrapper>
                  <TabPanelWrapper>
                    <>
                      <TabPanelTitle>
                        <div className="flex justify-between">
                          <p className="w-full">Assignees</p>
                          <Input
                            label=""
                            type="text"
                            value={assigneeSearch}
                            placeholder="Search Assignee"
                            onChange={(e) => setAssigneeSearch(e.target.value)}
                            className="max-w-40 w-full h-8 text-xs"
                          />
                        </div>
                      </TabPanelTitle>
                      <div className="mb-8 mt-2 flex flex-col gap-1">
                        {assignees &&
                          mapAssignees(Array.from(assignees.values())).map(
                            (assignee) => (
                              <Input
                                key={assignee.id}
                                type="checkbox"
                                checkboxLabel={assignee.userProfile.name || ""}
                                checked={temporaryAppFilter.assigneeIds.includes(
                                  assignee.userProfile.id,
                                )}
                                onChange={(e) =>
                                  onChangeAssignee(e, assignee.userProfile.id)
                                }
                              />
                            ),
                          )}
                      </div>
                    </>
                  </TabPanelWrapper>
                  <div className="absolute bottom-0 mt-5 flex w-full justify-between border-t bg-white p-2 ">
                    <Button onClick={onReset} appearance="secondary">
                      Reset
                    </Button>
                    <Button onClick={onApply}>Apply</Button>
                  </div>
                </TabPanels>
              </TabGroup>
            </>
          </PopoverPanel>
        </Transition>
      </Popover>
    </div>
  );
}

function TabItem({ children }: { children: React.ReactNode }) {
  return (
    <Tab className="focus:outline-none">
      {({ selected }) => (
        <div
          className={clsx(
            "mx-2 flex flex-col rounded-md p-2 pl-3 text-left text-xs transition ease-in hover:bg-gray-100 hover:text-gray-700 focus:outline-none",
            selected ? "bg-gray-100" : "text-gray-200",
          )}
        >
          {children}
        </div>
      )}
    </Tab>
  );
}

function TabPanelWrapper({ children }: { children: React.ReactNode }) {
  return (
    <TabPanel>
      <div className="relative mx-2 flex  flex-col gap-1 h-[calc(360px-47px)] max-h-[calc(100%-47px)] overflow-y-auto px-2 pb-2 pr-4 text-gray-700">
        {children}
      </div>
    </TabPanel>
  );
}

function TabPanelTitle({ children }: { children: React.ReactNode }) {
  return (
    <div className="sticky top-0 w-full bg-white pt-2 text-sm font-semibold">
      {children}
    </div>
  );
}
