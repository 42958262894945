import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@headlessui/react";
import { useState } from "react";

import { useWorkspaceConnections } from "@web-app/api";
import { Avatar, Button, Icon, Modal } from "@web-app/components";
import { Workspace } from "@web-app/types";

export default function SendToModal({
  onClose,
  onSend,
}: {
  onClose: () => void;
  onSend: (selectedWorkspaceId: string) => Promise<void>;
}) {
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = async () => {
    try {
      setIsLoading(true);
      await onSend(selectedWorkspaceId);
      setIsLoading(false);
      onClose();
    } catch (err) {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      title={
        <>
          <Icon name="fasCircleInfo" className=" text-red-600 mr-2" />
          Send Orders
        </>
      }
      isOpen={true}
      onClose={onClose}
      width={400}
      actionsAlign="end"
      actions={[
        <Button
          type="button"
          isLoading={isLoading}
          onClick={onConfirm}
          className="bg-black-600 hover:bg-black-700 text-white"
        >
          Confirm
        </Button>,
      ]}
    >
      <WorkspaceSelector
        onChange={(workspace) => setSelectedWorkspaceId(workspace.id)}
      />
    </Modal>
  );
}

function WorkspaceSelector({
  onChange,
}: {
  onChange: (workspace: Workspace) => void;
}) {
  const [query, setQuery] = useState("");
  const [selectedWorkspace, setSelectedWorkspace] = useState<Workspace | null>(
    null,
  );
  const { data: workspaceConnections } = useWorkspaceConnections();

  const filteredWorkspaceConnections =
    query === ""
      ? workspaceConnections
      : workspaceConnections?.filter((workspaceConnection) => {
          return workspaceConnection.connectedWorkspace.name
            .toLowerCase()
            .includes(query.toLowerCase());
        });

  const onSelectWorkspace = (workspace: Workspace) => {
    console.log("onselect", workspace);
    setSelectedWorkspace(workspace);
    onChange(workspace);
  };

  return (
    <Combobox
      value={selectedWorkspace}
      immediate
      onChange={onSelectWorkspace}
      onClose={() => setQuery("")}
      as="div"
      className="relative w-full"
    >
      <div className="relative">
        <ComboboxInput
          aria-label="Assignee"
          displayValue={(selectedWorkspace: Workspace) =>
            selectedWorkspace?.name
          }
          placeholder="Select a workspace"
          onChange={(event) => setQuery(event.target.value)}
          className="box-border h-[38px] w-full rounded-md bg-gray-100 px-2 py-3 text-sm leading-5 text-gray-900 outline-none transition ease-in placeholder:text-gray-500 hover:bg-white focus:bg-white focus:ring-2 focus:ring-gray-300 disabled:pointer-events-none disabled:bg-gray-50 disabled:text-gray-400"
        />
        <ComboboxOptions anchor="bottom" className="bg-white w-[350px]">
          <ComboboxOption
            value={{ id: "", name: "None" }}
            className="data-[focus]:bg-blue-100 cursor-pointer"
          >
            <div className="flex gap-x-4 items-center p-2">
              <div className="">
                <p className="text-sm">None</p>
              </div>
            </div>
          </ComboboxOption>
          {(filteredWorkspaceConnections || []).map((workspaceConnection) => (
            <ComboboxOption
              key={workspaceConnection.id}
              value={workspaceConnection.connectedWorkspace}
              className="data-[focus]:bg-blue-100 cursor-pointer"
            >
              <div className="flex gap-x-4 items-center p-2">
                <Avatar
                  name={workspaceConnection.connectedWorkspace.name}
                  size={8}
                  image={workspaceConnection.connectedWorkspace.avatarUrl ?? ""}
                />
                <div className="">
                  <p className="text-sm">
                    {workspaceConnection.connectedWorkspace.name}
                  </p>
                </div>
              </div>
            </ComboboxOption>
          ))}
        </ComboboxOptions>
      </div>
    </Combobox>
  );
}
