import debounce from "lodash/debounce";
import { useMemo, useState } from "react";

import { useCustomFieldsAndGroups } from "@web-app/api";
import { Input } from "@web-app/components";

import { mapCustomFieldsGroupData } from "../../../components/CustomFields/utils";
import type { CustomField } from "../../../types/customFields";
import TabTitle from "../TabTitle";

import CreateCustomFieldsModal from "./CreateCustomFieldsModal";
import CustomFieldGroup from "./CustomFieldsGroup";
import EditCustomFieldModal from "./EditCustomFieldModal";

export default function CustomFieldsTab({
  workspaceId,
}: {
  workspaceId: string;
}) {
  const [selectefCFGId, setSelectedCFGId] = useState<string | null>(null);
  const [selectedCFId, setSelectedCFId] = useState<string | null>(null);
  const [searchText, setSearchText] = useState("");

  const [
    { data: customFields, isSuccess: isSuccessCF, isLoading: isLoadingCF },
    {
      data: customFieldsGroups,
      isSuccess: isSuccessCFG,
      isLoading: isLoadingCFG,
    },
  ] = useCustomFieldsAndGroups();

  const mapedCustomFields: Map<
    string,
    {
      fields: CustomField[];
      name: string;
    }
  > = useMemo(() => {
    if (isSuccessCF && isSuccessCFG) {
      return mapCustomFieldsGroupData(
        customFieldsGroups,
        customFields,
        searchText,
      );
    }

    return new Map();
  }, [customFields, customFieldsGroups, isSuccessCF, isSuccessCFG, searchText]);

  const changeSearchText = debounce((value: string) => {
    setSearchText(value);
  }, 600);

  const selectedCustomField = selectedCFId
    ? customFields!.find((cf) => cf.id === selectedCFId)
    : null;

  if (isLoadingCF || isLoadingCFG) {
    return <div>Loading...</div>;
  }

  return (
    <div className="p-4 px-6 min-h-screen relative overflow-y-auto">
      <TabTitle icon="penField" styles="mb-8" title="Custom Fields" />
      <div className="mb-7">
        <Input
          type="text"
          placeholder="Search fields"
          className="w-full lg:1/2 xl:w-1/4"
          onChange={(e) => {
            changeSearchText(e.target.value);
          }}
        />
      </div>
      {
        <div>
          {mapedCustomFields.size === 0 && (
            <h1 className="text-md font-semibold">No custom fields found</h1>
          )}

          {!!mapedCustomFields.size &&
            Array.from(mapedCustomFields).map(
              (
                [groupId, data]: [
                  string,
                  {
                    fields: CustomField[];
                    name: string;
                  },
                ],
                idx,
              ) => {
                return (
                  <CustomFieldGroup
                    key={groupId}
                    groupName={data.name}
                    fields={data.fields}
                    onClickAdd={() => setSelectedCFGId(groupId)}
                    onClickEdit={(CFId) => setSelectedCFId(CFId)}
                    defaultOpen={idx === 0}
                  />
                );
              },
            )}
        </div>
      }
      {selectefCFGId && (
        <CreateCustomFieldsModal
          workspaceId={workspaceId}
          onClose={() => setSelectedCFGId(null)}
          groupId={selectefCFGId}
        />
      )}
      {selectedCFId && (
        <EditCustomFieldModal
          customField={selectedCustomField!}
          customFieldsGroups={customFieldsGroups || []}
          onClose={() => setSelectedCFId(null)}
        />
      )}
    </div>
  );
}
