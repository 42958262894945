import debounce from "lodash/debounce";

import type { CustomField } from "@web-app/types";

import Input from "../Input";

export function NumberField({
  fieldId,
  customField,
  fieldValues,
  isReadOnly,
  onChange,
  isRequired,
  isDebounce,
}: {
  fieldId: string;
  customField: CustomField;
  fieldValues: CustomField["defaultValues"];
  isReadOnly: boolean;
  isRequired?: boolean;
  isDebounce?: boolean;
  onChange: (
    fieldId: string,
    customField: CustomField,
    value: CustomField["defaultValues"],
    groupId: string,
  ) => void;
}) {
  const onChangeHander = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(
      fieldId,
      customField,
      [
        {
          value: e.target.value,
          isSelected: false,
        },
      ],
      customField.groupId,
    );
  };

  const debouncedOnChangeHandler = debounce(onChangeHander, 1000);

  return (
    <div>
      <Input
        // @ts-ignore - value is a string
        defaultValue={fieldValues[0].value}
        label={customField.name}
        toolTipText={customField.helpText}
        type="number"
        readOnly={isReadOnly}
        onChange={isDebounce ? debouncedOnChangeHandler : onChangeHander}
        min={0}
        onWheel={(e) => e.currentTarget.blur()}
        required={isRequired}
      />
    </div>
  );
}
