import type { FormEvent } from "react";
import { useState } from "react";
import toast from "react-hot-toast";

import { useAppMutation, useApps } from "@web-app/api";
import {
  AvatarInput,
  Button,
  Icon,
  Input,
  Tabs,
  URLField,
} from "@web-app/components";
import {
  App,
  AppEventType,
  type CreateAppPayload,
  SystemApp,
  Workspace,
} from "@web-app/types";

import TabTitle from "../TabTitle";
import Card from "../common/Card";

export default function AppsTab({ workspace }: { workspace: Workspace }) {
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const { data: apps } = useApps(workspace.id);

  return (
    <div className="p-4 px-6">
      {selectedId === "new" && (
        <CreateApp setSelectedId={setSelectedId} workspaceId={workspace.id} />
      )}

      {selectedId && selectedId !== "new" && (
        <EditApp
          app={apps?.find((app) => app.id === selectedId)}
          setSelectedId={setSelectedId}
        />
      )}

      {!selectedId && (
        <>
          <div>
            <TabTitle icon="faApps" title="Apps" styles="mb-3" />

            <p className="text-sm text-gray-400">
              Manage your apps by either adding one or editing an existing one
              below
            </p>
          </div>
          <div className="w-1/2 mt-6">
            <Tabs
              tabs={[
                {
                  title: "Custom Apps",
                  content: (
                    <div className="flex gap-4">
                      <Card
                        icon="fasPlus"
                        text="Add App"
                        onClick={() => setSelectedId("new")}
                      />
                      {apps?.map((app) => {
                        if (app.systemApp === SystemApp.PUBLIC_FORM) {
                          return null;
                        }
                        return (
                          <Card
                            icon="fasNetworkWired"
                            text={app.name}
                            onClick={() => setSelectedId(app.id)}
                            key={app.id}
                          />
                        );
                      })}
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </>
      )}
    </div>
  );
}

function CreateApp({
  workspaceId,
  setSelectedId,
}: {
  workspaceId: string;
  setSelectedId: (id: string | null) => void;
}) {
  const [name, setName] = useState("");
  const [secret, setSecret] = useState("");
  const [callbackUrl, setCallbackUrl] = useState("");

  const { createApp } = useAppMutation();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      name: { value: string };
      secret: { value: string };
      callbackUrl: { value: string };
    };

    const formData: CreateAppPayload = {
      name: target.name.value,
      secret: target.secret.value,
      callbackUrl: target.callbackUrl.value,
      avatarUrl: "",
      workspaceId: workspaceId,
      events: [],
    };

    await toast.promise(createApp.mutateAsync(formData), {
      loading: "Creating App ...",
      success: "Successfully Created App!",
      error: "Something went wrong, please try again!",
    });
    setSelectedId("");
  };

  return (
    <div className="w-full max-w-[500px]">
      <div
        onClick={() => setSelectedId("")}
        className="mb-3 cursor-pointer text-sm text-gray-500"
      >
        <Icon name="fasChevronLeft" className="mr-1" /> Apps
      </div>
      <h1 className="text-xl font-semibold text-gray-700">Create App</h1>
      <div className="mt-3">
        <form onSubmit={onSubmit}>
          <div>
            <Input
              type="text"
              label="Name"
              name="name"
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mt-3">
            <Input
              type="text"
              label="Secret"
              name="secret"
              value={secret}
              required
              onChange={(e) => setSecret(e.target.value)}
            />
          </div>
          <div className="mt-3">
            <Input
              type="text"
              label="Callback URL"
              name="callbackUrl"
              value={callbackUrl}
              required
              onChange={(e) => setCallbackUrl(e.target.value)}
            />
          </div>
          <div className="mt-6 flex gap-2">
            <Button type="submit" isLoading={createApp.isPending}>
              Create
            </Button>
            <Button
              type="button"
              appearance="secondary"
              onClick={() => setSelectedId("")}
              disabled={createApp.isPending}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

function EditApp({
  app,
  setSelectedId,
}: {
  app?: App;
  setSelectedId: (id: string | null) => void;
}) {
  const [name, setName] = useState(app?.name || "");
  const [avatarUrl, setAvatarUrl] = useState(app?.avatarUrl || "");
  const [callbackUrl, setCallbackUrl] = useState(app?.callbackUrl || "");

  const { updateApp } = useAppMutation();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      name: { value: string };
      callbackUrl: { value: string };
      ORDER_CREATED: { checked: boolean };
      ORDER_UPDATED: { checked: boolean };
      ORDER_CANCELLED: { checked: boolean };
      ORDER_IN_TRANSIT_TO_PICKUP: { checked: boolean };
      ORDER_ARRIVED_AT_PICKUP: { checked: boolean };
      ORDER_PICKED_UP: { checked: boolean };
      ORDER_IN_TRANSIT_TO_DROPOFF: { checked: boolean };
      ORDER_ARRIVED_AT_DROPOFF: { checked: boolean };
      ORDER_DROPPED_OFF: { checked: boolean };
      ORDER_ASSIGNED: { checked: boolean };
      ORDER_UNASSIGNED: { checked: boolean };
      ROUTE_CREATED: { checked: boolean };
      ROUTE_UPDATED: { checked: boolean };
    };

    const formData = {
      name: target.name.value,
      callbackUrl: target.callbackUrl.value,
      avatarURL: avatarUrl,
      events: [
        AppEventType.ORDER_CREATED,
        AppEventType.ORDER_UPDATED,
        AppEventType.ORDER_CANCELLED,
        AppEventType.ORDER_IN_TRANSIT_TO_PICKUP,
        AppEventType.ORDER_ARRIVED_AT_PICKUP,
        AppEventType.ORDER_PICKED_UP,
        AppEventType.ORDER_IN_TRANSIT_TO_DROPOFF,
        AppEventType.ORDER_ARRIVED_AT_DROPOFF,
        AppEventType.ORDER_DROPPED_OFF,
        AppEventType.ORDER_ASSIGNED,
        AppEventType.ORDER_UNASSIGNED,
        AppEventType.ROUTE_CREATED,
        AppEventType.ROUTE_UPDATED,
      ].reduce((acc, key) => {
        if (target[key].checked) {
          acc.push(key);
        }
        return acc;
      }, [] as AppEventType[]),
    };

    await toast.promise(
      updateApp.mutateAsync({
        id: app!.id,
        payload: formData,
      }),
      {
        loading: "Updating App ...",
        success: "Successfully Updated App!",
        error: "Something went wrong, please try again!",
      },
    );
  };

  const handleCancel = () => {
    setSelectedId(null);
  };

  if (!app) return null;

  return (
    <div className="max-w-lg">
      <div
        onClick={() => setSelectedId("")}
        className="mb-4 cursor-pointer text-sm text-gray-500"
      >
        <Icon name="fasChevronLeft" className="mr-1" /> Apps
      </div>
      <h1 className="mb-3 text-lg font-semibold">{app.name}</h1>
      <div className="mt-3">
        <form onSubmit={onSubmit}>
          <div className="mb-8">
            <AvatarInput
              imageUrl={avatarUrl || ""}
              setImageUrl={setAvatarUrl}
              name={name || ""}
            />
          </div>
          <div className="mt-6">
            <Input
              type="text"
              label="Name"
              name="name"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mt-3">
            <URLField label="App ID" url={app.id} openInNewTab={false} />
          </div>
          <h2 className="mt-7 text-sm font-semibold text-gray-600">
            Webhook Settings
          </h2>
          <div className="mt-3">
            <Input
              type="text"
              label="Callback URL"
              name="callbackUrl"
              value={callbackUrl}
              onChange={(e) => setCallbackUrl(e.target.value)}
              toolTipText="Provide the callback URL which will receive POST events from FULFLLD. You may include path and query parameters"
            />
          </div>
          <div className="mt-4">
            <h2 className="text-sm font-semibold text-gray-600">Events</h2>
            <p className="text-xs text-gray-500 leading-2 my-4">
              Your webhook can subscribe to the following events. Check all
              events for which you want your webhook to be triggered
            </p>
            <div>
              <span className="text-xs font-semibold text-gray-600 mb-2 flex">
                Order
              </span>
              <Input
                type="checkbox"
                name="ORDER_CREATED"
                checkboxLabel={
                  <span>
                    <b>ORDER_CREATED</b> : Whenever an order is created, this
                    webhook event is triggered.
                  </span>
                }
                defaultChecked={app.events?.includes(
                  AppEventType.ORDER_CREATED,
                )}
              />
              <Input
                type="checkbox"
                name="ORDER_UPDATED"
                checkboxLabel={
                  <span>
                    <b>ORDER_UPDATED</b> : Whenever an order is updated, this
                    webhook event is triggered.
                  </span>
                }
                defaultChecked={app.events?.includes(
                  AppEventType.ORDER_UPDATED,
                )}
              />
              <Input
                type="checkbox"
                name="ORDER_CANCELLED"
                checkboxLabel={
                  <span>
                    <b>ORDER_CANCELLED</b> : Whenever an order is cancelled,
                    this webhook event is triggered.
                  </span>
                }
                defaultChecked={app.events?.includes(
                  AppEventType.ORDER_CANCELLED,
                )}
              />
              <Input
                type="checkbox"
                name="ORDER_ASSIGNED"
                checkboxLabel={
                  <span>
                    <b>ORDER_ASSIGNED</b> : When the order is assigned to a
                    driver, this event is triggered.
                  </span>
                }
                defaultChecked={app.events?.includes(
                  AppEventType.ORDER_ASSIGNED,
                )}
              />
              <Input
                type="checkbox"
                name="ORDER_UNASSIGNED"
                checkboxLabel={
                  <span>
                    <b>ORDER_UNASSIGNED</b> : When the order is unassigned, this
                    event is triggered.
                  </span>
                }
                defaultChecked={app.events?.includes(
                  AppEventType.ORDER_UNASSIGNED,
                )}
              />
              <Input
                type="checkbox"
                name="ORDER_IN_TRANSIT_TO_PICKUP"
                checkboxLabel={
                  <span>
                    <b>ORDER_IN_TRANSIT_TO_PICKUP</b> : This indicates that the
                    driver has initiated to travel to the pickup address. This
                    is an explicit action that the driver marks in the mobile
                    app.
                  </span>
                }
                defaultChecked={app.events?.includes(
                  AppEventType.ORDER_IN_TRANSIT_TO_PICKUP,
                )}
              />
              <Input
                type="checkbox"
                name="ORDER_ARRIVED_AT_PICKUP"
                checkboxLabel={
                  <span>
                    <b>ORDER_ARRIVED_AT_PICKUP</b> : This indicates that the
                    driver has arrived near the pickup address.
                  </span>
                }
                defaultChecked={app.events?.includes(
                  AppEventType.ORDER_ARRIVED_AT_PICKUP,
                )}
              />
              <Input
                type="checkbox"
                name="ORDER_PICKED_UP"
                checkboxLabel={
                  <span>
                    <b>ORDER_PICKED_UP</b> : This indicates that the driver has
                    picked up the order from the pickup address. This is an
                    explicit action that the driver marks in the mobile app.
                  </span>
                }
                defaultChecked={app.events?.includes(
                  AppEventType.ORDER_PICKED_UP,
                )}
              />
              <Input
                type="checkbox"
                name="ORDER_IN_TRANSIT_TO_DROPOFF"
                checkboxLabel={
                  <span>
                    <b>ORDER_IN_TRANSIT_TO_DROPOFF</b> : This indicates that the
                    driver has initiated to travel to the dropoff address.
                  </span>
                }
                defaultChecked={app.events?.includes(
                  AppEventType.ORDER_IN_TRANSIT_TO_DROPOFF,
                )}
              />
              <Input
                type="checkbox"
                name="ORDER_ARRIVED_AT_DROPOFF"
                checkboxLabel={
                  <span>
                    <b>ORDER_ARRIVED_AT_DROPOFF</b> : This indicates that the
                    driver has arrived near the dropoff address.
                  </span>
                }
                defaultChecked={app.events?.includes(
                  AppEventType.ORDER_ARRIVED_AT_DROPOFF,
                )}
              />
              <Input
                type="checkbox"
                name="ORDER_DROPPED_OFF"
                checkboxLabel={
                  <span>
                    <b>ORDER_DROPPED_OFF</b> : This indicates that the driver
                    has dropped off the order in the dropoff address. This is an
                    explicit action that the driver marks in the mobile app.
                  </span>
                }
                defaultChecked={app.events?.includes(
                  AppEventType.ORDER_DROPPED_OFF,
                )}
              />
            </div>

            <div className="mt-4">
              <span className="text-xs font-semibold text-gray-600 mb-2 flex">
                Route
              </span>
              <Input
                type="checkbox"
                name="ROUTE_CREATED"
                checkboxLabel={
                  <span>
                    <b>ROUTE_CREATED</b> : Whenever a route is created, this
                    webhook event is triggered.
                  </span>
                }
                defaultChecked={app.events?.includes(
                  AppEventType.ROUTE_CREATED,
                )}
              />
              <Input
                type="checkbox"
                name="ROUTE_UPDATED"
                checkboxLabel={
                  <span>
                    <b>ROUTE_UPDATED</b> : Whenever a route is updated, this
                    webhook event is triggered.
                  </span>
                }
                defaultChecked={app.events?.includes(
                  AppEventType.ROUTE_UPDATED,
                )}
              />
            </div>
          </div>
          <div className="mt-8 flex justify-between">
            <div className="flex gap-2">
              <Button type="submit">Update</Button>
            </div>
            <Button type="button" appearance="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
