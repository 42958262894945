export default function HighlightString({
  text,
  searchTerm,
}: {
  text: string;
  searchTerm: string | undefined;
}) {
  if (!searchTerm) {
    return <span>{text}</span>;
  }

  const regex = new RegExp(`(${searchTerm})`, "gi");
  const parts = text.split(regex);

  return (
    <span className="truncate">
      {parts.map((part, index) =>
        regex.test(part) ? (
          <mark key={index}>{part}</mark>
        ) : (
          <span key={index}>{part}</span>
        ),
      )}
    </span>
  );
}
