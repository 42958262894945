import { InfoWindow } from "@react-google-maps/api";
import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";

import { Avatar, Button, Icon } from "@web-app/components";
import useDispatchStore from "@web-app/store/dispatchStore";
import { Order } from "@web-app/types";

export default function ClusterModal({
  selectedClusterLocation,
  clusterOrders,
  onClickOrderMarker,
  closeClusterModal,
}: {
  selectedClusterLocation: {
    lat: number;
    lng: number;
  };
  clusterOrders: string[];
  onClickOrderMarker: (order: Order) => void;
  closeClusterModal: () => void;
}) {
  const { selectedOrderIds, focusedOrderId, appFilter } = useDispatchStore();

  const queryClient = useQueryClient();
  const orders = queryClient.getQueryData<Map<string, Order>>([
    "orders",
    appFilter,
  ]);

  const getAddress = (order: Order) => {
    if (order.type === "INDIVIDUAL_PICKUP_AND_DROPOFF") {
      return `${order.pickup.address} - ${order.dropoff.address}`;
    } else if (order.type === "ROUTE_OPT_DROPOFF") {
      return `${order.dropoff.address}`;
    } else if (order.type === "ROUTE_OPT_PICKUP") {
      return `${order.pickup.address}`;
    }
  };

  return (
    <InfoWindow
      position={{
        lat: selectedClusterLocation.lat,
        lng: selectedClusterLocation.lng,
      }}
      options={{
        maxWidth: 700,
      }}
    >
      <div>
        <div className="relative">
          <div className="flex w-[700px] flex-col">
            <div className="flex items-center justify-between p-3 pt-5">
              <div className="sm:flex-auto">
                <h1 className="text-base font-semibold leading-6 text-gray-900">
                  {clusterOrders.length} Orders
                </h1>
                <p className="mt-1 text-sm text-gray-700">
                  Select an order to view in detail or create a route using
                  incomplete orders.
                </p>
              </div>
              <div className="flex justify-center items-center fixed top-6 right-4">
                <Button
                  type="button"
                  className="w-5 px-0 bg-transparent hover:bg-transparent rounded-none h-5"
                  onClick={closeClusterModal}
                >
                  <Icon name="xMark" className="text-black h-5 w-5" />
                </Button>
              </div>
            </div>
            <div className="h-[250px] overflow-auto">
              <table className="w-full border-separate border-spacing-0">
                <thead className="">
                  <tr className="">
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Delivery ID
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Assignee
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Address
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {clusterOrders.map((orderId, idx) => {
                    if (!orders) {
                      return <></>;
                    }

                    const order = orders.get(orderId);
                    if (!order) {
                      return <></>;
                    }
                    return (
                      <tr
                        key={idx}
                        onClick={() => onClickOrderMarker(order)}
                        className={clsx(
                          "cursor-pointer border-b transition ease-in hover:bg-gray-200",
                          (selectedOrderIds.has(order.id) ||
                            focusedOrderId === order.id) &&
                            "!bg-black text-white hover:!bg-black",
                        )}
                      >
                        <td
                          className={clsx(
                            idx !== clusterOrders.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            selectedOrderIds.has(order.id) ||
                              focusedOrderId === order.id
                              ? "text-white"
                              : "text-gray-900",
                            "whitespace-nowrap py-4 pl-3 pr-3 text-sm font-medium text-gray-900",
                          )}
                        >
                          {order.displayId}
                        </td>
                        <td
                          className={clsx(
                            idx !== clusterOrders.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                          )}
                        >
                          {order.deliveryTypeText}
                        </td>
                        <td
                          className={clsx(
                            idx !== clusterOrders.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                          )}
                        >
                          <div className="flex items-center gap-2">
                            {order.assignee && (
                              <div className="h-7 w-7 flex-shrink-0">
                                <Avatar
                                  size={7}
                                  name={order.assignee?.name ?? ""}
                                  image={order.assignee?.image ?? ""}
                                />
                              </div>
                            )}
                            <div className="">
                              <div>{order.assignee?.name ?? "Unassigned"}</div>
                            </div>
                          </div>
                        </td>
                        <td
                          className={clsx(
                            idx !== clusterOrders.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "text-ellipsis px-3 py-4 text-sm text-gray-500",
                          )}
                        >
                          {getAddress(order)}
                        </td>
                        <td
                          className={clsx(
                            idx !== clusterOrders.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap px-3 py-4 text-sm text-gray-500",
                          )}
                        >
                          {order.statusText}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </InfoWindow>
  );
}
