import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { SmsConfigType } from "@web-app/types/notification";

import { get, update } from "../services/notification";

const queryKey = "notificationConfig";

export const useNotificationConfig = (workspaceId: string) => {
  return useQuery({
    queryKey: [`${queryKey}`],
    queryFn: async () => {
      const response = await get(workspaceId);
      return response;
    },
    refetchOnWindowFocus: false,
  });
};

export const useNotificationConfigMutation = (workspaceId: string) => {
  const queryClient = useQueryClient();

  const updateNotificationConfig = useMutation({
    mutationFn: async (payload: SmsConfigType) => {
      const response = await update(workspaceId, payload);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey] });
    },
  });

  return {
    updateNotificationConfig,
  };
};
