import { z } from "zod";

import { OrderDeliveryType, SavedAddress } from "@web-app/types";
import { pickupChecklistSchema } from "@web-app/utils/order";

export const orderTypeOptions = (
  setOrderType: (val: OrderDeliveryType) => void,
) => [
  {
    id: "1",
    name: "Pickup Only",
    description: "Pick-up orders in a route",
    onChange: () => setOrderType(OrderDeliveryType.ROUTE_OPT_PICKUP),
  },
  {
    id: "2",
    name: "Drop-off Only",
    description: "Drop-off orders in a route",
    onChange: () => setOrderType(OrderDeliveryType.ROUTE_OPT_DROPOFF),
  },
];

export const generateOrderFormDefaultValue = (
  selectedPickupAddress: SavedAddress | null,
  selectedDropoffAddress: SavedAddress | null,
) => ({
  connectedWorkspaceId: "",
  description: "",
  dropOffBusinessName: selectedDropoffAddress?.businessName || "",
  dropoffLocation: selectedDropoffAddress?.address || null,
  dropOffName: selectedDropoffAddress?.name || "",
  dropOffNote: selectedDropoffAddress?.note || "",
  dropOffPhone: selectedDropoffAddress?.phone || "",
  dropOffStartTime: new Date(),
  externalId: "",
  itemsCount: "0",
  isOrderSourceAConnectedWorkspace: false,
  orderRequirements_NOTES: false,
  orderRequirements_PHOTO: false,
  orderRequirements_SIGNATURE: false,
  orderRequirements_ITEMCHECKLIST: false,
  pickupBusinessName: selectedPickupAddress?.businessName || "",
  pickupEndTime: new Date(),
  pickupLocation: selectedPickupAddress?.address || null,
  pickupName: selectedPickupAddress?.name || "",
  pickupNote: selectedPickupAddress?.note || "",
  pickupPhone: selectedPickupAddress?.phone || "",
  scheduledEndDateTime: new Date(),
  scheduledStartDateTime: new Date(),
  serviceTime: undefined,
  serviceTimeDropoff: "",
  tip: "0",
  value: "0",
  vehicleType: undefined,
  pickupChecklist: [] as z.infer<typeof pickupChecklistSchema>,
});
