import { Attachment } from "./attachments";
import { DriverEntity, DutyStatus } from "./drivers";
import { OrderSideBarEntity } from "./orderSidebarEntity";
import { OrderTimeline } from "./orderTimelines";
import { Order } from "./orders";
import "./routes";
import { Route, RoutePolylineType } from "./routes";
import { User } from "./users";

export * from "./apps";
export * from "./customFieldGroups";
export * from "./customFields";
export * from "./featureflags";
export * from "./google";
export * from "./orders";
export * from "./routes";
export * from "./savedAddress";
export * from "./users";
export * from "./workspaces";

export { type Attachment, type OrderTimeline };

export type Base = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
};

export enum UserRole {
  ADMIN = "ADMIN",
  DISPATCHER = "DISPATCHER",
  BEAST = "BEAST",
  MEMBER = "MEMBER",
  DRIVER = "DRIVER",
}

export enum OrderDeliveryType {
  ROUTE_OPT_PICKUP = "ROUTE_OPT_PICKUP",
  ROUTE_OPT_DROPOFF = "ROUTE_OPT_DROPOFF",
  INDIVIDUAL_PICKUP = "INDIVIDUAL_PICKUP",
  INDIVIDUAL_DROPOFF = "INDIVIDUAL_DROPOFF",
  INDIVIDUAL_PICKUP_AND_DROPOFF = "INDIVIDUAL_PICKUP_AND_DROPOFF",
}

export enum TransportType {
  WALK = "WALK",
  CYCLE = "CYCLE",
  BIKE = "BIKE",
  SEDAN = "SEDAN",
  ESTATE = "ESTATE",
  VAN = "VAN",
  PICK_UP = "PICK_UP",
  SUV = "SUV",
  TRUCK = "TRUCK",
  OTHER = "OTHER",
}

export type AppFilterType = {
  scheduledDateTimeStart: string;
  scheduledDateTimeEnd: string;
  orderStatus: Order["status"][];
  driverStatus: DutyStatus[];
  orderDeliveryType: Order["type"][];
  workspaceIds: string[];
  assigneeIds: string[];
};

export enum TableHeadersName {
  ActualDropOffTime = "Actual Drop-off Time",
  ActualPickupTime = "Actual Pickup Time",
  Assignee = "Assignee",
  Connections = "Connections",
  DeliveryType = "Delivery Type",
  Description = "Description",
  Distance = "Distance",
  DropoffAddress = "Drop-off Address",
  DropoffArrivalTime = "Drop-off Arrival Time",
  ExternalId = "External ID",
  InTransitToPickupTime = "In Transit to Pickup Time",
  ItemsCount = "Item Count",
  NotesRequirement = "Notes Requirement",
  OrderId = "Order ID",
  PickupAddress = "Pickup Address",
  PickupArrivalTime = "Pickup Arrival Time",
  PickupName = "Pickup Name",
  PickupNote = "Pickup Note",
  PickupBusinessName = "Pickup Business Name",
  PickupPhone = "Pickup Phone",
  Priority = "Priority",
  ProofOfDeliveryRequirement = "Proof of Delivery Requirement",
  RecipientName = "Recipient Name",
  RecipientNote = "Recipient Note",
  RecipientPhone = "Recipient Phone",
  RecipientBusinessName = "Recipient Business Name",
  ScheduledEndTime = "Scheduled End Time",
  ScheduledStartTime = "Scheduled Start Time",
  ServiceTime = "Service Time",
  SignatureRequirement = "Signature Requirement",
  Status = "Status",
  StatusNotes = "Status Notes",
  StatusReason = "Status Reason",
  Tip = "Tip",
  Value = "Value",
  VehicleType = "Vehicle Type",
}

export {
  DutyStatus,
  type DriverEntity,
  type Order,
  type OrderSideBarEntity,
  type Route,
  type RoutePolylineType,
  type User,
};
