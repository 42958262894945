import { useQuery } from "@tanstack/react-query";

import useDispatchStore from "@web-app/store/dispatchStore";

import { get } from "../services/drivers";

export const useGetDrivers = (props?: { refetchInterval: number }) => {
  const { user } = useDispatchStore();

  return useQuery({
    queryKey: ["drivers"],
    queryFn: async () => {
      const response = await get(user!.currentWorkspaceId);
      return response;
    },
    staleTime: 0,
    enabled: !!user?.currentWorkspaceId,
    refetchInterval: props?.refetchInterval,
  });
};
