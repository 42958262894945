import { Transition, TransitionChild } from "@headlessui/react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { useQueryClient } from "@tanstack/react-query";
import {
  Duration,
  addMinutes,
  differenceInMinutes,
  format,
  intervalToDuration,
} from "date-fns";
import { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";

import { Button, Input, SelectInput } from "@web-app/components";
import useDispatchStore from "@web-app/store/dispatchStore";
import { Order } from "@web-app/types";
import http from "@web-app/utils/http";

import type { DoordashQuotation } from "../../../../components/OrderCard";
import Route from "../../DispatchMap/Route";
import { options } from "../../DispatchMap/utils";

const DeliveryStatusMap: { [key: string]: string } = {
  quote: "Quote",
  created: "Created",
  confirmed: "Confirmed",
  enroute_to_pickup: "Enroute to pickup",
  arrived_at_pickup: "Arrived at pickup",
  picked_up: "Picked up",
  enroute_to_dropoff: "Enroute to drop-off",
  arrived_at_dropoff: "Arrived at drop-off",
  delivered: "Delivered",
  enroute_to_return: "Enroute to return",
  arrived_at_return: "Arrived at return",
  returned: "Returned",
  cancelled: "Cancelled",
};

export function OrderDoordashDetails({
  order,
  onClose,
  deliveryData,
}: {
  order: Order;
  onClose: () => void;
  deliveryData: DoordashQuotation | null;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const queryClient = useQueryClient();
  const { appFilter } = useDispatchStore();
  const [doordashQuotation, setDoordashQuotation] = useState<DoordashQuotation>(
    {
      external_delivery_id: "",
      fee: 0,
      pickup_time_estimated: "",
      dropoff_time_estimated: "",
      message: "",
      delivery_status: "",
      dasher_id: "",
      dasher_name: "",
      tracking_url: "",
    },
  );
  const [quotationTime, setQuotationTime] = useState<Date>(new Date());
  const [timeLeft, setTimeLeft] = useState<Duration>({
    minutes: 0,
    seconds: 0,
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDeliveryInProgress, setIsDeliveryInProgress] = useState(false);
  const [isDeliveryCancellable, setIsDeliveryCancellable] = useState(true);
  const [isOrderAlreadySent, setIsOrderAlreadySent] = useState(false);
  const [undeliverableAction, setUndeliverableAction] = useState("dispose");
  const [isAlcoholDelivery, setIsAlcoholDelivery] = useState(false);

  useEffect(() => {
    if (deliveryData) {
      setIsOrderAlreadySent(true);
    }

    if (
      !deliveryData ||
      ["quote", "cancelled"].includes(deliveryData.delivery_status)
    ) {
      fetchdoordashQuotation();
    } else {
      setDoordashQuotation(deliveryData);
      setIsLoading(false);
      setIsDeliveryInProgress(true);
      if (deliveryData.dasher_id) {
        setIsDeliveryCancellable(false);
      }
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedTimeLeft = doordashQuotation.fee
        ? intervalToDuration({
            start: new Date(),
            end: quotationTime,
          })
        : { minutes: 0, seconds: 0 };

      if (updatedTimeLeft.minutes === 0 && updatedTimeLeft.seconds === 0) {
        clearInterval(interval);
        setIsDisabled(true);
      }

      setTimeLeft(updatedTimeLeft);
    }, 1000);

    return () => clearInterval(interval);
  }, [doordashQuotation]);

  const fetchdoordashQuotation = async () => {
    setIsProcessing(true);
    const data = await toast
      .promise(
        http.post(`/integrations/doordash/quote?orderId=${order.id}`, {
          undeliverableAction,
          isAlcoholDelivery,
        }),
        {
          loading: "Fetching Doordash quotation...",
          success: "Doordash quotation fetched",
          error: (err) => {
            onClose();
            return (
              `Doordash: ${err.response?.data?.message}` ||
              "Failed to fetch Doordash quotation"
            );
          },
        },
      )
      .then((response) => response.data);

    setDoordashQuotation(data);
    setQuotationTime(addMinutes(new Date(), 5));
    setIsLoading(false);
    setIsProcessing(false);
    setIsDisabled(false);
  };

  const cancelDelivery = async () => {
    setIsProcessing(true);
    const externalAssigneeId = order.externalAssigneeId?.split("_")[1];
    const data = await toast
      .promise(
        http.put(
          `/integrations/doordash/deliveries/${externalAssigneeId}/cancel`,
        ),
        {
          loading: "Cancelling delivery...",
          success: "Doordash delivery Cancelled",
          error: (err) => {
            onClose();
            return (
              `Doordash: ${err.response?.data?.message}` ||
              "Doordash: Delivery can't be cancelled as it is already in progress"
            );
          },
        },
      )
      .then((response) => response.data);

    if (data.delivery_status === "cancelled") {
      onClose();
    }
  };

  const handleAcceptQuote = async () => {
    setIsProcessing(true);
    const response = await toast.promise(
      http.post(
        `/integrations/doordash/quote/${doordashQuotation.external_delivery_id}/accept?orderId=${order.id}`,
      ),
      {
        loading: "Sending order to Doordash...",
        success: "Order sent to Doordash",
        error: (err) => {
          onClose();
          return (
            `Doordash: ${err.response?.data?.message}` ||
            "Doordash: Failed to send order to Doordash"
          );
        },
      },
    );

    if (response.status === 200) {
      queryClient.invalidateQueries({ queryKey: ["orders", appFilter] });
      setIsProcessing(false);
      onClose();
    }
  };

  const pickupTime = new Date(doordashQuotation.pickup_time_estimated);
  const dropoffEtaTime = new Date(doordashQuotation.dropoff_time_estimated);
  const duration = differenceInMinutes(dropoffEtaTime, pickupTime);

  return (
    <Transition show as={Fragment}>
      <div className="relative z-20 w-[calc(100%-480px)]">
        <div className="fixed inset-0 w-[calc(100%-480px)] overflow-auto">
          <div className="absolute inset-0 overflow-auto">
            <div className="pointer-events-none flex w-full">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-out duration-500 sm:duration-600"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-out duration-500 sm:duration-600"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="pointer-events-auto w-full pl-0 lg:pl-20">
                  <div className="flex h-full min-h-screen w-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="custom-scrollbar flex min-h-screen w-full flex-1 flex-col overflow-y-auto pt-6">
                      <div className="sticky top-0 px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <div className="text-2xl font-semibold leading-6 text-gray-900">
                            Send Order to Doordash
                          </div>
                        </div>
                      </div>

                      <div>
                        <GoogleMap
                          mapContainerClassName="h-[350px] max-w-[95%] mx-auto mt-10 rounded-md mx-6"
                          zoom={12}
                          options={options}
                          onLoad={(map) => {
                            const bounds =
                              new window.google.maps.LatLngBounds();
                            bounds.extend({
                              lat: order.pickupLocation.coordinates[1],
                              lng: order.pickupLocation.coordinates[0],
                            });
                            bounds.extend({
                              lat: order.dropoffLocation.coordinates[1],
                              lng: order.dropoffLocation.coordinates[0],
                            });
                            map.fitBounds(bounds);
                          }}
                        >
                          <Marker
                            position={{
                              lat: order.pickupLocation.coordinates[1],
                              lng: order.pickupLocation.coordinates[0],
                            }}
                            icon={{
                              url: `/images/markers-updated/${order.status}.png`,
                              scaledSize: {
                                width: 30,
                                height: 30,
                                equals() {
                                  return true;
                                },
                              },
                            }}
                          />
                          <Marker
                            position={{
                              lat: order.dropoffLocation.coordinates[1],
                              lng: order.dropoffLocation.coordinates[0],
                            }}
                            icon={{
                              url: `/images/markers-updated/${order.status}.png`,
                              scaledSize: {
                                width: 30,
                                height: 30,
                                equals() {
                                  return true;
                                },
                              },
                            }}
                          />
                          <Route
                            order={order}
                            isHovered={true}
                            setHoveredPolylineElementId={() => {}}
                          />
                        </GoogleMap>
                      </div>
                      {isLoading && (
                        <div className="flex justify-center mt-5">
                          <div className="bg-white w-full p-5 rounded-md">
                            <div className="text-center text-gray-500">
                              Loading...
                            </div>
                          </div>
                        </div>
                      )}
                      {!isLoading && doordashQuotation.message && (
                        <div className="flex justify-center mt-5">
                          <div className="bg-white w-full p-5 rounded-md">
                            <div className="text-md text-gray-500">Error</div>
                            <div className="text-xs">
                              {doordashQuotation.message}
                            </div>
                          </div>
                        </div>
                      )}
                      {!isLoading && !doordashQuotation.message && (
                        <div className="w-full flex justify-center mt-5">
                          <div className="bg-white w-full p-5 rounded-md">
                            <div className="flex justify-between">
                              <div className="text-sm">
                                <div className="mt-5 flex gap-20">
                                  <div>
                                    <div className="text-gray-500 mb-2">
                                      Cost
                                    </div>
                                    <div className="text-gray-700 font-semibold">
                                      ${doordashQuotation.fee / 100}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-gray-500 mb-2">
                                      Duration
                                    </div>
                                    <div className="text-gray-700 font-semibold">
                                      {duration} minutes
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-gray-500 mb-2">
                                      Delivery Status
                                    </div>
                                    <div className="text-gray-700 font-semibold">
                                      {
                                        DeliveryStatusMap[
                                          doordashQuotation.delivery_status
                                        ]
                                      }
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-gray-500 mb-2">
                                      Driver Arrival ETA
                                    </div>
                                    <div className="text-gray-700 font-semibold">
                                      {format(
                                        pickupTime,
                                        "MM/dd/yyyy' 'hh:mm aa",
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-gray-500 mb-2">
                                      Driver Drop-off ETA
                                    </div>
                                    <div className="text-gray-700 font-semibold">
                                      {format(
                                        dropoffEtaTime,
                                        "MM/dd/yyyy' 'hh:mm aa",
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-5 flex gap-20">
                                  <div>
                                    <div className="text-gray-500 mb-2">
                                      Dasher Id
                                    </div>
                                    <div className="text-gray-700 font-semibold">
                                      {doordashQuotation.dasher_id ||
                                        "Information not available"}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-gray-500 mb-2">
                                      Dasher Name
                                    </div>
                                    <div className="text-gray-700 font-semibold">
                                      {doordashQuotation.dasher_name ||
                                        "Information not available"}
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-5 flex gap-20">
                                  <div>
                                    <div className="text-gray-500 mb-2">
                                      Tracking URL
                                    </div>
                                    <div className="text-gray-700 font-semibold">
                                      {doordashQuotation.tracking_url ? (
                                        <a
                                          href={doordashQuotation.tracking_url}
                                          className="block underline text-blue-700 font-semibold"
                                          target="_blank"
                                        >
                                          {doordashQuotation.tracking_url}
                                        </a>
                                      ) : (
                                        <div className="text-gray-700 font-semibold">
                                          "Not Available"
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {!isOrderAlreadySent && (
                                  <div className="mt-5 flex gap-20">
                                    <SelectInput
                                      options={[
                                        {
                                          label: "Dispose",
                                          value: "dispose",
                                        },
                                        {
                                          label: "Return to pickup",
                                          value: "return_to_pickup",
                                        },
                                      ]}
                                      selected={undeliverableAction}
                                      required={true}
                                      onChange={(value) => {
                                        setUndeliverableAction(value);
                                      }}
                                      label="Undeliverable action"
                                      placeholder="Undeliverable Action"
                                    />
                                  </div>
                                )}
                                {!isOrderAlreadySent && (
                                  <div className="mt-5 flex gap-20">
                                    <Input
                                      type={"checkbox"}
                                      onChange={() => {
                                        setIsAlcoholDelivery(
                                          () => !isAlcoholDelivery,
                                        );
                                      }}
                                      checkboxLabel={"Contains alcohol"}
                                    />
                                  </div>
                                )}
                              </div>
                              <div className="inline-block bg-gray-100 p-3 place-content-center text-center rounded-md">
                                {!isDeliveryInProgress && (
                                  <div>
                                    <div className="text-gray-500 text-xs">
                                      This quotation is valid until
                                    </div>
                                    <div className="text-blue-400 text-md font-semibold">
                                      {timeLeft.minutes}:{timeLeft.seconds}{" "}
                                      Minutes
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="sticky bottom-0 left-0 grid w-full  justify-items-end bg-gray-200 pr-3">
                      <div className="flex gap-5 p-2">
                        {!isDeliveryInProgress && (
                          <Button
                            type="button"
                            className="bg-green-600"
                            isLoading={isProcessing}
                            disabled={isDisabled}
                            onClick={() => handleAcceptQuote()}
                          >
                            Accept Quote
                          </Button>
                        )}
                        {!isDeliveryInProgress && (
                          <Button
                            type="button"
                            className="bg-gray-300 !text-gray-500"
                            isLoading={isProcessing}
                            onClick={() => {
                              fetchdoordashQuotation();
                            }}
                          >
                            Re-Request
                          </Button>
                        )}
                        {isDeliveryInProgress && (
                          <Button
                            type="button"
                            className="bg-red-500"
                            isLoading={isProcessing}
                            onClick={() => {
                              cancelDelivery();
                            }}
                            disabled={!isDeliveryCancellable}
                          >
                            Cancel Delivery
                          </Button>
                        )}
                        <Button
                          type="button"
                          isLoading={isProcessing}
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
