import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import Sidebar from "../Sidebar";

export default function SidebarWithDrag({
  children,
  isOpen = true,
}: {
  children: JSX.Element;
  isOpen: boolean;
}) {
  return (
    <DndProvider backend={HTML5Backend}>
      <Sidebar isOpen={isOpen}>{children}</Sidebar>
    </DndProvider>
  );
}
