import { byPrefixAndName } from "@awesome.me/kit-dde2cd9f7f/icons";

export const Icons = {
  user: byPrefixAndName.fal["user"],
  earchAmericas: byPrefixAndName.fal["earch-americas"],
  fileImage: byPrefixAndName.fal["file-image"],
  circleExclamation: byPrefixAndName.fal["circle-exclamation"],
  circle: byPrefixAndName.far["circle"],
  copy: byPrefixAndName.fas["copy"],
  locationDot: byPrefixAndName.fal["location-dot"],
  notes: byPrefixAndName.fal["notes"],
  flagSwallowtail: byPrefixAndName.fal["flag-swallowtail"],
  flag: byPrefixAndName.fas["flag"],
  font: byPrefixAndName.fal["font"],
  form: byPrefixAndName.fab["wpforms"],
  mobile: byPrefixAndName.fal["mobile"],
  clock: byPrefixAndName.fal["clock"],
  signature: byPrefixAndName.fal["signature"],
  images: byPrefixAndName.fal["images"],
  subtitles: byPrefixAndName.fal["subtitles"],
  moneyBill1: byPrefixAndName.fal["money-bill-1"],
  truck: byPrefixAndName.fal["truck"],
  locationPin: byPrefixAndName.fal["location-pin"],
  timeline: byPrefixAndName.fal["timeline"],
  listTree: byPrefixAndName.fal["list-tree"],
  list: byPrefixAndName.fal["list-tree"], // TODO: add the actual list icon to kit
  locationCrossHairs: byPrefixAndName.fal["location-crosshairs"],
  ellipsis: byPrefixAndName.fal["ellipsis"],
  rightFromBracket: byPrefixAndName.fal["right-from-bracket"],
  rightToBracket: byPrefixAndName.fal["right-to-bracket"],
  clockRotateLeft: byPrefixAndName.fal["clock-rotate-left"],
  circleXMark: byPrefixAndName.fal["circle-xmark"],
  solidCircleXMark: byPrefixAndName.fas["circle-xmark"],
  xMark: byPrefixAndName.fal["xmark"],
  globe: byPrefixAndName.fal["globe"],
  users: byPrefixAndName.fal["users"],
  terminal: byPrefixAndName.fal["terminal"],
  bars: byPrefixAndName.fal["bars"],
  mapMarker: byPrefixAndName.fal["map-marker"],
  table: byPrefixAndName.fal["table"],
  questionCircle: byPrefixAndName.fal["question-circle"],
  house: byPrefixAndName.fal["house"],
  sHouse: byPrefixAndName.fas["house"],
  sTable: byPrefixAndName.fas["table"],
  plus: byPrefixAndName.fal["plus"],
  route: byPrefixAndName.fal["route"],
  drawPolygon: byPrefixAndName.fal["draw-polygon"],
  ban: byPrefixAndName.fal["ban"],
  fileExport: byPrefixAndName.fal["file-export"],
  upload: byPrefixAndName.fal["upload"],
  circleCheck: byPrefixAndName.fas["circle-check"],
  chevronRight: byPrefixAndName.far["chevron-right"],
  arrowRight: byPrefixAndName.far["arrow-right"],
  arrowTurnDownRight: byPrefixAndName.far["arrow-turn-down-right"],
  clipboardCheck: byPrefixAndName.far["clipboard-check"],
  boxesPacking: byPrefixAndName.far["boxes-packing"],
  addressBook: byPrefixAndName.fal["address-book"],
  faAddressBook: byPrefixAndName.fal["address-book"],
  wareHouse: byPrefixAndName.fal["warehouse"],
  notificationBell: byPrefixAndName.fal["bell"],
  apps: byPrefixAndName.fal["grid-2"],
  faApps: byPrefixAndName.fas["grid-2"],
  fasUsers: byPrefixAndName.fas["users"],
  fasNotificationBell: byPrefixAndName.fas["bell"],
  fasWareHouse: byPrefixAndName.fas["warehouse"],
  fasUser: byPrefixAndName.fas["user"],
  fasGlobe: byPrefixAndName.fas["globe"],
  trash: byPrefixAndName.fal["trash"],
  penField: byPrefixAndName.fal["pen-field"],
  textSize: byPrefixAndName.fal["text-size"],
  hashtag: byPrefixAndName.fal["hashtag"],
  dropdown: byPrefixAndName.fal["square-caret-down"],
  circleNotch: byPrefixAndName.far["circle-notch"],
  filter: byPrefixAndName.fas["filter"],
  play: byPrefixAndName.fas["play"],
  chevronDown: byPrefixAndName.far["chevron-down"],
  exclamationTriangle: byPrefixAndName.far["exclamation-triangle"],
  magnifyingGlass: byPrefixAndName.far["magnifying-glass"],
  arrowRightFromBracket: byPrefixAndName.far["arrow-right-from-bracket"],
  arrowRightToBracket: byPrefixAndName.far["arrow-right-to-bracket"],
  fasChevronLeft: byPrefixAndName.fas["chevron-left"],
  fasChevronRight: byPrefixAndName.fas["chevron-right"],
  fasPencil: byPrefixAndName.fas["pencil"],
  fasEllipsis: byPrefixAndName.fas["ellipsis"],
  fasChevronUp: byPrefixAndName.fas["chevron-up"],
  fasChevronDown: byPrefixAndName.fas["chevron-down"],
  fasClockRotateLeft: byPrefixAndName.fas["clock-rotate-left"],
  fasRoute: byPrefixAndName.fas["route"],
  fasQuestionCircle: byPrefixAndName.fas["question-circle"],
  fasCarSide: byPrefixAndName.fas["car-side"],
  fasCheck: byPrefixAndName.fas["check"],
  fasCog: byPrefixAndName.fas["cog"],
  fasRoad: byPrefixAndName.fas["road"],
  fasTrash: byPrefixAndName.fas["trash"],
  fasPlus: byPrefixAndName.fas["plus"],
  fasNetworkWired: byPrefixAndName.fas["network-wired"],
  fasExclamationTriangle: byPrefixAndName.fas["exclamation-triangle"],

  fasCalendarDays: byPrefixAndName.fas["calendar-days"],
  fasFont: byPrefixAndName.fas["font"],
  fasHandHoldingDollar: byPrefixAndName.fas["hand-holding-dollar"],
  fasLocation: byPrefixAndName.fas["location"],
  fasLocationPin: byPrefixAndName.fas["location-pin"],
  fasMessage: byPrefixAndName.fas["message"],
  fasMobile: byPrefixAndName.fas["mobile"],
  fasNoteSticky: byPrefixAndName.fas["note-sticky"],
  fasPhotoFilm: byPrefixAndName.fas["photo-film"],
  fasSignature: byPrefixAndName.fas["signature"],
  fasStickyNote: byPrefixAndName.fas["sticky-note"],
  fasTruck: byPrefixAndName.fas["truck"],
  fasCircleInfo: byPrefixAndName.fas["circle-info"],
};

export type IconsType = keyof typeof Icons;
export type IconDefinitionType = (typeof Icons)[IconsType];
