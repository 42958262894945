import type { FormEvent } from "react";
import { useState } from "react";
import toast from "react-hot-toast";

import { useWorkspaceMutation } from "@web-app/api";
import { AvatarInput, Button, Input } from "@web-app/components";
import type { Workspace } from "@web-app/types/workspaces";

import TabTitle from "./TabTitle";

export default function WorkspaceTab({ workspace }: { workspace: Workspace }) {
  const { updateWorkspace } = useWorkspaceMutation();

  const [avatarUrl, setAvatarUrl] = useState(workspace.avatarUrl);
  const [name, setName] = useState(workspace.name);

  const onSaveWorkspace = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      name: { value: string };
      accentColor: { value: string };
      slug: { value: string };
    };

    const formData = {
      name: target.name.value,
      avatarUrl: avatarUrl || "",
      accentColor: target.accentColor.value,
    };

    try {
      toast.promise(
        updateWorkspace.mutateAsync({
          id: workspace.id,
          payload: formData,
        }),
        {
          loading: "Updating Workspace ...",
          success: "Successfully Updated Workspace!",
          error: "Something went wrong! Please try again",
        },
      );
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className="max-w-lg p-4 px-6">
      <TabTitle icon="fasGlobe" title="Workspace" styles="mb-8" />
      <form onSubmit={onSaveWorkspace}>
        <div className="mb-8">
          <AvatarInput
            imageUrl={avatarUrl || ""}
            setImageUrl={setAvatarUrl}
            name={name || ""}
          />
        </div>
        <div className="my-2">
          <Input
            type="text"
            required
            label="Name"
            name="name"
            defaultValue={workspace.name || ""}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mt-3">
          <Input
            type="text"
            label="Slug"
            name="slug"
            value={workspace.slug}
            required
            pattern="[A-Z]{3}"
            toolTipText="3 Characters and Uppercase only"
            disabled
          />
        </div>
        <div className="mt-3">
          <Input
            label="Brand Color"
            type="color"
            name="accentColor"
            defaultValue={workspace.accentColor || "#00000"}
          />
        </div>
        <div className="mt-6 flex gap-2">
          <Button type="submit">Update</Button>
        </div>
      </form>
    </div>
  );
}
