import { createFileRoute } from "@tanstack/react-router";

import { LoadingIcon } from "@web-app/components";
import { useApp } from "@web-app/hooks";
import ManagePage from "@web-app/pages/manage";

export const Route = createFileRoute("/manage")({
  component: Manage,
});

function Manage() {
  const { sessionUser, isLoading, logout } = useApp();

  if (isLoading || !sessionUser) {
    return (
      <div className="h-screen w-screen grid place-content-center">
        <LoadingIcon className="w-8 h-8" />
      </div>
    );
  }

  if (!sessionUser) {
    return <div>Not authenticated</div>;
  }

  return (
    <div>
      <ManagePage logout={logout} />
    </div>
  );
}
