import { ColDef } from "ag-grid-enterprise";

import { CustomField, Order, TableHeadersName } from "@web-app/types";

import AssigneeCell from "./Table/AssigneeCell";
import DateCell from "./Table/DateCell";
import StatusCell from "./Table/StatusCell";
import WorkspaceCell from "./Table/WorkspaceCell";

export const INITIAL_TABLE_DATA: ColDef[] = [
  {
    field: "displayId",
    headerName: TableHeadersName.OrderId,
    // pinned: "left",
  },
  {
    headerName: TableHeadersName.Assignee,
    cellRenderer: AssigneeCell,
    valueGetter: (p: { data: Order }) => p.data?.assignee?.id,
    minWidth: 200,
    enableRowGroup: true,
  },
  {
    headerName: TableHeadersName.Connections,
    cellRenderer: WorkspaceCell,
    valueGetter: (p: { data: Order }) => p.data?.connectedWorkspace?.id,
    minWidth: 200,
    enableRowGroup: true,
  },
  {
    field: "externalId",
    headerName: TableHeadersName.ExternalId,
    hide: true,
  },
  {
    field: "priority",
    headerName: TableHeadersName.Priority,
  },
  { field: "deliveryTypeText", headerName: TableHeadersName.DeliveryType },

  // Dropoff Information
  {
    field: "dropoff.name",
    headerName: TableHeadersName.RecipientName,
    hide: true,
  },
  {
    field: "dropoff.phone",
    headerName: TableHeadersName.RecipientPhone,
    hide: true,
  },
  {
    field: "dropoff.note",
    headerName: TableHeadersName.RecipientNote,
    hide: true,
  },
  {
    field: "dropoff.businessName",
    headerName: TableHeadersName.RecipientBusinessName,
    hide: true,
  },
  {
    field: "dropoff.address",
    headerName: TableHeadersName.DropoffAddress,
    wrapText: true,
    minWidth: 300,
  },

  // Pickup Information
  {
    field: "pickup.name",
    headerName: TableHeadersName.PickupName,
    hide: true,
  },
  {
    field: "pickup.phone",
    headerName: TableHeadersName.PickupPhone,
    hide: true,
  },
  {
    field: "pickup.note",
    headerName: TableHeadersName.PickupNote,
    hide: true,
  },
  {
    field: "pickup.businessName",
    headerName: TableHeadersName.PickupBusinessName,
    hide: true,
  },
  {
    field: "pickup.address",
    headerName: TableHeadersName.PickupAddress,
    wrapText: true,
    minWidth: 300,
  },

  // Dropoff Requirements
  {
    field: "dropoffRequirement.signature",
    headerName: TableHeadersName.SignatureRequirement,
    hide: true,
  },
  {
    field: "dropoffRequirement.photo",
    headerName: TableHeadersName.ProofOfDeliveryRequirement,
    hide: true,
  },
  {
    field: "dropoffRequirement.note",
    headerName: TableHeadersName.NotesRequirement,
    hide: true,
  },

  // Order Details
  {
    field: "details.serviceDuration",
    headerName: TableHeadersName.ServiceTime,
    hide: true,
  },
  {
    field: "details.description",
    headerName: TableHeadersName.Description,
    hide: true,
  },
  {
    field: "details.value",
    headerName: TableHeadersName.Value,
    hide: true,
    sortable: true,
  },
  {
    field: "details.tip",
    headerName: TableHeadersName.Tip,
    hide: true,
    sortable: true,
  },
  {
    field: "details.itemsCount",
    headerName: TableHeadersName.ItemsCount,
    hide: true,
    sortable: true,
  },
  {
    field: "details.distanceString",
    headerName: TableHeadersName.Distance,
    hide: true,
  },

  {
    field: "statusText",
    headerName: TableHeadersName.Status,
    cellRenderer: StatusCell,
    minWidth: 150,
  },
  {
    field: "statusDetails.reason",
    headerName: TableHeadersName.StatusReason,
    hide: true,
  },
  {
    field: "statusDetails.note",
    headerName: TableHeadersName.StatusNotes,
    hide: true,
  },
  {
    field: "minVehicle",
    headerName: TableHeadersName.VehicleType,
    hide: true,
  },
  {
    field: "scheduledStartDateTime",
    headerName: TableHeadersName.ScheduledStartTime,
    cellRenderer: DateCell,
    sortable: true,
    minWidth: 200,
  },
  {
    field: "scheduledEndDateTime",
    headerName: TableHeadersName.ScheduledEndTime,
    cellRenderer: DateCell,
    sortable: true,
    minWidth: 200,
  },
  {
    field: "actualStartDateTime",
    headerName: TableHeadersName.InTransitToPickupTime,
    cellRenderer: DateCell,
    sortable: true,
    minWidth: 200,
  },
  {
    field: "geoPickupDateTime",
    headerName: TableHeadersName.PickupArrivalTime,
    cellRenderer: DateCell,
    sortable: true,
    minWidth: 200,
  },
  {
    field: "actualPickupDateTime",
    headerName: TableHeadersName.ActualPickupTime,
    cellRenderer: DateCell,
    sortable: true,
    minWidth: 200,
  },
  {
    field: "geoDropoffDateTime",
    headerName: TableHeadersName.DropoffArrivalTime,
    cellRenderer: DateCell,
    sortable: true,
    minWidth: 200,
  },
  {
    field: "actualEndDateTime",
    headerName: TableHeadersName.ActualDropOffTime,
    cellRenderer: DateCell,
    sortable: true,
    minWidth: 200,
  },
];

export const getInitialTableData = (customFields?: CustomField[]) => {
  if (customFields?.length) {
    const customFieldDefs = customFields.map((customField) => ({
      headerName: customField.name,
      hide: true,
      valueGetter: (p: { data: Order }) => {
        const customFieldData = p.data?.customFields.find(
          (item) => item.definition.id === customField.id,
        );
        return customFieldData?.values?.length
          ? customFieldData?.values?.map((item) => item.value)?.join(",")
          : customFieldData?.definition?.defaultValues
              ?.map((item) => item?.value)
              ?.join(",");
      },
    }));

    return [...INITIAL_TABLE_DATA, ...customFieldDefs];
  }
  return INITIAL_TABLE_DATA;
};
