import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { CreateUserPayload, UpdateUserPayload } from "@web-app/types";

import {
  create,
  list,
  update,
  updateCurrentWorkspace,
} from "../services/users";

const queryKey = "users";

export const useUsers = (workspaceId: string) => {
  return useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const response = await list(workspaceId);
      return response;
    },
    refetchOnWindowFocus: false,
    select: (users) => {
      return new Map(users.map((user) => [user.id, user]));
    },
  });
};

export const useUserMutation = () => {
  const queryClient = useQueryClient();

  const createUser = useMutation({
    mutationFn: async (payload: CreateUserPayload) => {
      const response = await create(payload);
      return response;
    },
    onSuccess: () => {
      // @ts-ignore fix
      queryClient.invalidateQueries([queryKey]);
    },
  });

  const updateUser = useMutation({
    mutationFn: async (params: {
      id: string;
      payload: Partial<UpdateUserPayload>;
    }) => {
      const response = await update(params.id, params.payload);
      return response;
    },
    onSuccess: () => {
      // @ts-ignore fix
      queryClient.invalidateQueries([queryKey]);
    },
  });

  const switchWorkspace = useMutation({
    mutationFn: async (workspaceId: string) => {
      const response = await updateCurrentWorkspace(workspaceId);
      return response;
    },
  });

  return {
    createUser,
    updateUser,
    switchWorkspace,
  };
};
