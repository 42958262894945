import clsx from "clsx";
import { format } from "date-fns";

import { Icon } from "@web-app/components";
import { OrderTimeline } from "@web-app/types";
import { OrderTimelineSchema } from "@web-app/utils/helpers";

type OrderEditActivityPropTypes = {
  item: OrderTimeline;
  showTimelineBar: boolean;
};

export default function OrderEditActivity({
  item,
  showTimelineBar,
}: OrderEditActivityPropTypes) {
  const ActivityIcon =
    OrderTimelineSchema[item.entity].action[item.action].icon;

  const ordertimelineColor =
    OrderTimelineSchema[item.entity].action[item.action].color;

  return (
    <li key={item.id} className="relative flex gap-x-4">
      <div
        className={clsx(
          showTimelineBar ? "-bottom-6" : "h-6",
          "absolute left-0 top-0 flex w-6 justify-center",
        )}
      >
        <div className="w-px bg-gray-200" />
      </div>
      <div className="relative flex h-7 w-6 flex-none items-center justify-center bg-white">
        <Icon name={ActivityIcon} className={clsx("h-4", ordertimelineColor)} />
      </div>
      <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
        <span
          className={clsx(
            "inline-block font-medium text-gray-900",
            ordertimelineColor,
          )}
        >
          {`${OrderTimelineSchema[item.entity].action[item.action].text} ${
            OrderTimelineSchema[item.entity].entityPresentation
          }`}
          {item.previousValue &&
          item.updatedValue &&
          item.previousValue !== item.updatedValue
            ? item.updatedValue !== "true" && item.updatedValue !== "false"
              ? ` from ${item.previousValue.toLowerCase()} to ${item.updatedValue.toLowerCase()}`
              : ` from ${
                  item.previousValue === "true" ? "checked" : "unchecked"
                } to ${item.updatedValue === "true" ? "checked" : "unchecked"}`
            : ""}
        </span>
        <span className="block">
          {`${format(new Date(item?.createdAt), "MMMM dd, yyyy, hh:mma")} by ${
            item.createdBy?.name
          }`}
        </span>
      </p>
    </li>
  );
}
