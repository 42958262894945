import clsx from "clsx";
import type { ChangeEvent, TextareaHTMLAttributes } from "react";
import { useState } from "react";

type TextAreaProp = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
};

export default function TextArea(props: TextAreaProp) {
  const [value, setValue] = useState(props.defaultValue || "");

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  if (props.readOnly) {
    return (
      <div className="mb-6 w-full md:mb-0">
        <label className="mb-1 block text-xs font-semibold tracking-wide text-gray-700">
          {props.label}
          {props.required && "*"}
        </label>
        <div className="tw-form-field-readonly-style">{props.defaultValue}</div>
      </div>
    );
  }

  return (
    <div className="mb-6 w-full md:mb-0">
      <label className="mb-1 block text-xs font-semibold tracking-wide text-gray-700">
        {props.label}
        {props.required && "*"}
      </label>
      <div className="relative">
        <textarea
          {...{
            ...props,
            className: clsx(
              "rounded-md w-full bg-gray-100 px-2 py-3 text-sm leading-5 text-gray-900 outline-none transition-all ease-in placeholder:text-gray-500 focus:ring-2 focus:ring-gray-600 disabled:pointer-events-none disabled:bg-gray-50 disabled:text-gray-400",
              props.maxLength && "pr-12",
              props.disabled &&
                "border-none !text-gray-500 hover:cursor-not-allowed bg-transparent",
            ),
            onChange,
          }}
        />
        {props.maxLength && (
          <span className="absolute bottom-2 right-2 text-right text-xs text-gray-400">
            {value.toString().length}/{props.maxLength}
          </span>
        )}
      </div>
    </div>
  );
}
