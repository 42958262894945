import { z } from "zod";

import { Icons } from "@web-app/components";
import { CustomFieldType } from "@web-app/types";

export const customFieldSchema = z.object({
  type: z.enum(["TEXT", "NUMBER", "DATE", "BOOLEAN", "DROPDOWN"]),
  name: z.string({ required_error: "Name is required." }),
  helpText: z.string().optional(),
  defaultValue: z.string().optional(),
  isRequired: z.boolean().default(false),
  isVisibleMobile: z.boolean().default(false),
  isActive: z.boolean().default(true),
  groupId: z.string(),
});

export const inputTypeOptions: {
  value: string;
  label: string;
  disabled: boolean;
  icon: keyof typeof Icons;
}[] = [
  {
    value: "TEXT",
    label: "Text",
    disabled: false,
    icon: "textSize",
  },
  {
    value: "NUMBER",
    label: "Number",
    disabled: false,
    icon: "hashtag",
  },
  {
    value: "DROPDOWN",
    label: "Dropdown",
    disabled: true,
    icon: "dropdown",
  },
];

export const orderCardIconMapper: {
  [type in CustomFieldType]: keyof typeof Icons;
} = {
  TEXT: "textSize",
  NUMBER: "hashtag",
  DROPDOWN: "dropdown",
  DATE: "user",
  BOOLEAN: "user",
};
