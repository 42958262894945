import { QueryFunction } from "@tanstack/react-query";

import { OrderTimeline } from "@web-app/types";
import http from "@web-app/utils/http";

type QueryParams = {
  orderId: string;
};

export const getTimeline: QueryFunction<OrderTimeline[]> = async ({
  queryKey,
}) => {
  const [, params] = queryKey as [string, QueryParams];

  return http
    .get(`/orders/${params.orderId}/timeline`, { params })
    .then((response) => response.data.reverse());
};
