import { useEffect, useState } from "react";
import toast from "react-hot-toast";

import { useSavedAddressMutation } from "@web-app/api";
import { Button } from "@web-app/components";
import type { SavedAddress } from "@web-app/types/savedAddress";

import TabTitle from "../TabTitle";

import AddNewAddress from "./AddNewAddress";
import AddressCard from "./AddressCard";
import EditAddressSidebar from "./EditAddressSidebar";

export default function AddressBookTab({
  addresses,
  isLoading,
  currentWorkspaceId,
}: {
  addresses: SavedAddress[] | undefined;
  isLoading: boolean;
  currentWorkspaceId: string;
}) {
  const [addressesMap, setAddressesMap] = useState<Map<
    string,
    SavedAddress[]
  > | null>(null);
  const [focusedSavedAddress, setFocusedSavedAddress] =
    useState<SavedAddress | null>(null);
  const [isAddNewAddress, setIsAddNewAddress] = useState(false);

  useEffect(() => {
    if (addresses) {
      const map = addresses.reduce((map, addr) => {
        const key = addr.name[0].toUpperCase();
        map.set(key, [...(map.get(key) || []), addr]);
        return map;
      }, new Map());

      const sortedEntries = [...map.entries()].sort(([keyA], [keyB]) =>
        keyA.localeCompare(keyB),
      );
      map.clear();
      sortedEntries.forEach(([key, value]) => map.set(key, value));

      setAddressesMap(map);
    }
  }, [addresses]);

  const { deleteSavedAddress } = useSavedAddressMutation();

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <div className="p-4 px-6 min-h-screen relative overflow-y-auto">
      <div className="flex justify-between items-center">
        <TabTitle icon="faAddressBook" styles="mb-8" title="Address Book" />
        <Button type="button" onClick={() => setIsAddNewAddress(true)}>
          Add New
        </Button>
      </div>
      <div>
        {addressesMap && !!addressesMap.size ? (
          Array.from(addressesMap).map((data, index) => {
            return (
              <div key={index} className="mb-6">
                <h2 className=" text-sm font-normal text-gray-500 mb-2">
                  {data[0]}
                </h2>
                <div className="flex flex-wrap gap-4 md:gap-5 lg:gap-7 xl:gap-9 ">
                  {data[1].map((address) => {
                    return (
                      <AddressCard
                        key={address.id}
                        address={address}
                        onClick={() => {
                          setFocusedSavedAddress(address);
                        }}
                        onDelete={async () => {
                          await toast.promise(
                            deleteSavedAddress.mutateAsync(address.id),
                            {
                              loading: "Deleting address...",
                              success: "Successfully deleted address",
                              error: "Failed to delete address",
                            },
                          );
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })
        ) : (
          <span>No saved addresses</span>
        )}
      </div>
      {focusedSavedAddress && (
        <EditAddressSidebar
          onClose={() => setFocusedSavedAddress(null)}
          address={focusedSavedAddress}
        />
      )}

      {isAddNewAddress && (
        <AddNewAddress
          onClose={() => setIsAddNewAddress(false)}
          currentWorkspaceId={currentWorkspaceId}
        />
      )}
    </div>
  );
}

const LoadingSkeleton = () => {
  const getBox = (count: number): number[] => {
    return new Array(count).fill(0);
  };
  return (
    <div className="p-4 px-6 min-h-screen relative overflow-y-auto">
      <TabTitle icon="faAddressBook" styles="mb-8" title="Address Book" />
      <LoaderItem boxCount={getBox(5)} />
      <LoaderItem boxCount={getBox(1)} />
      <LoaderItem boxCount={getBox(3)} />
    </div>
  );
};

const LoaderItem = ({ boxCount }: { boxCount: number[] }) => {
  return (
    <div className="mb-6 w-full animate-pulse duration-100 ">
      <div className="max-w-[20px] w-5 bg-gray-200 inline-block h-5 rounded-sm text-sm font-normal text-gray-500 mb-2"></div>
      <div className="flex flex-wrap gap-4 md:gap-5 lg:gap-7 xl:gap-9 ">
        {boxCount.map((it, idx) => {
          return (
            <div className="flex" key={`${idx}+${it}`}>
              <div className="group transition-all flex relative items-start gap-4 w-[392px] rounded-lg bg-gray-200   p-4 h-[72px] max-h-[72px] "></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
