import { useEffect } from "react";

export default function useDocument({ title }: { title: string }) {
  useEffect(() => {
    document.title = `FULFLLD - ${title}`;

    return () => {
      document.title = "FULFLLD";
    };
  }, [title]);

  return null;
}
