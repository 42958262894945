import clsx from "clsx";
import type { ChangeEvent, InputHTMLAttributes } from "react";
import { useState } from "react";

import Tooltip from "./Tooltip";

interface InputType extends InputHTMLAttributes<HTMLInputElement> {
  type:
    | "text"
    | "number"
    | "email"
    | "password"
    | "color"
    | "date"
    | "datetime-local"
    | "month"
    | "range"
    | "search"
    | "tel"
    | "time"
    | "url"
    | "week"
    | "radio"
    | "string";
  label?: string;
  toolTipText?: string;
  lengthCountClasses?: string;
}
interface CheckboxInputType extends InputHTMLAttributes<HTMLInputElement> {
  type: "checkbox";
  checkboxLabel?: string | React.ReactNode;
  toolTipText?: string;
}

type InputProp =
  | InputType
  | (CheckboxInputType & Omit<CheckboxInputType, "label">);

export default function Input(props: InputProp) {
  const [value, setValue] = useState(props.defaultValue || "");

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);

    if (props.onChange) {
      props.onChange(e);
    }
  };

  if (props.type === "color") {
    return (
      <div className="mb-6 w-full md:mb-0">
        <div className="flex items-center">
          {props.label && (
            <label className="mb-1 block text-xs font-semibold tracking-wide text-gray-700">
              {props.label}
              {props.required && "*"}
            </label>
          )}
          {props.toolTipText && (
            <Tooltip text={props.toolTipText || ""} position="right" />
          )}
        </div>
        <div className="relative flex items-center gap-2 rounded-md border">
          <input
            {...{
              ...props,
              className: clsx("p-1 bg-transparent w-10 h-10 cursor-pointer"),
              onChange,
            }}
          />
          <div className="text-sm uppercase">{value}</div>
        </div>
      </div>
    );
  }

  if (props.type === "checkbox") {
    return (
      <div className="mb-1 w-full">
        <div className="flex items-center gap-2">
          <label
            className={clsx(
              "flex cursor-pointer items-center text-xs",
              props.disabled && "cursor-not-allowed !text-gray-500",
              props.readOnly && " pointer-events-none !text-gray-500",
            )}
          >
            <CheckBox {...{ ...props }} />
            {props.checkboxLabel && (
              <span className="ml-2 text-gray-700">{props.checkboxLabel}</span>
            )}
          </label>

          {props.toolTipText && (
            <Tooltip text={props.toolTipText || ""} position="right" />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="mb-6 w-full md:mb-0">
      <div className="flex items-center gap-2 mb-1">
        {props.label && (
          <label
            className={clsx(
              "block text-xs font-medium leading-5 tracking-wide",
              props.disabled ? "text-gray-400" : "text-gray-700",
            )}
          >
            {props.label}
            {props.required && "*"}
          </label>
        )}

        {props.toolTipText && (
          <Tooltip text={props.toolTipText || ""} position="right" />
        )}
      </div>
      {props.readOnly ? (
        <span className="tw-form-field-readonly-style">
          {props.defaultValue}
        </span>
      ) : (
        <div className="relative flex items-center">
          <input
            {...{
              ...props,
              className: clsx(
                "tw-form-field-style",
                props.maxLength && "pr-12",
                props.type === "radio" ? "!w-auto mr-2" : "",
                props.className,
              ),
              onChange,
            }}
          />
          {props.maxLength && (
            <span
              className={clsx(
                `absolute bottom-5 right-2 text-right text-xs text-gray-400 ${
                  props.lengthCountClasses ?? ""
                }`,
                props.disabled && "cursor-not-allowed !text-gray-500",
              )}
            >
              {value.toString().length}/{props.maxLength}
            </span>
          )}
        </div>
      )}
    </div>
  );
}

function CheckBox(props: InputProp) {
  return (
    <input
      {...{
        ...props,
        className: clsx(
          "w-4 h-4 text-gray-700 bg-gray-100 rounded-xl cursor-pointer accent-gray-700 transition ease-in",
          props.className,
        ),
      }}
    />
  );
}
