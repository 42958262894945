import { GetPlaceLatLngResponse, GoogleAddress } from "@web-app/types";
import http from "@web-app/utils/http";

const base_url = "/google";

export const getPlaceAutoCompletions = async (address: string) => {
  return http
    .get<GoogleAddress[]>(`${base_url}/autocomplete`, {
      params: {
        address: address,
      },
    })
    .then((response) => response.data);
};

export const getPlaceLatLng = async (placeId: string) => {
  return http
    .get<GetPlaceLatLngResponse>(`${base_url}/placedetails`, {
      params: {
        placeId: placeId,
      },
    })
    .then((response) => response.data);
};
