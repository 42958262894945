import clsx from "clsx";

import { Icon } from "@web-app/components/Icon";
import type { Icons } from "@web-app/components/Icon/utils";

export default function TabTitle({
  icon,
  title,
  styles,
}: {
  icon: keyof typeof Icons;
  title: string;
  styles?: string;
}) {
  return (
    <div>
      <h1
        className={clsx(
          "mb-3 flex items-center font-semibold text-2xl text-gray-700 ",
          styles,
        )}
      >
        <Icon name={icon} className="mr-2 h-5" /> <span>{title}</span>
      </h1>
    </div>
  );
}
