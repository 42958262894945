import toast from "react-hot-toast";

import { IconsType } from "@web-app/components/Icon/utils";
import {
  Order,
  OrderDeliveryType,
  Order as OrderType,
  RoutePolylineType,
} from "@web-app/types";

export function splitToPairs<T>(arr: T[]) {
  const pairs = [];
  for (let i = 0; i < arr.length - 1; i++) {
    const j = (i + 1) % arr.length;
    pairs.push([arr[i], arr[j]]);
  }
  return pairs;
}

// TODO: REMOVE THIS METHOD (this file should contain generic helpers)
export const getSortedOrders = (orders: Map<string, Order>) => {
  const sortedOrders = new Map(
    Array.from(orders.entries()).sort((a, b) => {
      const aOrder = a[1];
      const bOrder = b[1];
      const aDate = new Date(aOrder.scheduledStartDateTime);
      const bDate = new Date(bOrder.scheduledStartDateTime);

      return aDate.getTime() - bDate.getTime();
    }),
  );

  return sortedOrders;
};

export const getSortedList = <T>(list: T[], property: string): T[] => {
  const sortedList = list.sort((a: T, b: T) => {
    const valueA = (a as Record<string, string>)[property];
    const valueB = (b as Record<string, string>)[property];

    return valueA.localeCompare(valueB);
  });

  return sortedList;
};

export const getSearchResultThroughProperty = (
  searchText: string,
  property: string,
) => {
  const searchTextRegex = new RegExp(`\\w*?${searchText}\\w*?\\s*?`);

  if (property) {
    return (
      searchTextRegex.test(property) ||
      searchTextRegex.test(property.toLowerCase())
    );
  }

  return false;
};

// TODO: REMOVE THIS METHOD (this file should contain generic helpers)
export function searchOrders(
  searchText: string,
  list: Map<string, Order>,
): Map<string, Order> {
  if (searchText === "" || list.size === 0) {
    return new Map();
  }

  const filteredOrders = new Map<string, OrderType>();
  list.forEach((order, id) => {
    const pickupAddress = order?.pickup.address;
    const dropoffAddress = order?.dropoff.address;
    const orderDisplayId = order?.displayId;
    const recipientName = order?.dropoff.name;

    if (
      getSearchResultThroughProperty(searchText, pickupAddress!) ||
      getSearchResultThroughProperty(searchText, orderDisplayId) ||
      getSearchResultThroughProperty(searchText, recipientName) ||
      getSearchResultThroughProperty(searchText, dropoffAddress!)
    ) {
      filteredOrders.set(id, order);
    }
  });

  return getSortedOrders(filteredOrders);
}

export const OrderTimelineSchema: {
  [key: string]: {
    entityPresentation: string | EntityPresentationType;
    action: {
      [key: string]: {
        text: string;
        color: string;
        icon: IconsType;
      };
    };
  };
} = {
  clientId: {
    entityPresentation: "client",
    action: {
      UPDATE: {
        text: "Changed",
        color: "text-black",
        icon: "fasUser",
      },
    },
  },
  assigneeId: {
    entityPresentation: "Assignee",
    action: {
      UPDATE: {
        text: "Assigned order to",
        color: "text-black",
        icon: "fasLocationPin",
      },
      DELETE: {
        text: "Unassigned order",
        color: "text-black",
        icon: "fasLocationPin",
      },
    },
  },
  attachments: {
    entityPresentation: "Attachments",
    action: {
      CREATE: {
        text: "Added",
        color: "text-green-600",
        icon: "fasPhotoFilm",
      },
      DELETE: {
        text: "Removed",
        color: "text-red-600",
        icon: "fasPhotoFilm",
      },
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasPhotoFilm",
      },
    },
  },
  order: {
    entityPresentation: "order",
    action: {
      CREATE: {
        text: "Created",
        color: "text-black",
        icon: "fasLocationPin",
      },
      DELETE: {
        text: "Deleted",
        color: "text-red-600",
        icon: "fasLocationPin",
      },
    },
  },
  status: {
    entityPresentation: "status",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasLocationPin",
      },
    },
  },
  statusReason: {
    entityPresentation: "status reason",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasMessage",
      },
    },
  },
  statusNotes: {
    entityPresentation: "status notes",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasNoteSticky",
      },
    },
  },
  priority: {
    entityPresentation: "priority",
    action: {
      UPDATE: {
        text: "Changed",
        color: "text-black",
        icon: "fasLocationPin",
      },
    },
  },
  pickupName: {
    entityPresentation: "full name",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasFont",
      },
    },
  },
  recipientName: {
    entityPresentation: "recipient name",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasFont",
      },
    },
  },
  recipientBusinessName: {
    entityPresentation: "recipient business name",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasFont",
      },
    },
  },
  recipientPhone: {
    entityPresentation: "recipient phone number",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasMobile",
      },
    },
  },
  recipientNote: {
    entityPresentation: "recipient note",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasNoteSticky",
      },
    },
  },
  pickupBusinessName: {
    entityPresentation: "pickup business name",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasFont",
      },
    },
  },
  pickupPhone: {
    entityPresentation: "pickup phone number",
    action: {
      UPDATE: {
        text: "Changed",
        color: "text-black",
        icon: "fasMobile",
      },
    },
  },
  scheduledStartDateTime: {
    entityPresentation: {
      individual: "pickup at time",
      pickup: "pickup start time",
      dropoff: "drop-off start time",
    },
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasCalendarDays",
      },
    },
  },
  scheduledEndDateTime: {
    entityPresentation: {
      individual: "drop-off at time",
      pickup: "pickup end time",
      dropoff: "drop-off end time",
    },
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasCalendarDays",
      },
    },
  },
  serviceTime: {
    entityPresentation: "service time",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasLocationPin",
      },
    },
  },
  customerRequirementSignature: {
    entityPresentation: "customer requirement signature",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasSignature",
      },
    },
  },
  // same pattern from line 190-198 for customerRequirementNotes and customerRequirementPhoto
  customerRequirementNotes: {
    entityPresentation: "customer requirement notes",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasNoteSticky",
      },
    },
  },
  customerRequirementPhoto: {
    entityPresentation: "customer requirement photo",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasPhotoFilm",
      },
    },
  },
  pickupNote: {
    entityPresentation: "notes",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasNoteSticky",
      },
    },
  },
  description: {
    entityPresentation: "description",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasStickyNote",
      },
    },
  },
  value: {
    entityPresentation: "value",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasLocation",
      },
    },
  },
  tip: {
    entityPresentation: "tip",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasHandHoldingDollar",
      },
    },
  },
  itemsCount: {
    entityPresentation: "itemsCount",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasLocationPin",
      },
    },
  },
  minVehicle: {
    entityPresentation: "minVehicle",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasTruck",
      },
    },
  },
  externalId: {
    entityPresentation: "external reference id",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasLocationPin",
      },
    },
  },
  pickupAddress: {
    entityPresentation: "pickup address",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasLocationPin",
      },
    },
  },
  destinationAddress: {
    entityPresentation: "drop-off location",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasLocationPin",
      },
    },
  },
  geoDropoffDateTime: {
    entityPresentation: "near destination at",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasCalendarDays",
      },
    },
  },
  geoPickupDateTime: {
    entityPresentation: "near pickup at",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasCalendarDays",
      },
    },
  },
  actualPickupDateTime: {
    entityPresentation: "pickup at",
    action: {
      UPDATE: {
        text: "Updated",
        color: "text-black",
        icon: "fasCalendarDays",
      },
    },
  },
};

export const validatePhone = (phone: string) => {
  const phoneNumberRegex = /^\d{10,10}$/;

  if (phoneNumberRegex.test(phone)) {
    return true;
  } else {
    return false;
  }
};

// TODO: REMOVE THIS METHOD (this file should contain generic helpers)
export const validatePhoneNumber = (phone: string) => {
  const phoneNumberRegex = /^\d{10,10}$/;

  if (phone && phoneNumberRegex.test(phone)) {
    return true;
  } else {
    toast.error("Please enter a valid phone number");
    return false;
  }
};

export function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === "undefined" ? undefined : JSON.parse(value ?? "");
  } catch {
    console.log("parsing error on", { value });
    return undefined;
  }
}

export const getOrderCompletionTime = (order: Order) => {
  return order.type === OrderDeliveryType.ROUTE_OPT_PICKUP
    ? order.actualPickupDateTime
    : order.actualEndDateTime;
};

export const getPropertyLabelFromList = (
  value: string,
  options: { label: string; value: string }[],
): string => {
  return options.find((option) => option.value === value)?.label || "";
};

export type EntityPresentationType = {
  individual: string;
  pickup: string;
  dropoff: string;
};

export const getPolyLineDistance = (polyline: RoutePolylineType) => {
  const distanceInMeters = polyline.routes?.[0].distanceMeters;
  const distanceInMiles = distanceInMeters
    ? (distanceInMeters * 0.000621371192).toFixed(1)
    : null;
  return distanceInMiles;
};
