import { Disclosure, Transition } from "@headlessui/react";
import clsx from "clsx";
import React, { Children } from "react";

import { Icon } from "../Icon";

type AccordionPropsType = {
  children: React.ReactElement[];
  panelClassName?: string;
  defaultOpen?: boolean;
  className?: string;
  onLabelClick?: (isOpen: boolean) => void;
};

export default function AccordionV2(props: AccordionPropsType) {
  let _label: React.ReactElement, _content: React.ReactElement;

  Children.forEach(props.children, (child) => {
    child!.type === AccordionLabel && (_label = child);
    child!.type === AccordionContent && (_content = child);
  });

  return (
    <div className={clsx("w-full max-w-full", props.className)}>
      <Disclosure defaultOpen={props.defaultOpen}>
        {({ open }) => (
          <>
            <Disclosure.Button
              className="py-0 w-full"
              onClick={() => props.onLabelClick && props.onLabelClick!(open)}
            >
              <div className="flex items-center">
                <Icon
                  className={clsx(
                    open ? "rotate-90 transform" : "",
                    "duration-400 mr-2 inline-block transition ease-in-out",
                  )}
                  name="play"
                />
                {_label}
              </div>
            </Disclosure.Button>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 -translate-y-10 opacity-0"
              enterTo="transform scale-100 translate-y-0 opacity-100"
              leave="transition duration-75  ease-out"
              leaveFrom="transform scale-100 translate-y-10 opacity-100"
              leaveTo="transform scale-95 -translate-y-0 opacity-0"
            >
              <Disclosure.Panel
                className={clsx(
                  "ml-5 mt-2 max-w-full text-gray-500",
                  props.panelClassName,
                )}
              >
                {_content}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
}

const AccordionLabel = ({
  children,
  labelClassName,
}: {
  children: React.ReactNode;
  labelClassName?: string;
}) => {
  return (
    <div
      className={clsx(
        "font-medium text-gray-900 text-lg flex-1",
        labelClassName,
      )}
    >
      {children}
    </div>
  );
};

const AccordionContent = ({ children }: { children: React.ReactNode }) => (
  <>{children}</>
);

AccordionV2.Label = AccordionLabel;
AccordionV2.Content = AccordionContent;
