import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import { LoaderIcon } from "react-hot-toast";

import {
  Button,
  DraggableOrderCard,
  Icon,
  SelectAssignee,
  SidebarWithDrag,
} from "@web-app/components";
import useDispatchStore from "@web-app/store/dispatchStore";
import { DriverEntity, Order } from "@web-app/types";

import useManageRoute from "./useManageRoute";

export default function RouteEditModal() {
  const {
    regenerateRoute,
    handleAssignRoute,
    handleRemoveRoute,
    moveOrder,
    removeOrder,
    handleReorderRoute,
    handleCloseRouteEditModal,
    updatedAssignee,
    isRefetching,
    isAssigningRoute,
  } = useManageRoute();

  const { focusedRouteId, isRouteOrdersOrderChanged, appFilter, routes } =
    useDispatchStore();

  const queryClient = useQueryClient();
  const assignees = queryClient.getQueryData<Map<string, DriverEntity>>([
    "drivers",
  ]);
  const orders = queryClient.getQueryData<Map<string, Order>>([
    "orders",
    appFilter,
  ]);

  if (!assignees || !orders) {
    return <span>Loading</span>;
  }

  const route = routes.get(focusedRouteId!)!;
  const isUpdating = (isRefetching || isAssigningRoute) && updatedAssignee;

  return (
    <SidebarWithDrag isOpen={!!focusedRouteId}>
      <>
        <div className="flex items-center justify-between gap-32 p-4">
          <h1 className="text-xl font-semibold">Route {route?.displayId}</h1>
          <Icon
            name="xMark"
            className={clsx(
              "text-gray-500 h-[20px] cursor-pointer hover:text-gray-700",
              isUpdating && "pointer-events-none opacity-50",
            )}
            onClick={handleCloseRouteEditModal}
          />
        </div>
        <div className="grid grid-cols-3 gap-3 p-4 pb-0 items-stretch">
          <span className="col-span-2">
            <SelectAssignee
              onChange={handleAssignRoute}
              selectedId={route?.assigneeId || ""}
            />
          </span>
          <Button
            className="justify-center !h-full"
            onClick={() => handleRemoveRoute(route?.id)}
            icon="ban"
          >
            Clear Route
          </Button>
        </div>
        <div className="h-[calc(100%-210px)] overflow-y-auto px-4 flex flex-col gap-3 pt-3 mt-4">
          {isUpdating ? (
            <div className="w-full text-xs text-gray-600 mt-5 flex justify-center items-center ">
              <LoaderIcon className="mr-2" />
              <span>Updating list...</span>
            </div>
          ) : (
            (route?.ordersOrder || []).map((orderItem) => {
              if (!orderItem) {
                return;
              }

              if (orderItem?.type !== "order") {
                return <span key={orderItem?.type}></span>;
              }

              const order = orders.get(orderItem.id);

              if (!order) {
                return <></>;
              }

              return (
                <DraggableOrderCard
                  key={order.id}
                  order={order}
                  index={orderItem.index}
                  moveOrder={moveOrder}
                  removeOrder={removeOrder}
                  orderNumber={orderItem.index}
                />
              );
            })
          )}
        </div>

        <div className="px-4 py-3 absolute bottom-0 bg-gray-100 w-full ">
          <div className="flex items-center gap-3">
            {route && (
              <Button
                onClick={() => handleReorderRoute(route.id)}
                icon="route"
                disabled={!isRouteOrdersOrderChanged}
              >
                Save
              </Button>
            )}
            <Button appearance="secondary" onClick={regenerateRoute}>
              Reroute
            </Button>
          </div>
        </div>
      </>
    </SidebarWithDrag>
  );
}
