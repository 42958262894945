import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { CreateAppPayload, UpdateAppPayload } from "@web-app/types";

import { create, list, update } from "../services/app";

const queryKey = "users";

export const useApps = (workspaceId: string) => {
  return useQuery({
    queryKey: [`${queryKey}`],
    queryFn: async () => {
      const response = await list(workspaceId);
      return response;
    },
    refetchOnWindowFocus: false,
  });
};

export const useAppMutation = () => {
  const queryClient = useQueryClient();

  const createApp = useMutation({
    mutationFn: async (payload: CreateAppPayload) => {
      const response = await create(payload);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey] });
    },
  });

  const updateApp = useMutation({
    mutationFn: async (params: {
      id: string;
      payload: Partial<UpdateAppPayload>;
    }) => {
      const response = await update(params.id, params.payload);
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey] });
    },
  });

  return {
    createApp,
    updateApp,
  };
};
