import { Transition, TransitionChild } from "@headlessui/react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import {
  Duration,
  addMinutes,
  differenceInMinutes,
  format,
  intervalToDuration,
} from "date-fns";
import { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";

import { Button, Input, SelectInput } from "@web-app/components";
import { Order } from "@web-app/types";
import http from "@web-app/utils/http";

import type { UberQuotation } from "../../../../components/OrderCard";
import Route from "../../DispatchMap/Route";
import { options } from "../../DispatchMap/utils";

export function OrderUberDetails({
  order,
  onClose,
  deliveryData,
}: {
  order: Order;
  onClose: () => void;
  deliveryData: UberQuotation | null;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [uberQuotation, setUberQuotation] = useState<UberQuotation>({
    id: "",
    fee: 0,
    expires: "",
    pickup_eta: new Date(),
    dropoff_eta: "",
    dropoff_deadline: "",
    message: "",
    status: "quote",
    tracking_url: "",
  });
  const [timeLeft, setTimeLeft] = useState<Duration>({
    minutes: 0,
    seconds: 0,
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDeliveryInProgress, setIsDeliveryInProgress] = useState(false);
  const [isOrderAlreadySent, setIsOrderAlreadySent] = useState(false);

  const [deliverableAction, setDeliverableAction] = useState(
    "deliverable_action_meet_at_door",
  );
  const [undeliverableAction, setUndeliverableAction] = useState("return");
  const [testSpecification, setTestSpecification] = useState("happy");
  const [asapDelivery, setAsapDelivery] = useState(false);

  useEffect(() => {
    if (deliveryData) {
      setIsOrderAlreadySent(true);
    }

    if (!deliveryData || deliveryData.status === "cancelled") {
      fetchUberQuotation();
    } else {
      setUberQuotation(deliveryData);
      setIsLoading(false);
      setIsDeliveryInProgress(true);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedTimeLeft = uberQuotation.expires
        ? intervalToDuration({
            start: new Date(),
            end: new Date(uberQuotation.expires),
          })
        : { minutes: 0, seconds: 0 };

      if (updatedTimeLeft.minutes === 0 && updatedTimeLeft.seconds === 0) {
        clearInterval(interval);
        setIsDisabled(true);
      }

      setTimeLeft(updatedTimeLeft);
    }, 1000);

    return () => clearInterval(interval);
  }, [uberQuotation]);

  const fetchUberQuotation = async () => {
    setIsProcessing(true);
    const data = await toast
      .promise(http.post(`/integrations/uber/quote?orderId=${order.id}`), {
        loading: "Fetching Uber quotation...",
        success: "Uber quotation fetched",
        error: (err) => {
          onClose();
          return (
            `${err.response?.data?.message}` || "Failed to fetch uber quotation"
          );
        },
      })
      .then((response) => response.data);

    data.pickup_eta = addMinutes(new Date(), data.pickup_duration);
    setUberQuotation(data);
    setIsLoading(false);
    setIsProcessing(false);
    setIsDisabled(false);
  };

  const cancelDelivery = async () => {
    setIsProcessing(true);
    const externalAssigneeId = order.externalAssigneeId?.slice(5);
    const data = await toast
      .promise(
        http.post(`/integrations/uber/deliveries/${externalAssigneeId}/cancel`),
        {
          loading: "Cancelling delivery...",
          success: "Uber delivery Cancelled",
          error: (err) => {
            onClose();
            return (
              `${err.response?.data?.message}` || "Failed to cancel delivery"
            );
          },
        },
      )
      .then((response) => response.data);

    if (data.status === "canceled") {
      onClose();
    }
  };

  const handleAcceptQuote = async () => {
    setIsProcessing(true);
    await toast.promise(
      http.post(
        `/integrations/uber/deliveries?orderId=${order.id}&quoteId=${uberQuotation.id}`,
        {
          deliverableAction,
          undeliverableAction,
          testSpecification,
          asapDelivery,
        },
      ),
      {
        loading: "Sending order to uber...",
        success: "Order sent to uber",
        error: (err) => {
          onClose();
          return `${err.response?.data?.message}` || "Failed to send delivery";
        },
      },
    );
    setIsProcessing(false);
    onClose();
  };

  const dropoffEtaTime = new Date(uberQuotation.dropoff_eta);
  const dropoffDeadline = new Date(uberQuotation.dropoff_deadline);
  const duration = differenceInMinutes(
    dropoffEtaTime,
    new Date(uberQuotation.pickup_eta),
  );

  return (
    <Transition show as={Fragment}>
      <div className="relative z-20 w-[calc(100%-480px)]">
        <div className="fixed inset-0 w-[calc(100%-480px)] overflow-auto">
          <div className="absolute inset-0 overflow-auto ">
            <div className="pointer-events-none flex w-full">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-out duration-500 sm:duration-600"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-out duration-500 sm:duration-600"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <div className="pointer-events-auto w-full pl-0 lg:pl-20">
                  <div className="flex h-full min-h-screen w-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="custom-scrollbar flex min-h-screen w-full flex-1 flex-col overflow-y-auto pt-6">
                      <div className="sticky top-0 px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <div className="text-2xl font-semibold leading-6 text-gray-900">
                            Send Order to Uber
                          </div>
                        </div>
                      </div>

                      <div>
                        <GoogleMap
                          mapContainerClassName="h-[350px] max-w-[95%] mx-auto mt-10 rounded-md mx-6"
                          zoom={12}
                          options={options}
                          onLoad={(map) => {
                            const bounds =
                              new window.google.maps.LatLngBounds();
                            bounds.extend({
                              lat: order.pickupLocation.coordinates[1],
                              lng: order.pickupLocation.coordinates[0],
                            });
                            bounds.extend({
                              lat: order.dropoffLocation.coordinates[1],
                              lng: order.dropoffLocation.coordinates[0],
                            });
                            map.fitBounds(bounds);
                          }}
                        >
                          <Marker
                            position={{
                              lat: order.pickupLocation.coordinates[1],
                              lng: order.pickupLocation.coordinates[0],
                            }}
                            icon={{
                              url: `/images/markers-updated/${order.status}.png`,
                              scaledSize: {
                                width: 30,
                                height: 30,
                                equals() {
                                  return true;
                                },
                              },
                            }}
                          />
                          <Marker
                            position={{
                              lat: order.dropoffLocation.coordinates[1],
                              lng: order.dropoffLocation.coordinates[0],
                            }}
                            icon={{
                              url: `/images/markers-updated/${order.status}.png`,
                              scaledSize: {
                                width: 30,
                                height: 30,
                                equals() {
                                  return true;
                                },
                              },
                            }}
                          />
                          <Route
                            order={order}
                            isHovered={true}
                            setHoveredPolylineElementId={() => {}}
                          />
                        </GoogleMap>
                      </div>
                      {isLoading && (
                        <div className="flex justify-center mt-5">
                          <div className="bg-white w-full p-5 rounded-md">
                            <div className="text-center text-gray-500">
                              Loading...
                            </div>
                          </div>
                        </div>
                      )}
                      {!isLoading && uberQuotation.message && (
                        <div className="flex justify-center mt-5">
                          <div className="bg-white w-full p-5 rounded-md">
                            <div className="text-md text-gray-500">Error</div>
                            <div className="text-xs">
                              {uberQuotation.message}
                            </div>
                          </div>
                        </div>
                      )}
                      {!isLoading && !uberQuotation.message && (
                        <div className="w-full flex justify-center mt-5">
                          <div className="bg-white w-full p-5 rounded-md">
                            <div className="flex justify-between">
                              <div className="text-sm">
                                <div className="mt-5 flex gap-20">
                                  <div>
                                    <div className="text-gray-500 mb-2">
                                      Cost
                                    </div>
                                    <div className="text-gray-700 font-semibold">
                                      ${uberQuotation.fee / 100}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-gray-500 mb-2">
                                      Duration
                                    </div>
                                    <div className="text-gray-700 font-semibold">
                                      {duration} minutes
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-gray-500 mb-2">
                                      Delivery Status
                                    </div>
                                    <div className="text-gray-700 font-semibold">
                                      {uberQuotation.status ?? "Not Available"}
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-5 flex gap-20">
                                  <div>
                                    <div className="text-gray-500 mb-2">
                                      Driver Arrival ETA
                                    </div>
                                    <div className="text-gray-700 font-semibold">
                                      {format(
                                        new Date(uberQuotation.pickup_eta),
                                        "MM/dd/yyyy' 'hh:mm aa",
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-gray-500 mb-2">
                                      Driver Drop-off ETA
                                    </div>
                                    <div className="text-gray-700 font-semibold">
                                      {format(
                                        dropoffEtaTime,
                                        "MM/dd/yyyy' 'hh:mm aa",
                                      )}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="text-gray-500 mb-2">
                                      Drop-off Deadline
                                    </div>
                                    <div className="text-gray-700 font-semibold">
                                      {format(
                                        dropoffDeadline,
                                        "MM/dd/yyyy' 'hh:mm aa",
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-5 flex gap-20">
                                  <div>
                                    <div className="text-gray-500 mb-2">
                                      Tracking URL
                                    </div>
                                    {uberQuotation.tracking_url ? (
                                      <a
                                        href={uberQuotation.tracking_url}
                                        className="block underline text-blue-700 font-semibold"
                                        target="_blank"
                                      >
                                        {uberQuotation.tracking_url}
                                      </a>
                                    ) : (
                                      <div className="text-gray-700 font-semibold">
                                        "Not Available"
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {!isOrderAlreadySent && (
                                  <div className="mt-5 flex gap-20">
                                    <SelectInput
                                      options={[
                                        {
                                          label: "Meet at door",
                                          value:
                                            "deliverable_action_meet_at_door",
                                        },
                                        {
                                          label: "Leave at door",
                                          value:
                                            "deliverable_action_leave_at_door",
                                        },
                                      ]}
                                      selected={deliverableAction}
                                      required={true}
                                      onChange={(value) => {
                                        setDeliverableAction(value);
                                      }}
                                      label="Deliverable Action"
                                      placeholder="Deliverable Action"
                                    />
                                  </div>
                                )}

                                {!isOrderAlreadySent && (
                                  <div className="mt-5 flex gap-20">
                                    <SelectInput
                                      options={[
                                        { label: "Return", value: "return" },
                                        { label: "Discard", value: "discard" },
                                        {
                                          label: "Leave at door",
                                          value: "leave_at_door",
                                        },
                                      ]}
                                      selected={undeliverableAction}
                                      required={true}
                                      onChange={(value) => {
                                        setUndeliverableAction(value);
                                      }}
                                      label="Undeliverable Action"
                                      placeholder="Undeliverable Action"
                                    />
                                  </div>
                                )}

                                <div className="mt-5 flex gap-20">
                                  {!isOrderAlreadySent && (
                                    <SelectInput
                                      options={[
                                        {
                                          label: "Happy path for robo courier",
                                          value: "happy",
                                        },
                                        {
                                          label:
                                            "Unhappy path for robo courier",
                                          value: "unhappy",
                                        },
                                      ]}
                                      selected={testSpecification}
                                      required={true}
                                      onChange={(value) => {
                                        setTestSpecification(value);
                                      }}
                                      label="Test specification (For testing only)"
                                      placeholder="Test Specification"
                                    />
                                  )}
                                </div>

                                {!isOrderAlreadySent && (
                                  <div className="mt-5 flex gap-20">
                                    <Input
                                      type={"checkbox"}
                                      onChange={() => {
                                        setAsapDelivery(() => !asapDelivery);
                                      }}
                                      checkboxLabel={"ASAP Delivery"}
                                      placeholder="ASAP Specification"
                                    />
                                  </div>
                                )}
                              </div>
                              {!isDeliveryInProgress && (
                                <div className="inline-block bg-gray-100 p-3 place-content-center text-center rounded-md">
                                  <div>
                                    <div className="text-gray-500 text-xs">
                                      This quotation is valid until
                                    </div>
                                    <div className="text-blue-400 text-md font-semibold">
                                      {timeLeft.minutes}:{timeLeft.seconds}{" "}
                                      Minutes
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="sticky bottom-0 left-0 grid w-full  justify-items-end bg-gray-200 pr-3">
                      <div className="flex gap-5 p-2">
                        {!isDeliveryInProgress && (
                          <Button
                            type="button"
                            appearance="success"
                            isLoading={isProcessing}
                            disabled={isDisabled}
                            onClick={() => handleAcceptQuote()}
                          >
                            Accept Quote
                          </Button>
                        )}
                        {!isDeliveryInProgress && (
                          <Button
                            type="button"
                            appearance="secondary"
                            isLoading={isProcessing}
                            onClick={() => {
                              fetchUberQuotation();
                            }}
                          >
                            Re-Request
                          </Button>
                        )}
                        {isDeliveryInProgress && (
                          <Button
                            type="button"
                            appearance="alert"
                            isLoading={isProcessing}
                            onClick={() => {
                              cancelDelivery();
                            }}
                            disabled={false}
                          >
                            Cancel Delivery
                          </Button>
                        )}
                        <Button
                          type="button"
                          isLoading={isProcessing}
                          onClick={() => {
                            onClose();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
}
