import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { z } from "zod";

import {
  useNotificationConfig,
  useNotificationConfigMutation,
} from "@web-app/api/hooks/useNotificationConfig";
import { Button } from "@web-app/components";
import { Workspace } from "@web-app/types";
import { SmsConfigType } from "@web-app/types/notification";

import { TextFieldWithMentions } from "./TextFieldWithMentions";

const suggestions = [
  {
    id: "ORDER NUMBER",
    display: "ORDER NUMBER",
    markup: "/[ORDER NUMBER](ORDER NUMBER)",
    count: 15,
  },
  {
    id: "CUSTOMER NAME",
    display: "CUSTOMER NAME",
    markup: "/[CUSTOMER NAME](CUSTOMER NAME)",
    count: 8,
  },
  {
    id: "CLIENT NAME",
    display: "CLIENT NAME",
    markup: "/[CLIENT NAME](CLIENT NAME)",
    count: 10,
  },
  {
    id: "DRIVER NAME",
    display: "DRIVER NAME",
    markup: "/[DRIVER NAME](DRIVER NAME)",
    count: 8,
  },
  {
    id: "DRIVER CONTACT",
    display: "DRIVER CONTACT",
    markup: "/[DRIVER CONTACT](DRIVER CONTACT)",
    count: 12,
  },
  {
    id: "TRACKING URL",
    display: "TRACKING URL",
    markup: "/[TRACKING URL](TRACKING URL)",
    count: 100,
  },
];

// @ts-ignore
const suggestionMaxRefine = (value: any) => {
  let modifiedString = value;

  suggestions.forEach((element) => {
    modifiedString = modifiedString.replaceAll(
      element.markup,
      " ".repeat(element.count),
    );
  });

  return modifiedString.length <= 160;
};

const schema = z.object({
  orderAccept: z.string().min(1).refine(suggestionMaxRefine),
  driverAssign: z.string().min(1).refine(suggestionMaxRefine),
  pickup: z.string().min(1).refine(suggestionMaxRefine),
  nearbyPickup: z.string().min(1).refine(suggestionMaxRefine),
  startedDropoff: z.string().min(1).refine(suggestionMaxRefine),
  driverNearby: z.string().min(1).refine(suggestionMaxRefine),
  delivered: z.string().min(1).refine(suggestionMaxRefine),
  trackingUrl: z.string().min(1).refine(suggestionMaxRefine),
});

export default function SmsConfigForm({ workspace }: { workspace: Workspace }) {
  const [smsConfig, setSmsConfig] = useState<SmsConfigType>();
  const { data: config } = useNotificationConfig(workspace.id);
  const { updateNotificationConfig } = useNotificationConfigMutation(
    workspace.id,
  );

  const init = () => {
    if (config) {
      setSmsConfig(config.sms);
      Object.entries(config.sms).forEach(([key, value]) => {
        setValue(key, value.messageBody);
      });
    }
  };

  useEffect(init, [config]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onCheckboxChange = (name: string) => {
    if (smsConfig) {
      // @ts-ignore TODO
      setSmsConfig({
        ...smsConfig,
        [name]: {
          // @ts-ignore TODO
          ...smsConfig[name],
          // @ts-ignore TODO
          isEnabled: !smsConfig[name].isEnabled,
        },
      });
    }
  };

  // @ts-ignore
  const onSubmit = async (formData) => {
    const payload = smsConfig;

    if (payload) {
      Object.entries(formData).forEach(([key, value]) => {
        // @ts-ignore
        payload[key].messageBody = value;
      });
      try {
        await toast.promise(updateNotificationConfig.mutateAsync(payload), {
          loading: "Updating config ...",
          success: "Successfully Updated config!",
          error: "Something went wrong, please try again!",
        });
        toast.success("Successfully Updated");
      } catch (error: any) {
        toast.error(error.message);
      }
    }
  };

  return (
    <>
      {smsConfig && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-8">
            <Controller
              control={control}
              name="orderAccept"
              render={({ field }) => (
                <TextFieldWithMentions
                  name={field.name}
                  defaultValue={field.value}
                  maxLength={160}
                  label="Order accepted"
                  // tooltipText="When enabled, customers will receive notifications from the platform when their orders are accepted by the service provider. This means they'll be instantly informed once their orders have been confirmed and are being processed."
                  suggestions={suggestions}
                  isChecked={smsConfig?.orderAccept?.isEnabled}
                  onCheckboxChange={onCheckboxChange}
                  isError={Boolean(errors?.orderAccept)}
                  onValueChange={(event) => {
                    field.onChange(event);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="driverAssign"
              render={({ field }) => (
                <TextFieldWithMentions
                  name={field.name}
                  defaultValue={field.value}
                  maxLength={160}
                  label="Driver assigned"
                  // tooltipText="Enabling this option will notify customers when a driver has been assigned to their order for delivery. Customers will receive instant notifications once a driver has been allocated to handle their order, allowing them to track its progress in real-time."
                  isChecked={smsConfig?.driverAssign?.isEnabled}
                  onCheckboxChange={onCheckboxChange}
                  isError={Boolean(errors?.driverAssign)}
                  onValueChange={(event) => {
                    field.onChange(event);
                  }}
                  suggestions={suggestions}
                />
              )}
            />

            <Controller
              control={control}
              name="pickup"
              render={({ field }) => (
                <TextFieldWithMentions
                  defaultValue={field.value}
                  maxLength={160}
                  label="Driver on the way to pick up"
                  // tooltipText="Activating this option will trigger notifications to customers when a driver starts their journey towards the pick-up location. By enabling this setting, customers will receive timely updates as soon as the driver initiates the process, ensuring transparency and allowing them to anticipate the arrival of their order."
                  isChecked={smsConfig?.pickup?.isEnabled}
                  onCheckboxChange={onCheckboxChange}
                  onValueChange={(event) => {
                    field.onChange(event);
                  }}
                  suggestions={suggestions}
                  isError={Boolean(errors?.pickup)}
                  name={field.name}
                />
              )}
            />
            <Controller
              control={control}
              name="nearbyPickup"
              render={({ field }) => (
                <TextFieldWithMentions
                  defaultValue={field.value}
                  maxLength={160}
                  label="Driver nearby pick up"
                  // tooltipText="Activating this option will trigger notifications to customers when the driver is in close proximity to the pickup location. Enabling this setting ensures that customers are promptly informed when the driver is nearing the pickup point."
                  isChecked={smsConfig?.nearbyPickup?.isEnabled}
                  onCheckboxChange={onCheckboxChange}
                  onValueChange={(event) => {
                    field.onChange(event);
                  }}
                  suggestions={suggestions}
                  isError={Boolean(errors?.nearbyPickup)}
                  name={field.name}
                />
              )}
            />
            <Controller
              control={control}
              name="startedDropoff"
              render={({ field }) => (
                <TextFieldWithMentions
                  defaultValue={field.value}
                  maxLength={160}
                  label="Driver picked up the order"
                  // tooltipText="Enabling this option will prompt notifications to customers when a driver picks up the order and begins the journey towards the drop-off location. By activating this setting, customers will receive real-time updates as soon as the driver initiates the delivery process, allowing them to track the progress of their order accurately."
                  isChecked={smsConfig?.startedDropoff?.isEnabled}
                  onCheckboxChange={onCheckboxChange}
                  onValueChange={(event) => {
                    field.onChange(event);
                  }}
                  suggestions={suggestions}
                  isError={Boolean(errors?.startedDropoff)}
                  name={field.name}
                />
              )}
            />
            <Controller
              control={control}
              name="trackingUrl"
              render={({ field }) => (
                <TextFieldWithMentions
                  maxLength={160}
                  defaultValue={field.value}
                  label="Tracking Url"
                  tooltipText="Triggered once order is picked up. Can also be copied and pasted into any SMS notification."
                  isChecked={smsConfig?.trackingUrl?.isEnabled}
                  onCheckboxChange={onCheckboxChange}
                  onValueChange={(event) => {
                    field.onChange(event);
                  }}
                  suggestions={suggestions}
                  isError={Boolean(errors?.trackingUrl)}
                  name={field.name}
                />
              )}
            />
            <Controller
              control={control}
              name="driverNearby"
              render={({ field }) => (
                <TextFieldWithMentions
                  defaultValue={field.value}
                  maxLength={160}
                  label="Driver nearby drop-off"
                  // tooltipText="Activating this option will trigger notifications to customers when the driver is in close proximity to the drop-off location. Enabling this setting ensures that customers are promptly informed when the driver is nearing the drop-off point, allowing them to prepare for the delivery or make any necessary arrangements."
                  isChecked={smsConfig?.driverNearby?.isEnabled}
                  onCheckboxChange={onCheckboxChange}
                  onValueChange={(event) => {
                    field.onChange(event);
                  }}
                  suggestions={suggestions}
                  isError={Boolean(errors?.driverNearby)}
                  name={field.name}
                />
              )}
            />
            <Controller
              control={control}
              name="delivered"
              render={({ field }) => (
                <TextFieldWithMentions
                  maxLength={160}
                  defaultValue={field.value}
                  label="Delivery completed"
                  // tooltipText="Enabling this option will notify customers when the driver has successfully completed the delivery. By activating this notification, customers are promptly informed once their order has been delivered, providing them with timely updates on the status of their delivery."
                  isChecked={smsConfig?.delivered?.isEnabled}
                  onCheckboxChange={onCheckboxChange}
                  onValueChange={(event) => {
                    field.onChange(event);
                  }}
                  suggestions={suggestions}
                  isError={Boolean(errors?.delivered)}
                  name={field.name}
                />
              )}
            />
          </div>
          <div className="sticky bottom-0 left-0  grid  w-full justify-items-end bg-white">
            <div className="flex w-full justify-start gap-5 p-2">
              <Button
                type="submit"
                size="md"
                // isLoading={updateSmsConfig.isLoading}
              >
                Save
              </Button>
              <Button type="button" appearance="secondary" onClick={init}>
                Cancel
              </Button>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
