import type {
  CreateUserPayload,
  UpdateUserPayload,
  User,
} from "@web-app/types";
import http from "@web-app/utils/http";

const base_url = "/users";

export const list = async (workspaceId: string) => {
  return http
    .get<User[]>(base_url, {
      params: {
        workspaceId,
      },
    })
    .then((response) => response.data);
};

export const create = async (payload: CreateUserPayload) => {
  return http
    .post<User>(`${base_url}`, payload)
    .then((response) => response.data);
};

export const update = async (
  id: string,
  payload: Partial<UpdateUserPayload>,
) => {
  return http
    .patch<User>(`${base_url}/${id}`, payload)
    .then((response) => response.data);
};

export const updateCurrentWorkspace = async (workspaceId: string) => {
  return http
    .patch<User>(`${base_url}/switchWorkspace/${workspaceId}`, { workspaceId })
    .then((response) => response.data);
};
