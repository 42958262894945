import { format } from "date-fns";

function DateCell({ value }: { value: string }) {
  if (!value) {
    return "";
  }

  return (
    <div>
      <span>{format(new Date(value), "hh:mm a")}</span>
      <br />
      <span className="text-gray-400">
        {format(new Date(value), "LL/dd/yyyy")}
      </span>
    </div>
  );
}

export default DateCell;
