import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { feedbackIntegration } from "@sentry/react";
import { useLocation } from "@tanstack/react-router";
import clsx from "clsx";
import { Fragment, useState } from "react";

import { Avatar, Icon, WorkspaceSwitcher } from "@web-app/components";
import useDispatchStore from "@web-app/store/dispatchStore";

const navigation: {
  name: string;
  href: string;
  icon: React.ComponentProps<typeof Icon>["name"];
  selectedIcon: React.ComponentProps<typeof Icon>["name"];
}[] = [
  { name: "Dispatch", href: "/", icon: "house", selectedIcon: "sHouse" },
  { name: "Orders", href: "/manage", icon: "table", selectedIcon: "sTable" },
];

export default function Navigation({ logout }: { logout: () => void }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();
  const pathname = location.pathname;

  const currentNavigation = navigation.find((nav) => nav.href === pathname);
  const user = useDispatchStore((state) => state.user);

  // TODO: move this to global state
  const currentWorkspace = user?.currentWorkspace;
  const onClickSubmitFeedback = async () => {
    const feedback = feedbackIntegration({
      autoInject: false,
      showBranding: false,
      triggerLabel: "Submit a Feedback",
      formTitle: "Submit a Feedback",
      messagePlaceholder: "Whats the issue? What did you expect?",
      submitButtonLabel: "Submit Feedback",
      colorScheme: "light",
      useSentryUser: {
        email: "email",
        name: "name",
      },
      tags: {
        userName: user?.name,
        userEmail: user?.email,
        userWorkspace: user?.currentWorkspace.name,
      },
    });
    const form = await feedback.createForm();
    form.appendToDom();
    form.open();
  };

  return (
    <>
      <Transition show={isSidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setIsSidebarOpen}
        >
          <TransitionChild as={Fragment}>
            <div
              className={clsx([
                "fixed inset-0 bg-gray-900/80 transition-opacity ease-linear duration-300",
                "data-[enter]:data-[closed]:opacity-0",
                "data-[leave]:data-[closed]:opacity-100",
              ])}
            />
          </TransitionChild>

          <div className="fixed inset-0 flex">
            <TransitionChild as={Fragment}>
              <div
                className={clsx([
                  "transition ease-in-out duration-300 transform",
                  "data-[enter]:duration-300 data-[enter]:data-[closed]:-translate-x-full",
                  "data-[leave]:duration-300 data-[leave]:data-[closed]:translate-x-full",
                ])}
              >
                <DialogPanel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <TransitionChild as={Fragment}>
                    <div
                      className={clsx([
                        "absolute left-full top-0 flex w-16 justify-center pt-5 transition ease-in-out duration-300",
                        "data-[enter]:data-[closed]:opacity-0",
                        "data-[leave]:data-[closed]:opacity-100",
                      ])}
                    >
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setIsSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <Icon name="xMark" className="h-6 w-6 text-white" />
                      </button>
                    </div>
                  </TransitionChild>

                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                    {currentWorkspace && (
                      <div className="flex h-16 shrink-0 items-center">
                        <Avatar
                          name={currentWorkspace.name || ""}
                          size={10}
                          image={currentWorkspace.avatarUrl || ""}
                        />
                      </div>
                    )}
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="-mx-2 flex-1 space-y-1">
                        {navigation.map((item) => (
                          <li key={item?.name}>
                            <a
                              href={item.href}
                              className={clsx(
                                item.href === currentNavigation?.href
                                  ? "bg-gray-800 text-white"
                                  : "text-gray-400 hover:bg-gray-800 hover:text-white",
                                "group flex items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6",
                              )}
                            >
                              <Icon
                                name={
                                  currentNavigation?.href === item.href
                                    ? item.selectedIcon
                                    : item.icon
                                }
                                className="h-5 w-5 ml-2"
                              />
                              {item?.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </DialogPanel>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>

      {/* Static sidebar for desktop */}
      {user && (
        <div
          className={clsx(
            "relative hidden shadow-xl transition-all duration-200 ease-out lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block lg:bg-white lg:pb-4",
            isExpanded ? "lg:w-60" : "lg:w-20",
          )}
          onMouseEnter={() => setIsExpanded(true)}
          onMouseLeave={() => setIsExpanded(false)}
        >
          <WorkspaceSwitcher
            isExpanded={isExpanded}
            currentWorkspace={currentWorkspace}
          />
          <div className="flex h-[calc(100%-60px)] flex-col justify-between">
            <nav className={clsx("mt-8 px-5 transition-all ease-in")}>
              <ul
                role="list"
                className="flex flex-col justify-center space-y-2"
              >
                {navigation.map((item) => (
                  <li key={item?.name}>
                    <a
                      href={item.href}
                      className={clsx(
                        currentNavigation?.href === item.href
                          ? "text-gray-700"
                          : "text-gray-600 hover:text-gray-700",
                        "group flex items-center gap-x-3 rounded-md p-3 text-sm font-semibold leading-6 transition ease-in hover:bg-gray-100",
                      )}
                    >
                      <div className="flex h-4 w-4 items-center justify-center">
                        <Icon
                          name={
                            currentNavigation?.href === item.href
                              ? item.selectedIcon
                              : item.icon
                          }
                          className="h-5 w-5"
                        />
                      </div>
                      <div className={clsx(isExpanded ? "flex" : "hidden")}>
                        <span className={clsx("text-sm leading-none")}>
                          {item?.name}
                        </span>
                      </div>
                      <span className="sr-only">{item?.name}</span>
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
            <div className="flex flex-col items-center space-y-4 ">
              <div
                className={clsx(
                  "relative w-full px-4",
                  !isExpanded && "flex justify-center",
                )}
              >
                <Menu
                  as="div"
                  className="rounded-md p-3 hover:bg-gray-100 items-center flex transition ease-in"
                >
                  <MenuButton>
                    <div className="flex gap-4 text-sm">
                      <Icon
                        name="questionCircle"
                        className="h-5 w-5 text-gray-600 hover:text-gray-700"
                      />
                      {isExpanded && (
                        <span
                          className={clsx(
                            "font-semibold text-gray-700",
                            isExpanded ? "flex" : "hidden",
                          )}
                        >
                          Support
                        </span>
                      )}
                    </div>
                  </MenuButton>

                  <MenuItems
                    transition
                    className="transition duration-200 ease-out data-[enter]:scale-100 data-[closed]:scale-95 data-[closed]:opacity-0 absolute -top-[50px] right-[-230px] z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="py-1">
                      <MenuItem
                        as="div"
                        className="data-[active]:bg-gray-100 data-[active]:text-gray-900 text-gray-700"
                      >
                        <a
                          href="https://docs.fulflld.com"
                          target="_blank"
                          rel="noreferrer"
                          className="block px-4 py-2 text-sm"
                        >
                          Knowledgebase
                        </a>
                      </MenuItem>
                      <MenuItem
                        as="div"
                        className="data-[active]:bg-gray-100 data-[active]:text-gray-900 text-gray-700"
                      >
                        <a
                          href="#"
                          id="sentry-feedback"
                          className="block px-4 py-2 text-sm"
                          onClick={onClickSubmitFeedback}
                        >
                          Submit Feedback
                        </a>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </Menu>
              </div>

              <div className="relative cursor-pointer border border-transparent p-2 transition ease-in w-full ">
                <Menu as="div" className="w-full">
                  <MenuButton className="w-full">
                    <div className="flex items-center justify-center gap-4 text-sm w-full hover:bg-gray-100 px-2 py-2 rounded-md transition ease-in">
                      <Avatar
                        name={user?.name || ""}
                        size={10}
                        image={user.image || ""}
                      />
                      <span
                        className={clsx(
                          "font-semibold text-gray-700 text-ellipsis w-[125px] overflow-hidden whitespace-nowrap",
                          isExpanded ? "block" : "hidden",
                        )}
                      >
                        {user?.name}
                      </span>
                    </div>
                  </MenuButton>

                  <MenuItems
                    transition
                    className="transition duration-200 ease-out data-[enter]:scale-100 data-[closed]:scale-95 data-[closed]:opacity-0 absolute -top-[120px] right-[-240px] z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="px-4 py-3">
                      <p className="text-sm">Signed in as</p>
                      <p className="truncate text-sm font-medium text-gray-900">
                        {user.email}
                      </p>
                    </div>
                    <div className="py-1">
                      <MenuItem
                        as="div"
                        className="data-[active]:bg-gray-100 data-[active]:text-gray-900 text-gray-700"
                      >
                        <a href="/settings" className="block px-4 py-2 text-sm">
                          Settings
                        </a>
                      </MenuItem>
                      <MenuItem
                        as="div"
                        className="data-[active]:bg-gray-100 data-[active]:text-gray-900 text-gray-700"
                      >
                        <a
                          href="#"
                          className="block px-4 py-2 text-sm"
                          onClick={logout}
                        >
                          Sign Out
                        </a>
                      </MenuItem>
                    </div>
                  </MenuItems>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-gray-900 px-4 py-4 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-400 lg:hidden"
          onClick={() => setIsSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Icon name="bars" className="h-6 w-6" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-white">
          Dashboard
        </div>
        <a href="#">
          <span className="sr-only">Your profile</span>
        </a>
      </div>
    </>
  );
}
