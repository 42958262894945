import type { AxiosError } from "axios";
import type { FormEvent } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";

import { useSessionUser, useUserMutation } from "@web-app/api";
import { Button, Icon, Input, SelectInput } from "@web-app/components";
import { CreateUserPayload, UserRole, UserType } from "@web-app/types";
import { validatePhoneNumber } from "@web-app/utils/helpers";

export default function CreateUserPage({
  setCurrentUserId,
}: {
  setCurrentUserId: (userId: string) => void;
}) {
  const { data: currentUser } = useSessionUser();
  const [userType, setUserType] = useState<UserType>("USER");
  const [userRole, setUserRole] = useState<UserRole>(UserRole.ADMIN);

  const onChangeUserRole = (value: UserRole) => {
    setUserRole(value);
  };

  const [userPassword, setUserPassword] = useState("");

  const { createUser } = useUserMutation();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const target = e.target as typeof e.target & {
      name: { value: string };
      email: { value: string };
      phone?: { value: string };
    };

    const formData: CreateUserPayload = {
      name: target.name.value,
      email: target.email.value,
      type: userType,
      roles: [
        {
          workspaceId: currentUser!.currentWorkspaceId,
          userId: currentUser!.id,
          role: userRole,
        },
      ],
      password: userPassword,
      image: "",
      phone: target.phone?.value || "",
      currentWorkspaceId: currentUser?.currentWorkspaceId || "",
      status: "INACTIVE",
      emailVerified: "false",
      driverProfileId: "",
    };

    if (userType === "DRIVER") {
      const phoneNumber = target.phone?.value || "";

      if (!validatePhoneNumber(phoneNumber)) {
        return;
      }

      formData.phone = phoneNumber;
    }

    const toastPromise = toast.promise(
      createUser.mutateAsync(formData, {
        onSuccess: () => {
          setCurrentUserId("");
        },
      }),
      {
        loading: "Creating User ...",
        success: "Successfully Created User!",
        error: "Something went wrong, please try again!",
      },
    );

    toastPromise.catch((error: AxiosError) => {
      type errorType = {
        message: string;
      };

      if (
        (error.response?.data as string)?.includes(
          "CreateUser: user with this email already exists",
        )
      ) {
        toast.error("There is an existing user with this email address");
      } else {
        toast.error((error as errorType).message);
      }
    });
  };

  return (
    <div className="max-w-lg p-5">
      <div
        onClick={() => setCurrentUserId("")}
        className="mb-3 cursor-pointer text-sm text-gray-500"
      >
        <Icon name="fasChevronLeft" className="mr-1" /> Back
      </div>

      <h1 className="text-lg font-semibold">Create User</h1>
      <div className="mt-3">
        <form onSubmit={onSubmit}>
          <div className="mb-3">
            <SelectInput
              label="Type"
              options={[
                {
                  label: "User",
                  value: "USER",
                },
                {
                  label: "Driver",
                  value: "DRIVER",
                },
              ]}
              // display="normal"
              placeholder="Select User Type"
              selected={userType}
              onChange={(value) => {
                if (value === "DRIVER") {
                  setUserRole(UserRole.DRIVER);
                }

                setUserType(value);
              }}
              name="user-type-select"
              required
            />
          </div>
          <div>
            <Input type="text" label="Full Name" name="name" required />
          </div>
          <div className="mt-3">
            <Input type="email" label="Email" name="email" required />
          </div>
          {userType === "DRIVER" && (
            <div className="mt-3">
              <Input label="Phone" type="string" name="phone" />
            </div>
          )}
          {userType === "USER" && (
            <div className="mt-4">
              <div className="flex items-center ">
                <SelectInput
                  options={[
                    {
                      label: "Admin",
                      value: "ADMIN",
                    },
                    {
                      label: "Dispatcher",
                      value: "DISPATCHER",
                    },
                  ]}
                  label="Role"
                  selected={userRole}
                  // display="normal"
                  onChange={(value) => {
                    onChangeUserRole(value);
                  }}
                  placeholder="Select Role"
                  required
                />
              </div>
            </div>
          )}
          <div className="mt-3">
            <Input
              type="password"
              label="Password"
              name="password"
              value={userPassword}
              onChange={(e) => setUserPassword(e.target.value)}
            />
          </div>
          <div className="mt-6 flex gap-2">
            <Button type="submit" isLoading={createUser.isPending}>
              Create
            </Button>
            <Button
              type="button"
              appearance="secondary"
              onClick={() => setCurrentUserId("")}
              disabled={createUser.isPending}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
