import {
  UpdateWorkspacePayload,
  Workspace,
  WorkspaceConnection,
} from "@web-app/types";
import http from "@web-app/utils/http";

const base_url = "/workspaces";

export const update = async (
  id: string,
  payload: Partial<UpdateWorkspacePayload>,
) => {
  return http
    .patch<Workspace>(`${base_url}/${id}`, payload)
    .then((response) => response.data);
};

export const getWorkspaceConnections = async (workspaceId: string) => {
  return http
    .get<WorkspaceConnection[]>(`${base_url}/${workspaceId}/connections`)
    .then((response) => response.data);
};
