import clsx from "clsx";
import { format } from "date-fns";

import { Icon } from "@web-app/components";
import { OrderTimeline } from "@web-app/types";
import { OrderTimelineSchema } from "@web-app/utils/helpers";

export default function ConnectedWorkspaceActivity({
  item,
  showTimelineBar,
}: {
  item: OrderTimeline;
  showTimelineBar: boolean;
}) {
  return (
    <li key={item.id} className="relative flex gap-x-4">
      <div
        className={clsx(
          showTimelineBar ? "-bottom-6" : "h-6",
          "absolute left-0 top-0 flex w-6 justify-center",
        )}
      >
        <div className="w-px bg-gray-200" />
      </div>
      <div className="relative flex h-7 w-6 flex-none items-center justify-center bg-white">
        <Icon name="fasGlobe" className="h-4" />
      </div>
      <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
        <span className={"inline-block font-medium text-gray-900"}>
          {!item.updatedValue &&
            item.previousValue &&
            `${OrderTimelineSchema[item.entity].action.DELETE.text} ${
              item.previousValue
            } workspace`}
          {item.updatedValue &&
            `${OrderTimelineSchema[item.entity].action[item.action].text} ${
              item.updatedValue
            } workspace`}
        </span>
        <span className="block">
          {`${format(new Date(item?.createdAt), "MMMM dd, yyyy, hh:mma")} by ${
            item.createdBy?.name
          }`}
        </span>
      </p>
    </li>
  );
}
