import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import clsx from "clsx";

export default function Tabs({
  tabs,
}: {
  tabs: {
    title: string;
    content: React.ReactNode;
  }[];
}) {
  return (
    <TabGroup className="">
      <TabList className="sticky flex gap-3">
        {tabs.map((tab) => (
          <Tab key={tab.title}>
            {({ selected }) => (
              <div
                className={clsx(
                  "text-sm inline-flex cursor-pointer border-b-2 px-4 py-2 font-semibold transition ease-in hover:text-gray-500 hover:border-gray-500",
                  selected && "border-gray-500 text-gray-600",
                  !selected && "border-transparent text-gray-400",
                )}
              >
                {tab.title}
              </div>
            )}
          </Tab>
        ))}
      </TabList>

      <TabPanels className="flex mt-4">
        {tabs.map((tab) => (
          <TabPanel key={tab.title}>{tab.content}</TabPanel>
        ))}
      </TabPanels>
    </TabGroup>
  );
}
