import { GetRowIdFunc, GridReadyEvent } from "ag-grid-enterprise";
import { useMemo, useState } from "react";

import { useGetOrder } from "@web-app/api";
import Table from "@web-app/components/Table";
import { INITIAL_TABLE_DATA } from "@web-app/pages/manage/data";
import useDispatchStore from "@web-app/store/dispatchStore";
import { Order, TableHeadersName } from "@web-app/types";

import BulkActionsTooltip from "../../BulkActions";

export default function SidebarTableView() {
  const [gridApiRef, setGridApiRef] = useState<GridReadyEvent["api"] | null>(
    null,
  );
  const [selectedOrderIds, setSelectedOrderIds] = useState<string[]>([]);

  const { data: orders } = useGetOrder();

  const ordersList = useMemo(() => {
    if (!orders) {
      return [];
    }
    return Array.from(orders.values());
  }, [orders]);

  const visibleColumns: string[] = [
    TableHeadersName.OrderId,
    TableHeadersName.Connections,
    TableHeadersName.Status,
    TableHeadersName.Assignee,
    TableHeadersName.PickupAddress,
    TableHeadersName.DropoffAddress,
    TableHeadersName.ActualPickupTime,
    TableHeadersName.ActualDropOffTime,
    TableHeadersName.ScheduledStartTime,
    TableHeadersName.ScheduledEndTime,
  ];

  const columnDefs = useMemo(() => {
    const defs = [
      {
        headerCheckboxSelection: true,
        headerCheckboxSelectionCurrentPageOnly: true,
        checkboxSelection: true,
        showDisabledCheckboxes: true,
        lockPosition: "left",
        maxWidth: 100,
        suppressHeaderMenuButton: true,
      },
      ...INITIAL_TABLE_DATA.filter((def) => {
        return visibleColumns.includes(def.headerName!);
      }),
    ];
    return defs;
  }, []);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApiRef(params.api);
  };

  const onSelectionChange = () => {
    const selectedRows = gridApiRef?.getSelectedRows();
    if (selectedRows?.length) {
      if (selectedRows.length > 1) {
        setSelectedOrderIds(selectedRows.map((row) => row.id));
        useDispatchStore.setState({
          focusedOrderId: null,
        });
      } else {
        const order = selectedRows[0];
        useDispatchStore.setState({
          focusedOrderId: order.id,
        });
        setSelectedOrderIds([order.id]);
      }
    } else {
      useDispatchStore.setState({
        focusedOrderId: null,
      });
      setSelectedOrderIds([]);
    }
  };

  const onUnselectAllOrders = () => {
    setSelectedOrderIds([]);
    gridApiRef?.deselectAll();
  };

  const onSuccessBulkAction = async () => {
    onUnselectAllOrders;
  };

  const getRowId: GetRowIdFunc<Order> = (params) => {
    return params.data.id;
  };

  return (
    <>
      {selectedOrderIds.length > 1 && (
        <BulkActionsTooltip
          className="h-[33.8px] mb-2 w-full"
          selectedOrderIds={selectedOrderIds}
          onSuccess={onSuccessBulkAction}
          onUnselectAllOrders={onUnselectAllOrders}
        />
      )}

      <Table
        className="w-full ag-theme-quartz"
        rowData={ordersList}
        // @ts-ignore
        columnDefs={columnDefs}
        pagination
        paginationPageSizeSelector={[20, 50, 100]}
        paginationPageSize={20}
        suppressMovableColumns={true}
        // @ts-ignore
        onGridReady={onGridReady}
        rowSelection="multiple"
        suppressRowDeselection={true}
        onSelectionChanged={onSelectionChange}
        // @ts-ignore
        getRowId={getRowId}
      />
    </>
  );
}
