import { useState } from "react";

import { useUsers } from "@web-app/api";
import { Button } from "@web-app/components";
import { Workspace } from "@web-app/types";

import TabTitle from "../TabTitle";

import CreateUserPage from "./CreateUserPage";
import FTab from "./FTab";
import UserPage from "./UserPage";

export default function UsersTab({ workspace }: { workspace: Workspace }) {
  const { data: usersData, isLoading } = useUsers(workspace.id);

  const [currentUserId, setCurrentUserId] = useState("");

  if (currentUserId === "new") {
    return <CreateUserPage setCurrentUserId={setCurrentUserId} />;
  }

  if (currentUserId && usersData?.get(currentUserId)) {
    return (
      <UserPage
        setCurrentUserId={setCurrentUserId}
        user={usersData.get(currentUserId)!}
      />
    );
  }

  return (
    <div className="p-4 px-6  max-h-[calc(100vh-60px)]">
      <div className="sticky top-0 z-20 bg-white">
        <div>
          <TabTitle icon="fasUsers" title="Users" styles="mb-3" />

          <p className="text-sm text-gray-400">
            {`Manage users for your account`}
          </p>
        </div>
        <div className="py-4">
          <Button
            size="sm"
            type="button"
            onClick={() => setCurrentUserId("new")}
            title="Add User"
          >
            Add User
          </Button>
        </div>
      </div>

      <div className="mt-3">
        <FTab
          isLoading={isLoading}
          usersData={usersData!}
          setCurrentUserId={setCurrentUserId}
        />
      </div>
    </div>
  );
}
