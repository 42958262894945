import type {
  CreateSavedAddessPayload,
  SavedAddress,
  UpdateSavedAddessPayload,
} from "@web-app/types/savedAddress";
import http from "@web-app/utils/http";

const base_url = "/savedAddresses";

export const list = async (workspaceId: string) => {
  return http
    .get<SavedAddress[]>(`${base_url}?workspaceId=${workspaceId}`)
    .then((response) => response.data);
};

export const create = async (address: CreateSavedAddessPayload) => {
  return http
    .post<SavedAddress>(base_url, address)
    .then((response) => response.data);
};

export const update = async (payload: Partial<UpdateSavedAddessPayload>) => {
  return http
    .patch<SavedAddress>(`${base_url}/${payload.id}`, payload)
    .then((response) => response.data);
};

export const remove = async (addressId: string) => {
  return http.delete(`${base_url}/${addressId}`);
};
