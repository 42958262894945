import type { App, CreateAppPayload, UpdateAppPayload } from "@web-app/types";
import http from "@web-app/utils/http";

const base_url = "/apps";

export const list = async (workspaceId: string) => {
  return http
    .get<App[]>(base_url, {
      params: {
        workspaceId,
      },
    })
    .then((response) => response.data);
};

export const create = async (payload: CreateAppPayload) => {
  return http.post<App>(base_url, payload).then((response) => response.data);
};

export const update = async (
  id: string,
  payload: Partial<UpdateAppPayload>,
) => {
  return http
    .patch<App>(`${base_url}/${id}`, payload)
    .then((response) => response.data);
};

// export const remove = async (addressId: string) => {
//   return http.delete(`${base_url}/${addressId}`);
// };
