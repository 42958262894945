/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SigninRouteImport } from './routes/signin.route'
import { Route as SettingsRouteImport } from './routes/settings.route'
import { Route as ManageRouteImport } from './routes/manage.route'
import { Route as IndexRouteImport } from './routes/index.route'
import { Route as TrackingOrdersOrderIdRouteImport } from './routes/tracking.orders.$orderId.route'
import { Route as AuthGoogleCallbackRouteImport } from './routes/auth.google.callback.route'
import { Route as WorkspacesWorkspaceIdOrdersCreateImport } from './routes/workspaces.$workspaceId.orders.create'

// Create/Update Routes

const SigninRouteRoute = SigninRouteImport.update({
  id: '/signin',
  path: '/signin',
  getParentRoute: () => rootRoute,
} as any)

const SettingsRouteRoute = SettingsRouteImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => rootRoute,
} as any)

const ManageRouteRoute = ManageRouteImport.update({
  id: '/manage',
  path: '/manage',
  getParentRoute: () => rootRoute,
} as any)

const IndexRouteRoute = IndexRouteImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const TrackingOrdersOrderIdRouteRoute = TrackingOrdersOrderIdRouteImport.update(
  {
    id: '/tracking/orders/$orderId',
    path: '/tracking/orders/$orderId',
    getParentRoute: () => rootRoute,
  } as any,
)

const AuthGoogleCallbackRouteRoute = AuthGoogleCallbackRouteImport.update({
  id: '/auth/google/callback',
  path: '/auth/google/callback',
  getParentRoute: () => rootRoute,
} as any)

const WorkspacesWorkspaceIdOrdersCreateRoute =
  WorkspacesWorkspaceIdOrdersCreateImport.update({
    id: '/workspaces/$workspaceId/orders/create',
    path: '/workspaces/$workspaceId/orders/create',
    getParentRoute: () => rootRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexRouteImport
      parentRoute: typeof rootRoute
    }
    '/manage': {
      id: '/manage'
      path: '/manage'
      fullPath: '/manage'
      preLoaderRoute: typeof ManageRouteImport
      parentRoute: typeof rootRoute
    }
    '/settings': {
      id: '/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof SettingsRouteImport
      parentRoute: typeof rootRoute
    }
    '/signin': {
      id: '/signin'
      path: '/signin'
      fullPath: '/signin'
      preLoaderRoute: typeof SigninRouteImport
      parentRoute: typeof rootRoute
    }
    '/auth/google/callback': {
      id: '/auth/google/callback'
      path: '/auth/google/callback'
      fullPath: '/auth/google/callback'
      preLoaderRoute: typeof AuthGoogleCallbackRouteImport
      parentRoute: typeof rootRoute
    }
    '/tracking/orders/$orderId': {
      id: '/tracking/orders/$orderId'
      path: '/tracking/orders/$orderId'
      fullPath: '/tracking/orders/$orderId'
      preLoaderRoute: typeof TrackingOrdersOrderIdRouteImport
      parentRoute: typeof rootRoute
    }
    '/workspaces/$workspaceId/orders/create': {
      id: '/workspaces/$workspaceId/orders/create'
      path: '/workspaces/$workspaceId/orders/create'
      fullPath: '/workspaces/$workspaceId/orders/create'
      preLoaderRoute: typeof WorkspacesWorkspaceIdOrdersCreateImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRouteRoute
  '/manage': typeof ManageRouteRoute
  '/settings': typeof SettingsRouteRoute
  '/signin': typeof SigninRouteRoute
  '/auth/google/callback': typeof AuthGoogleCallbackRouteRoute
  '/tracking/orders/$orderId': typeof TrackingOrdersOrderIdRouteRoute
  '/workspaces/$workspaceId/orders/create': typeof WorkspacesWorkspaceIdOrdersCreateRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRouteRoute
  '/manage': typeof ManageRouteRoute
  '/settings': typeof SettingsRouteRoute
  '/signin': typeof SigninRouteRoute
  '/auth/google/callback': typeof AuthGoogleCallbackRouteRoute
  '/tracking/orders/$orderId': typeof TrackingOrdersOrderIdRouteRoute
  '/workspaces/$workspaceId/orders/create': typeof WorkspacesWorkspaceIdOrdersCreateRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRouteRoute
  '/manage': typeof ManageRouteRoute
  '/settings': typeof SettingsRouteRoute
  '/signin': typeof SigninRouteRoute
  '/auth/google/callback': typeof AuthGoogleCallbackRouteRoute
  '/tracking/orders/$orderId': typeof TrackingOrdersOrderIdRouteRoute
  '/workspaces/$workspaceId/orders/create': typeof WorkspacesWorkspaceIdOrdersCreateRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/manage'
    | '/settings'
    | '/signin'
    | '/auth/google/callback'
    | '/tracking/orders/$orderId'
    | '/workspaces/$workspaceId/orders/create'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/manage'
    | '/settings'
    | '/signin'
    | '/auth/google/callback'
    | '/tracking/orders/$orderId'
    | '/workspaces/$workspaceId/orders/create'
  id:
    | '__root__'
    | '/'
    | '/manage'
    | '/settings'
    | '/signin'
    | '/auth/google/callback'
    | '/tracking/orders/$orderId'
    | '/workspaces/$workspaceId/orders/create'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRouteRoute: typeof IndexRouteRoute
  ManageRouteRoute: typeof ManageRouteRoute
  SettingsRouteRoute: typeof SettingsRouteRoute
  SigninRouteRoute: typeof SigninRouteRoute
  AuthGoogleCallbackRouteRoute: typeof AuthGoogleCallbackRouteRoute
  TrackingOrdersOrderIdRouteRoute: typeof TrackingOrdersOrderIdRouteRoute
  WorkspacesWorkspaceIdOrdersCreateRoute: typeof WorkspacesWorkspaceIdOrdersCreateRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRouteRoute: IndexRouteRoute,
  ManageRouteRoute: ManageRouteRoute,
  SettingsRouteRoute: SettingsRouteRoute,
  SigninRouteRoute: SigninRouteRoute,
  AuthGoogleCallbackRouteRoute: AuthGoogleCallbackRouteRoute,
  TrackingOrdersOrderIdRouteRoute: TrackingOrdersOrderIdRouteRoute,
  WorkspacesWorkspaceIdOrdersCreateRoute:
    WorkspacesWorkspaceIdOrdersCreateRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/manage",
        "/settings",
        "/signin",
        "/auth/google/callback",
        "/tracking/orders/$orderId",
        "/workspaces/$workspaceId/orders/create"
      ]
    },
    "/": {
      "filePath": "index.route.tsx"
    },
    "/manage": {
      "filePath": "manage.route.tsx"
    },
    "/settings": {
      "filePath": "settings.route.tsx"
    },
    "/signin": {
      "filePath": "signin.route.tsx"
    },
    "/auth/google/callback": {
      "filePath": "auth.google.callback.route.tsx"
    },
    "/tracking/orders/$orderId": {
      "filePath": "tracking.orders.$orderId.route.tsx"
    },
    "/workspaces/$workspaceId/orders/create": {
      "filePath": "workspaces.$workspaceId.orders.create.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
