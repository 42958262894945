import clsx from "clsx";
import toast from "react-hot-toast";

import { useUserMutation, useWorkspaceRoles } from "@web-app/api";
import useDispatchStore from "@web-app/store/dispatchStore";
import type { Workspace } from "@web-app/types";

import Avatar from "../Avatar";
import { Icon } from "../Icon";
import SearchableDropdown from "../SearchableDropdown";

export default function WorkspaceSwitcher({
  currentWorkspace,
  isExpanded,
}: {
  currentWorkspace?: Workspace;
  isExpanded: boolean;
}) {
  const user = useDispatchStore((state) => state.user);
  const { switchWorkspace } = useUserMutation();

  const { data: workspaceRoles } = useWorkspaceRoles();
  const handleUpdateWorkspace = async (option: { value: string }) => {
    const workspaceId = option.value;
    try {
      const promise = toast.promise(switchWorkspace.mutateAsync(workspaceId), {
        loading: "Switching workspace...",
        success: "Workspace switched successfully. Refreshing ...",
        error: "Failed to switch workspace",
      });
      promise.then(() => {
        window.location.reload();
      });
    } catch (e) {
      toast.error("Failed to switch workspace");
    }
  };

  return (
    <div className="mt-5 flex shrink-0 items-center px-5">
      {user && currentWorkspace && (
        <div className="flex cursor-pointer items-center gap-2 relative">
          <SearchableDropdown
            panelClassName="right-[-280px] top-[0px] transition duration-200 ease-out"
            options={(workspaceRoles ?? [])
              .map((role) => ({
                id: role.id,
                label: role.workspace.name,
                value: role.workspace.id,
                avatar: {
                  name: role.workspace.name,
                  url: role.workspace?.avatarUrl || "",
                },
              }))
              .filter((role) => role.value !== currentWorkspace.id)
              .sort((a, b) => a.label.localeCompare(b.label))}
            onSelect={handleUpdateWorkspace}
            buttonComponent={
              <div className="flex gap-3 items-center">
                <Avatar
                  name={currentWorkspace.name || ""}
                  size={10}
                  image={currentWorkspace.avatarUrl || ""}
                />
                <span
                  className={clsx(
                    "text-sm font-semibold text-gray-700 flex items-center gap-4",
                    isExpanded ? "flex" : "hidden",
                  )}
                >
                  <span className="text-ellipsis w-[125px] overflow-hidden whitespace-nowrap">
                    {currentWorkspace.name}
                  </span>

                  {workspaceRoles && workspaceRoles.length > 1 && (
                    <Icon name="chevronRight" />
                  )}
                </span>
              </div>
            }
            placeholder="Search Workspace"
          />
        </div>
      )}
    </div>
  );
}
