import { useQuery } from "@tanstack/react-query";

import { getPlaceAutoCompletions, getPlaceLatLng } from "../services/google";

const queryKey = "google";

export const useAutoCompletion = (address: string) => {
  return useQuery({
    queryKey: [queryKey, address],
    queryFn: async () => {
      if (address === "") {
        return [];
      }
      const response = await getPlaceAutoCompletions(address);
      return response;
    },
    refetchOnWindowFocus: false,
  });
};

export const usePlaceLatLng = (placeId: string) => {
  return useQuery({
    queryKey: [queryKey, placeId],
    queryFn: async () => {
      const response = await getPlaceLatLng(placeId);
      return response;
    },
    refetchOnWindowFocus: false,
  });
};
