import clsx from "clsx";

import { mapLegendOptions } from "@web-app/utils/order";

export default function MapLegend() {
  return (
    <div className="absolute bottom-0 left-0 right-0 ml-[100px] hidden w-[calc(100%-550px)] items-center xl:flex">
      <div className="flex w-full items-center bg-transparent px-4 py-2 first:pl-0 gap-2">
        {mapLegendOptions.map((item, idx) => (
          <div className="divide flex items-center" key={idx}>
            <div
              className={clsx(
                "flex items-center gap-1 ",
                (item.label === "Driver Idle" ||
                  item.label === "Hub" ||
                  item.label === "Pickup") &&
                  "border-l pl-2 border-slate-300",
              )}
            >
              <div className="flex items-center gap-2">
                <img src={item.image} alt={item.label} className={item.style} />
              </div>
              <div
                className={clsx(
                  "text-xs flex gap-2 items-center font-semibold",
                  item.color,
                )}
              >
                {item.label}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
