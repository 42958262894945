import toast from "react-hot-toast";

import { useCustomFieldMutation } from "@web-app/api";
import { Icon, Toggle } from "@web-app/components";
import type { CustomField } from "@web-app/types";

import { orderCardIconMapper } from "../utils";

type CustomFieldCardPropsType = {
  field: CustomField;
  onClickEdit: () => void;
};

export default function CustomFieldCard({
  field,
  onClickEdit,
}: CustomFieldCardPropsType) {
  const { updateCustomField } = useCustomFieldMutation();

  const getTransformedText = (
    isVisibleOrder: boolean,
    isVisibleMobile: boolean,
  ) => {
    if (isVisibleOrder && isVisibleMobile) {
      return "Show in dispatcher app & driver app";
    } else if (isVisibleOrder) {
      return "Show in dispatcher app";
    } else if (isVisibleMobile) {
      return "Show in driver app";
    } else {
      return "Not visible anywhere";
    }
  };

  return (
    <div
      key={field?.id}
      className="grid grid-cols-4 justify-start mb-6 last:mb-0 w-full border border-gray-200 p-4 rounded-md"
    >
      <div className="flex items-center">
        <h1 className="font-semibold flex gap-4 items-center">
          <Icon
            name={orderCardIconMapper[field.type]}
            className="w-4 text-gray-900"
          />
          <span className="text-base text-black leading-none">
            {field.name}
          </span>
        </h1>
      </div>
      <div className="hidden md:col-span-2 md:flex items-center">
        <p className="text-xs leading-none text-gray-500">
          {getTransformedText(field.isVisibleOrder, field.isVisibleMobile)}
        </p>
      </div>
      <div className="flex gap-9 md:gap-16 lg:gap-20  items-center place-self-end">
        <Toggle
          label={field.isActive ? "Enabled" : "Disabled"}
          isChecked={field.isActive}
          onChange={async () => {
            await toast.promise(
              updateCustomField.mutateAsync({
                payload: {
                  isActive: !field.isActive,
                },
                customFieldId: field.id,
              }),
              {
                loading: "Updating...",
                success: "Updated successfully",
                error: "Failed to update",
              },
            );
          }}
        />
        <div
          className="px-3 cursor-pointer hover:bg-gray-200 rounded-md transition ease-in"
          onClick={onClickEdit}
        >
          <Icon name="chevronRight" />
        </div>
      </div>
    </div>
  );
}
