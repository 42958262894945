import { useState } from "react";

import { Input } from "@web-app/components";
import type { User } from "@web-app/types";

import UsersTable from "./UsersTable";
import { searchContacts } from "./helpers";

export default function FTab({
  isLoading,
  usersData,
  setCurrentUserId,
}: {
  isLoading: boolean;
  usersData: Map<string, User>;
  setCurrentUserId: (userId: string) => void;
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const userIds = searchContacts(searchTerm, usersData);

  return (
    <>
      <div className="mb-3">
        <Input
          placeholder="Search by name or email"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="max-w-lg"
          type="text"
          label=""
        />
      </div>
      <UsersTable
        userIds={userIds}
        setCurrentUserId={setCurrentUserId}
        isLoading={isLoading}
        usersData={usersData}
      />
    </>
  );
}
