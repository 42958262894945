import { Popover, Transition } from "@headlessui/react";
import clsx from "clsx";
import { format } from "date-fns/format";
import { enUS } from "date-fns/locale/en-US";
import { useState } from "react";
import { DatePickerCalendar } from "react-nice-dates";

import Button from "../Button";
import Input from "../Input";
import SelectInput from "../SelectInput";

type DateInputPropTypes = {
  value: Date;
  onChange?: (value: Date) => void;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  name?: string;
  readOnly?: boolean;
  minDate?: Date;
  maxDate?: Date;
};

function DateInput(props: DateInputPropTypes) {
  const [dateTime, setDateTime] = useState(props.value);
  const [hours, setHours] = useState(format(props.value, "hh"));
  const [minutes, setMinutes] = useState(format(props.value, "mm"));
  const [meridiem, setMeridiem] = useState(format(props.value, "a"));

  const onChange = () => {
    props.onChange?.(
      new Date(
        `${format(new Date(dateTime), "LLLL d, u")} ${
          meridiem === "AM"
            ? parseInt(hours) === 12
              ? parseInt("00")
              : parseInt(hours)
            : parseInt(hours) === 12
              ? parseInt(hours)
              : parseInt(hours) + 12
        }:${minutes}:00`,
      ),
    );
  };

  if (props.readOnly) {
    return (
      <div
        className="relative mb-6 w-full md:mb-0"
        data-testid={`date-input-${props.name}`}
      >
        {props.label && (
          <label
            className={clsx(
              "mb-1 block text-xs font-medium leading-5 tracking-wide",
              props.disabled ? "text-gray-400" : "text-gray-700",
            )}
          >
            {props.label}
            {props.required && "*"}
          </label>
        )}
        <span className="tw-form-field-readonly-style">
          {format(props.value, "MM/dd/yyyy' 'hh:mm aa")}
        </span>
      </div>
    );
  }

  return (
    <div
      className="relative mb-6 w-full md:mb-0"
      data-testid={`date-input-${props.name}`}
    >
      {props.label && (
        <label
          className={clsx(
            "mb-1 block text-xs font-medium leading-5 tracking-wide",
            props.disabled ? "text-gray-400" : "text-gray-700",
          )}
        >
          {props.label}
          {props.required && "*"}
        </label>
      )}
      {props.readOnly ? (
        <span className="tw-form-field-readonly-style">
          {format(props.value, "MM/dd/yyyy' 'hh:mm aa")}
        </span>
      ) : (
        <Popover>
          <Popover.Button
            className="tw-form-field-style flex items-center justify-start"
            disabled={props.disabled}
            name="button"
          >
            {format(props.value, "MM/dd/yyyy' 'hh:mm aa")}
          </Popover.Button>
          <Transition
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            className="relative z-[60] shadow-md"
            as="div"
          >
            <Popover.Panel className="absolute top-0 z-10  h-full w-full bg-white">
              {({ close }) => (
                <div className="flex max-w-sm flex-col rounded-md border bg-white p-2 shadow-md">
                  <DatePickerCalendar
                    date={dateTime}
                    onDateChange={(newDateTime) => setDateTime(newDateTime!)}
                    minimumDate={props.minDate || undefined}
                    maximumDate={props.maxDate || undefined}
                    locale={enUS}
                  />
                  <div className="flex gap-2">
                    <Input
                      type="number"
                      placeholder="hh"
                      min={0}
                      max={11}
                      value={hours}
                      onChange={(e) => setHours(e.target.value)}
                      name="hours"
                      label=""
                    />
                    <Input
                      type="number"
                      min={0}
                      max={59}
                      placeholder="mm"
                      value={minutes}
                      onChange={(e) => setMinutes(e.target.value)}
                      name="minutes"
                      label=""
                    />
                    <SelectInput
                      options={[
                        {
                          label: "AM",
                          value: "AM",
                          disabled: false,
                        },
                        {
                          label: "PM",
                          value: "PM",
                          disabled: false,
                        },
                      ]}
                      label={""}
                      placeholder={""}
                      selected={meridiem}
                      required
                      onChange={(selected) => setMeridiem(selected)}
                    />
                  </div>
                  <div className="items-right mt-2 flex flex-row-reverse border-t pt-2">
                    <Button
                      type="button"
                      size="xs"
                      onClick={() => {
                        onChange();
                        close();
                      }}
                    >
                      Done
                    </Button>
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </Popover>
      )}
    </div>
  );
}

export default DateInput;
