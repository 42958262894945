import { z } from "zod";

export const customFieldSchema = z.object({
  type: z.enum(["TEXT", "NUMBER", "DATE", "BOOLEAN", "DROPDOWN"]),
  name: z.string({ required_error: "Name is required." }),
  helpText: z.string().optional(),
  defaultValue: z.string().optional(),
  isRequired: z.boolean().default(false),
  isVisibleMobile: z.boolean().default(false),
  isVisibleOrder: z.boolean().default(false),
  isMulti: z.boolean().default(false),
  isVisibleAddressBook: z.boolean().default(false),
  isActive: z.boolean().default(true),
  defaultValues: z.string().optional(),
});
