import { useState } from "react";

import { Button, Icon, Modal } from "@web-app/components";

export default function DeleteModal({
  onClose,
  onDelete,
}: {
  onClose: () => void;
  onDelete: () => Promise<void>;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = async () => {
    try {
      setIsLoading(true);
      await onDelete();
      setIsLoading(false);
      onClose();
    } catch (err) {
      setIsLoading(false);
    }
  };
  return (
    <Modal
      title={
        <>
          <Icon name="fasCircleInfo" className=" text-red-600 mr-2" />
          Delete Orders
        </>
      }
      isOpen={true}
      onClose={onClose}
      width={400}
      actionsAlign="end"
      actions={[
        <Button
          type="button"
          isLoading={isLoading}
          onClick={onConfirm}
          className="bg-red-600 hover:bg-red-700 text-white"
        >
          Yes, Delete
        </Button>,
      ]}
    >
      <p className="text-sm font-normal leading-5">
        Are you sure you want to delete these orders?
      </p>
    </Modal>
  );
}
