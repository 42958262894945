import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { format } from "date-fns";

import { useGetTrackingOrder } from "@web-app/api";
import config from "@web-app/config";

import Route from "../common/DispatchMap/Route";
import { libraries, options } from "../common/DispatchMap/utils";

import TrackingOrderTimeline from "./OrderTimeline";

export default function TrackingOrderPage({ orderId }: { orderId: string }) {
  const { data: order } = useGetTrackingOrder(orderId);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: config.GOOGLE_MAPS_API_KEY,
    libraries,
  });

  if (!order) {
    return <div>Order not found</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-[#efefef] h-screen overflow-auto">
      <div className="flex justify-center items-center py-4 ">
        <img
          className=""
          src="https://i.ibb.co/F5ZvrtK/logo.png"
          alt={""}
          width={80}
          height={50}
        />
      </div>
      <div className="text-xl font-bold leading-6 text-gray-900 text-center">
        <h1 className="text-gray-700">Order Tracking</h1>
      </div>
      <div className="w-full flex justify-center mt-10">
        <div className="bg-white w-full max-w-[700px] p-4 rounded-md">
          <div className="font-bold text-gray-700">{order.displayId}</div>
          <div className="mt-5 flex gap-8">
            <div>
              <div className="text-gray-500 text-xs">Order ID</div>
              <div className="text-gray-700 text-xs font-semibold">
                {order.id}
              </div>
            </div>
            <div>
              <div className="text-gray-500 text-xs">Status</div>
              <div className="text-gray-700 text-xs font-semibold">
                {order.statusText}
              </div>
            </div>
            <div>
              <div className="text-gray-500 text-xs">Order Type</div>
              <div className="text-gray-700 text-xs font-semibold">
                {order.deliveryTypeText}
              </div>
            </div>
            <div>
              <div className="text-gray-500 text-xs">Pickup Address</div>
              <div className="text-gray-700 text-xs font-semibold">
                {order.pickup.address}
              </div>
            </div>
          </div>
          <div className="mt-5 flex gap-8">
            <div>
              <div className="text-gray-500 text-xs">Drop-off Address</div>
              <div className="text-gray-700 text-xs font-semibold">
                {order.dropoff.address}
              </div>
            </div>
            <div>
              <div className="text-gray-500 text-xs">Driver Name</div>
              <div className="text-gray-700 text-xs font-semibold">
                {order.assignee?.name ?? "Unassigned"}
              </div>
            </div>
            {order?.eta?.dateTime && (
              <div>
                <div className="text-gray-500 text-xs">ETA</div>
                <div className="text-gray-700 text-xs font-semibold">
                  {format(new Date(order.eta.dateTime), "MMM dd, yyyy HH:mm")}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <GoogleMap
          mapContainerClassName="h-[500px] max-w-[700px] mx-auto mt-10 rounded-md"
          zoom={12}
          center={{
            lat: order.pickupLocation?.coordinates[1],
            lng: order.pickupLocation?.coordinates[0],
          }}
          options={options}
        >
          <MarkerF
            position={{
              lat: order.pickupLocation?.coordinates[1],
              lng: order.pickupLocation?.coordinates[0],
            }}
            icon={{
              url: `/images/markers-updated/${order.status}.png`,
              scaledSize: {
                width: 30,
                height: 30,
                equals() {
                  return true;
                },
              },
            }}
          />
          <MarkerF
            position={{
              lat: order.dropoffLocation?.coordinates[1],
              lng: order.dropoffLocation?.coordinates[0],
            }}
            icon={{
              url: `/images/markers-updated/${order.status}.png`,
              scaledSize: {
                width: 30,
                height: 30,
                equals() {
                  return true;
                },
              },
            }}
          />
          {order.assigneeLocation?.latitude && (
            <MarkerF
              position={{
                lat: order.assigneeLocation?.latitude,
                lng: order.assigneeLocation?.longitude,
              }}
              icon={{
                url: `/images/markers-updated/driver_${order.assignee.dutyStatus}.png`,
                scaledSize: {
                  width: 30,
                  height: 30,
                  equals() {
                    return true;
                  },
                },
              }}
            />
          )}
          <Route
            order={order}
            assignee={order.assignee}
            isHovered={true}
            setHoveredPolylineElementId={() => {}}
          />
        </GoogleMap>
      </div>
      <div className="max-w-[700px] mx-auto mt-10">
        <div className="bg-white p-3 rounded-md">
          <TrackingOrderTimeline order={order} timeline={order.orderTimeline} />
        </div>
      </div>
    </div>
  );
}
