import clsx from "clsx";

function HalfWidthGridColumn({
  classes,
  children,
}: {
  classes?: string;
  children: React.ReactNode;
}) {
  return <div className={clsx("col-span-6 relative", classes)}>{children}</div>;
}

export default HalfWidthGridColumn;
