import clsx from "clsx";
import { useState } from "react";
import toast from "react-hot-toast";

import { useBulkOrderMutation } from "@web-app/api";
import { Button } from "@web-app/components";

import AssignModal from "./ActionModals/AssignModal";
import DeleteModal from "./ActionModals/DeleteModal";
import SendToModal from "./ActionModals/SendToModal";

export default function BulkActionsTooltip({
  className,
  selectedOrderIds,
  onSuccess,
  onUnselectAllOrders,
}: {
  className?: string;
  selectedOrderIds: string[];
  onSuccess: () => void;
  onUnselectAllOrders: () => void;
}) {
  const [bulkActionMode, setBulkActionMode] = useState<
    "assign" | "send" | "delete" | null
  >(null);

  const { update: bulkUpdateOrders, remove: bulkDeleteOrders } =
    useBulkOrderMutation();

  const onAssign = async (selectedAssigneeId: string) => {
    await toast.promise(
      bulkUpdateOrders.mutateAsync({
        orderIds: selectedOrderIds,
        updatedValues: {
          assigneeId: selectedAssigneeId,
        },
      }),
      {
        loading: "Bulk assigning orders ...",
        success: "Successfully assigned orders",
        error: "Failed to assign orders. Please try again or contact support",
      },
    );
    onSuccess();
    onCloseBulkActionModal();
  };

  const onSend = async (selectedWorkspaceId: string) => {
    await toast.promise(
      bulkUpdateOrders.mutateAsync({
        orderIds: selectedOrderIds,
        updatedValues: {
          connectedWorkspaceId: selectedWorkspaceId,
        },
      }),
      {
        loading: "Bulk sending orders ...",
        success: "Successfully sent orders",
        error: "Failed to send orders. Please try again or contact support",
      },
    );

    onSuccess();
    onCloseBulkActionModal();
  };

  const onDelete = async () => {
    await toast.promise(
      bulkDeleteOrders.mutateAsync({
        orderIds: selectedOrderIds,
      }),
      {
        loading: "Bulk deleting orders ...",
        success: "Successfully deleted orders",
        error: "Failed to delete orders. Please try again or contact support",
      },
    );
    onSuccess();
    onCloseBulkActionModal();
  };

  const onCloseBulkActionModal = () => {
    setBulkActionMode(null);
  };

  return (
    <>
      <div
        className={clsx(
          "flex w-[620px] h-[40px] p-2.5 px-2.5 justify-between items-center rounded-lg bg-gray-200",
          className,
        )}
      >
        <div className="flex pl-2.5 h-[25px] justify-center items-center gap-2 rounded-md bg-gray-700">
          <span className="text-gray-50">
            {selectedOrderIds.length} orders selected
          </span>
          <Button
            className="bg-gray-700 hover:bg-gray-500 h-full cursor-pointer"
            icon="xMark"
            onClick={() => {
              console.log("clicked");
              onUnselectAllOrders();
            }}
          ></Button>
        </div>
        <div className="flex items-center gap-2">
          <Button
            className="!h-[28px]"
            appearance="secondary"
            icon="user"
            onClick={() => setBulkActionMode("assign")}
          >
            Assign
          </Button>
          <Button
            className="!h-[28px]"
            appearance="secondary"
            icon="arrowTurnDownRight"
            onClick={() => setBulkActionMode("send")}
          >
            Send To
          </Button>
          <Button
            className="!h-[28px]"
            appearance="secondary"
            icon="fasTrash"
            onClick={() => setBulkActionMode("delete")}
          >
            Delete
          </Button>
        </div>
      </div>
      {bulkActionMode === "delete" && (
        <DeleteModal
          onDelete={onDelete}
          onClose={onCloseBulkActionModal}
        ></DeleteModal>
      )}
      {bulkActionMode === "assign" && (
        <AssignModal
          onAssign={onAssign}
          onClose={onCloseBulkActionModal}
        ></AssignModal>
      )}
      {bulkActionMode === "send" && (
        <SendToModal
          onSend={onSend}
          onClose={onCloseBulkActionModal}
        ></SendToModal>
      )}
    </>
  );
}
